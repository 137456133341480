.oos-dynamic-icon {
	display: inline-block;
	background-repeat: no-repeat !important;
	background-size: 100% auto !important;
	&:hover {
		cursor: default;
	}
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
	&.small {
		width: 60px;
		height: 60px;

		animation-duration: 0.3s;
		animation-timing-function: steps(16);
		&.active {
			animation-name: oos-dynamic-icon-60-16-active;
		}
		&.leave {
			animation-name: oos-dynamic-icon-60-16-leave;
		}
	}
	// Sprites.
	// Home solution category.
	&.intelligent-campus-category {
		background-image: url("../../images/dynamicIcon/solution/smart_campus.webp");
	}
	&.intelligent-police-category {
		background-image: url("../../images/dynamicIcon/solution/smart_police.webp");
	}
	&.intelligent-community-category {
		background-image: url("../../images/dynamicIcon/solution/smart_community.webp");
	}
	&.intelligent-scenic-spot-category {
		background-image: url("../../images/dynamicIcon/solution/smart_scenic_spot.webp");
	}
	&.emergency-category {
		background-image: url("../../images/dynamicIcon/solution/emergency_industry.webp");
	}
	&.general-category {
		background-image: url("../../images/dynamicIcon/solution/general_industry.webp");
	}
	// Product.
	// UniIO.
	&.deduplication {
		background-image: url("../../images/dynamicIcon/product/uniio/deduplication.webp");
	}
	&.qos {
		background-image: url("../../images/dynamicIcon/product/uniio/qos.webp");
	}
	&.scale {
		background-image: url("../../images/dynamicIcon/product/uniio/scale.webp");
	}
	&.remote-replication {
		background-image: url("../../images/dynamicIcon/product/uniio/remote_replication.webp");
	}
	&.performance-uniio {
		 background-image: url("../../images/dynamicIcon/product/uniio/performance.webp");
    }
	&.low-carbon {
		background-image: url("../../images/dynamicIcon/product/uniio/low_carbon.webp");
	}
	&.localization {
		background-image: url("../../images/dynamicIcon/product/uniio/localization.webp");
	}
	&.cloud {
		background-image: url("../../images/dynamicIcon/product/uniio/cloud.webp");
	}
	// OrcaFS.
	&.performance-orcafs {
		background-image: url("../../images/dynamicIcon/product/orcafs/performance.webp");
	}
	&.protocol {
		background-image: url("../../images/dynamicIcon/product/orcafs/protocol.webp");
	}
	&.verification {
		background-image: url("../../images/dynamicIcon/product/orcafs/verification.webp");
	}
	&.orcaond {
		background-image: url("../../images/dynamicIcon/product/orcafs/orcaond.webp");
	}
	&.capacity {
		background-image: url("../../images/dynamicIcon/product/orcafs/capacity.webp");
	}
	&.high-reliability-orcafs {
		background-image: url("../../images/dynamicIcon/product/orcafs/high_reliability.webp");
	}
	// OrcaBD.
	&.fusion-architecture {
		background-image: url("../../images/dynamicIcon/product/orcabd/fusion_architecture.webp");
	}
	&.intelligent-layering {
		background-image: url("../../images/dynamicIcon/product/orcabd/intelligent_layering.webp");
	}
	&.low-cost {
		background-image: url("../../images/dynamicIcon/product/orcabd/low_cost.webp");
	}
	&.easy-to-expand-orcabd {
		background-image: url("../../images/dynamicIcon/product/orcabd/easy_to_expand.webp");
	}
	&.high-reliability-orcabd {
		background-image: url("../../images/dynamicIcon/product/orcabd/high_reliability.webp");
	}
	&.easy-to-use-orcabd {
		background-image: url("../../images/dynamicIcon/product/orcabd/easy_to_use.webp");
	}
	// Solution.
	// Storage solution.
	// General category.
	// Data backup.
	&.easy-to-use-data-backup {
		background-image: url("../../images/dynamicIcon/solution/dataBackup/easy_to_use.webp");
	}
	// Big data.
	&.easy-to-expand-big-data {
		background-image: url("../../images/dynamicIcon/solution/bigData/easy_to_expand.webp");
	}
	&.high-reliability-big-data {
		background-image: url("../../images/dynamicIcon/solution/bigData/high_reliability.webp");
	}
	&.data-transfer {
		background-image: url("../../images/dynamicIcon/solution/bigData/data_transfer.webp");
	}
	&.easy-to-manage-big-data {
		background-image: url("../../images/dynamicIcon/solution/bigData/easy_to_manage.webp");
	}
	// High performance computing.

	// Cloud computing private cloud.

	// Cloud native container storage.

	// Data backup.

	// Industry category.
	// New energy vehicle.

	// Intelligent campus.
	&.big-capacity {
		background-image: url("../../images/dynamicIcon/solution/intelligentCampus/big_capacity.webp");
	}
	&.performance-intelligent-campus {
	    background-image: url("../../images/dynamicIcon/solution/intelligentCampus/performance.webp");
	}
	&.high-reliability-intelligent-campus {
		background-image: url("../../images/dynamicIcon/solution/intelligentCampus/high_reliability.webp");
	}
	// Intelligent security.
	&.easy-to-expand-intelligent-security {
		background-image: url("../../images/dynamicIcon/solution/intelligentSecurity/easy_to_expand.webp");
	}
	// AI.
	&.performance-ai {
		background-image: url("../../images/dynamicIcon/solution/ai/performance.webp");
	}
	&.high-reliability-ai {
		background-image: url("../../images/dynamicIcon/solution/ai/high_reliability.webp");
	}
	// IoT solution.
	// Intelligent Police category.
	// Bright project.

	// Major event security

	// Intelligent community category category.
	// Intelligent community.

	// Intelligent community security.

	// Intelligent elevator.

	// HighAltitude throw object.

	// Intelligent park category.
	// Intelligent park.

	// Intelligent scenic spot category.
	// Intelligent scenicSpot.

	// All area tourism.

	// Carbon neutrality category.
	// Carbon neutrality.

	// Intelligent garden category.
	// Intelligent garden.

	// Emergency category.
	// EmergencyCommand.

	// Safety production emergency.

	// General category.
	// IoT platform.

}
