.oos-honor {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-normal-banner-core();
	}
	.section-nav {
		.oos-section-nav-core();
	}
	.section-title {
		.oos-section-title-core();
	}
	.honor-box {
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		width: @content-width-1200;
		.honor-item {
			margin-top: 50px;
			.img-wrap {
				padding: 30px 20px;
				display: table-cell;
				text-align: center;
				vertical-align: middle;
				width: 362px;  // Left and right border will occupy 2px.
				height: 482px;
				border: 1px solid #d8d7d7;
				border-radius: 5px;
				&:hover {
					border: 1px solid @white;
					.oos-multiple-box-shadow();
				}
				.img {
					display: table-cell;
					margin: 0 auto;
					vertical-align: middle;
					max-width: 320px;
					max-height: 420px;
					cursor: pointer;
					user-select: none;
				}
			}
			.name {
				height: 45px;
				line-height: 45px;
				text-align: center;
				font-size: @m-font;
				color: #3e3e3e;
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			width: @content-width-1000;
			.honor-item {
				.img-wrap {
					padding: 15px;
					width: 324px; // Left and right border will occupy 2px.
					height: 452px;
				}
				.name {
					width: 324px;
					font-size: @s-font;
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			width: 100%;
			&:nth-last-child(1) {
				margin-bottom: 60px;
			}
			margin-top: 30px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.honor-item {
				margin-top: 30px;
				&:not(:nth-child(1)) {
					margin-top: 30px;
				}
				.img-wrap {
					padding: 15px;
					width: 362px; // Left and right border will occupy 2px.
					height: 482px;
				}
				.name {
					width: 362px;
					font-size: @s-font;
				}
			}
		}

		@media screen and (max-width: 450px) {
			&:nth-last-child(1) {
				margin-bottom: 30px;
			}
			margin-top: 20px;
			.honor-item {
				margin-top: 20px;
				.img-wrap {
					padding: 10px;
					width: 100%;
					min-width: 322px;
					height: 444px;
				}
				.name {
					width: 100%;
					min-width: 322px;
					font-size: @s-font;
				}
			}
		}
	}
}
