.oos-page-content-bottom-margin > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-page-content-bottom-margin > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-page-content-bottom-margin > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
/*
.oos-document-excel-table-content-column-core () {
	&:nth-child(1),
	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(7) {
		width: 10%;
	}
	&:nth-child(2) {
		width: 13%;
	}
	&:nth-child(5) {
		width: 7%;
	}
	&:nth-child(6) {
		width: 30%;
	}
}
*/
.oos-product-banner-core {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-product-banner-core .banner-content {
  padding: 0 calc((100% - 1600px) / 2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}
.oos-product-banner-core .banner-content .banner-title {
  position: relative;
  padding-top: 120px;
  width: 750px;
  font-weight: 300;
  color: #d5def2;
  z-index: 1;
}
.oos-product-banner-core .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-product-banner-core .banner-content .banner-title .sub-title {
  margin-top: 15px;
  line-height: 24px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-product-banner-core .banner-content .product-banner-anim-base-img {
  top: -40px;
  right: 0;
}
@media screen and (min-width: 1921px) {
  .oos-product-banner-core .banner-content {
    position: relative;
    margin: 0 auto;
    padding: unset;
    width: 1600px;
  }
}
@media screen and (max-width: 1800px) {
  .oos-product-banner-core .banner-content {
    padding: 0 calc((100% - 1600px) / 2);
  }
}
@media screen and (max-width: 1700px) {
  .oos-product-banner-core .banner-content {
    padding: 0 calc((100% - 1400px) / 2);
  }
  .oos-product-banner-core .banner-content .banner-title {
    width: 700px;
  }
  .oos-product-banner-core .banner-content .banner-title .title {
    font-size: 40px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-product-banner-core .banner-content {
    padding: 0 calc((100% - 1200px) / 2);
  }
  .oos-product-banner-core .banner-content .banner-title {
    padding-top: 80px;
    width: 500px;
  }
  .oos-product-banner-core .banner-content .banner-title .title {
    font-size: 28px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-product-banner-core .banner-content {
    padding: 0 calc((100% - 1000px) / 2);
  }
  .oos-product-banner-core .banner-content .banner-title {
    padding-top: 60px;
    width: 450px;
  }
  .oos-product-banner-core .banner-content .banner-title .title {
    font-size: 22px;
  }
}
@media screen and (max-width: 768px) {
  .oos-product-banner-core {
    height: auto;
  }
  .oos-product-banner-core .banner-content {
    padding-top: 30px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-product-banner-core .banner-content .banner-title {
    padding: 10px 20px 30px;
    width: 450px;
  }
  .oos-product-banner-core .banner-content .banner-title .sub-title {
    font-size: 13px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-product-banner-core .banner-content .banner-title .button-box {
    justify-content: center;
  }
  .oos-product-banner-core .banner-content .product-banner-anim-base-img {
    display: none;
  }
  .oos-product-banner-core .banner-content .product-banner-static-img-pad-ph {
    display: block;
  }
}
@media screen and (max-width: 450px) {
  .oos-product-banner-core .banner-content {
    padding-top: 0;
    display: block;
    width: 100%;
    overflow: hidden;
  }
  .oos-product-banner-core .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-product-banner-core .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-product-banner-core .banner-content .banner-title .sub-title {
    font-size: 12px;
  }
  .oos-product-banner-core .banner-content .banner-title .button-box {
    justify-content: center;
  }
  .oos-product-banner-core .banner-content .product-banner-anim-base-img {
    display: none;
  }
  .oos-product-banner-core .banner-content .product-banner-static-img-pad-ph {
    float: right;
  }
}
.oos-normal-banner-core {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-normal-banner-core .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-normal-banner-core .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-normal-banner-core .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-normal-banner-core .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-normal-banner-core .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-normal-banner-core .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-normal-banner-core .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-normal-banner-core .banner-content {
    width: 1400px;
  }
  .oos-normal-banner-core .banner-content .banner-title {
    width: 600px;
  }
  .oos-normal-banner-core .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-normal-banner-core .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-normal-banner-core .banner-content {
    width: 1200px;
  }
  .oos-normal-banner-core .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-normal-banner-core .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-normal-banner-core .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-normal-banner-core .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-normal-banner-core .banner-content {
    width: 1000px;
  }
  .oos-normal-banner-core .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-normal-banner-core .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-normal-banner-core {
    height: auto;
  }
  .oos-normal-banner-core .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-normal-banner-core .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-normal-banner-core .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-normal-banner-core .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-normal-banner-core .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-normal-banner-core .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-normal-banner-core .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-normal-banner-core .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-normal-banner-core .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-normal-banner-core .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.solution-banner-static-img.big-data {
  background-image: url("../images/solution/bigData/banner_static_img.webp");
}
.solution-banner-static-img.high-performance-computing {
  background-image: url("../images/solution/highPerformanceComputing/banner_static_img.webp");
}
.solution-banner-static-img.cloud-computing-private-cloud {
  background-image: url("../images/solution/cloudComputingPrivateCloud/banner_static_img.webp");
}
.solution-banner-static-img.cloud-native-container-storage {
  background-image: url("../images/solution/cloudNativeContainerStorage/banner_static_img.webp");
}
.solution-banner-static-img.data-backup {
  background-image: url("../images/solution/dataBackup/banner_static_img.webp");
}
.solution-banner-static-img.new-energy-vehicle {
  background-image: url("../images/solution/newEnergyVehicle/banner_static_img.webp");
}
.solution-banner-static-img.intelligent-campus {
  background-image: url("../images/solution/intelligentCampus/banner_static_img.webp");
}
.solution-banner-static-img.intelligent-security {
  background-image: url("../images/solution/intelligentSecurity/banner_static_img.webp");
}
.solution-banner-static-img.ai {
  background-image: url("../images/solution/ai/banner_static_img.webp");
}
.solution-banner-static-img.intelligent-campus {
  background-image: url("../images/solution/intelligentCampus/banner_static_img.webp");
}
.solution-banner-static-img.bright-project {
  background-image: url("../images/solution/brightProject/banner_static_img.webp");
}
.solution-banner-static-img.major-event-security {
  background-image: url("../images/solution/majorEventSecurity/banner_static_img.webp");
}
.solution-banner-static-img.intelligent-community {
  background-image: url("../images/solution/intelligentCommunity/banner_static_img.webp");
}
.solution-banner-static-img.intelligent-community-security {
  background-image: url("../images/solution/intelligentCommunitySecurity/banner_static_img.webp");
}
.solution-banner-static-img.intelligent-elevator {
  background-image: url("../images/solution/intelligentElevator/banner_static_img.webp");
}
.solution-banner-static-img.high-altitude-throw-object {
  background-image: url("../images/solution/highAltitudeThrowObject/banner_static_img.webp");
}
.solution-banner-static-img.intelligent-park {
  background-image: url("../images/solution/intelligentPark/banner_static_img.webp");
}
.solution-banner-static-img.intelligent-scenic-spot {
  background-image: url("../images/solution/intelligentScenicSpot/banner_static_img.webp");
}
.solution-banner-static-img.all-area-tourism {
  background-image: url("../images/solution/allAreaTourism/banner_static_img.webp");
}
.solution-banner-static-img.carbon-neutrality {
  background-image: url("../images/solution/carbonNeutrality/banner_static_img.webp");
}
.solution-banner-static-img.intelligent-garden {
  background-image: url("../images/solution/intelligentGarden/banner_static_img.webp");
}
.solution-banner-static-img.emergency-command {
  background-image: url("../images/solution/emergencyCommand/banner_static_img.webp");
}
.solution-banner-static-img.safety-production-emergency {
  background-image: url("../images/solution/safetyProductionEmergency/banner_static_img.webp");
}
.solution-banner-static-img.iot-platform {
  background-image: url("../images/solution/iotPlatform/banner_static_img.webp");
}
@keyframes oos-modal-mask-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes oos-top-bar-drop-down-menu-fade-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes oos-pad-and-ph-close-button-motion {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes oos-carousel-switch-fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes oos-carousel-pagination-progress {
  0% {
    width: 0;
  }
  100% {
    width: 99%;
  }
}
@keyframes oos-dynamic-icon-60-16-active {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -960px;
  }
}
@keyframes oos-dynamic-icon-60-16-leave {
  0% {
    background-position: 0 -960px;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes oos-product-banner-light-bar-thumb-left-right-motion {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(20px);
  }
}
@keyframes oos-product-banner-light-bar-thumb-right-left-motion {
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes oos-product-banner-light-ray-left-motion {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-140px);
  }
}
@keyframes oos-product-banner-light-ray-right-motion {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-223px);
  }
}
@keyframes oos-product-banner-curtain-fade-in-and-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    transform: translateY(2px);
    opacity: 0.4;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(2px);
    opacity: 0.4;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes oos-product-banner-conveyor-belt-stuff-motion {
  0% {
    transform: translateX(0) translateY(0);
  }
  100% {
    transform: translateX(-153px) translateY(-74px);
  }
}
@keyframes oos-product-banner-application-motion {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes oos-normal-banner-float-img-motion {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes oos-normal-banner-curtain-fade-in-and-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    transform: translateY(2px);
    opacity: 0.4;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(2px);
    opacity: 0.4;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes oos-normal-banner-curtain-window-fade-in-and-out {
  0% {
    opacity: 0.2;
    background-image: url("../images/normalBannerAnim/light_curtain_window_blue.webp");
  }
  20% {
    opacity: 0.4;
    background-image: url("../images/normalBannerAnim/light_curtain_window_blue.webp");
  }
  40% {
    opacity: 0.6;
    background-image: url("../images/normalBannerAnim/light_curtain_window_white.webp");
  }
  60% {
    opacity: 0.8;
    background-image: url("../images/normalBannerAnim/light_curtain_window_blue.webp");
  }
  80% {
    opacity: 0.6;
    background-image: url("../images/normalBannerAnim/light_curtain_window_white.webp");
  }
  100% {
    opacity: 0.2;
    background-image: url("../images/normalBannerAnim/light_curtain_window_blue.webp");
  }
}
@keyframes oos-normal-banner-light-point-fade-in-and-out {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes oos-normal-banner-light-bar-thumb-left-right-motion {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(20px);
  }
}
@keyframes oos-normal-banner-light-bar-thumb-right-left-motion {
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes oos-normal-banner-light-ray-up-motion {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-120px);
  }
}
@keyframes oos-normal-banner-light-ray-bottom-motion {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-145px);
  }
}
@keyframes oos-normal-banner-bright-project-car-a-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(87px) translateY(44px);
  }
  70% {
    transform: translateX(87px) translateY(44px);
    opacity: 1;
  }
  80% {
    transform: translateX(87px) translateY(44px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-bright-project-car-b-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(55px) translateY(-27px);
  }
  70% {
    transform: translateX(55px) translateY(-27px);
    opacity: 1;
  }
  80% {
    transform: translateX(55px) translateY(-27px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-intelligent-elevator-wifi-motion {
  50% {
    transform: translateY(-10px);
  }
}
@keyframes oos-normal-banner-intelligent-elevator-car-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  70% {
    transform: translateY(-90px);
    opacity: 1;
  }
  80% {
    transform: translateY(-90px);
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes oos-normal-banner-intelligent-elevator-door-left-motion {
  0% {
    transform: skewY(25deg) translateX(0);
  }
  15% {
    transform: skewY(25deg) translateX(-12px);
  }
  28% {
    transform: skewY(25deg) translateX(-12px);
  }
  41% {
    transform: skewY(25deg) translateX(0);
  }
  100% {
    transform: skewY(25deg) translateX(0);
  }
}
@keyframes oos-normal-banner-intelligent-elevator-door-right-motion {
  0% {
    transform: skewY(25deg) translateX(0);
  }
  15% {
    transform: skewY(25deg) translateX(12px);
  }
  28% {
    transform: skewY(25deg) translateX(12px);
  }
  41% {
    transform: skewY(25deg) translateX(0);
  }
  100% {
    transform: skewY(25deg) translateX(0);
  }
}
@keyframes oos-normal-banner-intelligent-elevator-man-left-fade-in-out {
  0% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-intelligent-elevator-man-right-fade-in-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-intelligent-garden-cloud-motion {
  50% {
    transform: translateX(10px) translateY(-10px);
  }
}
@keyframes oos-normal-banner-intelligent-garden-cable-car-motion {
  50% {
    transform: translateX(46px) translateY(41px);
  }
}
@keyframes oos-normal-banner-intelligent-garden-car-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(79px) translateY(38px);
  }
  70% {
    transform: translateX(79px) translateY(38px);
    opacity: 1;
  }
  80% {
    transform: translateX(79px) translateY(38px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-intelligent-community-car-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(76px) translateY(38px);
  }
  70% {
    transform: translateX(76px) translateY(38px);
    opacity: 1;
  }
  80% {
    transform: translateX(76px) translateY(38px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-intelligent-community-security-cloud-motion {
  50% {
    transform: translateX(-15px) translateY(15px);
  }
}
@keyframes oos-normal-banner-building-light-blink {
  50% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-intelligent-community-security-bicycle-man-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(91px) translateY(44px);
  }
  70% {
    transform: translateX(91px) translateY(44px);
    opacity: 1;
  }
  75% {
    transform: translateX(91px) translateY(44px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-intelligent-community-security-balloon-motion {
  50% {
    transform: translateY(-10px);
  }
}
@keyframes oos-normal-banner-intelligent-community-security-man-arm-motion {
  50% {
    transform: translateY(2px);
  }
}
@keyframes oos-normal-banner-ball-man-arm-motion {
  50% {
    transform: translateY(1px);
  }
}
@keyframes oos-normal-banner-ball-motion-x {
  50% {
    transform: translateX(43px);
  }
}
@keyframes oos-normal-banner-ball-motion-y {
  50% {
    transform: translateY(21px);
  }
}
@keyframes oos-normal-banner-intelligent-community-security-bus-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(-85px) translateY(-41px);
  }
  70% {
    transform: translateX(-85px) translateY(-41px);
    opacity: 1;
  }
  80% {
    transform: translateX(-85px) translateY(-41px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-high-altitude-throw-object-cloud-motion {
  50% {
    transform: translateX(10px) translateY(10px);
  }
}
@keyframes oos-normal-banner-high-altitude-throw-object-car-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(-85px) translateY(-41px);
  }
  70% {
    transform: translateX(-85px) translateY(-41px);
    opacity: 1;
  }
  80% {
    transform: translateX(-85px) translateY(-41px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-high-altitude-throw-object-bicycle-man-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(68px) translateY(32px);
  }
  70% {
    transform: translateX(68px) translateY(32px);
    opacity: 1;
  }
  75% {
    transform: translateX(68px) translateY(32px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-all-area-tourism-z-cloud-motion {
  0% {
    opacity: 0;
  }
  30% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(-5px);
    opacity: 1;
  }
  70% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-all-area-tourism-bird-motion {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateX(-80px) translateY(65px);
    opacity: 1;
  }
  80% {
    transform: translateX(-190px) translateY(35px);
    opacity: 1;
  }
  85% {
    transform: translateX(-190px) translateY(35px);
    opacity: 1;
  }
  87% {
    transform: translateX(-190px) translateY(35px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-all-area-tourism-bird-wing-motion {
  0% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  2.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  7.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  10% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  12.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  15% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  17.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  20% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  22.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  25% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  27.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  30% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  32.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  35% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  37.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  40% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  42.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  45% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  47.5% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
  }
  50% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  }
  51% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_c.webp");
  }
  100% {
    background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_c.webp");
  }
}
@keyframes oos-normal-intelligent-park-car-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(-90px) translateY(-44px);
  }
  70% {
    transform: translateX(-90px) translateY(-44px);
    opacity: 1;
  }
  80% {
    transform: translateX(-90px) translateY(-44px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-intelligent-scenic-spot-cloud-motion {
  50% {
    transform: translateX(10px) translateY(-10px);
  }
}
@keyframes oos-normal-banner-intelligent-scenic-spot-wave-motion {
  50% {
    transform: translateX(10px) translateY(5px);
  }
}
@keyframes oos-normal-banner-intelligent-scenic-spot-boat-motion {
  0% {
    opacity: 1;
  }
  75% {
    transform: translateX(66px) translateY(36px);
    opacity: 1;
  }
  80% {
    transform: translateX(66px) translateY(36px);
    opacity: 1;
  }
  90% {
    transform: translateX(66px) translateY(36px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-intelligent-scenic-spot-sailboat-man-motion {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 0;
  }
  19% {
    opacity: 0;
  }
  20% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  95% {
    transform: translateX(83px) translateY(40.5px);
    opacity: 1;
  }
  97.5% {
    transform: translateX(83px) translateY(40.5px);
    opacity: 1;
  }
  100% {
    transform: translateX(83px) translateY(40.5px);
    opacity: 0;
  }
}
@keyframes oos-normal-banner-carbon-neutrality-car-motion {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(-87px) translateY(-42px);
  }
  70% {
    transform: translateX(-87px) translateY(-42px);
    opacity: 1;
  }
  80% {
    transform: translateX(-87px) translateY(-42px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes oos-normal-banner-carbon-neutrality-smoke-a-motion {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  29% {
    transform: translateX(0);
    opacity: 0;
  }
  30% {
    transform: translateX(1px);
    opacity: 1;
  }
  34% {
    transform: translateX(2px);
    opacity: 1;
  }
  38% {
    transform: translateX(3px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 0;
  }
}
@keyframes oos-normal-banner-carbon-neutrality-smoke-b-motion {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  33% {
    transform: translateX(0);
    opacity: 0;
  }
  34% {
    transform: translateX(1px);
    opacity: 0;
  }
  38% {
    transform: translateX(2px);
    opacity: 1;
  }
  42% {
    transform: translateX(3px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 0;
  }
}
@keyframes oos-normal-banner-carbon-neutrality-smoke-c-motion {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  37% {
    transform: translateX(0);
    opacity: 0;
  }
  38% {
    transform: translateX(1px);
    opacity: 0;
  }
  42% {
    transform: translateX(2px);
    opacity: 1;
  }
  46% {
    transform: translateX(3px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 0;
  }
}
@keyframes oos-normal-banner-carbon-neutrality-smoke-d-motion {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  41% {
    transform: translateX(0);
    opacity: 0;
  }
  42% {
    transform: translateX(1px);
    opacity: 0;
  }
  46% {
    transform: translateX(2px);
    opacity: 1;
  }
  50% {
    transform: translateX(3px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 0;
  }
}
@keyframes oos-normal-banner-carbon-neutrality-smoke-e-motion {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  45% {
    transform: translateX(0);
    opacity: 0;
  }
  46% {
    transform: translateX(1px);
    opacity: 0;
  }
  50% {
    transform: translateX(2px);
    opacity: 1;
  }
  54% {
    transform: translateX(3px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 0;
  }
}
@keyframes oos-normal-banner-carbon-neutrality-smoke-f-motion {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  49% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(1px);
    opacity: 0;
  }
  54% {
    transform: translateX(2px);
    opacity: 1;
  }
  58% {
    transform: translateX(3px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 0;
  }
}
.oos-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  z-index: 5;
}
.oos-modal .oos-modal-content {
  position: relative;
  margin: 0 auto;
  padding: 0 50px 30px;
  background: #fff;
  border-radius: 5px;
  transition: top 0.1s ease-out;
}
.oos-modal .oos-modal-content .oos-modal-hide-button-box {
  position: relative;
  height: 40px;
  line-height: 40px;
  text-align: right;
}
.oos-modal .oos-modal-content .oos-modal-hide-button-box .oos-modal-hide-button {
  position: absolute;
  right: -45px;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.oos-modal .oos-modal-content .oos-modal-hide-button-box .oos-modal-hide-button::before,
.oos-modal .oos-modal-content .oos-modal-hide-button-box .oos-modal-hide-button::after {
  content: " ";
  display: inline-block;
  width: 2px;
  height: 12px;
  background-color: #888;
}
.oos-modal .oos-modal-content .oos-modal-hide-button-box .oos-modal-hide-button::before {
  transform: skewX(45deg);
}
.oos-modal .oos-modal-content .oos-modal-hide-button-box .oos-modal-hide-button::after {
  margin-left: -2px;
  transform: skewX(-45deg);
}
.oos-modal .oos-modal-content .oos-modal-hide-button-box .oos-modal-hide-button:hover::before,
.oos-modal .oos-modal-content .oos-modal-hide-button-box .oos-modal-hide-button:hover::after {
  background-color: #3e3e3e;
}
.oos-modal .oos-modal-content .oos-modal-title {
  padding-bottom: 40px;
  text-align: center;
  font-weight: 600;
  font-size: 38px;
  color: #2b3a58;
}
.oos-modal .oos-modal-content .oos-modal-form-content input {
  border: none;
  outline: none;
}
.oos-modal .oos-modal-content .oos-modal-form-content textarea {
  display: block;
  padding: 10px;
  width: 100%;
  height: 100%;
  border: 1px solid #d1d5db;
  border-radius: 5px;
  resize: none;
  outline: none;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item {
  margin-top: 22px;
  height: 40px;
  line-height: 38px;
  border: 1px solid #d1d5db;
  border-radius: 5px;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item:nth-child(1) {
  margin-top: 0;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item.large {
  height: 120px;
  line-height: 120px;
  border: none;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item.verification-code {
  display: inline-block;
  width: calc(100% - 120px - 10px);
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item.send-verify-code {
  display: inline-block;
  width: calc(100% - 90px);
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img {
  float: left;
  position: relative;
  width: 38px;
  height: 38px;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img::before {
  content: " ";
  position: absolute;
  top: 11px;
  right: 0;
  width: 1px;
  height: 18px;
  background-color: #dadada;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img.job {
  background-image: url("../images/modal/form/job.webp");
  background-size: 100% 100%;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img.fullname {
  background-image: url("../images/modal/form/fullname.webp");
  background-size: 100% 100%;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img.phone {
  background-image: url("../images/modal/form/phone.webp");
  background-size: 100% 100%;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img.email {
  background-image: url("../images/modal/form/email.webp");
  background-size: 100% 100%;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img.password {
  background-image: url("../images/modal/form/password.webp");
  background-size: 100% 100%;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img.verification-code {
  background-image: url("../images/modal/form/code.webp");
  background-size: 100% 100%;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img.resume {
  background-image: url("../images/modal/form/resume.webp");
  background-size: 100% 100%;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item .label-img.firm {
  background-image: url("../images/modal/form/firm.webp");
  background-size: 100% 100%;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item input {
  display: inline-block;
  vertical-align: middle;
  padding-left: 15px;
  width: calc(100% - 38px);
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item input[type="file"] {
  display: none;
}
.oos-modal .oos-modal-content .oos-modal-form-content .oos-modal-form-item input.fake-file-input {
  cursor: pointer;
}
.oos-modal .oos-modal-content .oos-modal-form-content .verification-code-img {
  float: right;
  margin-top: 22px;
  display: inline-block;
  border: 1px solid #d1d5db;
  border-radius: 5px;
  cursor: pointer;
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button {
  float: right;
  margin-top: 22px;
  display: inline-block;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #3e3e3e;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  border: 1px solid #d1d5db;
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-link-icon,
  .oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-download-icon,
  .oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-modal .oos-modal-content .oos-modal-form-content .send-verify-code-button.disabled {
  color: #888;
  cursor: not-allowed;
}
.oos-modal .oos-modal-content .oos-modal-form-content .confirm-service-agreement {
  margin-top: 12px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
}
.oos-modal .oos-modal-content .oos-modal-form-content .confirm-service-agreement input[type="checkbox"] {
  vertical-align: -1px;
}
.oos-modal .oos-modal-content .oos-modal-form-content .confirm-service-agreement label {
  user-select: none;
}
.oos-modal .oos-modal-content .oos-modal-form-content .confirm-service-agreement label .read-agreement {
  padding-left: 8px;
  color: #888;
}
.oos-modal .oos-modal-content .oos-modal-form-content .confirm-service-agreement label .agreement-name {
  padding-left: 2px;
  color: #608ff9;
  text-decoration: none;
  cursor: pointer;
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button {
  margin-top: 40px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  background-color: #2b3a58;
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-link-icon,
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-download-icon,
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-modal .oos-modal-content .oos-modal-form-content .submit-button.disabled {
  background-color: #888;
  cursor: not-allowed;
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action {
  margin-top: 10px;
  font-size: 12px;
  overflow: hidden;
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-tips {
  display: inline-block;
  color: #888;
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link {
  display: inline-block;
  padding: 10px;
  min-height: 30px;
  line-height: 30px;
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  color: 333333;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
  padding-left: 5px;
  color: #608ff9;
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-link-icon,
  .oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-download-icon,
  .oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-modal .oos-modal-content .oos-modal-form-switch-action .switch-action-link.forget-password {
  float: right;
  color: #ff7f00;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box {
  padding-top: 40px;
  text-align: center;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-img {
  margin: 0 auto;
  width: 140px;
  height: 80px;
  background-image: url("../images/modal/success_img.webp");
  background-size: 100% 100%;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-title {
  margin-top: 30px;
  font-size: 30px;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-desc {
  margin-top: 15px;
  line-height: 20px;
  font-size: 12px;
  color: #888;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button {
  margin: 35px auto 0;
  width: 140px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: #3e3e3e;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  border: 1px solid #a3a3a3;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-link-icon,
  .oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-download-icon,
  .oos-modal .oos-modal-content .oos-modal-success-tips-box .success-ok-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-modal .oos-modal-content .oos-certificate-picture-box {
  width: 100%;
}
.oos-modal .oos-modal-content .oos-certificate-picture-box img {
  display: block;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .oos-modal .oos-modal-content {
    padding: 0 20px 25px;
  }
  .oos-modal .oos-modal-content .oos-modal-hide-button-box .oos-modal-hide-button {
    right: -20px;
  }
  .oos-modal .oos-modal-content .oos-modal-title {
    padding-bottom: 30px;
    font-size: 28px;
  }
  .oos-modal .oos-modal-content .oos-modal-title::before,
  .oos-modal .oos-modal-content .oos-modal-title::after {
    vertical-align: 10px;
  }
  .oos-modal .oos-modal-content .oos-modal-form-content input,
  .oos-modal .oos-modal-content .oos-modal-form-content textarea {
    font-size: 13px;
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button {
    margin-top: 30px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    background-color: #2a3957;
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-link-icon {
    display: inline-block;
    transition: transform 0.2s linear;
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-link-icon.disabled {
    color: #bfbfbf;
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-link-icon.ph {
    padding-left: 5px;
    transition: none !important;
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-download-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/technicalSupport/download.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-download-icon.disabled {
    background-image: url("../images/technicalSupport/download_disabled.svg");
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-apply-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/about/recruitment/apply.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-apply-icon.disabled {
    background-image: url("../images/about/recruitment/apply_disabled.svg");
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button:hover .oos-button-link-icon:not(.disabled) {
    transform: translateX(4px);
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button:hover .oos-button-download-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button:hover .oos-button-apply-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button.disabled {
    background-color: #888;
    cursor: not-allowed;
  }
}
@media screen and (max-width: 768px) and screen and (max-width: 450px) {
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-link-icon,
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-download-icon,
  .oos-modal .oos-modal-content .oos-modal-form-content .submit-button .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (max-width: 450px) {
  .oos-modal .oos-modal-content .oos-modal-title {
    font-size: 24px;
  }
  .oos-modal .oos-modal-content .oos-modal-title::before,
  .oos-modal .oos-modal-content .oos-modal-title::after {
    vertical-align: 8px;
  }
  .oos-modal .oos-modal-content .oos-modal-form-content input,
  .oos-modal .oos-modal-content .oos-modal-form-content textarea {
    font-size: 12px;
  }
}
.oos-modal-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0.3;
  z-index: 4;
  animation: oos-modal-mask-fade-in 0.3s;
}
.oos-modal-mask.visible {
  display: block;
}
.oos-dynamic-icon {
  display: inline-block;
  background-repeat: no-repeat !important;
  background-size: 100% auto !important;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.oos-dynamic-icon:hover {
  cursor: default;
}
.oos-dynamic-icon.small {
  width: 60px;
  height: 60px;
  animation-duration: 0.3s;
  animation-timing-function: steps(16);
}
.oos-dynamic-icon.small.active {
  animation-name: oos-dynamic-icon-60-16-active;
}
.oos-dynamic-icon.small.leave {
  animation-name: oos-dynamic-icon-60-16-leave;
}
.oos-dynamic-icon.intelligent-campus-category {
  background-image: url("../images/dynamicIcon/solution/smart_campus.webp");
}
.oos-dynamic-icon.intelligent-police-category {
  background-image: url("../images/dynamicIcon/solution/smart_police.webp");
}
.oos-dynamic-icon.intelligent-community-category {
  background-image: url("../images/dynamicIcon/solution/smart_community.webp");
}
.oos-dynamic-icon.intelligent-scenic-spot-category {
  background-image: url("../images/dynamicIcon/solution/smart_scenic_spot.webp");
}
.oos-dynamic-icon.emergency-category {
  background-image: url("../images/dynamicIcon/solution/emergency_industry.webp");
}
.oos-dynamic-icon.general-category {
  background-image: url("../images/dynamicIcon/solution/general_industry.webp");
}
.oos-dynamic-icon.deduplication {
  background-image: url("../images/dynamicIcon/product/uniio/deduplication.webp");
}
.oos-dynamic-icon.qos {
  background-image: url("../images/dynamicIcon/product/uniio/qos.webp");
}
.oos-dynamic-icon.scale {
  background-image: url("../images/dynamicIcon/product/uniio/scale.webp");
}
.oos-dynamic-icon.remote-replication {
  background-image: url("../images/dynamicIcon/product/uniio/remote_replication.webp");
}
.oos-dynamic-icon.performance-uniio {
  background-image: url("../images/dynamicIcon/product/uniio/performance.webp");
}
.oos-dynamic-icon.low-carbon {
  background-image: url("../images/dynamicIcon/product/uniio/low_carbon.webp");
}
.oos-dynamic-icon.localization {
  background-image: url("../images/dynamicIcon/product/uniio/localization.webp");
}
.oos-dynamic-icon.cloud {
  background-image: url("../images/dynamicIcon/product/uniio/cloud.webp");
}
.oos-dynamic-icon.performance-orcafs {
  background-image: url("../images/dynamicIcon/product/orcafs/performance.webp");
}
.oos-dynamic-icon.protocol {
  background-image: url("../images/dynamicIcon/product/orcafs/protocol.webp");
}
.oos-dynamic-icon.verification {
  background-image: url("../images/dynamicIcon/product/orcafs/verification.webp");
}
.oos-dynamic-icon.orcaond {
  background-image: url("../images/dynamicIcon/product/orcafs/orcaond.webp");
}
.oos-dynamic-icon.capacity {
  background-image: url("../images/dynamicIcon/product/orcafs/capacity.webp");
}
.oos-dynamic-icon.high-reliability-orcafs {
  background-image: url("../images/dynamicIcon/product/orcafs/high_reliability.webp");
}
.oos-dynamic-icon.fusion-architecture {
  background-image: url("../images/dynamicIcon/product/orcabd/fusion_architecture.webp");
}
.oos-dynamic-icon.intelligent-layering {
  background-image: url("../images/dynamicIcon/product/orcabd/intelligent_layering.webp");
}
.oos-dynamic-icon.low-cost {
  background-image: url("../images/dynamicIcon/product/orcabd/low_cost.webp");
}
.oos-dynamic-icon.easy-to-expand-orcabd {
  background-image: url("../images/dynamicIcon/product/orcabd/easy_to_expand.webp");
}
.oos-dynamic-icon.high-reliability-orcabd {
  background-image: url("../images/dynamicIcon/product/orcabd/high_reliability.webp");
}
.oos-dynamic-icon.easy-to-use-orcabd {
  background-image: url("../images/dynamicIcon/product/orcabd/easy_to_use.webp");
}
.oos-dynamic-icon.easy-to-use-data-backup {
  background-image: url("../images/dynamicIcon/solution/dataBackup/easy_to_use.webp");
}
.oos-dynamic-icon.easy-to-expand-big-data {
  background-image: url("../images/dynamicIcon/solution/bigData/easy_to_expand.webp");
}
.oos-dynamic-icon.high-reliability-big-data {
  background-image: url("../images/dynamicIcon/solution/bigData/high_reliability.webp");
}
.oos-dynamic-icon.data-transfer {
  background-image: url("../images/dynamicIcon/solution/bigData/data_transfer.webp");
}
.oos-dynamic-icon.easy-to-manage-big-data {
  background-image: url("../images/dynamicIcon/solution/bigData/easy_to_manage.webp");
}
.oos-dynamic-icon.big-capacity {
  background-image: url("../images/dynamicIcon/solution/intelligentCampus/big_capacity.webp");
}
.oos-dynamic-icon.performance-intelligent-campus {
  background-image: url("../images/dynamicIcon/solution/intelligentCampus/performance.webp");
}
.oos-dynamic-icon.high-reliability-intelligent-campus {
  background-image: url("../images/dynamicIcon/solution/intelligentCampus/high_reliability.webp");
}
.oos-dynamic-icon.easy-to-expand-intelligent-security {
  background-image: url("../images/dynamicIcon/solution/intelligentSecurity/easy_to_expand.webp");
}
.oos-dynamic-icon.performance-ai {
  background-image: url("../images/dynamicIcon/solution/ai/performance.webp");
}
.oos-dynamic-icon.high-reliability-ai {
  background-image: url("../images/dynamicIcon/solution/ai/high_reliability.webp");
}
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  touch-action: auto;
}
.ps__rail-x {
  position: absolute;
  bottom: 0;
  display: none;
  height: 4px;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
}
.ps__rail-y {
  position: absolute;
  right: 0;
  display: none;
  width: 4px;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  z-index: 2;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}
.ps__thumb-x {
  position: absolute;
  bottom: 2px;
  height: 4px;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
}
.ps__thumb-y {
  position: absolute;
  right: 2px;
  width: 4px;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
}
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  height: 11px;
  background-color: #999;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 11px;
  background-color: #999;
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  height: 4px !important;
  background-color: #999;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 4px !important;
  background-color: #999;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img {
  position: absolute;
  width: 998px;
  height: 549px;
  background-image: url("../images/productBannerAnim/base.webp");
  background-size: 100% 100%;
  overflow: hidden;
  z-index: 0;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img i {
  display: block !important;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-curtain {
  position: absolute;
  z-index: 2;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-curtain.big {
  top: 151px;
  right: 354px;
  width: 135px;
  height: 172px;
  background-image: url("../images/productBannerAnim/light_curtain_big.webp");
  background-size: 100% 100%;
  animation: oos-product-banner-curtain-fade-in-and-out 5s linear infinite;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-curtain.small {
  top: 131px;
  right: 126px;
  width: 13px;
  height: 42px;
  background-image: url("../images/productBannerAnim/light_curtain_small.webp");
  background-size: 100% 100%;
  animation: oos-product-banner-curtain-fade-in-and-out 2.5s linear infinite;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-conveyor-belt-base {
  position: absolute;
  top: 299px;
  right: 408px;
  width: 426px;
  height: 218px;
  background-image: url("../images/productBannerAnim/conveyor_belt_base.webp");
  background-size: 100% 100%;
  z-index: 1;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-scenario {
  position: absolute;
  background-size: 100% 100%;
  z-index: 2;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-scenario.uniio {
  top: 273px;
  right: 586px;
  width: 235px;
  height: 143px;
  background-image: url("../images/productBannerAnim/uniio/scenario.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-scenario.orcabd {
  top: 250px;
  right: 586px;
  width: 235px;
  height: 167px;
  background-image: url("../images/productBannerAnim/orcabd/scenario.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-scenario.orcafs {
  top: 253px;
  right: 576px;
  width: 258px;
  height: 168px;
  background-image: url("../images/productBannerAnim/orcafs/scenario.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-application {
  position: absolute;
  animation: oos-product-banner-application-motion 10s linear infinite;
  background-size: 100% 100%;
  z-index: 2;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-application.uniio {
  top: 217px;
  right: 390px;
  width: 55px;
  height: 62px;
  background-image: url("../images/productBannerAnim/uniio/application.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-application.orcabd {
  top: 200px;
  right: 385px;
  width: 71px;
  height: 79px;
  background-image: url("../images/productBannerAnim/orcabd/application.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-application.orcafs {
  top: 170px;
  right: 380px;
  width: 82px;
  height: 119px;
  background-image: url("../images/productBannerAnim/orcafs/application.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-conveyor-belt-stuff {
  position: absolute;
  top: 467px;
  right: 432px;
  width: 48px;
  height: 38px;
  background-image: url("../images/productBannerAnim/conveyor_belt_stuff.webp");
  background-size: 100% 100%;
  animation: oos-product-banner-conveyor-belt-stuff-motion 8s ease-out infinite;
  z-index: 3;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-name {
  position: absolute;
  background-size: 100% 100%;
  z-index: 5;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-name.uniio {
  top: 365px;
  right: 320px;
  width: 103px;
  height: 81px;
  background-image: url("../images/productBannerAnim/uniio/name.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-name.orcabd {
  top: 365px;
  right: 318px;
  width: 99px;
  height: 77px;
  background-image: url("../images/productBannerAnim/orcabd/name.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-name.orcafs {
  top: 365px;
  right: 324px;
  width: 92px;
  height: 76px;
  background-image: url("../images/productBannerAnim/orcafs/name.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-data-process {
  position: absolute;
  top: 356px;
  right: 183px;
  width: 341px;
  height: 270px;
  background-image: url("../images/productBannerAnim/data_process.webp");
  background-size: 100% 100%;
  z-index: 4;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-bar-box {
  position: absolute;
  top: 431px;
  right: 710px;
  transform: skewY(28deg);
  z-index: 2;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-bar-box .light-bar {
  position: absolute;
  width: 20px;
  height: 5px;
  background-color: #384473;
  overflow: hidden;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-bar-box .light-bar::before {
  content: " ";
  position: absolute;
  display: block;
  width: 6px;
  height: 5px;
  background-color: #4d598b;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-bar-box .light-bar:nth-child(1)::before {
  animation: oos-product-banner-light-bar-thumb-left-right-motion 1s linear infinite;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-bar-box .light-bar:nth-child(2) {
  top: -9px;
  left: 0;
  width: 20px;
  height: 5px;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-bar-box .light-bar:nth-child(2)::before {
  animation: oos-product-banner-light-bar-thumb-right-left-motion 1s linear 1.7s infinite;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box {
  position: absolute;
  z-index: 0;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box .light-ray {
  position: absolute;
  top: 0;
  width: 12px;
  height: 6px;
  background-color: #49f3ff;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 2px 2px rgba(73, 243, 255, 0.2);
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.left {
  top: 315px;
  right: 485px;
  transform: skewY(-26deg);
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.left .light-ray {
  animation: oos-product-banner-light-ray-left-motion 2.33s ease-out infinite;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(1) {
  animation-delay: 0.3s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(2) {
  top: 9px;
  left: 9px;
  animation-delay: 1.1s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(3) {
  top: 18px;
  left: 18px;
  animation-delay: 2s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(4) {
  top: 27px;
  left: 27px;
  animation-delay: 0.7s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(5) {
  top: 37px;
  left: 38px;
  animation-delay: 1.8s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.right {
  top: 150px;
  right: 246px;
  transform: skewY(-23deg);
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.right .light-ray {
  animation: oos-product-banner-light-ray-right-motion 3.72s ease-out infinite;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(1) {
  animation-delay: 2s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(2) {
  top: 9px;
  left: 9px;
  animation-delay: 0.3s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(3) {
  top: 18px;
  left: 18px;
  animation-delay: 1.7s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(4) {
  top: 27px;
  left: 27px;
  animation-delay: 1.1s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(5) {
  top: 37px;
  left: 38px;
  animation-delay: 0.8s;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-point-small {
  position: absolute;
  width: 3px;
  height: 9px;
  top: 139px;
  right: 94px;
  border-radius: 3px;
  background-color: #fff;
  transform: skewY(-28deg);
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.2s infinite;
  z-index: 2;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-point-small::before,
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-point-small::after {
  content: " ";
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  border-radius: 3px;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-point-small::before {
  top: 0;
  left: -6px;
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.4s infinite;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-light-point-small::after {
  top: 0;
  left: -12px;
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.6s infinite;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-float-img {
  position: absolute;
  background-size: 100% 100%;
  z-index: 1;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-float-img > i {
  position: absolute;
  background-size: 100% 100%;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-float-img.uniio {
  top: 118px;
  right: 421px;
  width: 304px;
  height: 220px;
  background-image: url("../images/productBannerAnim/uniio/float_base.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-float-img.orcabd {
  top: 112px;
  right: 427px;
  width: 285px;
  height: 224px;
  background-image: url("../images/productBannerAnim/orcabd/float_base.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-anim-base-img .banner-float-img.orcafs {
  top: 104px;
  right: 427px;
  width: 285px;
  height: 202px;
  background-image: url("../images/productBannerAnim/orcafs/float_base.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .banner-point-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 1735px;
  height: 480px;
  background-image: url("../images/productBannerAnim/point_bg.webp");
  background-size: 100% 100%;
  z-index: -1;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-static-img-pad-ph {
  display: none;
  width: 268px;
  min-width: 268px;
  height: 156px;
  background-size: 100% 100%;
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-static-img-pad-ph.uniio {
  background-image: url("../images/product/uniio/banner_static_img.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-static-img-pad-ph.orcabd {
  background-image: url("../images/product/orcabd/banner_static_img.webp");
}
.banner .banner-carousel-wrap .carousel .carousel-item .product-banner-static-img-pad-ph.orcafs {
  background-image: url("../images/product/orcafs/banner_static_img.webp");
}
.banner .banner-content .product-banner-anim-base-img {
  position: absolute;
  width: 998px;
  height: 549px;
  background-image: url("../images/productBannerAnim/base.webp");
  background-size: 100% 100%;
  overflow: hidden;
  z-index: 0;
}
.banner .banner-content .product-banner-anim-base-img i {
  display: block !important;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-curtain {
  position: absolute;
  z-index: 2;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-curtain.big {
  top: 151px;
  right: 354px;
  width: 135px;
  height: 172px;
  background-image: url("../images/productBannerAnim/light_curtain_big.webp");
  background-size: 100% 100%;
  animation: oos-product-banner-curtain-fade-in-and-out 5s linear infinite;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-curtain.small {
  top: 131px;
  right: 126px;
  width: 13px;
  height: 42px;
  background-image: url("../images/productBannerAnim/light_curtain_small.webp");
  background-size: 100% 100%;
  animation: oos-product-banner-curtain-fade-in-and-out 2.5s linear infinite;
}
.banner .banner-content .product-banner-anim-base-img .banner-conveyor-belt-base {
  position: absolute;
  top: 299px;
  right: 408px;
  width: 426px;
  height: 218px;
  background-image: url("../images/productBannerAnim/conveyor_belt_base.webp");
  background-size: 100% 100%;
  z-index: 1;
}
.banner .banner-content .product-banner-anim-base-img .banner-scenario {
  position: absolute;
  background-size: 100% 100%;
  z-index: 2;
}
.banner .banner-content .product-banner-anim-base-img .banner-scenario.uniio {
  top: 273px;
  right: 586px;
  width: 235px;
  height: 143px;
  background-image: url("../images/productBannerAnim/uniio/scenario.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-scenario.orcabd {
  top: 250px;
  right: 586px;
  width: 235px;
  height: 167px;
  background-image: url("../images/productBannerAnim/orcabd/scenario.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-scenario.orcafs {
  top: 253px;
  right: 576px;
  width: 258px;
  height: 168px;
  background-image: url("../images/productBannerAnim/orcafs/scenario.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-application {
  position: absolute;
  animation: oos-product-banner-application-motion 10s linear infinite;
  background-size: 100% 100%;
  z-index: 2;
}
.banner .banner-content .product-banner-anim-base-img .banner-application.uniio {
  top: 217px;
  right: 390px;
  width: 55px;
  height: 62px;
  background-image: url("../images/productBannerAnim/uniio/application.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-application.orcabd {
  top: 200px;
  right: 385px;
  width: 71px;
  height: 79px;
  background-image: url("../images/productBannerAnim/orcabd/application.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-application.orcafs {
  top: 170px;
  right: 380px;
  width: 82px;
  height: 119px;
  background-image: url("../images/productBannerAnim/orcafs/application.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-conveyor-belt-stuff {
  position: absolute;
  top: 467px;
  right: 432px;
  width: 48px;
  height: 38px;
  background-image: url("../images/productBannerAnim/conveyor_belt_stuff.webp");
  background-size: 100% 100%;
  animation: oos-product-banner-conveyor-belt-stuff-motion 8s ease-out infinite;
  z-index: 3;
}
.banner .banner-content .product-banner-anim-base-img .banner-name {
  position: absolute;
  background-size: 100% 100%;
  z-index: 5;
}
.banner .banner-content .product-banner-anim-base-img .banner-name.uniio {
  top: 365px;
  right: 320px;
  width: 103px;
  height: 81px;
  background-image: url("../images/productBannerAnim/uniio/name.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-name.orcabd {
  top: 365px;
  right: 318px;
  width: 99px;
  height: 77px;
  background-image: url("../images/productBannerAnim/orcabd/name.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-name.orcafs {
  top: 365px;
  right: 324px;
  width: 92px;
  height: 76px;
  background-image: url("../images/productBannerAnim/orcafs/name.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-data-process {
  position: absolute;
  top: 356px;
  right: 183px;
  width: 341px;
  height: 270px;
  background-image: url("../images/productBannerAnim/data_process.webp");
  background-size: 100% 100%;
  z-index: 4;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-bar-box {
  position: absolute;
  top: 431px;
  right: 710px;
  transform: skewY(28deg);
  z-index: 2;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-bar-box .light-bar {
  position: absolute;
  width: 20px;
  height: 5px;
  background-color: #384473;
  overflow: hidden;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-bar-box .light-bar::before {
  content: " ";
  position: absolute;
  display: block;
  width: 6px;
  height: 5px;
  background-color: #4d598b;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-bar-box .light-bar:nth-child(1)::before {
  animation: oos-product-banner-light-bar-thumb-left-right-motion 1s linear infinite;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-bar-box .light-bar:nth-child(2) {
  top: -9px;
  left: 0;
  width: 20px;
  height: 5px;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-bar-box .light-bar:nth-child(2)::before {
  animation: oos-product-banner-light-bar-thumb-right-left-motion 1s linear 1.7s infinite;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box {
  position: absolute;
  z-index: 0;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box .light-ray {
  position: absolute;
  top: 0;
  width: 12px;
  height: 6px;
  background-color: #49f3ff;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 2px 2px rgba(73, 243, 255, 0.2);
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.left {
  top: 315px;
  right: 485px;
  transform: skewY(-26deg);
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.left .light-ray {
  animation: oos-product-banner-light-ray-left-motion 2.33s ease-out infinite;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(1) {
  animation-delay: 0.3s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(2) {
  top: 9px;
  left: 9px;
  animation-delay: 1.1s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(3) {
  top: 18px;
  left: 18px;
  animation-delay: 2s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(4) {
  top: 27px;
  left: 27px;
  animation-delay: 0.7s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.left .light-ray:nth-child(5) {
  top: 37px;
  left: 38px;
  animation-delay: 1.8s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.right {
  top: 150px;
  right: 246px;
  transform: skewY(-23deg);
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.right .light-ray {
  animation: oos-product-banner-light-ray-right-motion 3.72s ease-out infinite;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(1) {
  animation-delay: 2s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(2) {
  top: 9px;
  left: 9px;
  animation-delay: 0.3s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(3) {
  top: 18px;
  left: 18px;
  animation-delay: 1.7s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(4) {
  top: 27px;
  left: 27px;
  animation-delay: 1.1s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-ray-box.right .light-ray:nth-child(5) {
  top: 37px;
  left: 38px;
  animation-delay: 0.8s;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-point-small {
  position: absolute;
  width: 3px;
  height: 9px;
  top: 139px;
  right: 94px;
  border-radius: 3px;
  background-color: #fff;
  transform: skewY(-28deg);
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.2s infinite;
  z-index: 2;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-point-small::before,
.banner .banner-content .product-banner-anim-base-img .banner-light-point-small::after {
  content: " ";
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  border-radius: 3px;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-point-small::before {
  top: 0;
  left: -6px;
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.4s infinite;
}
.banner .banner-content .product-banner-anim-base-img .banner-light-point-small::after {
  top: 0;
  left: -12px;
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.6s infinite;
}
.banner .banner-content .product-banner-anim-base-img .banner-float-img {
  position: absolute;
  background-size: 100% 100%;
  z-index: 1;
}
.banner .banner-content .product-banner-anim-base-img .banner-float-img > i {
  position: absolute;
  background-size: 100% 100%;
}
.banner .banner-content .product-banner-anim-base-img .banner-float-img.uniio {
  top: 118px;
  right: 421px;
  width: 304px;
  height: 220px;
  background-image: url("../images/productBannerAnim/uniio/float_base.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-float-img.orcabd {
  top: 112px;
  right: 427px;
  width: 285px;
  height: 224px;
  background-image: url("../images/productBannerAnim/orcabd/float_base.webp");
}
.banner .banner-content .product-banner-anim-base-img .banner-float-img.orcafs {
  top: 104px;
  right: 427px;
  width: 285px;
  height: 202px;
  background-image: url("../images/productBannerAnim/orcafs/float_base.webp");
}
.banner .banner-content .banner-point-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 1735px;
  height: 480px;
  background-image: url("../images/productBannerAnim/point_bg.webp");
  background-size: 100% 100%;
  z-index: -1;
}
.banner .banner-content .product-banner-static-img-pad-ph {
  display: none;
  width: 268px;
  min-width: 268px;
  height: 156px;
  background-size: 100% 100%;
}
.banner .banner-content .product-banner-static-img-pad-ph.uniio {
  background-image: url("../images/product/uniio/banner_static_img.webp");
}
.banner .banner-content .product-banner-static-img-pad-ph.orcabd {
  background-image: url("../images/product/orcabd/banner_static_img.webp");
}
.banner .banner-content .product-banner-static-img-pad-ph.orcafs {
  background-image: url("../images/product/orcafs/banner_static_img.webp");
}
.banner .banner-content .normal-banner-anim-base-img {
  position: absolute;
  top: -22px;
  right: 19px;
  width: 574px;
  height: 461px;
  background-image: url("../images/normalBannerAnim/base.webp");
  background-size: 100% 100%;
  z-index: 0;
}
.banner .banner-content .normal-banner-anim-base-img i {
  display: block !important;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-curtain {
  position: absolute;
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-curtain.big {
  top: 45px;
  right: 265px;
  width: 220px;
  height: 207px;
  background-image: url("../images/normalBannerAnim/light_curtain_big.webp");
  background-size: 100% 100%;
  animation: oos-normal-banner-curtain-fade-in-and-out 5s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-curtain.small {
  top: 225px;
  right: 411px;
  width: 13px;
  height: 42px;
  background-image: url("../images/normalBannerAnim/light_curtain_small.webp");
  background-size: 100% 100%;
  animation: oos-normal-banner-curtain-fade-in-and-out 2.5s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-window-curtain {
  position: absolute;
  top: 77px;
  right: -23px;
  width: 62px;
  height: 44px;
  background-size: 100% 100%;
  z-index: 2;
  animation: oos-normal-banner-curtain-window-fade-in-and-out 10s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small {
  position: absolute;
  width: 3px;
  height: 9px;
  border-radius: 3px;
  background-color: #fff;
  transform: skewY(-28deg);
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small::before,
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small::after {
  content: " ";
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  border-radius: 3px;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small::before {
  top: 0;
  left: -6px;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small::after {
  top: 0;
  left: -12px;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small.left {
  top: 234px;
  right: 280px;
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.2s infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small.left::before {
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.4s infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small.left::after {
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.6s infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small.right {
  top: 107px;
  right: 76px;
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 2.6s infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small.right::before {
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 2.4s infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-point-small.right::after {
  animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 2.2s infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-circle {
  position: absolute;
  top: 324px;
  right: 40px;
  width: 169px;
  height: 84px;
  background-image: url("../images/normalBannerAnim/light_circle.webp");
  z-index: 2;
  filter: blur(4px);
  animation: oos-normal-banner-light-point-fade-in-and-out 6s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-bar-box {
  position: absolute;
  top: 163px;
  right: 91px;
  transform: skewY(-28deg);
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-bar-box .light-bar {
  position: absolute;
  width: 20px;
  height: 5px;
  background-color: #384473;
  overflow: hidden;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-bar-box .light-bar::before {
  content: " ";
  position: absolute;
  display: block;
  width: 6px;
  height: 5px;
  background-color: #4d598b;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-bar-box .light-bar:nth-child(1)::before {
  animation: oos-normal-banner-light-bar-thumb-left-right-motion 1s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-bar-box .light-bar:nth-child(2) {
  top: -9px;
  left: 0;
  width: 20px;
  height: 5px;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-bar-box .light-bar:nth-child(2)::before {
  animation: oos-normal-banner-light-bar-thumb-right-left-motion 1s linear 1.7s infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box {
  position: absolute;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box .light-ray {
  position: absolute;
  top: 0;
  width: 12px;
  height: 6px;
  background-color: #49f3ff;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 2px 2px rgba(73, 243, 255, 0.2);
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.top {
  top: 132px;
  right: 187px;
  transform: skewY(-26deg);
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.top .light-ray {
  animation: oos-normal-banner-light-ray-up-motion 2s ease-out infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.top .light-ray:nth-child(1) {
  animation-delay: 2s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.top .light-ray:nth-child(2) {
  top: 9px;
  left: 9px;
  animation-delay: 0.3s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.top .light-ray:nth-child(3) {
  top: 18px;
  left: 18px;
  animation-delay: 1.7s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.top .light-ray:nth-child(4) {
  top: 27px;
  left: 27px;
  animation-delay: 1.1s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.top .light-ray:nth-child(5) {
  top: 37px;
  left: 38px;
  animation-delay: 0.8s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.bottom {
  top: 306px;
  right: 213px;
  transform: skewY(26deg);
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.bottom .light-ray {
  animation: oos-normal-banner-light-ray-bottom-motion 2s ease-out infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.bottom .light-ray:nth-child(1) {
  top: -1px;
  left: 39px;
  animation-delay: 0.3s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.bottom .light-ray:nth-child(2) {
  top: 9px;
  left: 29px;
  animation-delay: 1.1s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.bottom .light-ray:nth-child(3) {
  top: 18px;
  left: 19px;
  animation-delay: 2s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.bottom .light-ray:nth-child(4) {
  top: 27px;
  left: 10px;
  animation-delay: 0.7s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-light-ray-box.bottom .light-ray:nth-child(5) {
  top: 37px;
  animation-delay: 1.8s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img {
  position: absolute;
  background-size: 100% 100%;
  z-index: 1;
  animation: oos-normal-banner-float-img-motion 10s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img > i {
  position: absolute;
  background-size: 100% 100%;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.big-data {
  top: 54px;
  right: 306px;
  width: 138px;
  height: 185px;
  background-image: url("../images/normalBannerAnim/solution/bigData/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-performance-computing {
  top: 29px;
  right: 274px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/solution/highPerformanceComputing/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.cloud-computing-private-cloud {
  top: 29px;
  right: 274px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/solution/cloudComputingPrivateCloud/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.cloud-native-container-storage {
  top: 29px;
  right: 274px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/solution/cloudNativeContainerStorage/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.data-backup {
  top: 88px;
  right: 290px;
  width: 171px;
  height: 153px;
  background-image: url("../images/normalBannerAnim/solution/dataBackup/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.new-energy-vehicle {
  top: 29px;
  right: 274px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/solution/newEnergyVehicle/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-campus {
  top: 40px;
  right: 275px;
  width: 201px;
  height: 197px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCampus/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-security {
  top: 65px;
  right: 319px;
  width: 106px;
  height: 148px;
  background-image: url("../images/normalBannerAnim/solution/intelligentSecurity/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.ai {
  top: 45px;
  right: 274px;
  width: 201px;
  height: 194px;
  background-image: url("../images/normalBannerAnim/solution/ai/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.bright-project {
  top: 110px;
  right: 274px;
  width: 201px;
  height: 130px;
  background-image: url("../images/normalBannerAnim/solution/brightProject/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.bright-project .build {
  top: 32px;
  right: 108px;
  width: 89px;
  height: 56px;
  background-image: url("../images/normalBannerAnim/solution/brightProject/float_building.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.bright-project .monitor-a {
  top: 0;
  right: 126px;
  width: 19px;
  height: 36px;
  background-image: url("../images/normalBannerAnim/solution/brightProject/float_monitor_a.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.bright-project .monitor-b {
  top: 52px;
  right: 41px;
  width: 19px;
  height: 36px;
  background-image: url("../images/normalBannerAnim/solution/brightProject/float_monitor_b.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.bright-project .monitor-c {
  top: 70px;
  right: 106px;
  width: 19px;
  height: 36px;
  background-image: url("../images/normalBannerAnim/solution/brightProject/float_monitor_c.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.bright-project .car-a {
  top: 26px;
  right: 114px;
  width: 16px;
  height: 9px;
  background-image: url("../images/normalBannerAnim/solution/brightProject/float_car_a.webp");
  animation: oos-normal-banner-bright-project-car-a-motion 12s ease-in-out infinite;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.bright-project .car-b {
  top: 84px;
  right: 132px;
  width: 13px;
  height: 11px;
  background-image: url("../images/normalBannerAnim/solution/brightProject/float_car_b.webp");
  animation: oos-normal-banner-bright-project-car-b-motion 8s ease-in-out infinite;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.major-event-security {
  top: 25px;
  right: 266px;
  width: 218px;
  height: 217px;
  background-image: url("../images/normalBannerAnim/solution/majorEventSecurity/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community {
  top: 65px;
  right: 275px;
  width: 201px;
  height: 177px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunity/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community .building {
  top: 54px;
  right: 116px;
  width: 85px;
  height: 71px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunity/float_building.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community .garden {
  top: 88px;
  right: 44px;
  width: 119px;
  height: 69px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunity/float_garden.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community .car {
  top: 76px;
  right: 104px;
  width: 34px;
  height: 17px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunity/float_car.webp");
  z-index: 1;
  animation: oos-normal-banner-intelligent-community-car-motion 12s ease-in-out infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security {
  top: 103px;
  right: 275px;
  width: 202px;
  height: 138px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .cloud {
  top: -55px;
  right: 118px;
  width: 32px;
  height: 31px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_cloud.webp");
  animation: oos-normal-banner-intelligent-community-security-cloud-motion 9s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .building {
  top: 8px;
  right: 118px;
  width: 66px;
  height: 67px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_building.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .building-light-a {
  top: 8px;
  right: 161px;
  width: 1px;
  height: 3px;
  background-color: #FF3333;
  box-shadow: 0 0 2px 1px #FF3333;
  animation: oos-normal-banner-building-light-blink 6s linear infinite 2.3s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .building-light-b {
  top: 12px;
  right: 163px;
  width: 1px;
  height: 3px;
  background-color: #FF3333;
  box-shadow: 0 0 2px 1px #FF3333;
  animation: oos-normal-banner-building-light-blink 6s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .bicycle-man {
  top: 39px;
  right: 139px;
  width: 10px;
  height: 13px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_bicycle_man.webp");
  animation: oos-normal-banner-intelligent-community-security-bicycle-man-motion 24s linear infinite 2s;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .balloon {
  top: -20px;
  right: 31px;
  width: 27px;
  height: 42px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_balloon.webp");
  animation: oos-normal-banner-intelligent-community-security-balloon-motion 6s ease-in-out infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .ball-man-arm-a {
  top: 72px;
  right: 135px;
  width: 1px;
  height: 3px;
  background-color: #d73c45;
  animation: oos-normal-banner-ball-man-arm-motion 4s ease-in-out infinite;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .ball-man-arm-b {
  top: 91px;
  right: 92px;
  width: 1px;
  height: 3px;
  background-color: #e5bf3e;
  animation: oos-normal-banner-ball-man-arm-motion 4s ease-in-out infinite;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .ball {
  top: 67px;
  right: 137px;
  animation: oos-normal-banner-ball-motion-x 8s ease-out infinite;
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .ball::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: #fff;
  z-index: 3;
  animation: oos-normal-banner-ball-motion-y 8s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-community-security .bus {
  top: 103px;
  right: 90px;
  width: 22px;
  height: 16px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_bus.webp");
  animation: oos-normal-banner-intelligent-community-security-bus-motion 12s ease-out infinite;
  z-index: 3;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-elevator {
  top: 85px;
  right: 287px;
  width: 177px;
  height: 158px;
  background-image: url("../images/normalBannerAnim/solution/intelligentElevator/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-elevator .wifi {
  top: -25px;
  right: 115px;
  width: 63px;
  height: 50px;
  background-image: url("../images/normalBannerAnim/solution/intelligentElevator/float_wifi.webp");
  animation: oos-normal-banner-intelligent-elevator-wifi-motion 6s ease-in-out infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-elevator .elevator-car {
  top: -29px;
  right: 15px;
  width: 82px;
  height: 153px;
  background-image: url("../images/normalBannerAnim/solution/intelligentElevator/float_elevator_car.webp");
  z-index: 1;
  animation: oos-normal-banner-intelligent-elevator-car-motion 8s ease-in-out infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-elevator .elevator-car .man-right {
  position: absolute;
  top: 100px;
  right: 38px;
  width: 10px;
  height: 34px;
  background-image: url("../images/normalBannerAnim/solution/intelligentElevator/float_man_right.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-elevator .door-frame {
  top: 27px;
  right: -5px;
  width: 119px;
  height: 107px;
  background-image: url("../images/normalBannerAnim/solution/intelligentElevator/float_door_frame.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-elevator .door-left {
  top: 68px;
  right: 82px;
  width: 12px;
  height: 47px;
  background-color: #e4dfdb;
  border-right: 1px solid #d8d7d7;
  transform: skewY(25deg) translateX(0);
  z-index: 1;
  animation: oos-normal-banner-intelligent-elevator-door-left-motion 8s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-elevator .door-right {
  top: 75px;
  right: 70px;
  width: 12px;
  height: 47px;
  background-color: #e4dfdb;
  transform: skewY(25deg) translateX(0);
  z-index: 1;
  animation: oos-normal-banner-intelligent-elevator-door-right-motion 8s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-elevator .man-left {
  top: 90px;
  right: 85px;
  width: 14px;
  height: 32px;
  background-image: url("../images/normalBannerAnim/solution/intelligentElevator/float_man_left.webp");
  z-index: 3;
  animation: oos-normal-banner-intelligent-elevator-man-left-fade-in-out 8s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object {
  top: 114px;
  right: 275px;
  width: 202px;
  height: 127px;
  background-image: url("../images/normalBannerAnim/solution/highAltitudeThrowObject/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .building {
  top: -45px;
  right: 130px;
  width: 71px;
  height: 117px;
  background-image: url("../images/normalBannerAnim/solution/highAltitudeThrowObject/float_building.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .building-light-a {
  top: -43px;
  right: 177px;
  width: 1px;
  height: 3px;
  background-color: #FF3333;
  box-shadow: 0 0 2px 1px #FF3333;
  animation: oos-normal-banner-building-light-blink 6s linear infinite 2.3s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .building-light-b {
  top: -48px;
  right: 175px;
  width: 1px;
  height: 3px;
  background-color: #FF3333;
  box-shadow: 0 0 2px 1px #FF3333;
  animation: oos-normal-banner-building-light-blink 6s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .cloud {
  top: -65px;
  right: 50px;
  width: 32px;
  height: 31px;
  background-image: url("../images/normalBannerAnim/solution/highAltitudeThrowObject/float_cloud.webp");
  animation: oos-normal-banner-high-altitude-throw-object-cloud-motion 9s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .car {
  top: 74px;
  right: 45px;
  width: 19px;
  height: 11px;
  background-image: url("../images/normalBannerAnim/solution/highAltitudeThrowObject/float_car.webp");
  animation: oos-normal-banner-high-altitude-throw-object-car-motion 12s ease-in-out infinite 1s;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .camera {
  top: 13px;
  right: 89px;
  width: 80px;
  height: 66px;
  background-image: url("../images/normalBannerAnim/solution/highAltitudeThrowObject/float_camera.webp");
  z-index: 4;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .bicycle-man {
  top: 63px;
  right: 163px;
  width: 10px;
  height: 13px;
  background-image: url("../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_bicycle_man.webp");
  animation: oos-normal-banner-high-altitude-throw-object-bicycle-man-motion 20s linear infinite 2s;
  z-index: 3;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .ball-man-arm-a {
  top: 61px;
  right: 135px;
  width: 1px;
  height: 3px;
  background-color: #d73c45;
  animation: oos-normal-banner-ball-man-arm-motion 4s ease-in-out infinite;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .ball-man-arm-b {
  top: 81px;
  right: 92px;
  width: 1px;
  height: 3px;
  background-color: #e5bf3e;
  animation: oos-normal-banner-ball-man-arm-motion 4s ease-in-out infinite;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .ball {
  top: 56px;
  right: 137px;
  animation: oos-normal-banner-ball-motion-x 8s ease-out infinite;
  z-index: 5;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.high-altitude-throw-object .ball::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: #fff;
  z-index: 5;
  animation: oos-normal-banner-ball-motion-y 8s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-park {
  top: 70px;
  right: 272px;
  width: 207px;
  height: 170px;
  background-image: url("../images/normalBannerAnim/solution/intelligentPark/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-park .building-a {
  top: 66px;
  right: 103px;
  width: 100px;
  height: 62px;
  background-image: url("../images/normalBannerAnim/solution/intelligentPark/float_building_a.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-park .building-b {
  top: 19px;
  right: 2px;
  width: 58px;
  height: 100px;
  background-image: url("../images/normalBannerAnim/solution/intelligentPark/float_building_b.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-park .building-c {
  top: 95px;
  right: 54px;
  width: 78px;
  height: 58px;
  background-image: url("../images/normalBannerAnim/solution/intelligentPark/float_building_c.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-park .car {
  top: 109px;
  right: 33px;
  width: 22px;
  height: 16px;
  background-image: url("../images/normalBannerAnim/solution/intelligentPark/float_car.webp");
  z-index: 1;
  animation: oos-normal-intelligent-park-car-motion 12s ease-in-out infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-scenic-spot {
  top: 110px;
  right: 273px;
  width: 203px;
  height: 124px;
  background-image: url("../images/normalBannerAnim/solution/intelligentScenicSpot/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-scenic-spot .cloud {
  top: -20px;
  right: 155px;
  width: 32px;
  height: 31px;
  background-image: url("../images/normalBannerAnim/solution/intelligentScenicSpot/float_cloud.webp");
  animation: oos-normal-banner-intelligent-scenic-spot-cloud-motion 9s linear infinite;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-scenic-spot .wave-a {
  top: 62px;
  right: 165px;
  width: 16px;
  height: 9px;
  background-image: url("../images/normalBannerAnim/solution/intelligentScenicSpot/float_wave.webp");
  animation: oos-normal-banner-intelligent-scenic-spot-wave-motion 6s linear infinite 0.3s;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-scenic-spot .wave-b {
  top: 70px;
  right: 140px;
  width: 16px;
  height: 9px;
  background-image: url("../images/normalBannerAnim/solution/intelligentScenicSpot/float_wave.webp");
  animation: oos-normal-banner-intelligent-scenic-spot-wave-motion 6s linear infinite 0.7s;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-scenic-spot .wave-c {
  top: 60px;
  right: 142px;
  width: 16px;
  height: 9px;
  background-image: url("../images/normalBannerAnim/solution/intelligentScenicSpot/float_wave.webp");
  animation: oos-normal-banner-intelligent-scenic-spot-wave-motion 6s linear infinite 1.3s;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-scenic-spot .wave-d {
  top: 85px;
  right: 110px;
  width: 16px;
  height: 9px;
  background-image: url("../images/normalBannerAnim/solution/intelligentScenicSpot/float_wave.webp");
  animation: oos-normal-banner-intelligent-scenic-spot-wave-motion 6s linear infinite 0.8s;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-scenic-spot .boat {
  top: 25px;
  right: 152px;
  width: 31px;
  height: 41px;
  background-image: url("../images/normalBannerAnim/solution/intelligentScenicSpot/float_boat.webp");
  animation: oos-normal-banner-intelligent-scenic-spot-boat-motion 12s linear infinite;
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-scenic-spot .sailboat-man {
  top: 36px;
  right: 178px;
  width: 17px;
  height: 28px;
  background-image: url("../images/normalBannerAnim/solution/intelligentScenicSpot/float_sailboat_man.webp");
  animation: oos-normal-banner-intelligent-scenic-spot-sailboat-man-motion 18s linear infinite;
  z-index: 3;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.all-area-tourism {
  top: 100px;
  right: 258px;
  width: 218px;
  height: 141px;
  background-image: url("../images/normalBannerAnim/solution/allAreaTourism/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.all-area-tourism .cloud {
  top: 17px;
  right: 62px;
  width: 144px;
  height: 35px;
  background-image: url("../images/normalBannerAnim/solution/allAreaTourism/cloud.webp");
  z-index: 1;
  animation: oos-normal-banner-all-area-tourism-z-cloud-motion 15s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.all-area-tourism .tree {
  top: 22px;
  right: 157px;
  width: 27px;
  height: 42px;
  background-image: url("../images/normalBannerAnim/solution/allAreaTourism/tree.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.all-area-tourism .bird {
  top: 10px;
  right: 10px;
  width: 17px;
  height: 11px;
  background-image: url("../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
  z-index: 3;
  animation: oos-normal-banner-all-area-tourism-bird-motion 16s linear infinite, oos-normal-banner-all-area-tourism-bird-wing-motion 16s ease-out infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.carbon-neutrality {
  top: 114px;
  right: 271px;
  width: 205px;
  height: 125px;
  background-image: url("../images/normalBannerAnim/solution/carbonNeutrality/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.carbon-neutrality .car {
  top: 75px;
  right: 61px;
  width: 19px;
  height: 16px;
  background-image: url("../images/normalBannerAnim/solution/carbonNeutrality/float_car.webp");
  animation: oos-normal-banner-carbon-neutrality-car-motion 12s linear infinite;
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.carbon-neutrality .factory {
  top: 19px;
  right: 84px;
  width: 108px;
  height: 85px;
  background-image: url("../images/normalBannerAnim/solution/carbonNeutrality/float_factory.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.carbon-neutrality .smoke-a {
  top: -6px;
  right: 123px;
  width: 31px;
  height: 38px;
  background-image: url("../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_a.webp");
  animation: oos-normal-banner-carbon-neutrality-smoke-a-motion 5s linear infinite;
  z-index: 3;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.carbon-neutrality .smoke-b {
  top: -18px;
  right: 83px;
  width: 69px;
  height: 56px;
  background-image: url("../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_d.webp");
  animation: oos-normal-banner-carbon-neutrality-smoke-b-motion 5s linear infinite;
  z-index: 4;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.carbon-neutrality .smoke-c {
  top: -30px;
  right: 24px;
  width: 128px;
  height: 105px;
  background-image: url("../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_c.webp");
  animation: oos-normal-banner-carbon-neutrality-smoke-c-motion 5s linear infinite;
  z-index: 5;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.carbon-neutrality .smoke-d {
  top: -40px;
  right: -26px;
  width: 186px;
  height: 121px;
  background-image: url("../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_d.webp");
  animation: oos-normal-banner-carbon-neutrality-smoke-d-motion 5s linear infinite;
  z-index: 6;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.carbon-neutrality .smoke-e {
  top: -28px;
  right: -8px;
  width: 129px;
  height: 88px;
  background-image: url("../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_e.webp");
  animation: oos-normal-banner-carbon-neutrality-smoke-e-motion 5s linear infinite;
  z-index: 7;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.carbon-neutrality .smoke-f {
  top: -12px;
  right: -18px;
  width: 118px;
  height: 76px;
  background-image: url("../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_f.webp");
  animation: oos-normal-banner-carbon-neutrality-smoke-f-motion 5s linear infinite;
  z-index: 8;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-garden {
  top: 85px;
  right: 275px;
  width: 207px;
  height: 147px;
  background-image: url("../images/normalBannerAnim/solution/intelligentGarden/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-garden .cloud {
  top: -10px;
  right: 150px;
  width: 32px;
  height: 31px;
  background-image: url("../images/normalBannerAnim/solution/intelligentGarden/float_cloud.webp");
  animation: oos-normal-banner-intelligent-garden-cloud-motion 6s linear infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-garden .cable-car {
  top: 9px;
  right: 81px;
  width: 16px;
  height: 17px;
  background-image: url("../images/normalBannerAnim/solution/intelligentGarden/float_cable_car.webp");
  z-index: 1;
  animation: oos-normal-banner-intelligent-garden-cable-car-motion 12s ease-in-out infinite;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-garden .cable {
  top: 4px;
  right: 34px;
  width: 61px;
  height: 58px;
  background-image: url("../images/normalBannerAnim/solution/intelligentGarden/float_cable.webp");
  z-index: 1;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-garden .cable-post-a {
  top: 3px;
  right: 93px;
  width: 4px;
  height: 8px;
  background-image: url("../images/normalBannerAnim/solution/intelligentGarden/float_cable_post.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-garden .cable-post-b {
  top: 58px;
  right: 31px;
  width: 4px;
  height: 8px;
  background-image: url("../images/normalBannerAnim/solution/intelligentGarden/float_cable_post.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-garden .guide-board {
  top: 44px;
  right: 42px;
  width: 21px;
  height: 55px;
  background-image: url("../images/normalBannerAnim/solution/intelligentGarden/float_guide_board.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-garden .car {
  top: 54px;
  right: 128px;
  width: 34px;
  height: 17px;
  background-image: url("../images/normalBannerAnim/solution/intelligentGarden/float_car.webp");
  z-index: 1;
  animation: oos-normal-banner-intelligent-garden-car-motion 12s ease-in-out infinite 1s;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.intelligent-garden .bottom-trees {
  top: 50px;
  right: 65px;
  width: 134px;
  height: 82px;
  background-image: url("../images/normalBannerAnim/solution/intelligentGarden/float_bottom_trees.webp");
  z-index: 2;
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.emergency-command {
  top: 25px;
  right: 275px;
  width: 209px;
  height: 217px;
  background-image: url("../images/normalBannerAnim/solution/emergencyCommand/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.safety-production-emergency {
  top: 40px;
  right: 275px;
  width: 203px;
  height: 195px;
  background-image: url("../images/normalBannerAnim/solution/safetyProductionEmergency/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.iot-platform {
  top: 46px;
  right: 274px;
  width: 201px;
  height: 193px;
  background-image: url("../images/normalBannerAnim/solution/iotPlatform/float_base.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.document,
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.document-detail {
  top: 10px;
  right: 275px;
  width: 201px;
  height: 231px;
  background-image: url("../images/normalBannerAnim/technicalSupport/float_document.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.after-sales-case {
  top: 29px;
  right: 275px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/technicalSupport/float_after_sales_case.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.image-gallery,
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.image-gallery-detail {
  top: 7px;
  right: 275px;
  width: 201px;
  height: 232px;
  background-image: url("../images/normalBannerAnim/technicalSupport/float_image_gallery.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.guarantee {
  top: 36px;
  right: 275px;
  width: 201px;
  height: 203px;
  background-image: url("../images/normalBannerAnim/technicalSupport/float_guarantee.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.firm-news,
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.firm-news-detail {
  top: 29px;
  right: 275px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/news/float_news.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.about-us {
  top: 29px;
  right: 275px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/about/float_about_us.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.leadership {
  top: 31px;
  right: 275px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/about/float_leadership.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.honor {
  top: 29px;
  right: 275px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/about/float_honor.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.recruitment {
  top: 29px;
  right: 275px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/about/float_recruitment.webp");
}
.banner .banner-content .normal-banner-anim-base-img .banner-float-img.contact-us {
  top: 29px;
  right: 275px;
  width: 201px;
  height: 210px;
  background-image: url("../images/normalBannerAnim/about/float_contact_us.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph {
  display: none;
  min-width: 300px;
  height: 170px;
  background-size: 100% 100%;
}
.banner .banner-content .normal-banner-static-img-pad-ph.big-data {
  background-image: url("../images/solution/bigData/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.high-performance-computing {
  background-image: url("../images/solution/highPerformanceComputing/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.cloud-computing-private-cloud {
  background-image: url("../images/solution/cloudComputingPrivateCloud/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.cloud-native-container-storage {
  background-image: url("../images/solution/cloudNativeContainerStorage/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.data-backup {
  background-image: url("../images/solution/dataBackup/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.new-energy-vehicle {
  background-image: url("../images/solution/newEnergyVehicle/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.intelligent-campus {
  background-image: url("../images/solution/intelligentCampus/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.intelligent-security {
  background-image: url("../images/solution/intelligentSecurity/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.ai {
  background-image: url("../images/solution/ai/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.intelligent-campus {
  background-image: url("../images/solution/intelligentCampus/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.bright-project {
  background-image: url("../images/solution/brightProject/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.major-event-security {
  background-image: url("../images/solution/majorEventSecurity/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.intelligent-community {
  background-image: url("../images/solution/intelligentCommunity/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.intelligent-community-security {
  background-image: url("../images/solution/intelligentCommunitySecurity/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.intelligent-elevator {
  background-image: url("../images/solution/intelligentElevator/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.high-altitude-throw-object {
  background-image: url("../images/solution/highAltitudeThrowObject/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.intelligent-park {
  background-image: url("../images/solution/intelligentPark/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.intelligent-scenic-spot {
  background-image: url("../images/solution/intelligentScenicSpot/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.all-area-tourism {
  background-image: url("../images/solution/allAreaTourism/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.carbon-neutrality {
  background-image: url("../images/solution/carbonNeutrality/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.intelligent-garden {
  background-image: url("../images/solution/intelligentGarden/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.emergency-command {
  background-image: url("../images/solution/emergencyCommand/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.safety-production-emergency {
  background-image: url("../images/solution/safetyProductionEmergency/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.iot-platform {
  background-image: url("../images/solution/iotPlatform/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.document,
.banner .banner-content .normal-banner-static-img-pad-ph.document-detail {
  background-image: url("../images/technicalSupport/document/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.after-sales-case {
  background-image: url("../images/technicalSupport/afterSalesCase/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.image-gallery,
.banner .banner-content .normal-banner-static-img-pad-ph.image-gallery-detail {
  background-image: url("../images/technicalSupport/imageGallery/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.guarantee {
  background-image: url("../images/technicalSupport/guarantee/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.firm-news,
.banner .banner-content .normal-banner-static-img-pad-ph.firm-news-detail {
  background-image: url("../images/firmNews/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.about-us {
  background-image: url("../images/about/aboutUs/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.leadership {
  background-image: url("../images/about/leadership/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.honor {
  background-image: url("../images/about/honor/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.recruitment {
  background-image: url("../images/about/recruitment/banner_static_img.webp");
}
.banner .banner-content .normal-banner-static-img-pad-ph.contact-us {
  background-image: url("../images/about/contactUs/banner_static_img.webp");
}
.oos-placeholder {
  height: 100%;
  background-color: #fff;
}
.oos-placeholder .placeholder-banner {
  height: 450px;
  background-color: #2b3a58;
}
.oos-placeholder .placeholder-content {
  height: calc(100% - 450px);
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .oos-placeholder .placeholder-banner {
    height: 250px;
  }
  .oos-placeholder .placeholder-content {
    height: calc(100% - 250px);
  }
}
@media screen and (max-width: 450px) {
  .oos-placeholder .placeholder-banner {
    height: 390px;
  }
  .oos-placeholder .placeholder-content {
    height: calc(100% - 390px);
  }
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
html {
  font-family: PingFangSC, helvetica neue, hiragino sans gb, arial, microsoft yahei ui, microsoft yahei, simsun, sans-serif;
}
body.scroll-disabled {
  height: 100%;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  body {
    min-width: 1200px;
  }
}
@media screen and (max-width: 768px) {
  body {
    min-width: 360px;
    -webkit-tap-highlight-color: transparent;
  }
}
input,
textarea {
  outline: none;
  border-radius: 0;
}
.oos-main-content {
  min-height: 1100px;
}
@media screen and (max-width: 450px) {
  .oos-main-content {
    min-height: 900px;
  }
}
.oos-top-bar {
  position: absolute;
  top: 0;
  padding: 0 30px;
  width: 100%;
  min-width: 1200px;
  height: 60px;
  line-height: 60px;
  background-color: #23314b;
  z-index: 5;
  user-select: none;
}
.oos-top-bar.news-carousel-active {
  background-color: rgba(42, 55, 78, 0.4);
  background-image: linear-gradient(rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01));
}
.oos-top-bar.news-carousel-active .top-bar-content .search-box {
  background-color: #1256cd;
}
.oos-top-bar.news-carousel-active .top-bar-content .search-box.active {
  background-color: #1256cd !important;
}
.oos-top-bar.news-carousel-active .top-bar-content .search-box .search-input {
  color: #fff;
  background-color: #1256cd;
}
.oos-top-bar.news-carousel-active .top-bar-content .search-box .search-input::-webkit-input-placeholder,
.oos-top-bar.news-carousel-active .top-bar-content .search-box .search-input::-moz-placeholder,
.oos-top-bar.news-carousel-active .top-bar-content .search-box .search-input::-ms-input-placeholder {
  color: #d3e7f0 !important;
  font-weight: 400 !important;
}
.oos-top-bar .top-bar-content {
  margin: 0 auto;
  max-width: 1920px;
  display: block;
  zoom: 1;
  opacity: 0.96;
}
.oos-top-bar .top-bar-content::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.oos-top-bar .top-bar-content .logo {
  float: left;
  margin-top: 15px;
  width: 80px;
  height: 30px;
  background-image: url("../images/bar/logo.webp");
  background-size: 100% 100%;
}
.oos-top-bar .top-bar-content .logo:hover {
  cursor: pointer;
}
.oos-top-bar .top-bar-content .menu-box {
  margin-left: 35px;
  display: inline-block;
}
.oos-top-bar .top-bar-content .menu-box .menu {
  float: left;
  position: relative;
  margin-right: 35px;
  padding: 0 15px;
  font-size: 12px;
  letter-spacing: 2px;
  cursor: pointer;
}
.oos-top-bar .top-bar-content .menu-box .menu:nth-last-child(1) {
  margin-right: 0;
}
.oos-top-bar .top-bar-content .menu-box .menu .menu-text {
  position: relative;
  display: block;
  color: rgba(255, 255, 255, 0.6);
}
.oos-top-bar .top-bar-content .menu-box .menu .menu-text.active {
  color: #fff;
}
.oos-top-bar .top-bar-content .menu-box .menu .menu-text.active::before {
  content: " ";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 15px;
  height: 2px;
  background-color: #4c8ffb;
  transform: translateX(-50%);
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu {
  display: none;
  position: absolute;
  top: 60px;
  left: -30px;
  padding: 0 30px;
  color: #3e3e3e;
  letter-spacing: 1px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 1px 1px #e9e9e9;
  z-index: 1;
  animation: oos-top-bar-drop-down-menu-fade-in 0.4s ease;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu.active {
  display: block;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu::before {
  content: " ";
  position: absolute;
  top: -16px;
  left: 70px;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu.product {
  width: 350px;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu.product::before {
  left: 50px;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu.solution {
  width: 360px;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu.solution::before {
  left: 65px;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu.technical-support {
  width: 375px;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu.technical-support::before {
  left: 65px;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu.about {
  width: 380px;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu.about::before {
  left: 52px;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu .menu-title {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  color: #2e394c;
  border-bottom: 1px solid #d1d5db;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu .sub-menu-box {
  padding: 10px 0 25px;
  line-height: 20px;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu .sub-menu-box .sub-menu-row .sub-menu {
  margin-right: 30px;
  display: inline-block;
  line-height: 40px;
  font-size: 12px;
  color: #2e394c;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu .sub-menu-box .sub-menu-row .sub-menu:hover {
  color: #2e96fb;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu .sub-menu-box .sub-menu-row .sub-menu:nth-last-child(1) {
  margin-right: 0;
}
.oos-top-bar .top-bar-content .menu-box .menu .normal-drop-down-menu .sub-menu-box .sub-menu-row .sub-menu.active {
  color: #2e96fb;
  border-bottom: 2px solid #4c8ffb;
}
.oos-top-bar .top-bar-content .button-box {
  float: right;
}
.oos-top-bar .top-bar-content .button-box .button {
  float: right;
  padding: 10px;
  min-height: 60px;
  line-height: 60px;
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  color: #adb4c0;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
  padding: 0 20px;
}
.oos-top-bar .top-bar-content .button-box .button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-top-bar .top-bar-content .button-box .button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-top-bar .top-bar-content .button-box .button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-top-bar .top-bar-content .button-box .button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-top-bar .top-bar-content .button-box .button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-top-bar .top-bar-content .button-box .button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-top-bar .top-bar-content .button-box .button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-top-bar .top-bar-content .button-box .button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-top-bar .top-bar-content .button-box .button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-top-bar .top-bar-content .button-box .button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-top-bar .top-bar-content .button-box .button .oos-button-link-icon,
  .oos-top-bar .top-bar-content .button-box .button .oos-button-download-icon,
  .oos-top-bar .top-bar-content .button-box .button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-top-bar .top-bar-content .button-box .button-ph {
  display: none;
  margin-top: 20px;
  width: 20px;
  height: 20px;
  background-size: 100%;
}
.oos-top-bar .top-bar-content .button-box .button-ph.menu {
  float: right;
  background-image: url("../images/bar/menu_phone.webp");
}
.oos-top-bar .top-bar-content .button-box .button-ph.sign {
  float: right;
  margin-right: 20px;
  background-image: url("../images/bar/sign_phone.webp");
}
.oos-top-bar .top-bar-content .search-box {
  float: right;
  position: relative;
  margin-right: 50px;
  padding: 0 20px;
  height: 60px;
  background-color: #202b44;
  transition: padding 0.2s linear;
  cursor: pointer;
}
.oos-top-bar .top-bar-content .search-box.active {
  background-color: #202b44 !important;
  cursor: text;
}
.oos-top-bar .top-bar-content .search-box.active .search-input {
  width: 300px;
  cursor: text;
}
.oos-top-bar .top-bar-content .search-box .search-input {
  width: 220px;
  font-size: 12px;
  color: #b3b9c4;
  background-color: #202b44;
  border: none;
  outline: none;
  transition: width 0.3s linear;
  cursor: pointer;
}
.oos-top-bar .top-bar-content .search-box .search-input::-webkit-input-placeholder,
.oos-top-bar .top-bar-content .search-box .search-input::-moz-placeholder,
.oos-top-bar .top-bar-content .search-box .search-input::-ms-input-placeholder {
  color: #696969 !important;
  font-weight: 400 !important;
}
.oos-top-bar .top-bar-content .search-box .search-input:focus {
  border: none;
}
.oos-top-bar .top-bar-content .search-box .search-icon,
.oos-top-bar .top-bar-content .search-box .search-icon-ph {
  float: right;
  margin-top: 21px;
  width: 18px;
  height: 18px;
  background-image: url("../images/home/search.svg");
  background-size: 100% 100%;
}
.oos-top-bar .top-bar-content .search-box .search-icon {
  display: block;
}
.oos-top-bar .top-bar-content .search-box .search-icon-ph {
  display: none;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  max-height: 275px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-y: auto;
  z-index: 3;
  animation: fade-in-bottom-up 0.3s;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box.visible {
  display: block;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item {
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  letter-spacing: 1px;
  border-bottom: 1px solid #dedede;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item:nth-last-child(1) {
  border-bottom: none;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item:not(.empty) {
  cursor: pointer;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item:not(.empty):hover {
  background-color: #f5f5f5;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item.empty {
  color: #888;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .result-item-text {
  margin-right: 20px;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-link-icon,
  .oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-download-icon,
  .oos-top-bar .top-bar-content .search-box .result-drop-down-box .result-item .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (max-width: 1500px) {
  .oos-top-bar .top-bar-content .menu-box .menu {
    margin-right: 20px;
  }
  .oos-top-bar .top-bar-content .search-box {
    margin-right: 30px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-top-bar .top-bar-content .menu-box .menu {
    margin-right: 10px;
  }
  .oos-top-bar .top-bar-content .search-box {
    margin-right: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-top-bar .top-bar-content .search-box {
    margin-right: 10px;
    background-color: #23314b;
  }
  .oos-top-bar .top-bar-content .search-box:not(.active) .search-input {
    background-color: #23314b;
  }
  .oos-top-bar .top-bar-content .search-box.active .search-input {
    width: 250px;
  }
  .oos-top-bar .top-bar-content .search-box .search-icon {
    display: none;
  }
  .oos-top-bar .top-bar-content .search-box .search-icon-ph {
    display: block;
  }
  .oos-top-bar .top-bar-content .search-box .search-input {
    width: 0;
  }
}
@media screen and (max-width: 768px) {
  .oos-top-bar .top-bar-content {
    padding: 0;
    min-width: 100%;
  }
  .oos-top-bar .top-bar-content .menu-box {
    display: none;
  }
  .oos-top-bar .top-bar-content .search-box {
    position: static;
    margin-right: 10px;
  }
  .oos-top-bar .top-bar-content .search-box.active {
    margin-right: 10px;
    padding-right: 10px;
  }
  .oos-top-bar .top-bar-content .search-box.active .search-input {
    width: 120px;
  }
  .oos-top-bar .top-bar-content .search-box .result-box {
    left: 15px;
    width: calc(100% - (15px * 2));
  }
  .oos-top-bar .top-bar-content .button-box .button.sign-in,
  .oos-top-bar .top-bar-content .button-box .button.sign-out,
  .oos-top-bar .top-bar-content .button-box .button.trial {
    display: none;
  }
  .oos-top-bar .top-bar-content .button-box .button-ph {
    display: block;
  }
}
@media screen and (max-width: 450px) {
  .oos-top-bar .top-bar-content .search-box .result-drop-down-box {
    left: 20px;
    right: 20px;
    width: auto;
  }
}
.oos-top-bar .product-drop-down-menu {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  width: 100vw;
  min-width: 1200px;
  height: 485px;
  background-color: #253250;
  box-shadow: 1px 3px 10px 0 rgba(42, 57, 87, 0.8);
  z-index: 1;
  animation: oos-top-bar-drop-down-menu-fade-in 0.4s ease;
}
.oos-top-bar .product-drop-down-menu.active {
  display: block;
}
.oos-top-bar .product-drop-down-menu .menu-box {
  padding: 10px 80px 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item {
  margin-left: 40px;
  margin-top: 30px;
  position: relative;
  padding: 30px 40px;
  min-width: 520px;
  height: 210px;
  background-color: #f2f8fd;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .title,
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .sub-title {
  position: relative;
  width: 320px;
  z-index: 3;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .title {
  line-height: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #283553;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .sub-title {
  padding-top: 10px;
  line-height: 20px;
  font-size: 13px;
  color: #9ca8c5;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  -ms-line-clamp: 2;
  -ms-box-orient: vertical;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button {
  margin-top: 40px;
  margin-left: -10px;
  padding: 10px;
  min-height: 12px;
  line-height: 12px;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  color: #adb4c0;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-link-icon,
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-download-icon,
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .view-detail-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 268px;
  height: 156px;
  background-size: 100% 100%;
  z-index: 2;
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .bg.uniio {
  background-image: url("../images/product/uniio/banner_static_img.webp");
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .bg.orcabd {
  background-image: url("../images/product/orcabd/banner_static_img.webp");
}
.oos-top-bar .product-drop-down-menu .menu-box .menu-item .bg.orcafs {
  background-image: url("../images/product/orcafs/banner_static_img.webp");
}
@media screen and (max-width: 1850px) {
  .oos-top-bar .product-drop-down-menu .menu-box {
    padding: 10px 60px 40px;
  }
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item {
    min-width: 490px;
  }
}
@media screen and (max-width: 1800px) {
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item {
    min-width: 480px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-top-bar .product-drop-down-menu .menu-box {
    padding: 10px 40px 30px;
  }
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item {
    min-width: 470px;
  }
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .title,
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .sub-title {
    width: 300px;
  }
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .title {
    font-size: 18px;
  }
}
@media screen and (max-width: 1680px) {
  .oos-top-bar .product-drop-down-menu .menu-box {
    padding: 10px 20px 20px;
  }
}
@media screen and (max-width: 1650px) {
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item {
    margin-left: 20px;
    min-width: 470px;
  }
}
@media screen and (max-width: 1550px) {
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item {
    min-width: 460px;
  }
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .title,
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .sub-title {
    width: 290px;
  }
}
@media screen and (max-width: 1490px) {
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item {
    min-width: 430px;
  }
}
@media screen and (max-width: 1390px) {
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item {
    margin-left: 40px;
    min-width: 560px;
  }
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .title,
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .sub-title {
    width: 300px;
  }
}
@media screen and (max-width: 1260px) {
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item {
    margin-left: 40px;
    min-width: 510px;
  }
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .title,
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .sub-title {
    width: 300px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item {
    min-width: 490px;
  }
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .title,
  .oos-top-bar .product-drop-down-menu .menu-box .menu-item .sub-title {
    width: 290px;
  }
}
.oos-top-bar .solution-drop-down-menu {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  width: 100vw;
  min-width: 1200px;
  height: 485px;
  background-color: #253250;
  box-shadow: 1px 3px 10px 0 rgba(42, 57, 87, 0.8);
  z-index: 1;
  animation: oos-top-bar-drop-down-menu-fade-in 0.4s ease;
}
.oos-top-bar .solution-drop-down-menu.active {
  display: flex;
  flex-direction: row;
}
.oos-top-bar .solution-drop-down-menu .category-menu-box {
  width: 180px;
  height: 100%;
  background-color: #202e49;
}
.oos-top-bar .solution-drop-down-menu .category-menu-box .category-menu-item {
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
.oos-top-bar .solution-drop-down-menu .category-menu-box .category-menu-item.active {
  background-color: #1d2841;
}
.oos-top-bar .solution-drop-down-menu .category-menu-box .category-menu-item.active .menu-text {
  color: #fff;
}
.oos-top-bar .solution-drop-down-menu .category-menu-box .category-menu-item .menu-text {
  padding-left: 35px;
  display: inline-block;
  width: 100%;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
}
.oos-top-bar .solution-drop-down-menu .category-content {
  width: calc(100% - 180px);
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-menu-box {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: 40px;
  background-color: #202e49;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-menu-box .secondary-category-menu-item {
  position: relative;
  padding: 0 20px;
  cursor: pointer;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-menu-box .secondary-category-menu-item .menu-text {
  display: inline-block;
  height: 38px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-menu-box .secondary-category-menu-item.active .menu-text {
  color: #fff;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-menu-box .secondary-category-menu-item.active .menu-text:before {
  content: " ";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 15px;
  height: 2px;
  background-color: #4c8ffb;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box {
  padding: 0 40px 40px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: calc(100% - 40px);
  overflow-y: auto;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
  margin-left: 40px;
  margin-top: 30px;
  position: relative;
  padding: 30px 40px;
  min-width: 500px;
  height: 210px;
  background-color: #f2f8fd;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title,
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
  position: relative;
  z-index: 3;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title {
  width: 280px;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
  width: 270px;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title {
  line-height: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #283553;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
  padding-top: 10px;
  line-height: 20px;
  font-size: 13px;
  color: #9ca8c5;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  -ms-line-clamp: 2;
  -ms-box-orient: vertical;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button {
  margin-top: 40px;
  margin-left: -10px;
  padding: 10px;
  min-height: 12px;
  line-height: 12px;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  color: #adb4c0;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-link-icon,
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-download-icon,
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .view-detail-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .big-arrow {
  position: absolute;
  top: 40px;
  right: 30px;
  width: 70px;
  height: 51px;
  background-image: url("../images/bar/arrow_big.webp");
  background-size: 100% 100%;
  opacity: 0.6;
  z-index: 1;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 330px;
  height: 170px;
  background-size: 100% 100%;
  z-index: 2;
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.big-data {
  background-image: url("../images/solution/bigData/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.high-performance-computing {
  background-image: url("../images/solution/highPerformanceComputing/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.cloud-computing-private-cloud {
  background-image: url("../images/solution/cloudComputingPrivateCloud/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.cloud-native-container-storage {
  background-image: url("../images/solution/cloudNativeContainerStorage/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.data-backup {
  background-image: url("../images/solution/dataBackup/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.new-energy-vehicle {
  background-image: url("../images/solution/newEnergyVehicle/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.intelligent-campus {
  background-image: url("../images/solution/intelligentCampus/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.intelligent-security {
  background-image: url("../images/solution/intelligentSecurity/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.ai {
  background-image: url("../images/solution/ai/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.intelligent-campus {
  background-image: url("../images/solution/intelligentCampus/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.bright-project {
  background-image: url("../images/solution/brightProject/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.major-event-security {
  background-image: url("../images/solution/majorEventSecurity/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.intelligent-community {
  background-image: url("../images/solution/intelligentCommunity/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.intelligent-community-security {
  background-image: url("../images/solution/intelligentCommunitySecurity/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.intelligent-elevator {
  background-image: url("../images/solution/intelligentElevator/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.high-altitude-throw-object {
  background-image: url("../images/solution/highAltitudeThrowObject/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.intelligent-park {
  background-image: url("../images/solution/intelligentPark/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.intelligent-scenic-spot {
  background-image: url("../images/solution/intelligentScenicSpot/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.all-area-tourism {
  background-image: url("../images/solution/allAreaTourism/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.carbon-neutrality {
  background-image: url("../images/solution/carbonNeutrality/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.intelligent-garden {
  background-image: url("../images/solution/intelligentGarden/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.emergency-command {
  background-image: url("../images/solution/emergencyCommand/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.safety-production-emergency {
  background-image: url("../images/solution/safetyProductionEmergency/banner_static_img.webp");
}
.oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .bg.iot-platform {
  background-image: url("../images/solution/iotPlatform/banner_static_img.webp");
}
@media screen and (max-width: 1850px) {
  .oos-top-bar .solution-drop-down-menu .category-menu-box {
    width: 170px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box {
    padding: 0 30px 30px 0;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    min-width: 480px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 250px;
  }
}
@media screen and (max-width: 1800px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    min-width: 480px;
  }
}
@media screen and (max-width: 1770px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box {
    padding: 0 30px 30px 0;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    min-width: 460px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 240px;
  }
}
@media screen and (max-width: 1710px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box {
    padding: 0 30px 30px 0;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    min-width: 440px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title,
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 250px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title {
    font-size: 18px;
  }
}
@media screen and (max-width: 1680px) {
  .oos-top-bar .solution-drop-down-menu .category-menu-box {
    width: 160px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content {
    width: calc(100% - 160px);
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box {
    padding: 0 20px 20px 0;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    min-width: 450px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title,
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 240px;
  }
}
@media screen and (max-width: 1650px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    margin-left: 20px;
    min-width: 430px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title,
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 220px;
  }
}
@media screen and (max-width: 1550px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    min-width: 410px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title {
    width: 200px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 190px;
  }
}
@media screen and (max-width: 1490px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    margin-left: 40px;
    min-width: 560px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title,
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 300px;
  }
}
@media screen and (max-width: 1390px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    margin-left: 40px;
    min-width: 500px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title,
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 260px;
  }
}
@media screen and (max-width: 1260px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    margin-left: 40px;
    min-width: 480px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title,
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 240px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item {
    min-width: 455px;
  }
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .title,
  .oos-top-bar .solution-drop-down-menu .category-content .secondary-category-content-box .solution-section-item .sub-title {
    width: 225px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-top-bar.news-carousel-active .top-bar-content .search-box:not(.active) .search-input {
    background-color: #1256cd;
  }
}
@media screen and (max-width: 768px) {
  .oos-top-bar {
    min-width: 100%;
    padding: 0 20px;
  }
}
.oos-pad-and-ph-drop-down-menu {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  background: #faf9f9;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
  user-select: none;
  z-index: 99;
}
.oos-pad-and-ph-drop-down-menu.active {
  transform: translateY(0);
}
.oos-pad-and-ph-drop-down-menu.active .menu-nav-title {
  transform: translateY(0);
}
.oos-pad-and-ph-drop-down-menu.active .menu-section {
  transform: translateX(0);
}
.oos-pad-and-ph-drop-down-menu .menu-nav-title {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  text-align: center;
  transform: translateY(-100%);
  transition: transform 0.3s ease 0.3s;
}
.oos-pad-and-ph-drop-down-menu .menu-section {
  position: relative;
  font-size: 13px;
  color: #3e3e3e;
  transform: translateX(120%);
}
.oos-pad-and-ph-drop-down-menu .menu-section::after {
  content: " ";
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 1px solid #d8d7d7;
  transform: scale(0.5, 0.5);
  transform-origin: top left;
  z-index: -1;
}
.oos-pad-and-ph-drop-down-menu .menu-section:nth-child(1) {
  transition: transform 0.3s ease 0.15s;
}
.oos-pad-and-ph-drop-down-menu .menu-section:nth-child(2) {
  transition: transform 0.3s ease 0.2s;
}
.oos-pad-and-ph-drop-down-menu .menu-section:nth-child(3) {
  transition: transform 0.3s ease 0.25s;
}
.oos-pad-and-ph-drop-down-menu .menu-section:nth-child(4) {
  transition: transform 0.3s ease 0.3s;
}
.oos-pad-and-ph-drop-down-menu .menu-section:nth-child(5) {
  transition: transform 0.3s ease 0.35s;
}
.oos-pad-and-ph-drop-down-menu .menu-section:nth-child(6) {
  transition: transform 0.3s ease 0.4s;
}
.oos-pad-and-ph-drop-down-menu .menu-section:nth-child(7) {
  transition: transform 0.3s ease 0.45s;
}
.oos-pad-and-ph-drop-down-menu .menu-section:nth-child(8) {
  transition: transform 0.3s ease 0.5s;
}
.oos-pad-and-ph-drop-down-menu .menu-section .menu-solution-category-name-ph {
  position: relative;
  height: 40px;
  line-height: 40px;
}
.oos-pad-and-ph-drop-down-menu .menu-section .menu-solution-category-name-ph.active .name-ph {
  font-size: 13px;
  color: #545c6c;
}
.oos-pad-and-ph-drop-down-menu .menu-section .menu-solution-category-name-ph.active .arrow-ph {
  background-image: url("../images/general/arrow_downward_active.svg");
  transform: rotate(180deg);
}
.oos-pad-and-ph-drop-down-menu .menu-section .menu-solution-category-name-ph .name-ph {
  font-size: 13px;
}
.oos-pad-and-ph-drop-down-menu .menu-section .menu-solution-category-name-ph .arrow-ph {
  content: " ";
  position: absolute;
  top: 12px;
  right: 0;
  width: 12px;
  height: 20px;
  background-image: url("../images/general/arrow_downward.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-pad-and-ph-drop-down-menu .menu-section .menu-box.solution {
  display: none;
}
.oos-pad-and-ph-drop-down-menu .menu-section .menu-box.solution.active {
  display: block;
}
.oos-pad-and-ph-drop-down-menu .menu-section .menu-box .menu-item {
  display: inline-block;
  padding: 15px 15px 15px 0;
  height: 50px;
  vertical-align: middle;
  z-index: 1;
}
.oos-pad-and-ph-drop-down-menu .menu-section .menu-box .menu-item.active {
  color: #2e96fb;
}
.oos-pad-and-ph-drop-down-menu .close-button {
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  transform: translateX(-50%);
}
.oos-pad-and-ph-drop-down-menu .close-button img {
  display: inline-block;
  margin-top: 10px;
  vertical-align: 10%;
  width: 20px;
  height: 20px;
}
.oos-pad-and-ph-drop-down-menu .close-button img.rock {
  animation: oos-pad-and-ph-close-button-motion 0.2s linear infinite;
}
@media screen and (max-width: 768px) {
  .oos-pad-and-ph-drop-down-menu {
    display: block;
  }
}
.oos-float-bar {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 120px;
  z-index: 10;
}
.oos-float-bar.visible {
  display: block;
}
.oos-float-bar .scroll-to-top {
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #23314b;
  border: 1px solid #1855d3;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0.8;
}
.oos-float-bar .scroll-to-top:hover .arrow {
  transform: translateY(-2px);
}
.oos-float-bar .scroll-to-top .arrow {
  display: inline-block;
  margin-top: 4px;
  width: 32px;
  height: 32px;
  background-image: url("../images/bar/arrow_scroll_top.webp");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-float-bar .apply-trial {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  color: #adb4c0;
  background-color: #23314b;
  border: 1px solid #1855d3;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0.8;
}
@media screen and (min-width: 1921px) {
  .oos-float-bar {
    right: calc((100vw - 1920px) / 2 + 20px);
  }
}
@media screen and (max-width: 450px) {
  .oos-float-bar {
    display: none !important;
  }
}
.oos-apply-trial {
  position: relative;
  padding: 0 calc((100% - 1600px) / 2);
  background-color: #1e2536;
  overflow: hidden;
}
.oos-apply-trial::before {
  content: " ";
  position: absolute;
  top: 0;
  right: calc((100% - 1600px) / 2);
  display: block;
  width: 800px;
  height: 150px;
  background-image: url("../images/bar/apply_trial_bg.webp");
  background-size: 100% 100%;
  z-index: 0;
  transition: transform 0.2s linear;
}
.oos-apply-trial:hover::before {
  transform: scale(1.02);
}
.oos-apply-trial .apply-trial-content {
  position: relative;
  margin: 0 auto;
  padding: 35px 0;
  height: 150px;
  overflow: hidden;
  z-index: 1;
}
.oos-apply-trial .apply-trial-content .apply-trial-title {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
}
.oos-apply-trial .apply-trial-content .apply-trial-button {
  margin-top: 17px;
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  background-color: #1855d3;
}
.oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-apply-trial .apply-trial-content .apply-trial-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-apply-trial .apply-trial-content .apply-trial-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-apply-trial .apply-trial-content .apply-trial-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-link-icon,
  .oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-download-icon,
  .oos-apply-trial .apply-trial-content .apply-trial-button .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (max-width: 1700px) {
  .oos-apply-trial {
    padding: 0 calc((100% - 1400px) / 2);
  }
  .oos-apply-trial::before {
    right: calc((100% - 1400px) / 2);
  }
}
@media screen and (max-width: 1400px) {
  .oos-apply-trial {
    padding: 0 calc((100% - 1200px) / 2);
  }
  .oos-apply-trial::before {
    right: calc((100% - 1200px) / 2);
  }
  .oos-apply-trial .apply-trial-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .oos-apply-trial .apply-trial-content .apply-trial-title {
    text-align: center;
  }
  .oos-apply-trial .apply-trial-content .apply-trial-button {
    margin: 0 auto;
  }
}
@media screen and (max-width: 1200px) {
  .oos-apply-trial {
    padding: 0 calc((100% - 1000px) / 2);
  }
  .oos-apply-trial::before {
    right: calc((100% - 1000px) / 2);
  }
}
@media screen and (max-width: 1000px) {
  .oos-apply-trial::before {
    right: 50%;
    transform: translateX(50%);
    transition: none;
  }
  .oos-apply-trial:hover::before {
    transform: translateX(50%);
  }
  .oos-apply-trial .apply-trial-content .apply-trial-title {
    font-size: 16px;
  }
  .oos-apply-trial .apply-trial-content .apply-trial-button {
    display: inline-block;
  }
}
@media screen and (max-width: 800px) {
  .oos-apply-trial::before {
    width: 100%;
  }
}
@media screen and (max-width: 440px) {
  .oos-apply-trial::before {
    right: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    transform: none;
  }
  .oos-apply-trial:hover::before {
    transform: none;
  }
}
.oos-service {
  padding: 42px 0;
  background-color: #272e42;
}
.oos-service .service-box {
  margin: 0 auto;
  width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.oos-service .service-box .service-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  line-height: 40px;
}
.oos-service .service-box .service-item .service-icon {
  width: 30px;
  height: 30px;
  background-size: 100% 100%;
}
.oos-service .service-box .service-item .service-icon.full-day {
  background-image: url("../images/bar/service_full_day.webp");
}
.oos-service .service-box .service-item .service-icon.original-factory {
  background-image: url("../images/bar/service_original_factory.webp");
}
.oos-service .service-box .service-item .service-icon.trial {
  background-image: url("../images/bar/service_trial.webp");
}
.oos-service .service-box .service-item .service-icon.all-period {
  background-image: url("../images/bar/service_all_period.webp");
}
.oos-service .service-box .service-item .service-text {
  margin-left: 10px;
  font-size: 20px;
  color: #fff;
  letter-spacing: 1px;
}
@media screen and (max-width: 1000px) {
  .oos-service .service-box .service-item .service-text {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .oos-service {
    padding: 30px 20px;
  }
  .oos-service .service-box {
    width: 100%;
    flex-wrap: wrap;
  }
  .oos-service .service-box .service-item {
    min-width: 50%;
    justify-content: center;
  }
  .oos-service .service-box .service-item:nth-child(n + 3) {
    margin-top: 20px;
  }
  .oos-service .service-box .service-item .service-text {
    font-size: 16px;
  }
}
@media screen and (max-width: 450px) {
  .oos-service {
    padding: 30px 20px;
  }
  .oos-service .service-box .service-item .service-icon {
    width: 20px;
    height: 20px;
  }
  .oos-service .service-box .service-item .service-text {
    font-size: 14px;
  }
}
.oos-foot-bar {
  padding: 45px calc((100% - 1600px) / 2) 0;
  color: #fff;
  background-color: #272e42;
  border-top: 1px solid #3d4355;
}
.oos-foot-bar .foot-bar-content {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 390px;
}
.oos-foot-bar .foot-bar-content .nav-box {
  float: left;
}
.oos-foot-bar .foot-bar-content .nav-box .nav-section {
  float: left;
  width: 180px;
}
.oos-foot-bar .foot-bar-content .nav-box .nav-section.product {
  width: 300px;
}
.oos-foot-bar .foot-bar-content .nav-box .nav-section .title {
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
}
.oos-foot-bar .foot-bar-content .nav-box .nav-section .item {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 300;
  color: #a5acc0;
  letter-spacing: 1px;
  cursor: pointer;
}
.oos-foot-bar .foot-bar-content .contact-box {
  float: right;
  text-align: right;
  letter-spacing: 1px;
}
.oos-foot-bar .foot-bar-content .contact-box .er-code-img {
  margin-top: 4px;
  display: inline-block;
  width: 155px;
  height: 155px;
  background-image: url("../images/bar/er_code.webp");
  background-size: 100% 100%;
  border-radius: 5px;
}
.oos-foot-bar .foot-bar-content .contact-box .er-code-title {
  margin-top: 15px;
  font-size: 20px;
}
.oos-foot-bar .foot-bar-content .contact-box .er-code-desc,
.oos-foot-bar .foot-bar-content .contact-box .phone-time,
.oos-foot-bar .foot-bar-content .contact-box .location,
.oos-foot-bar .foot-bar-content .contact-box .phone {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 300;
}
.oos-foot-bar .foot-bar-content .contact-box .phone {
  margin-top: 20px;
  font-size: 30px;
  letter-spacing: 1px;
}
.oos-foot-bar .foot-bar-content .contact-box .location {
  margin-top: 6px;
}
.oos-foot-bar .foot-bar-content .contact-box .er-code-desc,
.oos-foot-bar .foot-bar-content .contact-box .phone-time {
  font-size: 12px;
}
.oos-foot-bar .foot-bar-content .copyright-and-record {
  position: absolute;
  left: 0;
  bottom: 55px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: #8f9299;
}
.oos-foot-bar .foot-bar-content .copyright-and-record .copyright {
  display: inline;
}
.oos-foot-bar .foot-bar-content .copyright-and-record .copyright .divider {
  display: inline;
}
.oos-foot-bar .foot-bar-content .copyright-and-record .record {
  display: inline;
}
.oos-foot-bar .foot-bar-content .copyright-and-record .record a {
  color: #8f9299;
  text-decoration: none;
  height: 16px;
}
@media screen and (max-width: 1700px) {
  .oos-foot-bar {
    padding: 45px calc((100% - 1400px) / 2) 0;
  }
}
@media screen and (max-width: 1400px) {
  .oos-foot-bar {
    padding: 45px calc((100% - 1200px) / 2) 0;
  }
  .oos-foot-bar .foot-bar-content .nav-box .nav-section {
    width: 150px;
  }
  .oos-foot-bar .foot-bar-content .nav-box .nav-section.product {
    width: 270px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-foot-bar {
    padding: 45px calc((100% - 1000px) / 2) 0;
  }
  .oos-foot-bar .foot-bar-content {
    display: flex;
    flex-direction: column;
    height: 570px;
  }
  .oos-foot-bar .foot-bar-content .nav-box {
    display: flex;
    flex-direction: row;
  }
  .oos-foot-bar .foot-bar-content .nav-box .nav-section {
    flex: 1;
  }
  .oos-foot-bar .foot-bar-content .nav-box .nav-section.product {
    flex: 1.6;
    min-width: 210px;
  }
  .oos-foot-bar .foot-bar-content .contact-box {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    float: none;
    text-align: left;
  }
  .oos-foot-bar .foot-bar-content .contact-box .contact-text-content {
    margin-left: 30px;
  }
  .oos-foot-bar .foot-bar-content .contact-box .contact-text-content .er-code-title {
    margin-top: 10px;
    font-size: 18px;
  }
  .oos-foot-bar .foot-bar-content .contact-box .contact-text-content .phone {
    font-size: 22px;
  }
  .oos-foot-bar .foot-bar-content .copyright-and-record {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .oos-foot-bar {
    padding: 45px 20px 0;
  }
  .oos-foot-bar .foot-bar-content .copyright-and-record {
    font-size: 10px;
  }
}
@media screen and (max-width: 550px) {
  .oos-foot-bar {
    padding: 45px 20px 0;
  }
  .oos-foot-bar .foot-bar-content {
    display: block;
    height: 335px;
  }
  .oos-foot-bar .foot-bar-content .nav-box {
    display: none;
  }
  .oos-foot-bar .foot-bar-content .contact-box {
    margin-top: 0;
    display: block;
    text-align: right;
  }
  .oos-foot-bar .foot-bar-content .contact-box .er-code-img {
    margin-top: 0;
    width: 100px;
    height: 100px;
  }
  .oos-foot-bar .foot-bar-content .contact-box .contact-text-content {
    margin-left: 0;
  }
  .oos-foot-bar .foot-bar-content .copyright-and-record {
    bottom: 30px;
    width: 100%;
    text-align: right;
    font-size: 10px;
  }
  .oos-foot-bar .foot-bar-content .copyright-and-record .copyright {
    display: block;
  }
  .oos-foot-bar .foot-bar-content .copyright-and-record .copyright .divider {
    display: none;
  }
  .oos-foot-bar .foot-bar-content .copyright-and-record .record {
    display: block;
  }
}
.oos-cookie-policy-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 50px;
  background-color: #fff;
  transform: translateY(100%);
  transition: transform 0.8s ease;
  box-shadow: 0 10px 20px 10px hsla(0, 0%, 71%, 0.5);
  z-index: 3;
}
.oos-cookie-policy-bar.visible {
  display: block;
  transform: translateY(0);
}
.oos-cookie-policy-bar.hide {
  transform: translateY(100%);
}
.oos-cookie-policy-bar .oos-cookie-policy-content {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .text-wrap .title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .text-wrap .desc {
  margin-top: 10px;
  font-size: 14px;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .text-wrap .desc a {
  color: #608ff9;
  text-decoration: none;
  cursor: pointer;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button {
  display: inline-block;
  width: 110px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  background-color: #2a3957;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-link-icon,
  .oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-download-icon,
  .oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (max-width: 1700px) {
  .oos-cookie-policy-bar .oos-cookie-policy-content {
    width: 1400px;
  }
}
@media screen and (max-width: 1500px) {
  .oos-cookie-policy-bar .oos-cookie-policy-content {
    width: 1300px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-cookie-policy-bar .oos-cookie-policy-content {
    width: 1200px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-cookie-policy-bar .oos-cookie-policy-content {
    width: 1000px;
  }
  .oos-cookie-policy-bar .oos-cookie-policy-content .text-wrap {
    width: 800px;
  }
}
@media screen and (max-width: 1000px) {
  .oos-cookie-policy-bar .oos-cookie-policy-content {
    width: 800px;
  }
  .oos-cookie-policy-bar .oos-cookie-policy-content .text-wrap {
    width: 600px;
  }
}
@media screen and (max-width: 768px) {
  .oos-cookie-policy-bar {
    padding: 20px 20px;
  }
  .oos-cookie-policy-bar .oos-cookie-policy-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .oos-cookie-policy-bar .oos-cookie-policy-content .text-wrap {
    width: 100%;
  }
  .oos-cookie-policy-bar .oos-cookie-policy-content .text-wrap .desc {
    font-size: 12px;
  }
  .oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap {
    margin-top: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-cookie-policy-bar .oos-cookie-policy-content .text-wrap .title {
    font-size: 16px;
  }
  .oos-cookie-policy-bar .oos-cookie-policy-content .accept-button-wrap .accept-button {
    width: 100%;
  }
}
.oos-home .banner {
  position: relative;
  padding-top: 60px;
  height: 545px;
  background-color: #2b3a58;
  /*
		.banner-hot-tab-wrap {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 3;
			.banner-hot-tab-box {
				position: relative;
				margin: 0 auto;
				max-width: @content-width-1600;
				.banner-hot-tab {
					padding: 10px 15px;
					display: flex;
					flex-direction: row;
					width: 760px;
					height: 110px;
					background: linear-gradient(to bottom, #f1f3f6, @white);
					border: 1px solid #f1f3f6;
					border-radius: 5px;
					.oos-box-shadow(1px, 3px, 5px, 0, rgba(42, 57, 87, 0.2));
					user-select: none;

					.hot-link-item {
						flex: 1;
						width: 242.66px;
						padding-right: 10px;
						display: flex;
						flex-direction: row;
						align-items: center;
						height: 90px;
						z-index: 1;
						&:hover {
							cursor: pointer;
						}
						&:nth-child(1) {
							margin-left: 0;
						}
						.hot-link-item-icon {
							margin: 0 10px;
							width: 45px;
							height: 45px;
							&.product-icon {
								background-image: url("../../../images/home/banner/hot_tab_icon_product.webp");
								background-size: 100% 100%;
							}
							&.solution-icon {
								background-image: url("../../../images/home/banner/hot_tab_icon_solution.webp");
								background-size: 100% 100%;
							}
							&.news-icon {
								background-image: url("../../../images/home/banner/hot_tab_icon_news.webp");
								background-size: 100% 100%;
							}
						}
						.hot-link-item-text-content {
							// width: 242.66px - 60px - (15px * 2);
							width: ~"calc(100% - 45px - 20px - 5px)";
							margin-left: 5px;
							.title,
							.desc {
								.oos-text-overflow-ellipsis();
							}
							.title {
								font-size: @l-font;
								font-weight: 500;
								color: #2e394c;
								.oos-letter-spacing(1px);
							}
							.desc {
								margin-top: 2px;
								font-size: @xxs-font;
								color: #b3b9c4;
							}
						}
					}
				}
				.hot-link-thumb {
					position: absolute;
					top: 10px;
					left: 15px;
					width: 242.66px;
					height: 90px;
					background-color: @white;
					border: 1px solid #f1f3f6;
					border-radius: 5px;
					z-index: 0;
				}
			}
			@media screen and (max-width: 1800px) {
				.banner-hot-tab-box {
					max-width: @content-width-1600;
				}
			}

			@media screen and (max-width: 1700px) {
				.banner-hot-tab-box {
					max-width: @content-width-1400;
				}
			}

			@media screen and (max-width: 1550px) {
				.banner-hot-tab-box {
					max-width: @content-width-1300;
				}
			}

			@media screen and (max-width: 1400px) {
				.banner-hot-tab-box {
					max-width: @content-width-1200;
				}
			}

			@media screen and (max-width: 1280px) {
				.banner-hot-tab-box {
					max-width: @content-width-1100;
				}
			}

			@media screen and (max-width: 1200px) {
				.banner-hot-tab-box {
					max-width: @content-width-1000;
				}
			}
		}
		 */
}
.oos-home .banner.news-carousel-active {
  background-color: unset;
  background-image: linear-gradient(to right top, #2996ab 30%, #0b47d6);
}
.oos-home .banner .banner-carousel-wrap {
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
  height: 485px;
}
.oos-home .banner .banner-carousel-wrap:hover .carousel .switch-button {
  display: block;
}
.oos-home .banner .banner-carousel-wrap .carousel {
  position: relative;
  padding: 0 calc((100% - 1600px) / 2);
  height: 100%;
  z-index: 1;
}
.oos-home .banner .banner-carousel-wrap .carousel .switch-button {
  position: absolute;
  top: 210px;
  display: none;
  width: 16px;
  height: 29px;
  cursor: pointer;
  user-select: none;
  z-index: 2;
}
.oos-home .banner .banner-carousel-wrap .carousel .switch-button.prev {
  left: 15px;
  background-image: url("../images/home/banner/switch_prev.webp");
}
.oos-home .banner .banner-carousel-wrap .carousel .switch-button.next {
  right: 15px;
  background-image: url("../images/home/banner/switch_next.webp");
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item {
  display: none;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  animation: oos-carousel-switch-fade-in 0.8s ease-in;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc {
  position: relative;
  padding-top: 120px;
  width: 800px;
  height: 485px;
  font-weight: 300;
  color: #d5def2;
  z-index: 1;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .name {
  font-size: 45px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .name:nth-child(2) {
  font-size: 40px;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .desc {
  margin-top: 5px;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  -ms-line-clamp: 2;
  -ms-box-orient: vertical;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button {
  margin-top: 20px;
  width: 110px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  border: 1px solid #455370;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-link-icon,
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-download-icon,
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .view-detail-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .product-banner-anim-base-img {
  top: -64px;
  right: 0;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-bg {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  max-width: 1920px;
  height: 545px;
  background-image: url("../images/home/banner/news_carousel_bg.webp");
  background-size: cover;
  z-index: -1;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc {
  padding-top: 120px;
  min-width: 100%;
  text-align: center;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .title {
  font-size: 38px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .desc {
  padding-top: 15px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #b4c3e4;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button {
  margin: 55px auto 0;
  width: 110px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  border: 1px solid #63a2cd;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-link-icon,
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-download-icon,
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .view-detail-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-home .banner .banner-carousel-wrap .carousel .pagination {
  position: absolute;
  top: 400px;
  left: calc((100% - 1600px) / 2);
  display: inline-block;
  margin-right: 70px;
  height: 60px;
  line-height: 64px;
  user-select: none;
}
.oos-home .banner .banner-carousel-wrap .carousel .pagination.news-carousel-active .pagination-item {
  background-color: #bfd5e8;
  opacity: 0.8;
}
.oos-home .banner .banner-carousel-wrap .carousel .pagination.news-carousel-active .pagination-item.active {
  background-color: #bfd5e8;
}
.oos-home .banner .banner-carousel-wrap .carousel .pagination.news-carousel-active .pagination-item.active::after {
  background-color: #fff;
}
.oos-home .banner .banner-carousel-wrap .carousel .pagination .pagination-item {
  position: relative;
  margin-right: 10px;
  display: inline-block;
  width: 30px;
  height: 3px;
  background-color: #374766;
  border-radius: 2px;
}
.oos-home .banner .banner-carousel-wrap .carousel .pagination .pagination-item:nth-last-child(1) {
  margin-right: 0;
}
.oos-home .banner .banner-carousel-wrap .carousel .pagination .pagination-item.active {
  width: 100px;
  background-color: #374766;
}
.oos-home .banner .banner-carousel-wrap .carousel .pagination .pagination-item.active::after {
  content: " ";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #d5def2;
  border-radius: 2px;
  animation: oos-carousel-pagination-progress 8s linear;
}
.oos-home .banner .banner-carousel-wrap .carousel .pagination .pagination-item.paused::after {
  animation-play-state: paused;
}
.oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 485px;
  overflow: hidden;
  user-select: none;
}
.oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap .carousel-3d-model-bg-img {
  position: absolute;
  right: 0;
  width: 850px;
  height: 483px;
  background-image: url("../images/home/banner/3d_model_bg.webp");
  background-size: cover;
  z-index: 0;
}
.oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap .carousel-3d-model-content {
  position: absolute;
  right: calc((100% - 1600px) / 2);
  margin-top: 60px;
  width: 750px;
  height: 470px;
  z-index: 2;
}
@media screen and (max-width: 1800px) {
  .oos-home .banner .banner-carousel-wrap .carousel {
    padding: 0 calc((100% - 1600px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc {
    width: 770px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .pagination {
    left: calc((100% - 1600px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap .carousel-3d-model-bg-img {
    right: -30px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-home .banner .banner-carousel-wrap .carousel {
    padding: 0 calc((100% - 1400px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc {
    width: 700px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .name {
    font-size: 38px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .pagination {
    left: calc((100% - 1400px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap .carousel-3d-model-bg-img {
    right: -100px;
  }
}
@media screen and (max-width: 1550px) {
  .oos-home .banner .banner-carousel-wrap .carousel {
    padding: 0 calc((100% - 1300px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel .pagination {
    left: calc((100% - 1300px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap .carousel-3d-model-bg-img {
    right: -130px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-home .banner .banner-carousel-wrap .carousel {
    padding: 0 calc((100% - 1200px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc {
    padding-top: 120px;
    width: 550px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .name {
    font-size: 28px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .desc {
    font-size: 13px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.news .carousel-news-desc .title {
    font-size: 32px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .pagination {
    left: calc((100% - 1200px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap .carousel-3d-model-bg-img {
    right: -170px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap .carousel-3d-model-content {
    right: -40px;
  }
}
@media screen and (max-width: 1280px) {
  .oos-home .banner .banner-carousel-wrap .carousel {
    padding: 0 calc((100% - 1100px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel .pagination {
    left: calc((100% - 1100px) / 2);
  }
}
@media screen and (max-width: 1200px) {
  .oos-home .banner .banner-carousel-wrap .carousel {
    padding: 0 calc((100% - 1000px) / 2);
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc {
    padding-top: 80px;
    width: 400px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.product .carousel-product-desc .name {
    font-size: 20px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .pagination {
    left: calc((100% - 1000px) / 2);
  }
}
.oos-home .banner .banner-bottom-bg-placeholder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 55px;
  background-color: #fff;
}
@media screen and (max-width: 1200px) {
  .oos-home .banner .banner-carousel-wrap:hover .carousel .switch-button {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .oos-home .banner {
    height: 280px;
    /*
			.banner-hot-tab-wrap {
				top: 280px;
				height: 110px;
				.banner-hot-tab-box {
					width: 100%;
					max-width: 100%;
					.banner-hot-tab {
						margin: 0 auto;
						padding: 10px 10px;
						width: 100%;
						max-width: 100%;
						justify-content: center;
						border: none;
						.hot-link-item {
							margin-right: 5px;
							padding-top: 15px;
							padding-bottom: 10px;
							padding-right: 0;
							flex-direction: column;
							align-items: center;
							width: ~"calc((100% - (15px * 2) - (10px * 2)) / 3)";
							background-color: @white;
							border: 1px solid #f1f3f6;
							border-radius: 5px;
							&:nth-last-child(1) {
								margin-right: 0;
							}
							.hot-link-item-icon {
								width: 35px;
								height: 35px;
							}
							.hot-link-item-text-content {
								margin-top: 5px;
								margin-left: 0;
								padding: 0 10px;
								width: 100%;
								text-align: center;
								.title {
									font-size: @s-font;
									.oos-multiple-line-text-overflow-ellipsis(2);
								}
								.desc {
									display: none;
								}
							}
						}
					}
				}

			}
			*/
  }
  .oos-home .banner + .section-title-box {
    padding-top: 135px;
  }
  .oos-home .banner .banner-carousel-wrap {
    height: auto;
  }
  .oos-home .banner .banner-carousel-wrap .carousel {
    height: auto;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .switch-button {
    display: none;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item {
    padding-top: 30px;
    height: 220px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active {
    position: relative;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .carousel-product-desc {
    margin: 0 auto;
    padding-top: 20px;
    width: 100%;
    height: auto;
    text-align: center;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .carousel-product-desc .name {
    font-size: 18px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .carousel-product-desc .desc {
    padding: 0 6%;
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .carousel-product-desc .view-detail-button {
    margin: 20px auto 0;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .product-banner-anim-base-img,
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .banner-point-bg {
    display: none;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .product-banner-static-img-pad-ph {
    display: block;
    margin-top: 34px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.news .carousel-news-desc {
    padding-top: 10px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.news .carousel-news-desc .title {
    font-size: 22px;
    font-weight: 500;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.news .carousel-news-desc .view-detail-button {
    margin-top: 30px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .pagination {
    top: 170px;
    left: 50%;
    margin-right: 0;
    transform: translateX(-50%);
  }
  .oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap {
    display: none;
  }
  .oos-home .banner .banner-bottom-bg-placeholder {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .banner {
    height: 400px;
    /*
			.banner-hot-tab-wrap {
				top: @banner-height-400;
				.banner-hot-tab-box {
					.banner-hot-tab {
						.hot-link-item {
							padding-top: 10px;
							.hot-link-item-icon {
								width: 25px;
								height: 25px;
							}
							.hot-link-item-text-content {
								.title {
									font-size: @xxs-font;
									.oos-multiple-line-text-overflow-ellipsis(2);
								}
							}
						}
					}
				}
			}
			*/
  }
  .oos-home .banner .banner-carousel-wrap {
    height: 340px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel {
    height: 100%;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .switch-button {
    display: none;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item {
    height: 100%;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active {
    position: relative;
    display: block;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .carousel-product-desc {
    margin: 0 auto;
    padding: 0 20px 0;
    width: 100%;
    text-align: center;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .carousel-product-desc .name {
    font-size: 18px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .carousel-product-desc .desc {
    padding: 0 6%;
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .carousel-product-desc .view-detail-button {
    margin: 20px auto 0;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .product-banner-anim-base-img,
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .banner-point-bg {
    display: none;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.product .product-banner-static-img-pad-ph {
    float: right;
    margin-top: 24px;
    display: block;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.news .carousel-news-bg {
    width: 100%;
    height: 400px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.news .carousel-news-desc {
    padding: 65px 20px 0;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.news .carousel-news-desc .title {
    font-size: 18px;
    font-weight: 500;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.news .carousel-news-desc .desc {
    display: none;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .carousel-item.active.news .carousel-news-desc .view-detail-button {
    margin-top: 40px;
  }
  .oos-home .banner .banner-carousel-wrap .carousel .pagination {
    top: 285px;
    left: 50%;
    width: 220px;
    transform: translateX(-50%);
  }
  .oos-home .banner .banner-carousel-wrap .carousel-3d-model-wrap {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .banner.news-carousel-active {
    background-image: linear-gradient(to right top, #2996ab 20%, #0b47d6);
  }
}
.oos-home .product .product-title-tab-wrap {
  border-bottom: 1px solid #e3e6ed;
}
.oos-home .product .product-title-tab-wrap .product-title-tab {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-bottom: -1px;
  width: 1600px;
  height: 40px;
}
.oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item {
  position: relative;
  padding: 10px 0;
  width: 290px;
  cursor: pointer;
}
.oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item::after {
  content: " ";
  position: absolute;
  top: 13px;
  right: -1px;
  width: 1px;
  height: 14px;
  background-color: #c8d1dd;
}
.oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item:nth-last-child(1)::after {
  display: none;
}
.oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item.active {
  background-color: #fcfdff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #e3e6ed;
  border-bottom: 1px solid #fcfdff;
}
.oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item.active::after {
  display: none;
}
.oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item .title-text {
  display: inline-block;
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: #545c6c;
  letter-spacing: 1px;
  user-select: none;
}
.oos-home .product .product-content-wrap {
  position: relative;
  background-color: #f5f7fa;
  border-bottom: 1px solid #e3e6ed;
  transition: all 0.3s linear;
}
.oos-home .product .product-content-wrap:hover {
  background-color: transparent;
}
.oos-home .product .product-content-wrap:hover .product-content-active-bg {
  opacity: 1;
}
.oos-home .product .product-content-wrap .product-content-active-bg {
  position: absolute;
  opacity: 0;
  transition: all 0.5s linear;
}
.oos-home .product .product-content-wrap .product-content-active-bg.bg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../images/home/product/active_bg.webp");
  background-size: cover;
  z-index: -2;
}
.oos-home .product .product-content-wrap .product-content-active-bg.main-bg {
  right: 0;
  bottom: 0;
  width: 860px;
  height: 230px;
  background-image: url("../images/home/product/active_main_bg.webp");
  background-size: cover;
  z-index: -1;
}
.oos-home .product .product-content-wrap .product-content-box {
  margin: 0 auto;
  width: 1600px;
}
.oos-home .product .product-content-wrap .product-content-box .product-content {
  display: none;
}
.oos-home .product .product-content-wrap .product-content-box .product-content.active {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 70px 0;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content {
  width: 740px;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .name {
  font-size: 34px;
  font-weight: 600;
  color: #545c6c;
  letter-spacing: 2px;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .desc {
  margin-top: 10px;
  font-size: 14px;
  color: #545c6c;
  letter-spacing: 2px;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .scenario-box {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .scenario-box .scenario-item {
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  padding: 0 25px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #545c6c;
  letter-spacing: 1px;
  border: 1px solid #d2d7e2;
  border-radius: 3px;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button {
  margin-top: 23px;
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  background-color: #2a3957;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-link-icon,
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-download-icon,
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .view-detail-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-home .product .product-content-wrap .product-content-box .product-content .feature-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 760px;
  min-height: 210px;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 0;
  width: 240px;
  height: 90px;
  background: linear-gradient(to bottom, #f0f6ff, #fff);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 1px 5px 5px 0 rgba(116, 130, 166, 0.1);
  cursor: default;
  transition: box-shadow 0.3s linear;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature:hover {
  background: linear-gradient(to bottom, #fff, #fff);
  box-shadow: 1px 6px 5px 0 rgba(116, 130, 166, 0.3);
}
.oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature .feature-icon {
  display: block;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature .feature-text-content {
  width: calc(100% - 60px);
}
.oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature .feature-text-content .title,
.oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature .feature-text-content .desc {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature .feature-text-content .title {
  font-size: 18px;
  font-weight: 500;
  color: #2e394c;
  letter-spacing: 1px;
}
.oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature .feature-text-content .desc {
  margin-top: 2px;
  font-size: 12px;
  color: #b3b9c4;
}
@media screen and (max-width: 1800px) {
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content {
    width: 670px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-home .product .product-title-tab-wrap .product-title-tab {
    width: 1400px;
  }
  .oos-home .product .product-content-wrap .product-content-box {
    width: 1400px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content {
    width: 600px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .name {
    font-size: 30px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .desc {
    font-size: 13px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-home .product .product-title-tab-wrap .product-title-tab {
    width: 1200px;
  }
  .oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item {
    width: 280px;
  }
  .oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item .title-text {
    font-size: 14px;
  }
  .oos-home .product .product-content-wrap .product-content-box {
    width: 1200px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content {
    width: 480px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .name {
    font-size: 24px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .desc {
    font-size: 13px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 4;
    box-orient: vertical;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 4;
    -moz-box-orient: vertical;
    -ms-line-clamp: 4;
    -ms-box-orient: vertical;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .feature-box {
    width: 660px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature {
    width: 210px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature .feature-text-content .title {
    font-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-home .product .product-title-tab-wrap .product-title-tab {
    width: 1000px;
  }
  .oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item {
    width: 240px;
  }
  .oos-home .product .product-title-tab-wrap .product-title-tab .product-name-tab-item .title-text {
    font-size: 13px;
  }
  .oos-home .product .product-content-wrap .product-content-box {
    width: 1000px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content {
    width: 460px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .name {
    font-size: 20px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .overview-content .desc {
    font-size: 13px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 5;
    box-orient: vertical;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 5;
    -moz-box-orient: vertical;
    -ms-line-clamp: 5;
    -ms-box-orient: vertical;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .feature-box {
    width: 460px;
    min-height: 300px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature {
    width: 210px;
  }
  .oos-home .product .product-content-wrap .product-content-box .product-content .feature-box .feature .feature-text-content .title {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .oos-home .product-ph {
    border-bottom: 1px solid #e3e6ed;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-name-ph {
    position: relative;
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    background-color: #fcfdff;
    border-top: 1px solid #e3e6ed;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-name-ph.active .arrow-ph {
    background-image: url("../images/general/arrow_downward_active.svg");
    transform: rotate(180deg);
  }
  .oos-home .product-ph .product-content-wrap-ph .product-name-ph .name-ph {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #545c6c;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-name-ph .arrow-ph {
    content: " ";
    position: absolute;
    top: 15px;
    right: 12px;
    width: 12px;
    height: 20px;
    background-image: url("../images/general/arrow_downward.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph {
    display: none;
    padding: 25px 20px;
    background-color: #f6f7fb;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph.active {
    display: block;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .overview-content-ph .desc-ph {
    line-height: 22px;
    font-size: 13px;
    font-weight: 300;
    color: #545c6c;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .overview-content-ph .scenario-box-ph {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .overview-content-ph .scenario-box-ph .scenario-item-ph {
    margin-top: 10px;
    margin-right: 10px;
    padding: 4px 14px;
    font-size: 12px;
    color: #545c6c;
    border: 1px solid #d1d6df;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .feature-box-ph {
    padding-top: 10px;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .feature-box-ph .feature-ph {
    margin-top: 15px;
    padding: 15px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: linear-gradient(to bottom, #f0f6ff, #fff);
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 1px 6px 5px 0 rgba(116, 130, 166, 0.3);
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .feature-box-ph .feature-ph .feature-text-content-ph {
    padding-left: 5px;
    width: calc(100% - 60px);
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .feature-box-ph .feature-ph .feature-text-content-ph .title-ph {
    font-size: 16px;
    color: #2e394c;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .feature-box-ph .feature-ph .feature-text-content-ph .desc-ph {
    padding-top: 3px;
    font-size: 12px;
    color: #b3b9c4;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph {
    margin: 30px auto 0;
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: #d5def2;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    background-color: #2a3957;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-link-icon {
    display: inline-block;
    transition: transform 0.2s linear;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-link-icon.disabled {
    color: #bfbfbf;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-link-icon.ph {
    padding-left: 5px;
    transition: none !important;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-download-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/technicalSupport/download.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-download-icon.disabled {
    background-image: url("../images/technicalSupport/download_disabled.svg");
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-apply-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/about/recruitment/apply.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-apply-icon.disabled {
    background-image: url("../images/about/recruitment/apply_disabled.svg");
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph:hover .oos-button-link-icon:not(.disabled) {
    transform: translateX(4px);
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph:hover .oos-button-download-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph:hover .oos-button-apply-icon:not(.disabled) {
    transform: translateY(2px);
  }
}
@media screen and (max-width: 768px) and screen and (max-width: 450px) {
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-link-icon,
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-download-icon,
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .button-box-ph .view-detail-button-ph .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (min-width: 601px) {
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .feature-box-ph {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .feature-box-ph .feature-ph {
    max-width: calc(50% - 5px);
    min-width: calc(50% - 5px);
  }
  .oos-home .product-ph .product-content-wrap-ph .product-content-ph .feature-box-ph .feature-ph:nth-child(2n + 1) {
    margin-right: 10px;
  }
}
.oos-home .solution {
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
  background-color: #f5f7fa;
  border-top: 1px solid #e3e6ed;
  border-bottom: 1px solid #e3e6ed;
  transition: opacity 0.3s linear;
}
.oos-home .solution:hover {
  background-color: transparent;
}
.oos-home .solution:hover .solution-content-active-bg {
  opacity: 1;
}
.oos-home .solution .solution-content-active-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../images/home/solution/active_bg.webp");
  background-size: cover;
  opacity: 0;
  transition: opacity 0.5s linear;
  z-index: -1;
}
.oos-home .solution .solution-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.oos-home .solution .solution-content .solution-overview {
  width: 450px;
  height: 480px;
  background: #2b3a58 url("../images/home/solution/overview.webp") no-repeat center bottom;
  overflow: hidden;
}
.oos-home .solution .solution-content .solution-overview .solution-overview-text {
  padding: 50px 40px 0;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-home .solution .solution-content .solution-category-box {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  padding: 65px 50px 55px;
  width: calc(100% - 450px);
  height: 480px;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 15px 15px 15px;
  width: 430px;
  height: 160px;
  background: linear-gradient(to bottom, #f0f6ff, #fff);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 1px 5px 5px 0 rgba(116, 130, 166, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s linear;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item:hover {
  background: linear-gradient(to bottom, #fff, #fff);
  box-shadow: 1px 6px 5px 0 rgba(116, 130, 166, 0.3);
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-icon {
  position: absolute;
  top: -25px;
  left: 22px;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content {
  position: relative;
  width: 120px;
  height: 100%;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .title {
  padding-top: 6px;
  font-size: 24px;
  letter-spacing: 2px;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button {
  position: absolute;
  left: -10px;
  bottom: 0;
  padding: 10px;
  min-height: 12px;
  line-height: 12px;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  color: #adb4c0;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-link-icon,
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-download-icon,
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .view-detail-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-text-content {
  position: relative;
  padding: 0 12px;
  width: calc(100% - 120px);
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-text-content .angle {
  position: absolute;
  display: block;
  width: 22px;
  height: 14px;
  background-image: url("../images/home/text_content_angle.webp");
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-text-content .angle.top-left {
  top: -12px;
  left: 0;
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-text-content .angle.bottom-right {
  bottom: -12px;
  right: 0;
  transform: rotateZ(180deg);
}
.oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-text-content .solution-category-text-content-text {
  line-height: 20px;
  font-size: 12px;
  color: #959eae;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 5;
  box-orient: vertical;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 5;
  -moz-box-orient: vertical;
  -ms-line-clamp: 5;
  -ms-box-orient: vertical;
}
@media screen and (max-width: 1870px) {
  .oos-home .solution .solution-content .solution-category-box .solution-category-item {
    width: 32%;
  }
}
@media screen and (max-width: 1700px) {
  .oos-home .solution .solution-content .solution-category-box {
    padding-left: 30px;
    padding-right: 30px;
  }
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content {
    width: 100px;
  }
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .title {
    font-size: 20px;
  }
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-text-content {
    width: calc(100% - 100px);
  }
}
@media screen and (max-width: 1600px) {
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content {
    width: 80px;
  }
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-title-content .title {
    font-size: 16px;
  }
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .solution-category-text-content {
    width: calc(100% - 80px);
  }
}
@media screen and (max-width: 1400px) {
  .oos-home .solution .solution-content {
    flex-direction: column;
  }
  .oos-home .solution .solution-content .solution-overview {
    width: 100%;
    height: 200px;
    background-position: 80% 50%;
  }
  .oos-home .solution .solution-content .solution-overview .solution-overview-text {
    margin-left: 150px;
    padding-top: 80px;
    width: 550px;
    text-align: left;
    font-size: 16px;
  }
  .oos-home .solution .solution-content .solution-category-box {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .oos-home .solution .solution-content .solution-overview {
    background-position: 100% 10%;
  }
  .oos-home .solution .solution-content .solution-overview .solution-overview-text {
    margin-left: 30px;
    padding: 70px 0 0 0;
    width: 370px;
    font-size: 14px;
  }
  .oos-home .solution .solution-content .solution-category-box {
    flex-direction: row;
    flex-wrap: wrap;
    height: 1320px;
  }
  .oos-home .solution .solution-content .solution-category-box .solution-category-item {
    margin-top: 40px;
    width: 100%;
  }
  .oos-home .solution .solution-content .solution-category-box .solution-category-item .oos-dynamic-icon {
    width: 50px;
    height: 50px;
  }
  .oos-home .solution .solution-content .solution-category-box .solution-category-item:nth-child(1) {
    margin-top: 0;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .solution .solution-content .solution-overview {
    width: 100vw;
    height: 100vw;
    background-size: 100% 100%;
    background-position: 10% 70%;
  }
  .oos-home .solution .solution-content .solution-overview .solution-overview-text {
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
}
.oos-home .source {
  position: relative;
  height: 450px;
  background-color: #f5f7fa;
}
.oos-home .source .source-globe-wrap {
  position: relative;
  height: 450px;
  overflow: hidden;
  user-select: none;
}
.oos-home .source .source-globe-wrap .source-globe-content.hide {
  display: none;
}
.oos-home .source .source-globe-wrap .source-globe-content .source-globe {
  position: relative;
  top: -150px;
  left: -160px;
  margin: 0 auto;
  width: 1400px;
  height: 1400px;
}
.oos-home .source .source-globe-wrap .source-globe-content .source-globe-placeholder-wrap {
  position: relative;
  top: -150px;
  left: -160px;
  margin: 0 auto;
  width: 1400px;
  height: 1400px;
  z-index: 0;
}
.oos-home .source .source-globe-wrap .source-globe-content .source-globe-placeholder-wrap.hide {
  display: none;
}
.oos-home .source .source-globe-wrap .source-globe-content .source-globe-placeholder-wrap .source-globe-placeholder {
  position: absolute;
  top: 212px;
  left: 212px;
  width: 977px;
  height: 605.36px;
  background-image: url("../images/home/source/globe_placeholder.webp");
  background-size: 100% 100%;
}
.oos-home .source .source-globe-wrap .source-globe-static-img-ph.hide {
  display: none;
}
.oos-home .source .source-globe-legend-wrap {
  position: absolute;
  top: 80px;
  left: 50%;
  width: 1400px;
  transform: translateX(-50%);
}
.oos-home .source .source-globe-legend-wrap .source-globe-legend-content {
  position: absolute;
  top: 0;
  right: 50px;
  width: 150px;
}
.oos-home .source .source-globe-legend-wrap .source-globe-legend-content .legend-title {
  font-size: 28px;
  letter-spacing: 2px;
}
.oos-home .source .source-globe-legend-wrap .source-globe-legend-content .legend-item {
  position: relative;
  margin-top: 20px;
  padding-left: 18px;
  font-size: 16px;
  font-weight: 300;
  color: #b3b9c4;
  letter-spacing: 1px;
}
.oos-home .source .source-globe-legend-wrap .source-globe-legend-content .legend-item::before {
  content: " ";
  position: absolute;
  top: 8px;
  left: 0;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.oos-home .source .source-globe-legend-wrap .source-globe-legend-content .legend-item.opened::before {
  background-color: #23815d;
}
.oos-home .source .source-globe-legend-wrap .source-globe-legend-content .legend-item.opening::before {
  background-color: #2a86f8;
}
.oos-home .source .source-globe-legend-wrap .source-globe-legend-content .legend-item.to-be-opened::before {
  background-color: #3c1d14;
}
.oos-home .source .source-content-wrap {
  position: absolute;
  top: 300px;
  left: 50%;
  padding: 25px 25px 25px 0;
  display: flex;
  flex-direction: row;
  width: 1400px;
  height: 300px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 5px rgba(116, 130, 166, 0.1);
  transform: translateX(-50%);
  z-index: 1;
}
.oos-home .source .source-content-wrap .overview-text-content-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px;
  width: 500px;
  height: 250px;
}
.oos-home .source .source-content-wrap .overview-text-content-box .overview-text-content-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 105px;
  text-align: center;
}
.oos-home .source .source-content-wrap .overview-text-content-box .overview-text-content-item .item-number {
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  color: #1c2d47;
  letter-spacing: 2px;
}
.oos-home .source .source-content-wrap .overview-text-content-box .overview-text-content-item .item-text {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  color: #495672;
}
.oos-home .source .source-content-wrap .industry-trend-wrap {
  flex: 1;
  height: 250px;
  background: linear-gradient(to right, #f3f5f8, #fff);
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel {
  position: relative;
  margin: 0 auto;
  height: 100%;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-opinion {
  position: absolute;
  top: 10px;
  right: 40px;
  width: 98px;
  height: 31px;
  background-image: url("../images/home/source/opinion.webp");
  background-size: 100% 100%;
  z-index: 2;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item {
  display: none;
  margin: 0 auto;
  height: 100%;
  padding: 0 40px;
  overflow: hidden;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item.active {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  animation: oos-carousel-switch-fade-in 0.4s ease-in-out forwards;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap {
  min-width: 130px;
  height: 100px;
  position: relative;
  z-index: -1;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap .industry-trend-img {
  position: absolute;
  bottom: -90px;
  left: -50px;
  background-size: 100% 100%;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap .industry-trend-img.distributed-storage {
  width: 215px;
  height: 177px;
  background-image: url("../images/home/source/distributed_storage.webp");
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap .industry-trend-img.iot {
  width: 215px;
  height: 178px;
  background-image: url("../images/home/source/iot.webp");
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap .industry-trend-img.edge-computing {
  width: 215px;
  height: 179px;
  background-image: url("../images/home/source/edge_computing.webp");
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap .industry-trend-img.virtualization {
  width: 215px;
  height: 179px;
  background-image: url("../images/home/source/virtualization.webp");
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content {
  position: relative;
  margin-left: 15px;
  flex: 1;
  letter-spacing: 1px;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .title {
  padding-bottom: 5px;
  font-weight: 500;
  letter-spacing: 2px;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .text {
  height: 78px;
  line-height: 26px;
  font-size: 13px;
  color: #545c6c;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 3;
  -moz-box-orient: vertical;
  -ms-line-clamp: 3;
  -ms-box-orient: vertical;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .quote {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("../images/home/text_content_quote.webp");
  background-size: 100% 100%;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .quote.top-left {
  top: -50px;
  left: 0;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .quote.bottom-right {
  bottom: -70px;
  right: 0;
  transform: rotateZ(180deg);
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .pagination {
  position: absolute;
  right: 100px;
  bottom: 20px;
  user-select: none;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .pagination .pagination-item {
  position: relative;
  margin-right: 10px;
  display: inline-block;
  width: 30px;
  height: 3px;
  background-color: #bdc7da;
  border-radius: 2px;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .pagination .pagination-item:nth-last-child(1) {
  margin-right: 0;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .pagination .pagination-item.active {
  width: 100px;
  background-color: #bdc7da;
}
.oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .pagination .pagination-item.active::after {
  content: " ";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #788298;
  border-radius: 2px;
  animation: oos-carousel-pagination-progress 10s linear;
}
@media screen and (max-width: 1500px) {
  .oos-home .source .source-globe-legend-wrap {
    width: 1200px;
  }
  .oos-home .source .source-content-wrap {
    width: 1200px;
  }
}
@media screen and (max-width: 1300px) {
  .oos-home .source .source-globe-legend-wrap {
    width: 1000px;
  }
  .oos-home .source .source-content-wrap {
    width: 1000px;
  }
  .oos-home .source .source-content-wrap .overview-text-content-box {
    width: 350px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-home .source .source-globe-legend-wrap .source-globe-legend-content {
    right: 0;
    width: 100px;
  }
  .oos-home .source .source-globe-legend-wrap .source-globe-legend-content .legend-title {
    font-size: 22px;
  }
  .oos-home .source .source-globe-legend-wrap .source-globe-legend-content .legend-item {
    font-size: 16px;
  }
  .oos-home .source .source-content-wrap .overview-text-content-box {
    width: 320px;
  }
  .oos-home .source .source-content-wrap .overview-text-content-box .overview-text-content-item .item-number {
    font-size: 28px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .text {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .oos-home .source {
    padding: 0 20px;
  }
  .oos-home .source .source-globe-wrap .source-globe-static-img-ph {
    margin: 50px auto 0;
    width: 600px;
    height: 371.77px;
    background-image: url("../images/home/source/globe_placeholder.webp");
    background-size: 100% 100%;
  }
  .oos-home .source .source-globe-legend-wrap {
    display: none;
    width: 100%;
  }
  .oos-home .source .source-content-wrap {
    top: 200px;
    padding-top: 0;
    padding-bottom: 0;
    width: calc(100% - (20px * 2));
    height: 380px;
    flex-direction: column;
    justify-content: center;
  }
  .oos-home .source .source-content-wrap .overview-text-content-box {
    flex-direction: column;
    width: 100%;
    height: 130px;
  }
  .oos-home .source .source-content-wrap .overview-text-content-box .overview-text-content-item {
    width: 25%;
    height: 130px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap {
    width: 100%;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-opinion {
    right: 0;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item {
    padding: 0;
    height: 250px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap {
    min-width: 130px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap .industry-trend-img {
    position: absolute;
    bottom: -95px;
    left: -25px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .user-name-title {
    font-size: 14px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .quote {
    width: 30px;
    height: 30px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .quote.top-left {
    top: -40px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .quote.bottom-right {
    bottom: -55px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .pagination {
    bottom: 29px;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .source .source-globe-wrap .source-globe-static-img-ph {
    width: 300px;
    height: 185.88px;
  }
  .oos-home .source .source-content-wrap {
    top: 125px;
    padding-right: 0;
    height: 585px;
    overflow: hidden;
  }
  .oos-home .source .source-content-wrap .overview-text-content-box {
    padding: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    height: 200px;
  }
  .oos-home .source .source-content-wrap .overview-text-content-box .overview-text-content-item {
    width: 50%;
    height: 70px;
  }
  .oos-home .source .source-content-wrap .overview-text-content-box .overview-text-content-item .item-number {
    font-size: 20px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap {
    height: 385px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-opinion {
    top: 20px;
    right: 10px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item {
    padding: 20px 10px 0;
    height: 375px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item.active {
    flex-direction: column;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap {
    height: 120px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-img-wrap .industry-trend-img {
    position: relative;
    top: -20px;
    bottom: unset;
    left: unset;
    transform: scale(0.8, 0.8);
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content {
    margin-top: 30px;
    margin-left: 0;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .title {
    padding-top: 5px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .text {
    height: 130px;
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 5;
    box-orient: vertical;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 5;
    -moz-box-orient: vertical;
    -ms-line-clamp: 5;
    -ms-box-orient: vertical;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .quote {
    width: 20px;
    height: 20px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .quote.top-left {
    top: -25px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .industry-trend-carousel-item .industry-trend-content .quote.bottom-right {
    bottom: 10px;
  }
  .oos-home .source .source-content-wrap .industry-trend-wrap .industry-trend-carousel .pagination {
    bottom: 20px;
    left: 50%;
    width: 220px;
    transform: translateX(-50%);
  }
}
.oos-home .case {
  margin-top: 10px;
  margin-bottom: 60px;
}
.oos-home .case .case-box {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1430px;
}
.oos-home .case .case-box .case-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 22px 17px 15px;
  width: 450px;
  height: 170px;
  background: linear-gradient(to bottom, #f0f6ff, #fff);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0 rgba(116, 130, 166, 0.2);
  cursor: pointer;
  transition: box-shadow 0.3s linear;
}
.oos-home .case .case-box .case-item:hover {
  background: linear-gradient(to bottom, #fff, #fff);
  box-shadow: 0 0 5px 0 rgba(116, 130, 166, 0.3);
}
.oos-home .case .case-box .case-item .angle {
  position: absolute;
  display: block;
  width: 22px;
  height: 14px;
  background-image: url("../images/home/text_content_angle.webp");
}
.oos-home .case .case-box .case-item .angle.top-left {
  top: -10px;
  left: -8px;
}
.oos-home .case .case-box .case-item .angle.bottom-right {
  bottom: -10px;
  right: -8px;
  transform: rotateZ(180deg);
}
.oos-home .case .case-box .case-item .case-img {
  width: 130px;
  height: 130px;
  background-size: 100% 100%;
  border: 1px solid #fff;
  border-radius: 5px;
}
.oos-home .case .case-box .case-item .case-img.intelligent-security-community {
  background-image: url("../images/home/case/xi_xian_police_department.webp");
}
.oos-home .case .case-box .case-item .case-img.intelligent-community-security {
  background-image: url("../images/home/case/qin_han_new_city_wei_liu_jia_yuan_intelligent_community.webp");
}
.oos-home .case .case-box .case-item .case-img.all-area-tourism {
  background-image: url("../images/home/case/qin_han_new_city_all_area_tourism.webp");
}
.oos-home .case .case-box .case-item .case-content {
  position: relative;
  margin-left: 15px;
  width: calc(100% - 130px - 15px);
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .case-title-text {
  width: calc(100% - 75px);
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button {
  padding: 10px;
  min-height: 12px;
  line-height: 12px;
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  color: #adb4c0;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
  margin-right: -10px;
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-link-icon,
  .oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-download-icon,
  .oos-home .case .case-box .case-item .case-content .case-title-wrap .view-detail-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-home .case .case-box .case-item .case-content .case-text-content {
  line-height: 24px;
  font-size: 12px;
  color: #959eae;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 3;
  -moz-box-orient: vertical;
  -ms-line-clamp: 3;
  -ms-box-orient: vertical;
}
@media screen and (max-width: 1800px) {
  .oos-home .case .case-box {
    width: 1420px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-home .case .case-box {
    width: 1390px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-home .case .case-box {
    width: 1260px;
  }
  .oos-home .case .case-box .case-item {
    width: 410px;
  }
  .oos-home .case .case-box .case-item .case-content .case-title-wrap .case-title-text {
    padding-top: 4px;
  }
}
@media screen and (max-width: 1300px) {
  .oos-home .case .case-box {
    width: 1230px;
  }
  .oos-home .case .case-box .case-item {
    width: 400px;
  }
  .oos-home .case .case-box .case-item .case-img {
    width: 90px;
    height: 90px;
  }
  .oos-home .case .case-box .case-item .case-content {
    width: calc(100% - 90px - 15px);
  }
}
@media screen and (max-width: 1200px) {
  .oos-home .case .case-box {
    flex-direction: column;
    width: 100%;
  }
  .oos-home .case .case-box .case-item {
    margin: 0 auto 20px;
    width: 80%;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .case .case-box {
    padding: 0 20px;
  }
  .oos-home .case .case-box .case-item {
    margin: 0 auto 20px;
    width: 100%;
  }
  .oos-home .case .case-box .case-item:nth-last-child(1) {
    margin-bottom: 0;
  }
  .oos-home .case .case-box .case-item .case-img {
    width: 80px;
    height: 80px;
  }
  .oos-home .case .case-box .case-item .case-content {
    width: calc(100% - 80px - 15px);
  }
  .oos-home .case .case-box .case-item .case-content .case-title-wrap .case-title-text {
    padding: 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-home .case {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
.oos-home .partner {
  padding: 0 10px 60px 20px;
  background-color: #f7f8f9;
}
.oos-home .partner .partner-content-wrap {
  margin: 0 auto;
  max-width: 1920px;
}
.oos-home .partner .partner-content-wrap .partner-box {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item {
  flex: 1;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-decoration: none;
  background: linear-gradient(to bottom, #f0f6ff, #fff);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 1px 5px 5px 0 rgba(116, 130, 166, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s linear;
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item:hover {
  background: linear-gradient(to bottom, #fff, #fff);
  box-shadow: 1px 6px 5px 0 rgba(116, 130, 166, 0.3);
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo {
  width: 160px;
  height: 40px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.h3c {
  background-image: url("../images/home/partnerLogo/h3c.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.intel {
  background-image: url("../images/home/partnerLogo/intel.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.szsm {
  background-image: url("../images/home/partnerLogo/szsm.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.zgdx {
  background-image: url("../images/home/partnerLogo/zgdx.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.hw {
  background-image: url("../images/home/partnerLogo/hw.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.zksg {
  background-image: url("../images/home/partnerLogo/zksg.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.zgtxfw {
  background-image: url("../images/home/partnerLogo/zgtxfw.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.zgkjbkxjsyjs {
  background-image: url("../images/home/partnerLogo/zgkjbkxjsyjs.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.gabjtyjs {
  background-image: url("../images/home/partnerLogo/gabjtyjs.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.wxxjjsyjs {
  background-image: url("../images/home/partnerLogo/wxxjjsyjs.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.zghz {
  background-image: url("../images/home/partnerLogo/zghz.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.lenovo {
  background-image: url("../images/home/partnerLogo/lenovo.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.lc {
  background-image: url("../images/home/partnerLogo/lc.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.xbnlkjdx {
  background-image: url("../images/home/partnerLogo/xbnlkjdx.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.xxzc {
  background-image: url("../images/home/partnerLogo/xxzc.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.xbgydx {
  background-image: url("../images/home/partnerLogo/xbgydx.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.alibaba {
  background-image: url("../images/home/partnerLogo/alibaba.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.sxkjdx {
  background-image: url("../images/home/partnerLogo/sxkjdx.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.jrsa {
  background-image: url("../images/home/partnerLogo/jrsa.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.xbsj {
  background-image: url("../images/home/partnerLogo/xbsj.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.kp {
  background-image: url("../images/home/partnerLogo/kp.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.ft {
  background-image: url("../images/home/partnerLogo/ft.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.yfy {
  background-image: url("../images/home/partnerLogo/yfy.webp");
}
.oos-home .partner .partner-content-wrap .partner-box .partner-item .partner-logo.hkws {
  background-image: url("../images/home/partnerLogo/hkws.webp");
}
@media screen and (max-width: 1750px) {
  .oos-home .partner .partner-content-wrap .partner-box .partner-item {
    height: 100px;
  }
}
@media screen and (max-width: 1600px) {
  .oos-home .partner .partner-content-wrap .partner-box {
    flex-wrap: wrap;
  }
  .oos-home .partner .partner-content-wrap .partner-box .partner-item {
    min-width: calc(25% - 10px);
    height: 120px;
  }
  .oos-home .partner .partner-content-wrap .partner-box .partner-item:nth-child(n + 5) {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .oos-home .partner .partner-content-wrap .partner-box .partner-item {
    min-width: calc(50% - 10px);
    height: 110px;
  }
  .oos-home .partner .partner-content-wrap .partner-box .partner-item:nth-child(n + 5) {
    margin-top: 0;
  }
  .oos-home .partner .partner-content-wrap .partner-box .partner-item:nth-child(n + 3) {
    margin-top: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .partner .partner-content-wrap .partner-box .partner-item {
    height: 80px;
  }
}
.oos-home .partner .partner-content-wrap .show-more-button-box {
  padding-top: 10px;
  text-align: center;
}
.oos-home .partner .partner-content-wrap .show-more-button-box.hide {
  display: none;
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button {
  display: none;
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  background-color: #2a3957;
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-link-icon,
  .oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-download-icon,
  .oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (max-width: 1600px) {
  .oos-home .partner {
    padding-left: 80px;
    padding-right: 70px;
  }
}
@media screen and (max-width: 1600px) {
  .oos-home .partner .partner-content-wrap .show-more-button-box .show-more-button {
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .oos-home .partner {
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .partner {
    padding-left: 10px;
    padding-right: 0;
    padding-bottom: 30px;
  }
}
.oos-home .contact-us {
  position: relative;
  overflow: hidden;
}
.oos-home .contact-us::before {
  content: " ";
  position: absolute;
  bottom: -35px;
  right: 300px;
  display: block;
  width: 830px;
  height: 229px;
  background-image: url("../images/home/contact_us_bg.webp");
  background-size: 100% 100%;
  opacity: 0.2;
}
.oos-home .contact-us .contact-us-content {
  margin: 0 auto;
  padding: 0 0 90px;
  width: 1400px;
}
.oos-home .contact-us .contact-us-content input[type="text"],
.oos-home .contact-us .contact-us-content textarea {
  font-size: 16px;
  color: #3e3e3e;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d1d5db;
  outline: none;
  transition: border 0.5s linear;
}
.oos-home .contact-us .contact-us-content input[type="text"]:focus,
.oos-home .contact-us .contact-us-content textarea:focus {
  border-bottom: 1px solid #3e3e3e;
}
.oos-home .contact-us .contact-us-content .username-phone {
  margin-bottom: 30px;
}
.oos-home .contact-us .contact-us-content .username-phone input[type="text"] {
  display: inline-block;
  width: 49%;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
}
.oos-home .contact-us .contact-us-content .username-phone input[type="text"]::-webkit-input-placeholder,
.oos-home .contact-us .contact-us-content .username-phone input[type="text"]::-moz-placeholder,
.oos-home .contact-us .contact-us-content .username-phone input[type="text"]::-ms-input-placeholder {
  color: #696969 !important;
  font-weight: 400 !important;
}
.oos-home .contact-us .contact-us-content .username-phone input[type="text"]:nth-child(2) {
  margin-left: 2%;
}
.oos-home .contact-us .contact-us-content .consultation-input {
  margin-bottom: 30px;
  display: block;
  width: 100%;
  height: 110px;
  resize: none;
}
.oos-home .contact-us .contact-us-content .consultation-input::-webkit-input-placeholder,
.oos-home .contact-us .contact-us-content .consultation-input::-moz-placeholder,
.oos-home .contact-us .contact-us-content .consultation-input::-ms-input-placeholder {
  color: #696969 !important;
  font-weight: 400 !important;
}
.oos-home .contact-us .contact-us-content .confirm-privacy-statement {
  margin-bottom: 40px;
  height: 18px;
  line-height: 18px;
  font-size: 13px;
}
.oos-home .contact-us .contact-us-content .confirm-privacy-statement input[type="checkbox"] {
  vertical-align: -1px;
}
.oos-home .contact-us .contact-us-content .confirm-privacy-statement label {
  user-select: none;
}
.oos-home .contact-us .contact-us-content .confirm-privacy-statement label .read-statement {
  padding-left: 8px;
  color: #888;
}
.oos-home .contact-us .contact-us-content .confirm-privacy-statement label .privacy-statement-name {
  color: #608ff9;
  text-decoration: none;
  cursor: pointer;
}
.oos-home .contact-us .contact-us-content .confirm-privacy-statement label .store-data {
  color: #888;
}
.oos-home .contact-us .contact-us-content .submit-button {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  background-color: #2a3957;
}
.oos-home .contact-us .contact-us-content .submit-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-home .contact-us .contact-us-content .submit-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-home .contact-us .contact-us-content .submit-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-home .contact-us .contact-us-content .submit-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .contact-us .contact-us-content .submit-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-home .contact-us .contact-us-content .submit-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-home .contact-us .contact-us-content .submit-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-home .contact-us .contact-us-content .submit-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-home .contact-us .contact-us-content .submit-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-home .contact-us .contact-us-content .submit-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-home .contact-us .contact-us-content .submit-button .oos-button-link-icon,
  .oos-home .contact-us .contact-us-content .submit-button .oos-button-download-icon,
  .oos-home .contact-us .contact-us-content .submit-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-home .contact-us .contact-us-content .submit-button.disabled {
  background-color: #888;
  cursor: not-allowed;
}
@media screen and (max-width: 1800px) {
  .oos-home .contact-us::before {
    right: 200px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-home .contact-us::before {
    right: 100px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-home .contact-us::before {
    bottom: -85px;
    right: -200px;
  }
  .oos-home .contact-us .contact-us-content {
    width: 1200px;
  }
  .oos-home .contact-us .contact-us-content .submit-button {
    margin: 25px auto 0;
  }
}
@media screen and (max-width: 1300px) {
  .oos-home .contact-us .contact-us-content {
    width: 1000px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-home .contact-us .contact-us-content {
    width: 1000px;
  }
}
@media screen and (max-width: 1000px) {
  .oos-home .contact-us {
    padding: 0 20px;
  }
  .oos-home .contact-us .contact-us-content {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .contact-us::before {
    display: none;
  }
  .oos-home .contact-us .contact-us-content {
    padding-bottom: 50px;
  }
  .oos-home .contact-us .contact-us-content input[type="text"],
  .oos-home .contact-us .contact-us-content textarea {
    font-size: 14px;
  }
  .oos-home .contact-us .contact-us-content .confirm-privacy-statement {
    font-size: 12px;
  }
}
.oos-home .section-title {
  padding: 60px 0 30px;
  text-align: center;
  color: #212d40;
}
.oos-home .section-title.gray-bg {
  background-color: #f7f8f9;
}
.oos-home .section-title .title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-home .section-title .sub-title {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .oos-home .section-title {
    padding: 22px 20px 18px;
  }
  .oos-home .section-title .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .section-title .title {
    font-size: 18px;
  }
}
.oos-home .section-title.home-case-title {
  padding: 205px 0 30px;
}
@media screen and (max-width: 768px) {
  .oos-home .section-title.home-product-title {
    padding: 42px 0 18px;
  }
  .oos-home .section-title.home-case-title {
    padding: 155px 20px 18px;
  }
}
@media screen and (max-width: 450px) {
  .oos-home .section-title.home-case-title {
    padding: 285px 20px 18px;
  }
}
.oos-product > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-product > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-product > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-product .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-product .banner .banner-content {
  padding: 0 calc((100% - 1600px) / 2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}
.oos-product .banner .banner-content .banner-title {
  position: relative;
  padding-top: 120px;
  width: 750px;
  font-weight: 300;
  color: #d5def2;
  z-index: 1;
}
.oos-product .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-product .banner .banner-content .banner-title .sub-title {
  margin-top: 15px;
  line-height: 24px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-product .banner .banner-content .product-banner-anim-base-img {
  top: -40px;
  right: 0;
}
@media screen and (min-width: 1921px) {
  .oos-product .banner .banner-content {
    position: relative;
    margin: 0 auto;
    padding: unset;
    width: 1600px;
  }
}
@media screen and (max-width: 1800px) {
  .oos-product .banner .banner-content {
    padding: 0 calc((100% - 1600px) / 2);
  }
}
@media screen and (max-width: 1700px) {
  .oos-product .banner .banner-content {
    padding: 0 calc((100% - 1400px) / 2);
  }
  .oos-product .banner .banner-content .banner-title {
    width: 700px;
  }
  .oos-product .banner .banner-content .banner-title .title {
    font-size: 40px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-product .banner .banner-content {
    padding: 0 calc((100% - 1200px) / 2);
  }
  .oos-product .banner .banner-content .banner-title {
    padding-top: 80px;
    width: 500px;
  }
  .oos-product .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-product .banner .banner-content {
    padding: 0 calc((100% - 1000px) / 2);
  }
  .oos-product .banner .banner-content .banner-title {
    padding-top: 60px;
    width: 450px;
  }
  .oos-product .banner .banner-content .banner-title .title {
    font-size: 22px;
  }
}
@media screen and (max-width: 768px) {
  .oos-product .banner {
    height: auto;
  }
  .oos-product .banner .banner-content {
    padding-top: 30px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-product .banner .banner-content .banner-title {
    padding: 10px 20px 30px;
    width: 450px;
  }
  .oos-product .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-product .banner .banner-content .banner-title .button-box {
    justify-content: center;
  }
  .oos-product .banner .banner-content .product-banner-anim-base-img {
    display: none;
  }
  .oos-product .banner .banner-content .product-banner-static-img-pad-ph {
    display: block;
  }
}
@media screen and (max-width: 450px) {
  .oos-product .banner .banner-content {
    padding-top: 0;
    display: block;
    width: 100%;
    overflow: hidden;
  }
  .oos-product .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-product .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-product .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
  }
  .oos-product .banner .banner-content .banner-title .button-box {
    justify-content: center;
  }
  .oos-product .banner .banner-content .product-banner-anim-base-img {
    display: none;
  }
  .oos-product .banner .banner-content .product-banner-static-img-pad-ph {
    float: right;
  }
}
.oos-product .section-nav {
  position: sticky;
  top: 0;
  background-color: #f2f4f7;
  border: 1px solid #e3e6ed;
  z-index: 1;
}
.oos-product .section-nav .section-nav-box {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 1600px;
  height: 50px;
  line-height: 50px;
  user-select: none;
}
.oos-product .section-nav .section-nav-box .nav-item {
  position: relative;
  margin-right: 40px;
  font-size: 14px;
  color: #233759;
}
.oos-product .section-nav .section-nav-box .nav-item:hover {
  cursor: pointer;
}
.oos-product .section-nav .section-nav-box .nav-item.active::before {
  content: " ";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 15px;
  height: 2px;
  background-color: #4c8ffb;
  transform: translateX(-50%);
}
@media screen and (max-width: 1800px) {
  .oos-product .section-nav .section-nav-box {
    width: 1600px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-product .section-nav .section-nav-box {
    width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-product .section-nav .section-nav-box {
    width: 1200px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-product .section-nav .section-nav-box {
    width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .oos-product .section-nav {
    display: none;
  }
}
.oos-product .section-title {
  padding: 60px 0 30px;
  text-align: center;
  color: #212d40;
}
.oos-product .section-title.gray-bg {
  background-color: #f7f8f9;
}
.oos-product .section-title .title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-product .section-title .sub-title {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .oos-product .section-title {
    padding: 22px 20px 18px;
  }
  .oos-product .section-title .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-product .section-title .title {
    font-size: 18px;
  }
}
.oos-product .introduction {
  background-color: #f2f5f8;
}
.oos-product .introduction .introduction-content-wrap {
  margin: 0 auto;
  width: 1600px;
}
.oos-product .introduction .introduction-content-wrap .overview-content {
  padding: 40px 40px 0;
}
.oos-product .introduction .introduction-content-wrap .overview-content .name {
  font-size: 30px;
  font-weight: 600;
  color: #545c6c;
}
.oos-product .introduction .introduction-content-wrap .overview-content .desc-box {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.oos-product .introduction .introduction-content-wrap .overview-content .desc-box .desc-item {
  width: 720px;
  line-height: 24px;
  font-size: 12px;
  color: #545c6c;
  letter-spacing: 2px;
}
.oos-product .introduction .introduction-content-wrap .overview-content .scenario-box {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
}
.oos-product .introduction .introduction-content-wrap .overview-content .scenario-box .scenario-item {
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  padding: 0 25px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #545c6c;
  letter-spacing: 1px;
  border: 1px solid #d2d7e2;
  border-radius: 3px;
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button,
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  background-color: #2a3957;
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-link-icon,
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-link-icon.disabled,
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-link-icon.ph,
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-download-icon,
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-download-icon.disabled,
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-apply-icon,
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-apply-icon.disabled,
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button:hover .oos-button-link-icon:not(.disabled),
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button:hover .oos-button-download-icon:not(.disabled),
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button:hover .oos-button-apply-icon:not(.disabled),
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-link-icon,
  .oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-link-icon,
  .oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-download-icon,
  .oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-download-icon,
  .oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button .oos-button-view-icon,
  .oos-product .introduction .introduction-content-wrap .overview-content .button-box .view-document-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button {
  margin-right: 10px;
}
.oos-product .introduction .introduction-content-wrap .feature-box {
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  min-height: 420px;
}
.oos-product .introduction .introduction-content-wrap .feature-box.uniio {
  min-height: 580px;
}
.oos-product .introduction .introduction-content-wrap .feature-box .feature-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 25px;
  width: 490px;
  height: 160px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: default;
  transition: box-shadow 0.3s linear;
}
.oos-product .introduction .introduction-content-wrap .feature-box .feature-item.placeholder {
  visibility: hidden;
}
.oos-product .introduction .introduction-content-wrap .feature-box .feature-item:hover {
  background: linear-gradient(to bottom, #fff, #fff);
  box-shadow: 1px 6px 5px 0 rgba(116, 130, 166, 0.3);
}
.oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-icon {
  display: block;
}
.oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content {
  margin-left: 10px;
  width: calc(100% - 60px - 10px);
}
.oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content .title {
  font-size: 20px;
  font-weight: 600;
  color: #2e394c;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content .desc {
  margin-top: 2px;
  font-size: 12px;
  color: #a6acb7;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 4;
  box-orient: vertical;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 4;
  -moz-box-orient: vertical;
  -ms-line-clamp: 4;
  -ms-box-orient: vertical;
}
@media screen and (max-width: 1800px) {
  .oos-product .introduction .introduction-content-wrap {
    width: 1600px;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content {
    padding: 40px 0 0;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .desc-box .desc-item {
    width: 740px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box {
    padding: 40px 0;
  }
}
@media screen and (max-width: 1700px) {
  .oos-product .introduction .introduction-content-wrap {
    width: 1400px;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .desc-box .desc-item {
    width: 685px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item {
    width: 450px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-product .introduction .introduction-content-wrap {
    width: 1200px;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content {
    padding: 40px 0 0;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .name {
    font-size: 24px;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .desc-box .desc-item {
    width: 580px;
    line-height: 26px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box {
    min-height: 395px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box.uniio {
    min-height: 545px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item {
    padding: 15px 10px;
    width: 390px;
    height: 150px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content .title {
    font-size: 16px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content .desc {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 5;
    box-orient: vertical;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 5;
    -moz-box-orient: vertical;
    -ms-line-clamp: 5;
    -ms-box-orient: vertical;
  }
}
@media screen and (max-width: 1200px) {
  .oos-product .introduction .introduction-content-wrap {
    width: 1000px;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .name {
    font-size: 22px;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .desc-box .desc-item {
    width: 490px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item {
    padding: 10px;
    width: 325px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content {
    margin-left: 0;
    width: calc(100% - 60px);
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content .title {
    font-size: 14px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content .desc {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 6;
    box-orient: vertical;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 6;
    -moz-box-orient: vertical;
    -ms-line-clamp: 6;
    -ms-box-orient: vertical;
  }
}
@media screen and (max-width: 768px) {
  .oos-product .introduction .introduction-content-wrap {
    padding: 0 20px;
    width: 100%;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .name {
    text-align: center;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .desc-box {
    flex-direction: column;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .desc-box .desc-item {
    width: 100%;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .button-box {
    justify-content: center;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .button-box .apply-trial-button {
    margin-right: 30px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box {
    padding: 30px 0;
    min-height: 545px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box.uniio {
    min-height: 545px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item {
    padding: 15px 10px;
    width: 355px;
    height: 150px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content .title {
    font-size: 16px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content .desc {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 6;
    box-orient: vertical;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 6;
    -moz-box-orient: vertical;
    -ms-line-clamp: 6;
    -ms-box-orient: vertical;
  }
}
@media screen and (max-width: 760px) {
  .oos-product .introduction .introduction-content-wrap .feature-box {
    min-height: 895px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box.uniio {
    min-height: 1155px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item {
    width: 100%;
    height: 130px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item.placeholder {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-product .introduction .introduction-content-wrap .overview-content {
    padding-top: 20px;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .name {
    font-size: 18px;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .desc-box .desc-item {
    line-height: 24px;
    text-align: center;
    font-size: 12px;
  }
  .oos-product .introduction .introduction-content-wrap .overview-content .scenario-box .scenario-item {
    padding: 4px 14px;
    line-height: 22px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box {
    min-height: 1015px;
    padding: 20px 0;
    flex-direction: column;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box.uniio {
    min-height: 1315px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item {
    width: 100%;
    height: 150px;
  }
  .oos-product .introduction .introduction-content-wrap .feature-box .feature-item .feature-text-content .title {
    font-size: 14px;
  }
}
.oos-product .scenario .scenario-content-wrap {
  margin: 0 auto;
  padding: 0 40px;
  width: 1600px;
}
.oos-product .scenario .scenario-content-wrap .scenario-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.oos-product .scenario .scenario-content-wrap .scenario-box:nth-child(n + 2) {
  margin-top: 35px;
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item {
  display: flex;
  flex-direction: row;
  padding: 30px;
  width: calc(50% - 30px);
  min-height: 220px;
  background-color: #f4f6f9;
  border-radius: 5px;
  box-shadow: 1px 5px 5px 0 rgba(116, 130, 166, 0.1);
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon {
  margin-top: 10px;
  display: block;
  width: 80px;
  height: 80px;
  background-size: 100% 100%;
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.virtualization {
  background-image: url("../images/product/uniio/virtualization.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.cloud-computing {
  background-image: url("../images/product/uniio/cloud_computing.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.database {
  background-image: url("../images/product/uniio/database.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.private-cloud {
  background-image: url("../images/product/uniio/private_cloud.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.five-g {
  background-image: url("../images/product/uniio/five_g.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.container-cloud {
  background-image: url("../images/product/uniio/cloud_computing.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.high-performance-computing {
  background-image: url("../images/product/orcafs/high_performance_computing.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.big-data-analysis {
  background-image: url("../images/product/orcafs/big_data_analysis.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.non-linear-editing {
  background-image: url("../images/product/orcafs/non_linear_editing.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.medical-imaging {
  background-image: url("../images/product/orcafs/medical_imaging.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.video-monitor {
  background-image: url("../images/product/orcabd/video_monitor.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.hybrid-cloud-storage {
  background-image: url("../images/product/orcabd/hybrid_cloud_storage.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.data-archiving {
  background-image: url("../images/product/orcabd/data_archiving.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon.disaster-recovery-backup {
  background-image: url("../images/product/orcabd/disaster_recovery_backup.webp");
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content {
  margin-left: 20px;
  width: calc(100% - 100px - 20px);
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .title {
  font-size: 22px;
  font-weight: 600;
  color: #2e394c;
  letter-spacing: 2px;
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .desc {
  margin-top: 5px;
  line-height: 22px;
  font-size: 12px;
  color: #2b3744;
  letter-spacing: 1px;
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .ability-box {
  margin-top: 10px;
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .ability-box .ability-item {
  margin-top: 5px;
  position: relative;
  padding-left: 15px;
  line-height: 20px;
  font-size: 12px;
  color: #54616e;
  letter-spacing: 1px;
}
.oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .ability-box .ability-item::before {
  content: " ";
  position: absolute;
  top: 4px;
  left: 0;
  display: block;
  width: 10px;
  height: 10px;
  background-color: #3392ff;
}
@media screen and (max-width: 1800px) {
  .oos-product .scenario .scenario-content-wrap {
    padding: 0;
  }
}
@media screen and (max-width: 1700px) {
  .oos-product .scenario .scenario-content-wrap {
    width: 1400px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box:nth-child(n + 2) {
    margin-top: 30px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item {
    padding: 20px;
    width: calc(50% - 20px);
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-product .scenario .scenario-content-wrap {
    width: 1200px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box:nth-child(n + 2) {
    margin-top: 25px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item {
    width: calc(50% - 15px);
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .title {
    font-size: 18px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-product .scenario .scenario-content-wrap {
    width: 1000px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box:nth-child(n + 2) {
    margin-top: 20px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item {
    padding: 20px 15px;
    width: calc(50% - 10px);
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .title {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .oos-product .scenario .scenario-content-wrap {
    padding: 0 20px;
    width: 100%;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box {
    flex-direction: column;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box:nth-child(n + 2) {
    margin-top: 20px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item {
    width: 100%;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item:nth-child(n + 2) {
    margin-top: 20px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .title {
    font-size: 20px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .ability-box .ability-item {
    line-height: 20px;
    font-size: 12px;
  }
}
@media screen and (max-width: 450px) {
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item {
    flex-direction: column;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-icon {
    margin: 0 auto;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .title {
    text-align: center;
    font-size: 16px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .desc {
    margin-top: 10px;
    font-size: 13px;
    line-height: 22px;
  }
  .oos-product .scenario .scenario-content-wrap .scenario-box .scenario-item .scenario-text-content .ability-box .ability-item {
    margin-top: 10px;
    line-height: 20px;
    font-size: 12px;
  }
}
.oos-product .specification .specification-img {
  margin: 0 auto;
  display: block;
  width: 1600px;
}
@media screen and (max-width: 1800px) {
  .oos-product .specification .specification-img {
    width: 1600px;
  }
}
@media screen and (max-width: 1600px) {
  .oos-product .specification .specification-img {
    width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-product .specification .specification-img {
    width: 1200px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-product .specification .specification-img {
    width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .oos-product .specification {
    padding: 0 20px;
  }
  .oos-product .specification .specification-img {
    width: 100%;
  }
}
.oos-product .resource .resource-box {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
}
.oos-product .resource .resource-box .resource-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  width: calc((100% - 60px - 60px) / 3);
  height: 150px;
  background: linear-gradient(to bottom, #f0f6ff, #fff);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0 rgba(116, 130, 166, 0.2);
  cursor: pointer;
  transition: box-shadow 0.3s linear;
}
.oos-product .resource .resource-box .resource-item:hover {
  background: linear-gradient(to bottom, #fff, #fff);
  box-shadow: 0 0 5px 0 rgba(116, 130, 166, 0.3);
}
.oos-product .resource .resource-box .resource-item:hover .resource-action-icon.download {
  transform: translateY(5px);
}
.oos-product .resource .resource-box .resource-item:hover .resource-action-icon.forward {
  transform: translateX(5px);
}
.oos-product .resource .resource-box .resource-item .resource-icon {
  display: block;
  width: 72px;
  height: 95px;
  background-image: url("../images/product/resource.webp");
  background-size: 100% 100%;
}
.oos-product .resource .resource-box .resource-item .resource-text-content {
  width: calc(100% - 72px - 25px);
}
.oos-product .resource .resource-box .resource-item .resource-text-content .resource-title {
  font-size: 28px;
  color: #2b3744;
}
.oos-product .resource .resource-box .resource-item .resource-text-content .resource-desc {
  margin-top: 10px;
  font-size: 16px;
  color: #5d616b;
}
.oos-product .resource .resource-box .resource-item .resource-action-icon {
  position: absolute;
  right: 40px;
  display: block;
  transition: transform 0.2s linear;
}
.oos-product .resource .resource-box .resource-item .resource-action-icon.download {
  top: 64.5px;
  width: 24px;
  height: 21px;
  background-image: url("../images/product/download.webp");
  background-size: 100% 100%;
}
.oos-product .resource .resource-box .resource-item .resource-action-icon.forward {
  top: 63.5px;
  width: 14px;
  height: 23px;
  background-image: url("../images/product/forward.webp");
  background-size: 100% 100%;
}
@media screen and (max-width: 1800px) {
  .oos-product .resource .resource-box {
    width: 1600px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-product .resource .resource-box {
    width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-product .resource .resource-box {
    width: 1200px;
  }
  .oos-product .resource .resource-box .resource-item .resource-text-content .resource-title {
    font-size: 22px;
  }
  .oos-product .resource .resource-box .resource-item .resource-text-content .resource-desc {
    font-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-product .resource .resource-box {
    width: 1000px;
  }
  .oos-product .resource .resource-box .resource-item {
    padding: 0 20px;
  }
  .oos-product .resource .resource-box .resource-item .resource-action-icon {
    right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .oos-product .resource {
    padding: 0 20px;
  }
  .oos-product .resource .resource-box {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .oos-product .resource .resource-box .resource-item {
    padding: 0 40px;
    width: 500px;
  }
  .oos-product .resource .resource-box .resource-item:nth-child(n + 2) {
    margin-top: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-product .resource {
    padding: 0 20px;
  }
  .oos-product .resource .resource-box .resource-item {
    width: 100%;
    height: 100px;
  }
  .oos-product .resource .resource-box .resource-item:nth-child(n + 2) {
    margin-top: 20px;
  }
  .oos-product .resource .resource-box .resource-item:hover .resource-action-icon.download {
    transform: none;
  }
  .oos-product .resource .resource-box .resource-item:hover .resource-action-icon.forward {
    transform: none;
  }
  .oos-product .resource .resource-box .resource-item .resource-icon {
    width: 36px;
    height: 47.5px;
  }
  .oos-product .resource .resource-box .resource-item .resource-text-content {
    width: calc(100% - 36px - 25px);
  }
  .oos-product .resource .resource-box .resource-item .resource-text-content .resource-title {
    font-size: 16px;
  }
  .oos-product .resource .resource-box .resource-item .resource-text-content .resource-desc {
    font-size: 13px;
  }
  .oos-product .resource .resource-box .resource-item .resource-action-icon {
    transition: none;
  }
  .oos-product .resource .resource-box .resource-item .resource-action-icon.download {
    top: 39.5px;
  }
  .oos-product .resource .resource-box .resource-item .resource-action-icon.forward {
    top: 38.5px;
  }
}
.oos-product .case .case-box {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 1200px;
}
.oos-product .case .case-box .case-item {
  padding: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6f9;
  border-radius: 5px;
}
.oos-product .case .case-box .case-item .text-content {
  width: calc(100% - 50px - 500px);
}
.oos-product .case .case-box .case-item .text-content.one-row {
  width: 100%;
}
.oos-product .case .case-box .case-item .text-content .title {
  font-size: 22px;
  color: #2b3744;
  letter-spacing: 1px;
}
.oos-product .case .case-box .case-item .text-content .introduction {
  padding-top: 10px;
  font-size: 14px;
  line-height: 26px;
  color: #959eae;
}
.oos-product .case .case-box .case-item .text-content .point-content {
  padding-top: 10px;
}
.oos-product .case .case-box .case-item .text-content .point-content .point {
  position: relative;
  padding-bottom: 10px;
  font-size: 14px;
  color: #2e394c;
}
.oos-product .case .case-box .case-item .text-content .point-content .point:not(.no-symbol) {
  padding-left: 16px;
}
.oos-product .case .case-box .case-item .text-content .point-content .point:not(.no-symbol)::before {
  content: " ";
  position: absolute;
  top: 5px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #2e394c;
  border-radius: 100%;
}
.oos-product .case .case-box .case-item .text-content .point-content .point:nth-last-child(1) {
  padding-bottom: 0;
}
.oos-product .case .case-box .case-item .img {
  width: 500px;
  border-radius: 5px;
}
@media screen and (max-width: 1200px) {
  .oos-product .case .case-box {
    width: 1000px;
  }
  .oos-product .case .case-box .case-item .text-content {
    width: calc(100% - 50px - 400px);
  }
  .oos-product .case .case-box .case-item .img {
    width: 400px;
  }
}
@media screen and (max-width: 768px) {
  .oos-product .case .case-box {
    padding: 0 20px;
    width: 100%;
  }
  .oos-product .case .case-box .case-item {
    padding: 20px;
    flex-direction: column;
  }
  .oos-product .case .case-box .case-item .text-content {
    width: 100%;
  }
  .oos-product .case .case-box .case-item .text-content .title {
    text-align: center;
    font-size: 16px;
  }
  .oos-product .case .case-box .case-item .text-content .introduction {
    font-size: 12px;
  }
  .oos-product .case .case-box .case-item .text-content .point-content .point {
    font-size: 12px;
  }
  .oos-product .case .case-box .case-item .img {
    margin-top: 30px;
    width: 500px;
  }
}
@media screen and (max-width: 450px) {
  .oos-product .case .case-box .case-item .img {
    width: 100%;
  }
}
.oos-solution > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-solution > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-solution > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-solution .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-solution .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-solution .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-solution .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-solution .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-solution .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-solution .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-solution .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-solution .banner .banner-content {
    width: 1400px;
  }
  .oos-solution .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-solution .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-solution .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-solution .banner .banner-content {
    width: 1200px;
  }
  .oos-solution .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-solution .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-solution .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-solution .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-solution .banner .banner-content {
    width: 1000px;
  }
  .oos-solution .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-solution .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-solution .banner {
    height: auto;
  }
  .oos-solution .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-solution .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-solution .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-solution .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-solution .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-solution .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-solution .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-solution .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-solution .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-solution .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-solution .section-nav {
  position: sticky;
  top: 0;
  background-color: #f2f4f7;
  border: 1px solid #e3e6ed;
  z-index: 1;
}
.oos-solution .section-nav .section-nav-box {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 1600px;
  height: 50px;
  line-height: 50px;
  user-select: none;
}
.oos-solution .section-nav .section-nav-box .nav-item {
  position: relative;
  margin-right: 40px;
  font-size: 14px;
  color: #233759;
}
.oos-solution .section-nav .section-nav-box .nav-item:hover {
  cursor: pointer;
}
.oos-solution .section-nav .section-nav-box .nav-item.active::before {
  content: " ";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 15px;
  height: 2px;
  background-color: #4c8ffb;
  transform: translateX(-50%);
}
@media screen and (max-width: 1800px) {
  .oos-solution .section-nav .section-nav-box {
    width: 1600px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-solution .section-nav .section-nav-box {
    width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-solution .section-nav .section-nav-box {
    width: 1200px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-solution .section-nav .section-nav-box {
    width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .oos-solution .section-nav {
    display: none;
  }
}
.oos-solution .section-title {
  padding: 60px 0 30px;
  text-align: center;
  color: #212d40;
}
.oos-solution .section-title.gray-bg {
  background-color: #f7f8f9;
}
.oos-solution .section-title .title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-solution .section-title .sub-title {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .oos-solution .section-title {
    padding: 22px 20px 18px;
  }
  .oos-solution .section-title .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-solution .section-title .title {
    font-size: 18px;
  }
}
.oos-solution .customer-pain-point {
  padding: 60px 0;
  background-color: #f2f5f8;
}
.oos-solution .customer-pain-point .customer-pain-point-box {
  padding: 0 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 1600px;
}
.oos-solution .customer-pain-point .customer-pain-point-box:nth-child(2) {
  padding-top: 30px;
}
.oos-solution .customer-pain-point .customer-pain-point-box.two-items {
  width: 1400px;
}
.oos-solution .customer-pain-point .customer-pain-point-box.two-items .customer-pain-point-item {
  width: 600px;
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item {
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
  width: 470px;
  min-height: 140px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: default;
  transition: box-shadow 0.3s linear;
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item:hover {
  background: linear-gradient(to bottom, #fff, #fff);
  box-shadow: 1px 6px 5px 0 rgba(116, 130, 166, 0.3);
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon {
  margin-top: 10px;
  display: block;
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.temp {
  border: 1px solid #3392ff;
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.inefficient-hdfs {
  background-image: url("../images/solution/bigData/inefficient_hdfs.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.lack-of-segregation {
  background-image: url("../images/solution/bigData/lack_of_segregation.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.data-silos {
  background-image: url("../images/solution/bigData/data_silos.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.lack-of-capacity-high-performance-computing {
  background-image: url("../images/solution/highPerformanceComputing/lack_of_capacity.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.high-complexity-high-performance-computing {
  background-image: url("../images/solution/highPerformanceComputing/high_complexity.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.performance-barely-meets-requirement-high-performance-computing {
  background-image: url("../images/solution/highPerformanceComputing/performance_barely_meets_requirement.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.lack-of-flexibility-cloud-computing-private-cloud {
  background-image: url("../images/solution/cloudComputingPrivateCloud/lack_of_flexibility.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.lack-of-sharing-cloud-computing-private-cloud {
  background-image: url("../images/solution/cloudComputingPrivateCloud/lack_of_sharing.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.no-reliability-guarantee-cloud-computing-private-cloud {
  background-image: url("../images/solution/cloudComputingPrivateCloud/no_reliability_guarantee.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.lack-of-flexibility-cloud-native-container-storage {
  background-image: url("../images/solution/cloudNativeContainerStorage/lack_of_flexibility.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.lack-of-sharing-cloud-native-container-storage {
  background-image: url("../images/solution/cloudNativeContainerStorage/lack_of_sharing.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.no-reliability-guarantee-cloud-native-container-storage {
  background-image: url("../images/solution/cloudNativeContainerStorage/no_reliability_guarantee.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.too-much-data {
  background-image: url("../images/solution/dataBackup/too_much_data.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.too-many-clients {
  background-image: url("../images/solution/dataBackup/too_many_clients.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.many-backup-types {
  background-image: url("../images/solution/dataBackup/many_backup_types.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.low-reliability-new-energy-vehicle {
  background-image: url("../images/solution/newEnergyVehicle/low_reliability.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.high-cost-new-energy-vehicle {
  background-image: url("../images/solution/newEnergyVehicle/high_cost.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-manage-new-energy-vehicle {
  background-image: url("../images/solution/newEnergyVehicle/hard_to_manage.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-store-data {
  background-image: url("../images/solution/intelligentCampus/hard_to_store_data.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.poor-hd-experience {
  background-image: url("../images/solution/intelligentCampus/poor_hd_experience.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.low-reliability {
  background-image: url("../images/solution/intelligentCampus/low_reliability.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-expansion {
  background-image: url("../images/solution/intelligentSecurity/hard_to_expansion.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.low-efficiency-of-solve-case {
  background-image: url("../images/solution/intelligentSecurity/low_efficiency_of_solve_case.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.low-reliability {
  background-image: url("../images/solution/intelligentSecurity/low_reliability.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.data-mining {
  background-image: url("../images/solution/ai/data_mining.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.data-process {
  background-image: url("../images/solution/ai/data_process.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.low-reliability {
  background-image: url("../images/solution/ai/low_reliability.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-construct {
  background-image: url("../images/solution/brightProject/hard_to_construct.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.weak-consciousness {
  background-image: url("../images/solution/brightProject/weak_consciousness.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.have-leak {
  background-image: url("../images/solution/brightProject/have_leak.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.frequent-safety-incident {
  background-image: url("../images/solution/majorEventSecurity/frequent_safety_incident.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.lack-of-prevention {
  background-image: url("../images/solution/majorEventSecurity/lack_of_prevention.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.lack-of-overall-planning {
  background-image: url("../images/solution/intelligentCommunity/lack_of_overall_planning.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-integrate {
  background-image: url("../images/solution/intelligentCommunity/hard_to_integrate.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-difference {
  background-image: url("../images/solution/intelligentCommunity/hard_to_difference.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hidden-danger {
  background-image: url("../images/solution/intelligentCommunitySecurity/hidden_danger.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-get-evidence {
  background-image: url("../images/solution/intelligentCommunitySecurity/hard_to_get_evidence.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.security-weakness {
  background-image: url("../images/solution/intelligentCommunitySecurity/security_weakness.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-supervise {
  background-image: url("../images/solution/highAltitudeThrowObject/hard_to_supervise.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.old-elevator {
  background-image: url("../images/solution/intelligentElevator/old_elevator.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-rescue {
  background-image: url("../images/solution/intelligentElevator/hard_to_rescue.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.limited-human-resource {
  background-image: url("../images/solution/intelligentElevator/limited_human_resource.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-evidence {
  background-image: url("../images/solution/highAltitudeThrowObject/hard_to_evidence.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.low-efficiency {
  background-image: url("../images/solution/intelligentPark/low_efficiency.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.data-not-precipitated {
  background-image: url("../images/solution/intelligentPark/data_not_precipitated.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.high-ecological-richness {
  background-image: url("../images/solution/intelligentPark/high_ecological_richness.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-monitor {
  background-image: url("../images/solution/intelligentScenicSpot/hard_to_monitor.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.low-efficiency {
  background-image: url("../images/solution/intelligentScenicSpot/low_efficiency.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-manageCar {
  background-image: url("../images/solution/intelligentScenicSpot/hard_to_manageCar.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-analyse-customer {
  background-image: url("../images/solution/intelligentScenicSpot/hard_to_analyse_customer.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-operate {
  background-image: url("../images/solution/carbonNeutrality/hard_to_operate.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.low-efficiency {
  background-image: url("../images/solution/carbonNeutrality/low_efficiency.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.high-cost-and-pollution {
  background-image: url("../images/solution/carbonNeutrality/high_cost_and_pollution.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.state-supervision {
  background-image: url("../images/solution/intelligentGarden/state_supervision.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.achievement-supervision {
  background-image: url("../images/solution/intelligentGarden/achievement_supervision.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.curing-guidance {
  background-image: url("../images/solution/intelligentGarden/curing_guidance.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.lack-of-preparation {
  background-image: url("../images/solution/emergencyCommand/lack_of_preparation.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.monitor-untimely {
  background-image: url("../images/solution/emergencyCommand/monitor_untimely.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-coordinate {
  background-image: url("../images/solution/emergencyCommand/hard_to_coordinate.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.no-precise-query {
  background-image: url("../images/solution/safetyProductionEmergency/no_precise_query.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.limited-law-enforcement {
  background-image: url("../images/solution/safetyProductionEmergency/limited_law_enforcement.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.insufficient-supervision {
  background-image: url("../images/solution/safetyProductionEmergency/insufficient_supervision.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.low-responsibility {
  background-image: url("../images/solution/safetyProductionEmergency/low_responsibility.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.similar-feature {
  background-image: url("../images/solution/iotPlatform/similar_feature.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.data-redundancy {
  background-image: url("../images/solution/iotPlatform/data_redundancy.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.hard-to-interwork {
  background-image: url("../images/solution/iotPlatform/hard_to_interwork.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-icon.long-chain {
  background-image: url("../images/solution/iotPlatform/long_chain.webp");
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content {
  margin-left: 10px;
  width: calc(100% - 60px - 10px);
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content .title {
  font-size: 18px;
  font-weight: 500;
  color: #2e394c;
  letter-spacing: 1px;
}
.oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content .desc {
  margin-top: 5px;
  line-height: 24px;
  font-size: 13px;
  color: #b3b9c4;
}
@media screen and (max-width: 1800px) {
  .oos-solution .customer-pain-point .customer-pain-point-box {
    padding: 0;
    width: 1600px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box.two-items {
    padding-left: 40px;
    padding-right: 40px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item {
    width: 460px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-solution .customer-pain-point .customer-pain-point-box {
    width: 1400px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item {
    width: 450px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-solution .customer-pain-point .customer-pain-point-box {
    width: 1200px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box.two-items {
    width: 1200px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box.two-items .customer-pain-point-item {
    width: 540px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item {
    padding: 20px;
    width: 380px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-solution .customer-pain-point .customer-pain-point-box {
    width: 1000px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box.two-items {
    padding-left: 0;
    padding-right: 0;
    width: 1000px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box.two-items .customer-pain-point-item {
    width: 480px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item {
    padding: 10px;
    width: 310px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content {
    width: calc(100% - 60px);
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content .title {
    font-size: 18px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content .desc {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .oos-solution .customer-pain-point {
    padding: 10px 0 30px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box {
    padding: 0 20px;
    width: 100%;
    display: block;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box:nth-child(2) {
    padding-top: 0;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box.two-items {
    padding: 0 20px;
    width: 100%;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box.two-items .customer-pain-point-item {
    width: 100%;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item {
    padding: 20px 30px;
    margin-top: 20px;
    margin-right: 10px;
    width: 100%;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content {
    width: calc(100% - 60px);
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content .title {
    font-size: 18px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content .desc {
    font-size: 16px;
  }
}
@media screen and (max-width: 450px) {
  .oos-solution .customer-pain-point .customer-pain-point-box {
    padding: 0 20px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item {
    padding: 20px 10px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content .title {
    font-size: 14px;
  }
  .oos-solution .customer-pain-point .customer-pain-point-box .customer-pain-point-item .customer-pain-point-text-content .desc {
    font-size: 12px;
  }
}
.oos-solution .scheme-introduction .scheme-introduction-box {
  margin: 0 auto;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1600px;
  color: #203150;
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content {
  width: calc(100% - 800px);
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .title {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .desc {
  margin-top: 5px;
  line-height: 26px;
  font-size: 14px;
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .desc.main {
  font-weight: 600;
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img {
  width: 712px;
  height: 412px;
  background-size: 100% 100%;
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.big-data {
  background-image: url('../images/solution/bigData/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.high-performance-computing {
  background-image: url('../images/solution/highPerformanceComputing/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.cloud-computing-private-cloud {
  background-image: url('../images/solution/cloudComputingPrivateCloud/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.cloud-native-container-storage {
  background-image: url('../images/solution/cloudNativeContainerStorage/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.data-backup {
  background-image: url('../images/solution/dataBackup/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.new-energy-vehicle {
  background-image: url('../images/solution/newEnergyVehicle/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.intelligent-campus {
  background-image: url('../images/solution/intelligentCampus/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.intelligent-security {
  background-image: url('../images/solution/intelligentSecurity/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.ai {
  background-image: url('../images/solution/ai/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.bright-project {
  background-image: url('../images/solution/brightProject/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.major-event-security {
  background-image: url('../images/solution/majorEventSecurity/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.intelligent-community {
  background-image: url('../images/solution/intelligentCommunity/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.intelligent-community-security {
  background-image: url('../images/solution/intelligentCommunitySecurity/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.intelligent-elevator {
  background-image: url('../images/solution/intelligentElevator/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.high-altitude-throw-object {
  background-image: url('../images/solution/highAltitudeThrowObject/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.intelligent-park {
  background-image: url('../images/solution/intelligentPark/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.intelligent-scenic-spot {
  background-image: url('../images/solution/intelligentScenicSpot/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.all-area-tourism {
  background-image: url('../images/solution/allAreaTourism/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.carbon-neutrality {
  background-image: url('../images/solution/carbonNeutrality/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.intelligent-garden {
  background-image: url('../images/solution/intelligentGarden/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.emergency-command {
  background-image: url('../images/solution/emergencyCommand/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.safety-production-emergency {
  background-image: url('../images/solution/safetyProductionEmergency/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.iot-platform {
  background-image: url('../images/solution/iotPlatform/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.big-data {
  background-image: url('../images/solution/bigData/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.ai {
  background-image: url('../images/solution/ai/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.data-backup {
  background-image: url('../images/solution/dataBackup/introduction.webp');
}
.oos-solution .scheme-introduction .scheme-introduction-box .introduction-img.intelligent-security {
  background-image: url('../images/solution/intelligentSecurity/introduction.webp');
}
@media screen and (max-width: 1800px) {
  .oos-solution .scheme-introduction .scheme-introduction-box {
    padding: 20px 0;
    width: 1600px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-solution .scheme-introduction .scheme-introduction-box {
    width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-solution .scheme-introduction .scheme-introduction-box {
    width: 1200px;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content {
    width: calc(100% - 600px);
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .title {
    font-size: 28px;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .desc {
    line-height: 24px;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-img {
    width: 550px;
    height: 318.258px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-solution .scheme-introduction .scheme-introduction-box {
    width: 1000px;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content {
    width: calc(100% - 520px);
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .title {
    font-size: 22px;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .desc {
    font-size: 13px;
    line-height: 22px;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-img {
    width: 500px;
    height: 289.326px;
  }
}
@media screen and (max-width: 768px) {
  .oos-solution .scheme-introduction .scheme-introduction-box {
    padding: 20px 20px;
    flex-direction: column;
    width: 100%;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content {
    width: 100%;
    text-align: center;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .title {
    font-size: 20px;
    font-weight: 400;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-img {
    margin-top: 20px;
    width: 712px;
    max-width: 100%;
    height: 412px;
  }
}
@media screen and (max-width: 450px) {
  .oos-solution .scheme-introduction .scheme-introduction-box {
    padding: 0 20px 20px;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content {
    width: 100%;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .title {
    font-size: 16px;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-text-content .desc {
    font-size: 12px;
  }
  .oos-solution .scheme-introduction .scheme-introduction-box .introduction-img {
    margin-top: 10px;
    width: 356px;
    height: 206px;
  }
}
.oos-solution .customer-value {
  padding-top: 25px;
}
.oos-solution .customer-value .customer-value-box {
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 1600px;
}
.oos-solution .customer-value .customer-value-box:not(:nth-child(1)) {
  margin-top: 60px;
}
.oos-solution .customer-value .customer-value-box.two-items {
  width: 1400px;
}
.oos-solution .customer-value .customer-value-box.two-items .customer-value-item {
  width: 600px;
}
.oos-solution .customer-value .customer-value-box .customer-value-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  width: 470px;
  min-height: 160px;
  background-color: #f4f6f9;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: default;
  transition: box-shadow 0.3s linear;
}
.oos-solution .customer-value .customer-value-box .customer-value-item:hover {
  box-shadow: 1px 6px 5px 0 rgba(116, 130, 166, 0.3);
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon {
  position: absolute;
  display: block;
  top: -25px;
  left: 63px;
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.temp {
  border: 1px solid #3392ff;
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.big-capacity-high-performance-computing {
  background-image: url("../images/solution/highPerformanceComputing/big_capacity.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-performance-high-performance-computing {
  background-image: url("../images/solution/highPerformanceComputing/high_performance.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.easy-to-use-high-performance-computing {
  background-image: url("../images/solution/highPerformanceComputing/easy_to_use.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-resource-utilization-cloud-computing-private-cloud {
  background-image: url("../images/solution/cloudComputingPrivateCloud/high_resource_utilization.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-safety-cloud-computing-private-cloud {
  background-image: url("../images/solution/cloudComputingPrivateCloud/high_safety.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-performance-cloud-computing-private-cloud {
  background-image: url("../images/solution/cloudComputingPrivateCloud/high_performance.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-resource-utilization-cloud-native-container-storage {
  background-image: url("../images/solution/cloudNativeContainerStorage/high_resource_utilization.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-safety-cloud-native-container-storage {
  background-image: url("../images/solution/cloudNativeContainerStorage/high_safety.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-performance-cloud-native-container-storage {
  background-image: url("../images/solution/cloudNativeContainerStorage/high_performance.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.flexibility-operation {
  background-image: url("../images/solution/dataBackup/flexibility_operation.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.overall-backup {
  background-image: url("../images/solution/dataBackup/overall_backup.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.easy-to-use {
  background-image: url("../images/solution/dataBackup/easy_to_use.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-reliability-new-energy-vehicle {
  background-image: url("../images/solution/newEnergyVehicle/high_reliability.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.low-cost-new-energy-vehicle {
  background-image: url("../images/solution/newEnergyVehicle/low_cost.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.intelligent-management-new-energy-vehicle {
  background-image: url("../images/solution/newEnergyVehicle/intelligent_management.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.unified-resources {
  background-image: url("../images/solution/intelligentSecurity/unified_resources.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.easy-to-expand {
  background-image: url("../images/solution/intelligentSecurity/easy_to_expand.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.intelligent-manage {
  background-image: url("../images/solution/intelligentSecurity/intelligent_manage.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-performance {
  background-image: url("../images/solution/ai/high_performance.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.high-reliability {
  background-image: url("../images/solution/ai/high_reliability.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.hardware-independence {
  background-image: url("../images/solution/ai/hardware_independence.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.rational-layout {
  background-image: url("../images/solution/brightProject/rational_layout.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.information-sharing {
  background-image: url("../images/solution/brightProject/information_sharing.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.scientific-operation {
  background-image: url("../images/solution/brightProject/scientific_operation.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.system-security {
  background-image: url("../images/solution/brightProject/system_security.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.ai-and-big-data {
  background-image: url("../images/solution/brightProject/ai_and_big_data.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.one-screen-control {
  background-image: url("../images/solution/majorEventSecurity/one_screen_control.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.practical-application {
  background-image: url("../images/solution/majorEventSecurity/practical_application.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.multi-level-fusion {
  background-image: url("../images/solution/majorEventSecurity/multi_level_fusion.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.owner-value {
  background-image: url("../images/solution/intelligentCommunity/owner_value.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.property-value {
  background-image: url("../images/solution/intelligentCommunity/property_value.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.real-estate-value {
  background-image: url("../images/solution/intelligentCommunity/real_estate_value.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.government-property {
  background-image: url("../images/solution/intelligentCommunity/government_property.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.community-grid {
  background-image: url("../images/solution/intelligentCommunitySecurity/community_grid.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.defense-line {
  background-image: url("../images/solution/intelligentCommunitySecurity/defense_line.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.information-integration {
  background-image: url("../images/solution/intelligentCommunitySecurity/information_integration.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.key-person {
  background-image: url("../images/solution/intelligentCommunitySecurity/key_person.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.community-archive {
  background-image: url("../images/solution/intelligentCommunitySecurity/community_archive.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.maintenance-supervision {
  background-image: url("../images/solution/intelligentElevator/maintenance_supervision.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.data-archive {
  background-image: url("../images/solution/intelligentElevator/data_archive.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.cut-cost {
  background-image: url("../images/solution/intelligentElevator/cut_cost.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.emergency-ability {
  background-image: url("../images/solution/intelligentElevator/emergency_ability.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.accurate-monitoring {
  background-image: url("../images/solution/highAltitudeThrowObject/accurate_monitoring.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.automatic-alarm {
  background-image: url("../images/solution/highAltitudeThrowObject/automatic_alarm.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.strong-adaptability {
  background-image: url("../images/solution/highAltitudeThrowObject/strong_adaptability.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.unified-management {
  background-image: url("../images/solution/intelligentPark/unified_management.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.linkage-command {
  background-image: url("../images/solution/intelligentPark/linkage_command.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.experience-optimization {
  background-image: url("../images/solution/intelligentPark/experience_optimization.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.intelligent-management {
  background-image: url("../images/solution/intelligentPark/intelligent_management.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.strong-versatility {
  background-image: url("../images/solution/intelligentPark/strong_versatility.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.all-area-security-control {
  background-image: url("../images/solution/intelligentPark/all_area_security_control.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.software-defined-scenic {
  background-image: url("../images/solution/intelligentScenicSpot/software_defined_scenic.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.highly-integrated {
  background-image: url("../images/solution/intelligentScenicSpot/highly_integrated.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.unified-management {
  background-image: url("../images/solution/intelligentScenicSpot/unified_management.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.cloud-flexible {
  background-image: url("../images/solution/intelligentScenicSpot/cloud_flexible.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.face-track {
  background-image: url("../images/solution/intelligentScenicSpot/face_track.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.help-for-acceptance {
  background-image: url("../images/solution/allAreaTourism/help_for_acceptance.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.big-data-support {
  background-image: url("../images/solution/allAreaTourism/big_data_support.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.improve-service-level {
  background-image: url("../images/solution/allAreaTourism/improve_service_level.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.promote-consumption {
  background-image: url("../images/solution/allAreaTourism/promote_consumption.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.energy-saving {
  background-image: url("../images/solution/carbonNeutrality/energy_saving.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.improve-efficiency {
  background-image: url("../images/solution/carbonNeutrality/improve_efficiency.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.reform-industry {
  background-image: url("../images/solution/carbonNeutrality/reform_industry.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.economic-transformation {
  background-image: url("../images/solution/carbonNeutrality/economic_transformation.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.intelligent-manage {
  background-image: url("../images/solution/intelligentGarden/intelligent_manage.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.data-monitor {
  background-image: url("../images/solution/intelligentGarden/data_monitor.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.cut-cost {
  background-image: url("../images/solution/intelligentGarden/cut_cost.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.alarm-quickly {
  background-image: url("../images/solution/emergencyCommand/alarm_quickly.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.early-warning {
  background-image: url("../images/solution/emergencyCommand/early_warning.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.unified-scheduling {
  background-image: url("../images/solution/emergencyCommand/unified_scheduling.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.comprehensive-supervision {
  background-image: url("../images/solution/safetyProductionEmergency/comprehensive_supervision.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.visual-monitoring {
  background-image: url("../images/solution/safetyProductionEmergency/visual_monitoring.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.easy-to-develop {
  background-image: url("../images/solution/iotPlatform/easy_to_develop.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.continuous-upgrade {
  background-image: url("../images/solution/iotPlatform/continuous_upgrade.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.optimal-scheme {
  background-image: url("../images/solution/iotPlatform/optimal_scheme.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon.security-compatibility {
  background-image: url("../images/solution/iotPlatform/security_compatibility.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-title {
  position: relative;
  padding: 0 30px;
  width: 150px;
  text-align: center;
  font-size: 24px;
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc {
  position: relative;
  padding: 0 12px;
  width: calc(100% - 150px);
  line-height: 26px;
  font-size: 14px;
  color: #959eae;
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc::before,
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc::after {
  content: " ";
  position: absolute;
  display: block;
  width: 22px;
  height: 14px;
  background-image: url("../images/solution/text_content_angle_dark.webp");
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc::before {
  top: -12px;
  left: 0;
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc::after {
  bottom: -12px;
  right: 0;
  transform: rotateZ(180deg);
}
.oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc.small-font {
  font-size: 12px;
}
@media screen and (max-width: 1800px) {
  .oos-solution .customer-value {
    padding-top: 25px;
  }
  .oos-solution .customer-value .customer-value-box {
    padding: 0;
    width: 1600px;
  }
  .oos-solution .customer-value .customer-value-box.two-items {
    padding: 0 40px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item {
    width: 460px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-solution .customer-value .customer-value-box {
    width: 1400px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item {
    padding: 20px 10px;
    width: 450px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-title {
    padding: 0 20px;
    width: 130px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc {
    width: calc(100% - 130px);
  }
}
@media screen and (max-width: 1400px) {
  .oos-solution .customer-value .customer-value-box {
    width: 1200px;
  }
  .oos-solution .customer-value .customer-value-box.two-items {
    width: 1200px;
  }
  .oos-solution .customer-value .customer-value-box.two-items .customer-value-item {
    width: 540px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item {
    width: 380px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon {
    left: 33px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-title {
    padding: 0 15px;
    width: 110px;
    font-size: 22px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc {
    width: calc(100% - 110px);
  }
}
@media screen and (max-width: 1200px) {
  .oos-solution .customer-value .customer-value-box {
    width: 1000px;
  }
  .oos-solution .customer-value .customer-value-box.two-items {
    padding-left: 0;
    padding-right: 0;
    width: 1000px;
  }
  .oos-solution .customer-value .customer-value-box.two-items .customer-value-item {
    width: 480px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item {
    width: 310px;
  }
}
@media screen and (max-width: 768px) {
  .oos-solution .customer-value {
    padding-top: 25px;
  }
  .oos-solution .customer-value .customer-value-box {
    padding: 0 20px;
    width: 100%;
    display: block;
  }
  .oos-solution .customer-value .customer-value-box:not(:nth-child(1)) {
    margin-top: 50px;
  }
  .oos-solution .customer-value .customer-value-box.two-items {
    padding: 0 20px;
    width: 100%;
  }
  .oos-solution .customer-value .customer-value-box.two-items .customer-value-item {
    width: 100%;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item {
    padding: 20px;
    width: 100%;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item:nth-child(n + 2) {
    margin-top: 50px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon {
    left: 64px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-title {
    padding: 0 30px;
    width: 150px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc {
    width: calc(100% - 150px);
    font-size: 18px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc.small-font {
    font-size: 16px;
  }
}
@media screen and (max-width: 450px) {
  .oos-solution .customer-value .customer-value-box .customer-value-item:not(:nth-child(1)) {
    margin-top: 40px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-icon {
    top: -30px;
    left: 28px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-title {
    padding: 0 15px;
    width: 80px;
    font-size: 16px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc {
    width: calc(100% - 80px);
    font-size: 13px;
  }
  .oos-solution .customer-value .customer-value-box .customer-value-item .customer-value-desc.small-font {
    font-size: 12px;
  }
}
.oos-solution .networking .networking-img {
  margin: 0 auto;
  display: block;
  width: 1600px;
}
.oos-solution .networking .networking-img.normal {
  width: 900px;
}
.oos-solution .networking .networking-img.small {
  width: 800px;
}
@media screen and (max-width: 1800px) {
  .oos-solution .networking .networking-img {
    width: 1600px;
  }
}
@media screen and (max-width: 1600px) {
  .oos-solution .networking .networking-img {
    width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-solution .networking .networking-img {
    width: 1200px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-solution .networking .networking-img {
    width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .oos-solution .networking {
    padding: 0 20px;
  }
  .oos-solution .networking .networking-img {
    width: 100%;
  }
  .oos-solution .networking .networking-img.normal {
    width: 100%;
  }
  .oos-solution .networking .networking-img.small {
    width: 100%;
  }
}
.oos-solution .case .case-box {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 1200px;
}
.oos-solution .case .case-box .case-item {
  padding: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6f9;
  border-radius: 5px;
}
.oos-solution .case .case-box .case-item:not(:nth-child(1)) {
  margin-top: 30px;
}
.oos-solution .case .case-box .case-item .text-content {
  width: calc(100% - 50px - 500px);
}
.oos-solution .case .case-box .case-item .text-content.one-row {
  width: 100%;
}
.oos-solution .case .case-box .case-item .text-content .title {
  font-size: 22px;
  color: #2b3744;
  letter-spacing: 1px;
}
.oos-solution .case .case-box .case-item .text-content .introduction {
  padding-top: 10px;
  font-size: 14px;
  line-height: 26px;
  color: #959eae;
}
.oos-solution .case .case-box .case-item .text-content .point-content {
  padding-top: 10px;
}
.oos-solution .case .case-box .case-item .text-content .point-content .point {
  position: relative;
  padding-bottom: 10px;
  font-size: 14px;
  color: #2e394c;
}
.oos-solution .case .case-box .case-item .text-content .point-content .point:not(.no-symbol) {
  padding-left: 16px;
}
.oos-solution .case .case-box .case-item .text-content .point-content .point:not(.no-symbol)::before {
  content: " ";
  position: absolute;
  top: 5px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #2e394c;
  border-radius: 100%;
}
.oos-solution .case .case-box .case-item .text-content .point-content .point:nth-last-child(1) {
  padding-bottom: 0;
}
.oos-solution .case .case-box .case-item .img {
  width: 500px;
  border-radius: 5px;
}
@media screen and (max-width: 1200px) {
  .oos-solution .case .case-box {
    width: 1000px;
  }
  .oos-solution .case .case-box .case-item .text-content {
    width: calc(100% - 50px - 400px);
  }
  .oos-solution .case .case-box .case-item .img {
    width: 400px;
  }
}
@media screen and (max-width: 768px) {
  .oos-solution .case .case-box {
    padding: 0 20px;
    width: 100%;
  }
  .oos-solution .case .case-box .case-item {
    padding: 20px;
    flex-direction: column;
  }
  .oos-solution .case .case-box .case-item:not(:nth-child(1)) {
    margin-top: 20px;
  }
  .oos-solution .case .case-box .case-item .text-content {
    width: 100%;
  }
  .oos-solution .case .case-box .case-item .text-content .title {
    text-align: center;
    font-size: 18px;
  }
  .oos-solution .case .case-box .case-item .text-content .introduction {
    font-size: 14px;
  }
  .oos-solution .case .case-box .case-item .text-content .point-content .point {
    font-size: 13px;
  }
  .oos-solution .case .case-box .case-item .img {
    margin-top: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .oos-solution .case .case-box .case-item .text-content .title {
    font-size: 16px;
  }
  .oos-solution .case .case-box .case-item .text-content .introduction {
    font-size: 12px;
  }
  .oos-solution .case .case-box .case-item .text-content .point-content .point {
    font-size: 12px;
  }
}
.oos-technical-support > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-technical-support > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-technical-support > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-technical-support .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-technical-support .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-technical-support .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-technical-support .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-technical-support .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-technical-support .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-technical-support .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-technical-support .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-technical-support .banner .banner-content {
    width: 1400px;
  }
  .oos-technical-support .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-technical-support .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-technical-support .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-technical-support .banner .banner-content {
    width: 1200px;
  }
  .oos-technical-support .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-technical-support .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-technical-support .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-technical-support .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-technical-support .banner .banner-content {
    width: 1000px;
  }
  .oos-technical-support .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-technical-support .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-technical-support .banner {
    height: auto;
  }
  .oos-technical-support .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-technical-support .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-technical-support .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-technical-support .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-technical-support .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-technical-support .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-technical-support .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-technical-support .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-technical-support .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-technical-support .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-technical-support .content .technical-support-content {
  position: relative;
  margin: 0 auto;
  padding-top: 60px;
  width: 1200px;
  min-height: 700px;
}
.oos-technical-support .content .technical-support-content .technical-support-table-search {
  position: absolute;
  top: -40px;
  padding: 31px 30px;
  width: 100%;
  height: 80px;
  background: linear-gradient(to bottom, rgba(219, 224, 234, 0.96), rgba(232, 235, 241, 0.96), rgba(253, 253, 253, 0.96));
  backdrop-filter: blur(10px);
  border-radius: 5px;
  box-shadow: 1px 3px 5px 0 rgba(42, 57, 87, 0.2);
  z-index: 1;
}
.oos-technical-support .content .technical-support-content .technical-support-table-search .search-input {
  width: calc(100% - 30px);
  font-size: 16px;
  color: #000;
  letter-spacing: 1px;
  background-color: transparent;
  border: none;
  outline: none;
}
.oos-technical-support .content .technical-support-content .technical-support-table-search .search-input::-webkit-input-placeholder,
.oos-technical-support .content .technical-support-content .technical-support-table-search .search-input::-moz-placeholder,
.oos-technical-support .content .technical-support-content .technical-support-table-search .search-input::-ms-input-placeholder {
  color: #696969 !important;
  font-weight: 400 !important;
}
.oos-technical-support .content .technical-support-content .technical-support-table-search .search-input:focus {
  border: none;
}
.oos-technical-support .content .technical-support-content .technical-support-table-search .search-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("../images/technicalSupport/search.svg");
  background-size: 100% 100%;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title {
  padding: 0 30px;
  height: 60px;
  line-height: 60px;
  background-color: #f2f2f2;
  border: 1px solid #d8d7d7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  zoom: 1;
  user-select: none;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .title-text {
  font-size: 14px;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-label {
  float: right;
  height: 58px;
  line-height: 58px;
  font-size: 13px;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box {
  float: right;
  margin-top: 11px;
  display: inline-block;
  vertical-align: middle;
  min-width: 138px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #3c3d3c;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box.product {
  margin-right: 30px;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box:hover > .product-filter-item {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box:hover > .product-filter-item .arrow {
  background-image: url("../images/general/arrow_downward_active.svg");
  transform: rotate(180deg);
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box:hover .product-filter-drop-down-box {
  display: block;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box .product-filter-item {
  padding: 0 15px;
  height: 36px;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box > .product-filter-item {
  position: relative;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box > .product-filter-item .arrow {
  position: absolute;
  top: 8px;
  right: 15px;
  display: block;
  width: 12px;
  height: 20px;
  background-image: url("../images/general/arrow_downward.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box .product-filter-drop-down-box {
  display: none;
  position: relative;
  background-color: #fff;
  z-index: 1;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box .product-filter-drop-down-box .product-filter-item {
  border: 1px solid #c2c2c2;
  border-top: none;
  cursor: pointer;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box .product-filter-drop-down-box .product-filter-item:nth-last-child(1) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box .product-filter-drop-down-box .product-filter-item:hover {
  color: #4c8ffb;
}
.oos-technical-support .content .technical-support-content .technical-support-table:nth-last-child(1) .technical-support-table-row:nth-last-child(1) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header {
  padding: 0 30px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  background-color: #f6f6f6;
  border: 1px solid #d8d7d7;
  border-top: none;
  overflow: hidden;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column {
  float: left;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #5f5f5f;
  letter-spacing: 1px;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column:nth-last-child(1) {
  border-right: none;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.document-name {
  width: calc(100% - 200px - 260px);
  text-align: left;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.document-name.one-button {
  width: calc(100% - 200px - 120px);
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.publish-time {
  width: 200px;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.actions {
  width: 260px;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.actions.one-button {
  width: 120px;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row {
  padding: 0 30px;
  width: 100%;
  height: 80px;
  font-size: 14px;
  color: #696969;
  background-color: #fff;
  border: 1px solid #d8d7d7;
  border-top: none;
  overflow: hidden;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row:hover {
  background-color: #fbfbfb;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column {
  float: left;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name {
  width: calc(100% - 200px - 260px);
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name.one-button {
  width: calc(100% - 200px - 120px);
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name span:not(.disabled):hover {
  color: #2e96fb;
  cursor: pointer;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name .lock-tag {
  margin-right: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: -2px;
  background-image: url("../images/technicalSupport/lock.svg");
  background-size: 100% 100%;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.publish-time {
  width: 200px;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions {
  width: 260px;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions.one-button {
  width: 120px;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button {
  width: 120px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #3e3e3e;
  letter-spacing: 1px;
  user-select: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  background: linear-gradient(to bottom, #fafafa, #f9f9f9, #f8f8f8, #f7f7f7, #f6f6f6, #f5f5f5, #f4f4f4, #f3f3f3);
  cursor: pointer;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-link-icon,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-link-icon.disabled,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon.disabled,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-link-icon.ph,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon.ph,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-download-icon,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-download-icon.disabled,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon.disabled,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-apply-icon,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-apply-icon,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-apply-icon.disabled,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-apply-icon.disabled,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button:hover .oos-button-link-icon:not(.disabled),
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-link-icon:not(.disabled),
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button:hover .oos-button-download-icon:not(.disabled),
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-download-icon:not(.disabled),
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button:hover .oos-button-apply-icon:not(.disabled),
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-apply-icon:not(.disabled),
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-view-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-view-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button:hover,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover {
  background-color: #fff;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button.disabled,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button.disabled,
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button.disabled {
  color: #c3c3c3;
  cursor: not-allowed;
  border: 1px solid #d8d7d7;
}
.oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button {
  margin-right: 20px;
}
.oos-technical-support .content .technical-support-content .table-empty-placeholder {
  display: inline-block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 12px;
  text-align: center;
  color: #696969;
  letter-spacing: 1px;
  border: 1px solid #d8d7d7;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media screen and (max-width: 1200px) {
  .oos-technical-support .content .technical-support-content {
    width: 1000px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.document-name {
    width: calc(100% - 120px - 220px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.document-name.one-button {
    width: calc(100% - 120px - 110px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.publish-time {
    width: 120px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.actions {
    width: 220px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.actions.one-button {
    width: 110px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name {
    width: calc(100% - 120px - 220px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name.one-button {
    width: calc(100% - 120px - 110px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.publish-time {
    width: 120px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions {
    width: 220px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions.one-button {
    width: 110px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button {
    width: 100px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: #3e3e3e;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon {
    display: inline-block;
    transition: transform 0.2s linear;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-link-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon.disabled {
    color: #bfbfbf;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-link-icon.ph,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon.ph,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon.ph {
    padding-left: 5px;
    transition: none !important;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/technicalSupport/download.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-download-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon.disabled {
    background-image: url("../images/technicalSupport/download_disabled.svg");
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-apply-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-apply-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-apply-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/about/recruitment/apply.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-apply-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-apply-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-apply-icon.disabled {
    background-image: url("../images/about/recruitment/apply_disabled.svg");
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button:hover .oos-button-link-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-link-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-link-icon:not(.disabled) {
    transform: translateX(4px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button:hover .oos-button-download-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-download-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-download-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button:hover .oos-button-apply-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-apply-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-apply-icon:not(.disabled) {
    transform: translateY(2px);
  }
}
@media screen and (max-width: 1200px) and screen and (max-width: 450px) {
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button .oos-button-view-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-view-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (max-width: 768px) {
  .oos-technical-support .content {
    padding: 0 20px;
  }
  .oos-technical-support .content .technical-support-content {
    margin: 0 auto;
    padding-top: 50px;
    width: 100%;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-search {
    top: -35px;
    padding: 25px 20px;
    height: 70px;
    background: linear-gradient(to bottom, rgba(219, 224, 234, 0.99), rgba(232, 235, 241, 0.99), rgba(253, 253, 253, 0.99));
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-search .search-input {
    width: calc(100% - 30px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-search .search-icon {
    top: 25px;
    right: 20px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-title {
    padding: 0 20px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header {
    padding: 0 20px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.document-name {
    width: calc(100% - 120px - 100px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.publish-time {
    width: 120px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.actions {
    width: 100px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row {
    padding: 0 20px;
    height: 60px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column {
    height: 60px;
    line-height: 60px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name {
    width: calc(100% - 120px - 100px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.publish-time {
    width: 120px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions {
    width: 100px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-document-button {
    display: none;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button {
    width: 100px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: #3e3e3e;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon {
    display: inline-block;
    transition: transform 0.2s linear;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon.disabled {
    color: #bfbfbf;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon.ph,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon.ph {
    padding-left: 5px;
    transition: none !important;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/technicalSupport/download.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon.disabled {
    background-image: url("../images/technicalSupport/download_disabled.svg");
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-apply-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-apply-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/about/recruitment/apply.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-apply-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-apply-icon.disabled {
    background-image: url("../images/about/recruitment/apply_disabled.svg");
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-link-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-link-icon:not(.disabled) {
    transform: translateX(4px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-download-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-download-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-apply-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-apply-icon:not(.disabled) {
    transform: translateY(2px);
  }
}
@media screen and (max-width: 768px) and screen and (max-width: 450px) {
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-view-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (max-width: 450px) {
  .oos-technical-support .content .technical-support-content {
    margin: 0 auto;
    padding-top: 40px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-search {
    top: -30px;
    padding: 21px 10px;
    height: 60px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-search .search-input {
    width: calc(100% - 25px);
    font-size: 13px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-search .search-icon {
    top: 20px;
    right: 10px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-title {
    padding: 0 10px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-label {
    font-size: 12px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box {
    min-width: 100px;
    font-size: 12px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table-title .product-filter-box.product {
    margin-right: 20px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header {
    padding: 0 10px;
    height: 30px;
    font-size: 12px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column {
    height: 30px;
    line-height: 30px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.document-name {
    width: calc(100% - 30px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.document-name.one-button {
    width: calc(100% - 30px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.publish-time {
    display: none;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.actions {
    width: 30px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-header .header-column.actions.one-button {
    width: 30px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row {
    padding: 0 10px;
    height: 40px;
    font-size: 12px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column {
    height: 40px;
    line-height: 40px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name {
    width: calc(100% - 30px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name.one-button {
    width: calc(100% - 30px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.document-name .lock-tag {
    margin-right: 3px;
    width: 12px;
    height: 12px;
    vertical-align: -1px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.publish-time {
    display: none;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions {
    width: 30px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions.one-button {
    width: 30px;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: #3e3e3e;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    border-radius: 100%;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon {
    display: inline-block;
    transition: transform 0.2s linear;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon.disabled {
    color: #bfbfbf;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon.ph,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon.ph {
    padding-left: 5px;
    transition: none !important;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/technicalSupport/download.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon.disabled {
    background-image: url("../images/technicalSupport/download_disabled.svg");
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-apply-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-apply-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/about/recruitment/apply.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-apply-icon.disabled,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-apply-icon.disabled {
    background-image: url("../images/about/recruitment/apply_disabled.svg");
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-link-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-link-icon:not(.disabled) {
    transform: translateX(4px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-download-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-download-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button:hover .oos-button-apply-icon:not(.disabled),
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button:hover .oos-button-apply-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .button-text,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .button-text {
    display: none;
  }
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-apply-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-apply-icon {
    margin-left: 0;
  }
}
@media screen and (max-width: 450px) and screen and (max-width: 450px) {
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-link-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-download-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .download-button .oos-button-view-icon,
  .oos-technical-support .content .technical-support-content .technical-support-table .technical-support-table-row .row-column.actions .view-image-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-guarantee .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-guarantee .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-guarantee .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-guarantee .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-guarantee .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-guarantee .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-guarantee .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-guarantee .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-guarantee .banner .banner-content {
    width: 1400px;
  }
  .oos-guarantee .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-guarantee .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-guarantee .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-guarantee .banner .banner-content {
    width: 1200px;
  }
  .oos-guarantee .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-guarantee .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-guarantee .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-guarantee .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-guarantee .banner .banner-content {
    width: 1000px;
  }
  .oos-guarantee .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-guarantee .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-guarantee .banner {
    height: auto;
  }
  .oos-guarantee .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-guarantee .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-guarantee .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-guarantee .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-guarantee .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-guarantee .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-guarantee .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-guarantee .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-guarantee .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-guarantee .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-guarantee .content .guarantee-content {
  margin: 0 auto;
  width: 1200px;
}
.oos-guarantee .content .guarantee-content .search-box-label {
  margin-top: 60px;
  font-size: 18px;
}
.oos-guarantee .content .guarantee-content .search-box {
  position: relative;
  margin-top: 8px;
  padding: 0 30px;
  height: 70px;
  line-height: 70px;
  border: 1px solid #97a6b8;
  border-radius: 10px;
}
.oos-guarantee .content .guarantee-content .search-box input {
  width: 95%;
  font-size: 16px;
  outline: none;
}
.oos-guarantee .content .guarantee-content .search-box img {
  float: right;
  margin-top: 24px;
  cursor: pointer;
}
.oos-guarantee .content .guarantee-content .search-result-box {
  margin-bottom: 20px;
  padding: 40px 30px;
  color: #3e3e3e;
  background: linear-gradient(to bottom, #f0f6ff, #fff);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0 rgba(116, 130, 166, 0.2);
}
.oos-guarantee .content .guarantee-content .search-result-box .state {
  font-size: 22px;
}
.oos-guarantee .content .guarantee-content .search-result-box .state .state-text.invalid {
  color: #ff7f00;
}
.oos-guarantee .content .guarantee-content .search-result-box .state .state-text.valid {
  color: #0dab84;
}
.oos-guarantee .content .guarantee-content .search-result-box .expiration {
  margin-top: 12px;
}
.oos-guarantee .content .guarantee-content .search-result-box .customer {
  margin-top: 8px;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header {
  padding: 0 30px;
  font-size: 14px;
  color: #5f5f5f;
  background-color: #f6f6f6;
  border: 1px solid #d8d7d7;
  border-top: none;
  overflow: hidden;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column {
  float: left;
  height: 40px;
  line-height: 40px;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column.state {
  width: 300px;
  text-align: left;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column.explain {
  width: 698px;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row {
  padding: 0 30px;
  font-size: 14px;
  border: 1px solid #d8d7d7;
  border-top: none;
  overflow: hidden;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row:nth-last-child(1) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column {
  float: left;
  height: 70px;
  line-height: 70px;
  color: #696969;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.state {
  width: 300px;
  text-align: left;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.state.invalid {
  color: #ff7f00;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.state.valid {
  color: #0dab84;
}
.oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.explain {
  width: 698px;
}
@media screen and (max-width: 1200px) {
  .oos-guarantee .content .guarantee-content {
    width: 1000px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header {
    font-size: 13px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column.state {
    width: 180px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column.explain {
    width: calc(100% - 180px);
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row {
    font-size: 13px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column {
    height: 60px;
    line-height: 60px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.state {
    width: 180px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.explain {
    width: calc(100% - 180px);
  }
}
@media screen and (max-width: 768px) {
  .oos-guarantee .content {
    padding: 0 20px;
  }
  .oos-guarantee .content .guarantee-content {
    width: 100%;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header {
    font-size: 13px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column {
    height: 30px;
    line-height: 30px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column.state {
    width: 90px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column.explain {
    width: calc(100% - 90px);
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row {
    font-size: 13px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column {
    height: 60px;
    line-height: 60px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.state {
    width: 90px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.explain {
    width: calc(100% - 90px);
  }
}
@media screen and (max-width: 450px) {
  .oos-guarantee .content .guarantee-content .search-result-box {
    padding: 30px 10px;
  }
  .oos-guarantee .content .guarantee-content .search-result-box .state {
    font-size: 16px;
  }
  .oos-guarantee .content .guarantee-content .search-result-box .expiration,
  .oos-guarantee .content .guarantee-content .search-result-box .customer {
    font-size: 13px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header {
    padding: 0 10px;
    font-size: 12px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column {
    height: 30px;
    line-height: 30px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column.state {
    width: 50px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-header .header-column.explain {
    width: calc(100% - 50px);
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row {
    padding: 0 10px;
    font-size: 12px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row.more-text-ph .row-column {
    height: 90px;
    line-height: 90px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row.more-text-ph .row-column .text {
    display: inline-block;
    vertical-align: middle;
    line-height: 22px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column {
    min-height: 60px;
    line-height: 60px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.state {
    width: 50px;
  }
  .oos-guarantee .content .guarantee-content .guarantee-state-explain-table .guarantee-state-explain-table-row .row-column.explain {
    width: calc(100% - 50px);
  }
}
.oos-document-detail .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-document-detail .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-document-detail .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-document-detail .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-document-detail .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-document-detail .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-document-detail .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-document-detail .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-document-detail .banner .banner-content {
    width: 1400px;
  }
  .oos-document-detail .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-document-detail .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-document-detail .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-document-detail .banner .banner-content {
    width: 1200px;
  }
  .oos-document-detail .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-document-detail .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-document-detail .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-document-detail .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-document-detail .banner .banner-content {
    width: 1000px;
  }
  .oos-document-detail .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-document-detail .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-document-detail .banner {
    height: auto;
  }
  .oos-document-detail .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-document-detail .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-document-detail .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-document-detail .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-document-detail .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-document-detail .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-document-detail .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-document-detail .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-document-detail .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-document-detail .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-document-detail .content .document-title {
  padding-top: 75px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 22px;
  letter-spacing: 1px;
}
.oos-document-detail .content .button-bar {
  width: 1200px;
  margin: 0 auto 30px;
  height: 30px;
  text-align: right;
}
.oos-document-detail .content .download-document-button {
  display: inline-block;
  width: 110px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #333333;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  border: 1px solid #333333;
}
.oos-document-detail .content .download-document-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-document-detail .content .download-document-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-document-detail .content .download-document-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-document-detail .content .download-document-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-document-detail .content .download-document-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-document-detail .content .download-document-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-document-detail .content .download-document-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-document-detail .content .download-document-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-document-detail .content .download-document-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-document-detail .content .download-document-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-document-detail .content .download-document-button .oos-button-link-icon,
  .oos-document-detail .content .download-document-button .oos-button-download-icon,
  .oos-document-detail .content .download-document-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-document-detail .content .document-content {
  width: 1200px;
  margin: 50px auto 0;
  height: 900px;
}
.oos-document-detail .content .document-content.pdf .document-pdf-iframe {
  height: 100%;
  border: 1px solid #ccc;
}
.oos-document-detail .content .document-content.excel {
  height: auto;
  min-height: 600px;
}
.oos-document-detail .content .document-content.excel .document-excel-frame {
  height: 100%;
}
.oos-document-detail .content .document-content.excel .document-excel-frame table {
  display: block;
  font-size: 14px;
  border-collapse: collapse;
}
.oos-document-detail .content .document-content.excel .document-excel-frame table tbody th,
.oos-document-detail .content .document-content.excel .document-excel-frame table tbody td {
  padding: 10px;
  text-align: left;
  font-weight: normal;
  border: 1px solid #ccc;
}
.oos-document-detail .content .document-content.excel .document-excel-frame table tbody th span,
.oos-document-detail .content .document-content.excel .document-excel-frame table tbody td span {
  font-size: 14px !important;
}
.oos-document-detail .content .document-content.excel .document-excel-frame .excel-cover-sheet table tbody tr td:first-child {
  background-color: #f5f7f9;
}
.oos-document-detail .content .document-content.excel .document-excel-frame .excel-content-sheet {
  margin-top: 50px;
  margin-bottom: 30px;
}
.oos-document-detail .content .document-content.excel .document-excel-frame .excel-content-sheet table thead tr th {
  background-color: #f5f7f9;
}
.oos-document-detail .content .document-content.excel .document-excel-frame .excel-content-sheet table tbody tr:nth-child(1) {
  background-color: #f5f7f9;
}
.oos-document-detail .content .document-content.excel .document-excel-frame .excel-content-sheet table tbody tr td {
  position: relative;
}
.oos-document-detail .content .document-content.excel .document-excel-frame .excel-content-sheet table tbody tr td.active::before {
  content: " ";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border: 1px solid #06bc7b;
}
.oos-document-detail .content .section-title {
  padding: 60px 0 30px;
  text-align: center;
  color: #212d40;
}
.oos-document-detail .content .section-title.gray-bg {
  background-color: #f7f8f9;
}
.oos-document-detail .content .section-title .title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-document-detail .content .section-title .sub-title {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .oos-document-detail .content .section-title {
    padding: 22px 20px 18px;
  }
  .oos-document-detail .content .section-title .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-document-detail .content .section-title .title {
    font-size: 18px;
  }
}
.oos-document-detail .content .feedback-content {
  margin: 20px auto 50px;
  width: 1200px;
}
.oos-document-detail .content .feedback-content .feedback-title {
  margin-bottom: 50px;
  padding-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid #5c8ae1;
}
.oos-document-detail .content .feedback-content .feedback-title::before {
  content: " ";
  float: left;
  margin-top: 5px;
  margin-right: 10px;
  display: block;
  width: 6px;
  height: 20px;
  background-color: #608ff9;
}
.oos-document-detail .content .feedback-content .feedback-title .feedback-sub-title {
  padding-left: 20px;
  font-size: 13px;
  color: #888;
}
.oos-document-detail .content .feedback-content .content-input,
.oos-document-detail .content .feedback-content input {
  font-size: 16px;
  color: #3e3e3e;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d1d5db;
  outline: none;
  transition: border 0.5s linear;
}
.oos-document-detail .content .feedback-content .content-input::-webkit-input-placeholder,
.oos-document-detail .content .feedback-content input::-webkit-input-placeholder,
.oos-document-detail .content .feedback-content .content-input::-moz-placeholder,
.oos-document-detail .content .feedback-content input::-moz-placeholder,
.oos-document-detail .content .feedback-content .content-input::-ms-input-placeholder,
.oos-document-detail .content .feedback-content input::-ms-input-placeholder {
  color: #696969 !important;
  font-weight: 400 !important;
}
.oos-document-detail .content .feedback-content .content-input:focus,
.oos-document-detail .content .feedback-content input:focus {
  border-bottom: 1px solid #3e3e3e;
}
.oos-document-detail .content .feedback-content .content-input {
  margin-bottom: 30px;
  display: block;
  width: 100%;
  height: 110px;
  resize: none;
}
.oos-document-detail .content .feedback-content .content-input::-webkit-input-placeholder,
.oos-document-detail .content .feedback-content .content-input::-moz-placeholder,
.oos-document-detail .content .feedback-content .content-input::-ms-input-placeholder {
  color: #696969 !important;
  font-weight: 400 !important;
}
.oos-document-detail .content .feedback-content .contact-tips {
  margin-bottom: 30px;
  font-size: 13px;
  color: #888;
}
.oos-document-detail .content .feedback-content .contact-box {
  margin-bottom: 30px;
}
.oos-document-detail .content .feedback-content .contact-box input {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
}
.oos-document-detail .content .feedback-content .contact-box input:nth-child(1) {
  width: 18%;
}
.oos-document-detail .content .feedback-content .contact-box input:nth-child(2) {
  margin-left: 2%;
  width: 18%;
}
.oos-document-detail .content .feedback-content .contact-box input:nth-child(3) {
  margin-left: 2%;
  width: 18%;
}
.oos-document-detail .content .feedback-content .contact-box input:nth-child(4) {
  margin-left: 2%;
  width: 40%;
}
.oos-document-detail .content .feedback-content .is-problem-solved-box {
  margin-bottom: 30px;
  color: #3e3e3e;
}
.oos-document-detail .content .feedback-content .is-problem-solved-box .is-problem-solved-title {
  padding-right: 10px;
}
.oos-document-detail .content .feedback-content .is-problem-solved-box input[type="radio"] {
  margin-right: 5px;
  vertical-align: -1px;
}
.oos-document-detail .content .feedback-content .is-problem-solved-box label {
  padding-right: 10px;
}
.oos-document-detail .content .feedback-content .confirm-privacy-statement-box {
  margin-bottom: 40px;
  height: 18px;
  line-height: 18px;
  font-size: 13px;
}
.oos-document-detail .content .feedback-content .confirm-privacy-statement-box input[type="checkbox"] {
  vertical-align: -1px;
}
.oos-document-detail .content .feedback-content .confirm-privacy-statement-box label {
  user-select: none;
}
.oos-document-detail .content .feedback-content .confirm-privacy-statement-box label .read-statement {
  padding-left: 8px;
  color: #888;
}
.oos-document-detail .content .feedback-content .confirm-privacy-statement-box label .privacy-statement-name {
  color: #608ff9;
  text-decoration: none;
  cursor: pointer;
}
.oos-document-detail .content .feedback-content .confirm-privacy-statement-box label .store-data {
  color: #888;
}
.oos-document-detail .content .feedback-content .submit-button {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  background-color: #2a3957;
}
.oos-document-detail .content .feedback-content .submit-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-document-detail .content .feedback-content .submit-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-document-detail .content .feedback-content .submit-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-document-detail .content .feedback-content .submit-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-document-detail .content .feedback-content .submit-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-document-detail .content .feedback-content .submit-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-document-detail .content .feedback-content .submit-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-document-detail .content .feedback-content .submit-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-document-detail .content .feedback-content .submit-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-document-detail .content .feedback-content .submit-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-document-detail .content .feedback-content .submit-button .oos-button-link-icon,
  .oos-document-detail .content .feedback-content .submit-button .oos-button-download-icon,
  .oos-document-detail .content .feedback-content .submit-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-document-detail .content .feedback-content .submit-button.disabled {
  background-color: #888;
  cursor: not-allowed;
}
@media screen and (max-width: 1200px) {
  .oos-document-detail .content .button-bar {
    width: 1000px;
  }
  .oos-document-detail .content .document-content {
    width: 1000px;
  }
  .oos-document-detail .content .feedback-content {
    width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .oos-document-detail .content {
    padding: 0 20px;
  }
  .oos-document-detail .content .document-title {
    padding-top: 50px;
    padding-bottom: 15px;
    font-size: 20px;
  }
  .oos-document-detail .content .button-bar {
    width: 100%;
  }
  .oos-document-detail .content .document-content {
    margin-top: 30px;
    width: 100%;
  }
  .oos-document-detail .content .document-content.excel .document-excel-frame table {
    font-size: 10px;
    overflow: auto;
  }
  .oos-document-detail .content .document-content.excel .document-excel-frame table tbody {
    display: table-row-group;
    vertical-align: middle;
  }
  .oos-document-detail .content .document-content.excel .document-excel-frame table tbody th,
  .oos-document-detail .content .document-content.excel .document-excel-frame table tbody td {
    padding: 5px;
  }
  .oos-document-detail .content .document-content.excel .document-excel-frame table tbody th span,
  .oos-document-detail .content .document-content.excel .document-excel-frame table tbody td span {
    font-size: 10px !important;
  }
  .oos-document-detail .content .document-content.excel .document-excel-frame .excel-content-sheet {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .oos-document-detail .content .feedback-content {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .oos-document-detail .content .document-title {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .oos-document-detail .content .button-bar {
    text-align: center;
    margin-bottom: 20px;
  }
  .oos-document-detail .content .document-content {
    margin-top: 20px;
  }
  .oos-document-detail .content .feedback-content {
    margin-top: 10px;
  }
  .oos-document-detail .content .feedback-content .content-input,
  .oos-document-detail .content .feedback-content input {
    font-size: 14px;
  }
  .oos-document-detail .content .feedback-content .contact-tips {
    margin-bottom: 10px;
  }
  .oos-document-detail .content .feedback-content .contact-box input:nth-child(1),
  .oos-document-detail .content .feedback-content .contact-box input:nth-child(2),
  .oos-document-detail .content .feedback-content .contact-box input:nth-child(3),
  .oos-document-detail .content .feedback-content .contact-box input:nth-child(4) {
    margin-left: 0;
    width: 100%;
  }
  .oos-document-detail .content .feedback-content .is-problem-solved-box {
    margin-bottom: 20px;
  }
  .oos-document-detail .content .feedback-content .is-problem-solved-box .is-problem-solved-title,
  .oos-document-detail .content .feedback-content .is-problem-solved-box label {
    font-size: 14px;
  }
  .oos-document-detail .content .feedback-content .is-problem-solved-box .is-problem-solved-title {
    display: block;
    margin-bottom: 10px;
  }
  .oos-document-detail .content .feedback-content .is-problem-solved-box label {
    padding-right: 20px;
  }
  .oos-document-detail .content .feedback-content .confirm-privacy-statement-box {
    font-size: 12px;
  }
  .oos-document-detail .content .feedback-content .submit-button {
    margin: 0 auto;
  }
}
.oos-image-gallery-detail .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-image-gallery-detail .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-image-gallery-detail .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-image-gallery-detail .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-image-gallery-detail .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-image-gallery-detail .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-image-gallery-detail .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-image-gallery-detail .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-image-gallery-detail .banner .banner-content {
    width: 1400px;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-image-gallery-detail .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-image-gallery-detail .banner .banner-content {
    width: 1200px;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-image-gallery-detail .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-image-gallery-detail .banner .banner-content {
    width: 1000px;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-image-gallery-detail .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-image-gallery-detail .banner {
    height: auto;
  }
  .oos-image-gallery-detail .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-image-gallery-detail .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-image-gallery-detail .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-image-gallery-detail .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-image-gallery-detail .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-image-gallery-detail .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-image-gallery-detail .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-image-gallery-detail .content .image-gallery-detail-content {
  margin: 0 auto;
  width: 1200px;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .image-gallery-title {
  padding-top: 75px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 22px;
  letter-spacing: 1px;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .button-bar {
  margin-bottom: 30px;
  height: 30px;
  text-align: right;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button {
  display: inline-block;
  width: 110px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #333333;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  border: 1px solid #333333;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-link-icon,
  .oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-download-icon,
  .oos-image-gallery-detail .content .image-gallery-detail-content .download-all-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button {
  display: inline-block;
  padding: 10px;
  min-height: 30px;
  line-height: 30px;
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  color: 333333;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
  letter-spacing: 2px;
  padding: 0;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-link-icon,
  .oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-download-icon,
  .oos-image-gallery-detail .content .image-gallery-detail-content .download-one-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail {
  position: relative;
  margin-bottom: 20px;
  background-color: #f5f7fa;
  border: 1px solid #f5f7fa;
  border-radius: 5px;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail.active {
  border: 1px solid #5c8ae1;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail:hover {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail:hover .download-one-button,
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail:hover .thumbnail-tip {
  display: block;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .download-one-button {
  display: none;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .thumbnail-tip {
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 0;
  font-size: 14px;
  color: #545c6c;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .gallery-thumbnail-wrap {
  padding: 15px 20px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: 380px;
  height: 180px;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .gallery-thumbnail-wrap .thumbnail {
  margin: 0 auto;
  max-width: 340px;
  max-height: 150px;
  cursor: pointer;
  user-select: none;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box {
  margin-bottom: 120px;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .gallery-large-image-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button {
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #333333;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  border: 1px solid #333333;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-link-icon,
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-download-icon,
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .button-bar .download-one-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box .gallery-large-image {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .oos-image-gallery-detail .content .image-gallery-detail-content {
    width: 1000px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .thumbnail-tip {
    font-size: 12px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .gallery-thumbnail-wrap {
    padding: 10px;
    width: 320px;
    height: 150px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .gallery-thumbnail-wrap .thumbnail {
    max-width: 300px;
    max-height: 130px;
  }
}
@media screen and (max-width: 768px) {
  .oos-image-gallery-detail .content {
    padding: 0 20px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content {
    width: 100%;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .image-gallery-title {
    padding-top: 50px;
    padding-bottom: 15px;
    font-size: 20px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .gallery-thumbnail-wrap {
    width: 340px;
    height: 160px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .gallery-thumbnail-wrap .thumbnail {
    max-width: 320px;
    max-height: 140px;
  }
}
@media screen and (max-width: 450px) {
  .oos-image-gallery-detail .content .image-gallery-detail-content .image-gallery-title {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .button-bar {
    text-align: center;
    margin-bottom: 20px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box {
    flex-direction: column;
    align-items: center;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail {
    width: 320px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail:hover .thumbnail-tip {
    display: none;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .gallery-thumbnail-wrap {
    width: 320px;
    height: 150px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-box .gallery-thumbnail .gallery-thumbnail-wrap .thumbnail {
    max-width: 300px;
    max-height: 130px;
  }
  .oos-image-gallery-detail .content .image-gallery-detail-content .gallery-large-image-box {
    display: none;
  }
}
.oos-news > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-news > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-news > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-news .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-news .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-news .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-news .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-news .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-news .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-news .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-news .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-news .banner .banner-content {
    width: 1400px;
  }
  .oos-news .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-news .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-news .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-news .banner .banner-content {
    width: 1200px;
  }
  .oos-news .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-news .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-news .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-news .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-news .banner .banner-content {
    width: 1000px;
  }
  .oos-news .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-news .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-news .banner {
    height: auto;
  }
  .oos-news .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-news .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-news .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-news .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-news .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-news .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-news .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-news .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-news .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-news .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-news .content {
  margin: 0 auto;
  width: 1200px;
}
.oos-news .content .news-box {
  margin-top: 60px;
  padding-bottom: 25px;
  overflow: hidden;
}
.oos-news .content .news-box .news-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 30px;
  height: 250px;
  transition: transform 0.2s linear;
  cursor: pointer;
}
.oos-news .content .news-box .news-item::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 30px;
  right: 30px;
  width: calc(100% - 30px - 30px);
  height: 1px;
  background-color: #d8d7d7;
}
.oos-news .content .news-box .news-item:nth-child(1)::before {
  content: "";
  display: none;
}
.oos-news .content .news-box .news-item:hover {
  transform: translateY(-1px);
  box-shadow: 0 10px 20px 0 hsla(0, 0%, 71%, 0.5);
}
.oos-news .content .news-box .news-item:hover::before {
  content: "";
  display: none;
}
.oos-news .content .news-box .news-item:hover + .news-item::before {
  content: "";
  display: none;
}
.oos-news .content .news-box .news-item .cover {
  width: 370px;
  height: 170px;
  background-color: #f5f5f5;
  background-size: cover;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}
.oos-news .content .news-box .news-item .time {
  width: 220px;
  height: 170px;
  text-align: center;
}
.oos-news .content .news-box .news-item .time .date {
  margin-top: 60px;
  font-size: 22px;
  color: #2b3744;
  letter-spacing: 2px;
}
.oos-news .content .news-box .news-item .time .year {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2px;
}
.oos-news .content .news-box .news-item .text {
  width: 550px;
  height: 170px;
}
.oos-news .content .news-box .news-item .text .title {
  padding-top: 10px;
  font-size: 18px;
  color: #2b3744;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.oos-news .content .news-box .news-item .text .summary {
  margin-top: 25px;
  line-height: 30px;
  font-size: 14px;
  color: #959eae;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  -ms-line-clamp: 2;
  -ms-box-orient: vertical;
}
.oos-news .content .news-box .news-item .text .view-detail-button {
  padding: 10px;
  min-height: 12px;
  line-height: 12px;
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  color: #adb4c0;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
  float: right;
  display: inline-block;
  margin-top: 20px;
  margin-left: -10px;
}
.oos-news .content .news-box .news-item .text .view-detail-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-news .content .news-box .news-item .text .view-detail-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-news .content .news-box .news-item .text .view-detail-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-news .content .news-box .news-item .text .view-detail-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-news .content .news-box .news-item .text .view-detail-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-news .content .news-box .news-item .text .view-detail-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-news .content .news-box .news-item .text .view-detail-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-news .content .news-box .news-item .text .view-detail-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-news .content .news-box .news-item .text .view-detail-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-news .content .news-box .news-item .text .view-detail-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-news .content .news-box .news-item .text .view-detail-button .oos-button-link-icon,
  .oos-news .content .news-box .news-item .text .view-detail-button .oos-button-download-icon,
  .oos-news .content .news-box .news-item .text .view-detail-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-news .content .news-box .skeleton-item {
  position: relative;
  padding: 40px 30px;
  display: flex;
  flex-direction: row;
  height: 250px;
  cursor: pointer;
}
.oos-news .content .news-box .skeleton-item::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 30px;
  right: 30px;
  width: calc(100% - 30px - 30px);
  height: 1px;
  background-color: #f2f2f2;
}
.oos-news .content .news-box .skeleton-item:nth-child(1)::before {
  content: "";
  display: none;
}
.oos-news .content .news-box .skeleton-item .cover {
  width: 370px;
  height: 170px;
  background-color: #f5f5f5;
}
.oos-news .content .news-box .skeleton-item .time {
  width: 220px;
  height: 170px;
  text-align: center;
}
.oos-news .content .news-box .skeleton-item .time .date {
  margin: 60px auto 0;
  width: 60px;
  height: 27px;
  background-color: #f5f5f5;
}
.oos-news .content .news-box .skeleton-item .time .year {
  margin: 10px auto 0;
  width: 60px;
  height: 14px;
  background-color: #f5f5f5;
}
.oos-news .content .news-box .skeleton-item .text {
  width: 550px;
  height: 170px;
}
.oos-news .content .news-box .skeleton-item .text .title {
  height: 35px;
  background-color: #f5f5f5;
}
.oos-news .content .news-box .skeleton-item .text .summary {
  margin-top: 30px;
  height: 70px;
  background-color: #f5f5f5;
}
.oos-news .content .news-box .skeleton-item .text .view-detail-button {
  margin-top: 10px;
  height: 20px;
  background-color: #f5f5f5;
}
.oos-news .content .news-pagination {
  margin-top: 50px;
  height: 30px;
  text-align: center;
}
.oos-news .content .news-pagination .news-pagination-item {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  color: #2b3744;
  vertical-align: middle;
  cursor: pointer;
}
.oos-news .content .news-pagination .news-pagination-item:hover {
  color: #1855d3;
}
.oos-news .content .news-pagination .news-pagination-item.active {
  color: #fff;
  background-color: #1855d3;
  border-radius: 5px;
}
@media screen and (max-width: 1200px) {
  .oos-news .content {
    width: 1000px;
  }
  .oos-news .content .news-box .news-item .cover {
    width: 320px;
  }
  .oos-news .content .news-box .news-item .time {
    width: 150px;
  }
  .oos-news .content .news-box .news-item .time .date {
    font-size: 20px;
  }
  .oos-news .content .news-box .news-item .text {
    width: 470px;
  }
  .oos-news .content .news-box .news-item .text .title {
    font-size: 16px;
  }
  .oos-news .content .news-box .news-item .text .summary {
    font-size: 13px;
  }
  .oos-news .content .news-box .skeleton-item .cover {
    width: 320px;
  }
  .oos-news .content .news-box .skeleton-item .time {
    width: 150px;
  }
  .oos-news .content .news-box .skeleton-item .text {
    width: 470px;
  }
}
@media screen and (max-width: 768px) {
  .oos-news .content {
    width: 100%;
  }
  .oos-news .content .news-box {
    padding: 0 20px;
  }
  .oos-news .content .news-box .news-item {
    padding: 30px 0;
    border-bottom: 1px solid #d8d7d7;
  }
  .oos-news .content .news-box .news-item::before {
    content: "";
    display: none;
  }
  .oos-news .content .news-box .news-item:hover {
    box-shadow: none;
    transform: none;
  }
  .oos-news .content .news-box .news-item:hover::before {
    content: "";
    display: none;
  }
  .oos-news .content .news-box .news-item .cover {
    min-width: 248px;
  }
  .oos-news .content .news-box .news-item .time {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
  }
  .oos-news .content .news-box .news-item .text {
    width: 380px;
    max-width: 380px;
    min-width: 380px;
  }
  .oos-news .content .news-box .skeleton-item {
    padding: 30px 0;
  }
  .oos-news .content .news-box .skeleton-item .cover {
    min-width: 248px;
  }
  .oos-news .content .news-box .skeleton-item .time {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
  }
  .oos-news .content .news-box .skeleton-item .text {
    width: 380px;
    max-width: 380px;
    min-width: 380px;
  }
  .oos-news .content .news-pagination {
    margin-top: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-news .content .news-box {
    margin-top: 20px;
    padding: 0 20px;
  }
  .oos-news .content .news-box .news-item {
    padding: 20px 0;
    flex-direction: column;
    height: auto;
  }
  .oos-news .content .news-box .news-item .cover {
    min-width: 100%;
    width: 100%;
    height: 200px;
  }
  .oos-news .content .news-box .news-item .time {
    min-width: 100%;
    width: 100%;
    height: auto;
    text-align: left;
  }
  .oos-news .content .news-box .news-item .time .date,
  .oos-news .content .news-box .news-item .time .year {
    margin-top: 10px;
    display: inline-block;
  }
  .oos-news .content .news-box .news-item .time .date {
    font-size: 16px;
  }
  .oos-news .content .news-box .news-item .time .year {
    padding-left: 10px;
    vertical-align: 1px;
    font-weight: 400;
  }
  .oos-news .content .news-box .news-item .text {
    margin-top: 10px;
    min-width: 100%;
    width: 100%;
    height: auto;
  }
  .oos-news .content .news-box .news-item .text .title {
    font-size: 14px;
  }
  .oos-news .content .news-box .news-item .text .summary {
    margin-top: 10px;
    line-height: 24px;
  }
  .oos-news .content .news-box .news-item .text .view-detail-button {
    margin-top: 10px;
  }
  .oos-news .content .news-box .skeleton-item {
    padding: 20px 0;
    flex-direction: column;
    height: auto;
  }
  .oos-news .content .news-box .skeleton-item .cover {
    min-width: 100%;
    width: 100%;
    height: 200px;
  }
  .oos-news .content .news-box .skeleton-item .time {
    min-width: 100%;
    width: 100%;
    height: auto;
  }
  .oos-news .content .news-box .skeleton-item .time .date,
  .oos-news .content .news-box .skeleton-item .time .year {
    margin-top: 10px;
    display: inline-block;
    height: 15px;
  }
  .oos-news .content .news-box .skeleton-item .time .year {
    margin-left: 10px;
  }
  .oos-news .content .news-box .skeleton-item .text {
    margin-top: 10px;
    min-width: 100%;
    width: 100%;
    height: auto;
  }
  .oos-news .content .news-box .skeleton-item .text .summary {
    margin-top: 10px;
  }
  .oos-news .content .news-pagination {
    margin-top: 30px;
  }
}
.oos-news-detail > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-news-detail > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-news-detail > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-news-detail .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-news-detail .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-news-detail .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-news-detail .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-news-detail .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-news-detail .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-news-detail .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-news-detail .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-news-detail .banner .banner-content {
    width: 1400px;
  }
  .oos-news-detail .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-news-detail .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-news-detail .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-news-detail .banner .banner-content {
    width: 1200px;
  }
  .oos-news-detail .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-news-detail .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-news-detail .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-news-detail .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-news-detail .banner .banner-content {
    width: 1000px;
  }
  .oos-news-detail .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-news-detail .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-news-detail .banner {
    height: auto;
  }
  .oos-news-detail .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-news-detail .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-news-detail .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-news-detail .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-news-detail .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-news-detail .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-news-detail .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-news-detail .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-news-detail .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-news-detail .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-news-detail .content {
  margin: 0 auto;
  padding-top: 75px;
  width: 1200px;
  min-height: 1100px;
  display: block;
  zoom: 1;
}
.oos-news-detail .content::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.oos-news-detail .content .news-item {
  float: left;
  margin-bottom: 65px;
  padding-right: 120px;
  width: 900px;
}
.oos-news-detail .content .news-item .title {
  font-size: 26px;
}
.oos-news-detail .content .news-item .time {
  margin-top: 10px;
  font-size: 12px;
  color: #959eae;
  letter-spacing: 1px;
}
.oos-news-detail .content .news-item .time .view-count {
  margin-left: 30px;
}
.oos-news-detail .content .news-item .news-content {
  margin-top: 35px;
  white-space: pre-wrap;
}
.oos-news-detail .content .news-item .news-content > p {
  display: block !important;
  min-height: 22px;
}
.oos-news-detail .content .news-item .news-content img {
  max-width: 100% !important;
  border-radius: 5px !important;
}
.oos-news-detail .content .skeleton-news-item {
  float: left;
  margin-bottom: 65px;
  padding-right: 120px;
  width: 900px;
}
.oos-news-detail .content .skeleton-news-item .title {
  height: 40px;
  background-color: #f5f5f5;
}
.oos-news-detail .content .skeleton-news-item .time {
  margin-top: 15px;
  width: 290px;
  height: 12px;
  background-color: #f5f5f5;
}
.oos-news-detail .content .skeleton-news-item .news-content {
  margin-top: 35px;
  height: 900px;
  background-color: #f5f5f5;
}
.oos-news-detail .content .right-side-bar {
  float: right;
  padding-top: 5px;
  width: 300px;
}
.oos-news-detail .content .right-side-bar .hot-product-img {
  height: 150px;
  background-image: url("../images/product/hot_product.webp");
  background-size: 100% 100%;
  border-radius: 5px;
  cursor: pointer;
}
.oos-news-detail .content .right-side-bar .last-new {
  margin-top: 55px;
}
.oos-news-detail .content .right-side-bar .last-new.fixed {
  position: fixed;
  top: 20px;
  right: calc((100vw - 1200px) / 2);
  margin-top: 0;
  width: 300px;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-title {
  position: relative;
  padding-bottom: 15px;
  font-size: 18px;
  color: #2b3744;
  border-bottom: 1px solid #f2f2f2;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-title::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 50%;
  height: 1px;
  background-color: #1855d3;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-box {
  margin-top: 25px;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-box .last-news-item {
  position: relative;
  padding: 15px 0;
  height: 100px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-box .last-news-item::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #1855d3;
  transition: width 0.2s;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-box .last-news-item:hover {
  transform: scale(1.02, 1.02);
}
.oos-news-detail .content .right-side-bar .last-new .last-news-box .last-news-item:hover::before {
  width: 100%;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-box .last-news-item .cover {
  float: left;
  width: 100px;
  height: 70px;
  border-radius: 5px;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-box .last-news-item .text-content {
  float: right;
  width: 185px;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-box .last-news-item .text-content .title {
  font-size: 16px;
  color: #2b3744;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  -ms-line-clamp: 2;
  -ms-box-orient: vertical;
}
.oos-news-detail .content .right-side-bar .last-new .last-news-box .last-news-item .text-content .time {
  margin-top: 10px;
  font-size: 12px;
  color: #959eae;
  letter-spacing: 1px;
}
.oos-news-detail .content .skeleton-right-side-bar {
  float: right;
  padding-top: 5px;
  width: 300px;
}
.oos-news-detail .content .skeleton-right-side-bar .hot-product-img {
  height: 150px;
  background-color: #f5f5f5;
}
.oos-news-detail .content .skeleton-right-side-bar .last-new {
  margin-top: 55px;
}
.oos-news-detail .content .skeleton-right-side-bar .last-new.fixed {
  position: fixed;
  top: 20px;
  right: calc((100vw - 1200px) / 2);
  margin-top: 0;
  width: 300px;
}
.oos-news-detail .content .skeleton-right-side-bar .last-new .last-news-title {
  position: relative;
  padding-bottom: 15px;
  height: 27px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
.oos-news-detail .content .skeleton-right-side-bar .last-new .last-news-box {
  margin-top: 25px;
}
.oos-news-detail .content .skeleton-right-side-bar .last-new .last-news-box .last-news-item {
  position: relative;
  padding: 15px 0;
  height: 100px;
  overflow: hidden;
}
.oos-news-detail .content .skeleton-right-side-bar .last-new .last-news-box .last-news-item .cover {
  float: left;
  width: 100px;
  height: 70px;
  background-color: #f5f5f5;
}
.oos-news-detail .content .skeleton-right-side-bar .last-new .last-news-box .last-news-item .text-content {
  float: right;
  width: 185px;
}
.oos-news-detail .content .skeleton-right-side-bar .last-new .last-news-box .last-news-item .text-content .title {
  height: 45px;
  background-color: #f5f5f5;
}
.oos-news-detail .content .skeleton-right-side-bar .last-new .last-news-box .last-news-item .text-content .time {
  margin-top: 10px;
  height: 16px;
  background-color: #f5f5f5;
}
@media screen and (max-width: 768px) {
  .oos-news-detail .content {
    padding: 0 20px;
    width: 100%;
    min-height: 800px;
  }
  .oos-news-detail .content .news-item {
    padding-right: 0;
    width: 100%;
  }
  .oos-news-detail .content .news-item .title {
    margin-top: 30px;
    font-size: 22px;
  }
  .oos-news-detail .content .news-item .time {
    margin-top: 5px;
    font-size: 13px;
  }
  .oos-news-detail .content .news-item .news-content {
    margin-top: 20px;
  }
  .oos-news-detail .content .news-item .news-content > p {
    min-height: 16px;
  }
  .oos-news-detail .content .skeleton-news-item {
    padding-right: 0;
    width: 100%;
  }
  .oos-news-detail .content .skeleton-news-item .title {
    margin-top: 30px;
  }
  .oos-news-detail .content .skeleton-news-item .time {
    margin-top: 10px;
  }
  .oos-news-detail .content .skeleton-news-item .news-content {
    margin-top: 20px;
  }
  .oos-news-detail .content .skeleton-news-item .news-content > p {
    min-height: 16px;
  }
  .oos-news-detail .content .right-side-bar,
  .oos-news-detail .content .skeleton-right-side-bar {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-news-detail .content .news-item .title {
    margin-top: 20px;
    font-size: 18px;
  }
  .oos-news-detail .content .news-item .time {
    margin-top: 5px;
    font-size: 12px;
  }
  .oos-news-detail .content .news-item .news-content > p {
    min-height: 16px;
  }
}
.oos-about-us > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-about-us > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-about-us > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-about-us .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-about-us .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-about-us .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-about-us .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-about-us .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-about-us .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-about-us .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-about-us .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-about-us .banner .banner-content {
    width: 1400px;
  }
  .oos-about-us .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-about-us .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-about-us .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-about-us .banner .banner-content {
    width: 1200px;
  }
  .oos-about-us .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-about-us .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-about-us .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-about-us .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-about-us .banner .banner-content {
    width: 1000px;
  }
  .oos-about-us .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-about-us .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-about-us .banner {
    height: auto;
  }
  .oos-about-us .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-about-us .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-about-us .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-about-us .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-about-us .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-about-us .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-about-us .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-about-us .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-about-us .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-about-us .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-about-us .section-title {
  padding: 60px 0 30px;
  text-align: center;
  color: #212d40;
}
.oos-about-us .section-title.gray-bg {
  background-color: #f7f8f9;
}
.oos-about-us .section-title .title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-about-us .section-title .sub-title {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .oos-about-us .section-title {
    padding: 22px 20px 18px;
  }
  .oos-about-us .section-title .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-about-us .section-title .title {
    font-size: 18px;
  }
}
.oos-about-us .introduction {
  margin: 0 auto;
  width: 1200px;
  color: #545c6c;
}
.oos-about-us .introduction .firm-name {
  font-size: 22px;
  font-weight: 600;
  color: #2b3744;
  letter-spacing: 1px;
}
.oos-about-us .introduction .firm-alias-name {
  padding: 10px 0 15px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
}
.oos-about-us .introduction .desc {
  line-height: 32px;
  font-size: 16px;
}
.oos-about-us .introduction .introduction-bg {
  margin: 50px auto 0;
  display: block;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .oos-about-us .introduction {
    width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .oos-about-us .introduction {
    padding: 0 20px;
    width: 100%;
    text-align: center;
  }
  .oos-about-us .introduction .firm-name {
    font-size: 18px;
  }
  .oos-about-us .introduction .firm-alias-name {
    font-size: 12px;
  }
  .oos-about-us .introduction .desc {
    font-size: 14px;
  }
  .oos-about-us .introduction .introduction-bg {
    margin-top: 30px;
  }
}
@media screen and (max-width: 450px) {
  .oos-about-us .introduction .firm-name {
    font-size: 16px;
  }
  .oos-about-us .introduction .introduction-bg {
    margin-top: 20px;
  }
}
.oos-about-us .mission {
  margin: 0 auto;
  width: 1000px;
  color: #545c6c;
}
.oos-about-us .mission .desc {
  text-align: center;
  font-size: 16px;
}
.oos-about-us .mission .mission-box {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.oos-about-us .mission .mission-box .mission-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
}
.oos-about-us .mission .mission-box .mission-item .img {
  width: 110px;
  height: 109px;
  background-size: 100% 100%;
}
.oos-about-us .mission .mission-box .mission-item .img.focus {
  background-image: url("../images/about/aboutUs/focus.webp");
}
.oos-about-us .mission .mission-box .mission-item .img.trustworthy {
  background-image: url("../images/about/aboutUs/trustworthy.webp");
}
.oos-about-us .mission .mission-box .mission-item .img.quality {
  background-image: url("../images/about/aboutUs/quality.webp");
}
.oos-about-us .mission .mission-box .mission-item .desc {
  margin-top: 20px;
  line-height: 34px;
  font-size: 14px;
}
@media screen and (max-width: 1200px) {
  .oos-about-us .mission {
    width: 800px;
  }
}
@media screen and (max-width: 768px) {
  .oos-about-us .mission {
    padding: 0 20px;
    width: 100%;
    display: block;
  }
  .oos-about-us .mission .desc {
    font-size: 14px;
  }
  .oos-about-us .mission .mission-box {
    margin-top: 30px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-about-us .mission .desc {
    line-height: 28px;
  }
  .oos-about-us .mission .mission-box {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }
  .oos-about-us .mission .mission-box .mission-item:not(:nth-child(1)) {
    margin-top: 20px;
  }
  .oos-about-us .mission .mission-box .mission-item .desc {
    line-height: 28px;
    font-size: 13px;
  }
}
.oos-about-us .honor {
  margin: 0 auto;
  width: 1200px;
  color: #545c6c;
}
.oos-about-us .honor .desc {
  text-align: center;
  font-size: 16px;
}
.oos-about-us .honor .honor-box {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.oos-about-us .honor .honor-box .honor-item .img-wrap {
  padding: 30px 20px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 362px;
  height: 482px;
  border: 1px solid #d8d7d7;
  border-radius: 5px;
}
.oos-about-us .honor .honor-box .honor-item .img-wrap .img {
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  max-width: 320px;
  max-height: 420px;
}
.oos-about-us .honor .honor-box .honor-item .name {
  padding-top: 10px;
  width: 362px;
  height: 45px;
  text-align: center;
  font-size: 16px;
}
.oos-about-us .honor .view-more-button {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  margin: 50px auto 0;
  background-color: #2a3957;
}
.oos-about-us .honor .view-more-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-about-us .honor .view-more-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-about-us .honor .view-more-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-about-us .honor .view-more-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-about-us .honor .view-more-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-about-us .honor .view-more-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-about-us .honor .view-more-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-about-us .honor .view-more-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-about-us .honor .view-more-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-about-us .honor .view-more-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-about-us .honor .view-more-button .oos-button-link-icon,
  .oos-about-us .honor .view-more-button .oos-button-download-icon,
  .oos-about-us .honor .view-more-button .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .oos-about-us .honor {
    width: 1000px;
  }
  .oos-about-us .honor .honor-box .honor-item .img-wrap {
    padding: 15px;
    width: 324px;
    height: 452px;
  }
  .oos-about-us .honor .honor-box .honor-item .name {
    width: 324px;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .oos-about-us .honor {
    padding: 0 20px;
    width: 100%;
  }
  .oos-about-us .honor .desc {
    font-size: 14px;
  }
  .oos-about-us .honor .honor-box {
    margin-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .oos-about-us .honor .honor-box .honor-item:not(:nth-child(1)) {
    margin-top: 30px;
  }
  .oos-about-us .honor .honor-box .honor-item .img-wrap {
    padding: 15px;
    width: 362px;
    height: 482px;
  }
  .oos-about-us .honor .honor-box .honor-item .name {
    width: 362px;
    font-size: 14px;
  }
  .oos-about-us .honor .view-more-button {
    margin: 30px auto 50px;
  }
}
@media screen and (max-width: 450px) {
  .oos-about-us .honor .desc {
    line-height: 28px;
  }
  .oos-about-us .honor .honor-box {
    margin-top: 20px;
  }
  .oos-about-us .honor .honor-box .honor-item {
    margin-top: 20px;
  }
  .oos-about-us .honor .honor-box .honor-item .img-wrap {
    padding: 10px;
    width: 100%;
    min-width: 322px;
    height: 444px;
  }
  .oos-about-us .honor .honor-box .honor-item .name {
    width: 100%;
    min-width: 322px;
    font-size: 14px;
  }
  .oos-about-us .honor .view-more-button {
    margin: 20px auto 30px;
  }
}
.oos-leadership > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-leadership > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-leadership > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-leadership .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-leadership .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-leadership .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-leadership .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-leadership .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-leadership .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-leadership .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-leadership .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-leadership .banner .banner-content {
    width: 1400px;
  }
  .oos-leadership .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-leadership .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-leadership .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-leadership .banner .banner-content {
    width: 1200px;
  }
  .oos-leadership .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-leadership .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-leadership .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-leadership .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-leadership .banner .banner-content {
    width: 1000px;
  }
  .oos-leadership .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-leadership .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-leadership .banner {
    height: auto;
  }
  .oos-leadership .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-leadership .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-leadership .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-leadership .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-leadership .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-leadership .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-leadership .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-leadership .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-leadership .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-leadership .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-leadership .section-title {
  padding: 60px 0 30px;
  text-align: center;
  color: #212d40;
}
.oos-leadership .section-title.gray-bg {
  background-color: #f7f8f9;
}
.oos-leadership .section-title .title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-leadership .section-title .sub-title {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .oos-leadership .section-title {
    padding: 22px 20px 18px;
  }
  .oos-leadership .section-title .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-leadership .section-title .title {
    font-size: 18px;
  }
}
.oos-leadership .content .leader-box {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 1100px;
}
.oos-leadership .content .leader-box .leader-item {
  margin-top: 90px;
  display: flex;
  flex-direction: row;
}
.oos-leadership .content .leader-box .leader-item .img {
  width: 260px;
  height: 340px;
  background-size: 100% 100%;
  border-radius: 5px;
}
.oos-leadership .content .leader-box .leader-item .img.president {
  background-image: url("../images/about/leadership/president.webp");
}
.oos-leadership .content .leader-box .leader-item .img.ceo {
  background-image: url("../images/about/leadership/ceo.webp");
}
.oos-leadership .content .leader-box .leader-item .img.cto {
  background-image: url("../images/about/leadership/cto.webp");
}
.oos-leadership .content .leader-box .leader-item .img.technical-director {
  background-image: url("../images/about/leadership/technical_director.webp");
}
.oos-leadership .content .leader-box .leader-item .img.vice-president {
  background-image: url("../images/about/leadership/vice_president.webp");
}
.oos-leadership .content .leader-box .leader-item .text-content {
  padding-left: 45px;
  width: calc(100% - 260px);
  color: #3e3e3e;
}
.oos-leadership .content .leader-box .leader-item .text-content .name {
  font-size: 24px;
  letter-spacing: 2px;
  color: #2b3744;
}
.oos-leadership .content .leader-box .leader-item .text-content .name .en-name {
  padding-left: 10px;
  vertical-align: 3px;
  font-size: 16px;
  font-weight: 300;
}
.oos-leadership .content .leader-box .leader-item .text-content .title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
}
.oos-leadership .content .leader-box .leader-item .text-content .introduction {
  margin-top: 75px;
  line-height: 32px;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .oos-leadership .content .leader-box {
    padding: 0 20px;
    flex-direction: column;
    width: 100%;
  }
  .oos-leadership .content .leader-box .leader-item {
    margin-top: 40px;
    flex-direction: column;
  }
  .oos-leadership .content .leader-box .leader-item .img {
    margin: 0 auto;
  }
  .oos-leadership .content .leader-box .leader-item .text-content {
    padding-left: 0;
    width: 100%;
  }
  .oos-leadership .content .leader-box .leader-item .text-content .name {
    margin-top: 20px;
    text-align: center;
  }
  .oos-leadership .content .leader-box .leader-item .text-content .title {
    text-align: center;
  }
  .oos-leadership .content .leader-box .leader-item .text-content .introduction {
    margin-top: 15px;
  }
}
@media screen and (max-width: 450px) {
  .oos-leadership .content .leader-box .leader-item {
    margin-top: 30px;
  }
  .oos-leadership .content .leader-box .leader-item .text-content {
    padding-left: 0;
  }
  .oos-leadership .content .leader-box .leader-item .text-content .name {
    font-size: 18px;
  }
  .oos-leadership .content .leader-box .leader-item .text-content .name .en-name {
    vertical-align: 0.5px;
  }
  .oos-leadership .content .leader-box .leader-item .text-content .title {
    font-size: 14px;
  }
  .oos-leadership .content .leader-box .leader-item .text-content .introduction {
    font-size: 12px;
  }
}
.oos-honor > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-honor > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-honor > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-honor .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-honor .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-honor .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-honor .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-honor .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-honor .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-honor .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-honor .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-honor .banner .banner-content {
    width: 1400px;
  }
  .oos-honor .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-honor .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-honor .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-honor .banner .banner-content {
    width: 1200px;
  }
  .oos-honor .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-honor .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-honor .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-honor .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-honor .banner .banner-content {
    width: 1000px;
  }
  .oos-honor .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-honor .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-honor .banner {
    height: auto;
  }
  .oos-honor .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-honor .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-honor .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-honor .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-honor .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-honor .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-honor .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-honor .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-honor .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-honor .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-honor .section-nav {
  position: sticky;
  top: 0;
  background-color: #f2f4f7;
  border: 1px solid #e3e6ed;
  z-index: 1;
}
.oos-honor .section-nav .section-nav-box {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 1600px;
  height: 50px;
  line-height: 50px;
  user-select: none;
}
.oos-honor .section-nav .section-nav-box .nav-item {
  position: relative;
  margin-right: 40px;
  font-size: 14px;
  color: #233759;
}
.oos-honor .section-nav .section-nav-box .nav-item:hover {
  cursor: pointer;
}
.oos-honor .section-nav .section-nav-box .nav-item.active::before {
  content: " ";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 15px;
  height: 2px;
  background-color: #4c8ffb;
  transform: translateX(-50%);
}
@media screen and (max-width: 1800px) {
  .oos-honor .section-nav .section-nav-box {
    width: 1600px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-honor .section-nav .section-nav-box {
    width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-honor .section-nav .section-nav-box {
    width: 1200px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-honor .section-nav .section-nav-box {
    width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .oos-honor .section-nav {
    display: none;
  }
}
.oos-honor .section-title {
  padding: 60px 0 30px;
  text-align: center;
  color: #212d40;
}
.oos-honor .section-title.gray-bg {
  background-color: #f7f8f9;
}
.oos-honor .section-title .title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-honor .section-title .sub-title {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .oos-honor .section-title {
    padding: 22px 20px 18px;
  }
  .oos-honor .section-title .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-honor .section-title .title {
    font-size: 18px;
  }
}
.oos-honor .honor-box {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1200px;
}
.oos-honor .honor-box .honor-item {
  margin-top: 50px;
}
.oos-honor .honor-box .honor-item .img-wrap {
  padding: 30px 20px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 362px;
  height: 482px;
  border: 1px solid #d8d7d7;
  border-radius: 5px;
}
.oos-honor .honor-box .honor-item .img-wrap:hover {
  border: 1px solid #fff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.oos-honor .honor-box .honor-item .img-wrap .img {
  display: table-cell;
  margin: 0 auto;
  vertical-align: middle;
  max-width: 320px;
  max-height: 420px;
  cursor: pointer;
  user-select: none;
}
.oos-honor .honor-box .honor-item .name {
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  color: #3e3e3e;
}
@media screen and (max-width: 1200px) {
  .oos-honor .honor-box {
    width: 1000px;
  }
  .oos-honor .honor-box .honor-item .img-wrap {
    padding: 15px;
    width: 324px;
    height: 452px;
  }
  .oos-honor .honor-box .honor-item .name {
    width: 324px;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .oos-honor .honor-box {
    padding: 0 20px;
    width: 100%;
    margin-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .oos-honor .honor-box:nth-last-child(1) {
    margin-bottom: 60px;
  }
  .oos-honor .honor-box .honor-item {
    margin-top: 30px;
  }
  .oos-honor .honor-box .honor-item:not(:nth-child(1)) {
    margin-top: 30px;
  }
  .oos-honor .honor-box .honor-item .img-wrap {
    padding: 15px;
    width: 362px;
    height: 482px;
  }
  .oos-honor .honor-box .honor-item .name {
    width: 362px;
    font-size: 14px;
  }
}
@media screen and (max-width: 450px) {
  .oos-honor .honor-box {
    margin-top: 20px;
  }
  .oos-honor .honor-box:nth-last-child(1) {
    margin-bottom: 30px;
  }
  .oos-honor .honor-box .honor-item {
    margin-top: 20px;
  }
  .oos-honor .honor-box .honor-item .img-wrap {
    padding: 10px;
    width: 100%;
    min-width: 322px;
    height: 444px;
  }
  .oos-honor .honor-box .honor-item .name {
    width: 100%;
    min-width: 322px;
    font-size: 14px;
  }
}
.oos-recruitment-us > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-recruitment-us > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-recruitment-us > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-recruitment-us .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-recruitment-us .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-recruitment-us .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-recruitment-us .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-recruitment-us .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-recruitment-us .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-recruitment-us .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-recruitment-us .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-recruitment-us .banner .banner-content {
    width: 1400px;
  }
  .oos-recruitment-us .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-recruitment-us .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-recruitment-us .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-recruitment-us .banner .banner-content {
    width: 1200px;
  }
  .oos-recruitment-us .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-recruitment-us .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-recruitment-us .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-recruitment-us .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-recruitment-us .banner .banner-content {
    width: 1000px;
  }
  .oos-recruitment-us .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-recruitment-us .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-recruitment-us .banner {
    height: auto;
  }
  .oos-recruitment-us .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-recruitment-us .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-recruitment-us .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-recruitment-us .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-recruitment-us .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-recruitment-us .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-recruitment-us .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-recruitment-us .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-recruitment-us .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-recruitment-us .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-recruitment-us .section-title {
  padding: 60px 0 30px;
  text-align: center;
  color: #212d40;
}
.oos-recruitment-us .section-title.gray-bg {
  background-color: #f7f8f9;
}
.oos-recruitment-us .section-title .title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-recruitment-us .section-title .sub-title {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .oos-recruitment-us .section-title {
    padding: 22px 20px 18px;
  }
  .oos-recruitment-us .section-title .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-recruitment-us .section-title .title {
    font-size: 18px;
  }
}
.oos-recruitment-us .team-building {
  margin: 0 auto;
  width: 1200px;
}
.oos-recruitment-us .team-building .desc {
  line-height: 32px;
  font-size: 16px;
  color: #545c6c;
}
.oos-recruitment-us .team-building .team-building-box {
  position: relative;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.oos-recruitment-us .team-building .team-building-box .team-building-item {
  min-width: 400px;
  height: 270px;
  background-size: 100% 100%;
}
.oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(1) {
  background-image: url("../images/about/recruitment/team_building_1.webp");
  border-top-left-radius: 5px;
}
.oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(2) {
  background-image: url("../images/about/recruitment/team_building_2.webp");
}
.oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(3) {
  background-image: url("../images/about/recruitment/team_building_3.webp");
  border-top-right-radius: 5px;
}
.oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(4) {
  background-image: url("../images/about/recruitment/team_building_4.webp");
  border-bottom-left-radius: 5px;
}
.oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(5) {
  background-image: url("../images/about/recruitment/team_building_5.webp");
}
.oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(6) {
  background-image: url("../images/about/recruitment/team_building_6.webp");
  border-bottom-right-radius: 5px;
}
.oos-recruitment-us .team-building .team-building-box .bubble-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 1200px;
  height: 800px;
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble {
  position: relative;
  float: left;
  width: 30px;
  height: 30px;
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 50%;
  transform: scale3d(0.1, 0.1, 1);
  transition: 0.5s transform ease-in, 0.5s background ease-in;
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:hover::before {
  transform: scale3d(1.5, 1.5, 1.5);
  transition: 0s transform;
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(1):hover::before {
  background-color: rgba(18, 24, 226, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(2):hover::before {
  background-color: rgba(250, 76, 43, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(3):hover::before {
  background-color: rgba(184, 95, 85, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(4):hover::before {
  background-color: rgba(169, 49, 191, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(5):hover::before {
  background-color: rgba(105, 171, 88, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(6):hover::before {
  background-color: rgba(243, 209, 17, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(7):hover::before {
  background-color: rgba(233, 50, 84, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(8):hover::before {
  background-color: rgba(74, 181, 170, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(9):hover::before {
  background-color: rgba(14, 144, 174, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(10):hover::before {
  background-color: rgba(194, 22, 52, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(11):hover::before {
  background-color: rgba(30, 29, 222, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(12):hover::before {
  background-color: rgba(48, 29, 96, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(13):hover::before {
  background-color: rgba(40, 128, 29, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(14):hover::before {
  background-color: rgba(139, 15, 41, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(15):hover::before {
  background-color: rgba(178, 83, 203, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(16):hover::before {
  background-color: rgba(183, 143, 233, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(17):hover::before {
  background-color: rgba(109, 107, 17, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(18):hover::before {
  background-color: rgba(188, 178, 129, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(19):hover::before {
  background-color: rgba(228, 25, 122, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(20):hover::before {
  background-color: rgba(42, 24, 231, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(21):hover::before {
  background-color: rgba(206, 60, 118, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(22):hover::before {
  background-color: rgba(202, 187, 4, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(23):hover::before {
  background-color: rgba(58, 189, 224, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(24):hover::before {
  background-color: rgba(102, 157, 18, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(25):hover::before {
  background-color: rgba(63, 219, 201, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(26):hover::before {
  background-color: rgba(40, 109, 108, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(27):hover::before {
  background-color: rgba(199, 117, 242, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(28):hover::before {
  background-color: rgba(224, 13, 47, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(29):hover::before {
  background-color: rgba(138, 30, 202, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(30):hover::before {
  background-color: rgba(227, 67, 110, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(31):hover::before {
  background-color: rgba(2, 218, 177, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(32):hover::before {
  background-color: rgba(79, 207, 161, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(33):hover::before {
  background-color: rgba(249, 17, 82, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(34):hover::before {
  background-color: rgba(151, 84, 149, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(35):hover::before {
  background-color: rgba(191, 44, 251, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(36):hover::before {
  background-color: rgba(123, 7, 18, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(37):hover::before {
  background-color: rgba(188, 139, 22, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(38):hover::before {
  background-color: rgba(251, 153, 51, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(39):hover::before {
  background-color: rgba(242, 64, 249, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(40):hover::before {
  background-color: rgba(60, 34, 164, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(41):hover::before {
  background-color: rgba(244, 128, 60, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(42):hover::before {
  background-color: rgba(218, 234, 190, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(43):hover::before {
  background-color: rgba(80, 231, 29, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(44):hover::before {
  background-color: rgba(19, 218, 220, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(45):hover::before {
  background-color: rgba(64, 222, 234, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(46):hover::before {
  background-color: rgba(50, 249, 213, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(47):hover::before {
  background-color: rgba(233, 29, 95, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(48):hover::before {
  background-color: rgba(4, 222, 250, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(49):hover::before {
  background-color: rgba(98, 136, 117, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(50):hover::before {
  background-color: rgba(39, 11, 62, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(51):hover::before {
  background-color: rgba(135, 143, 173, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(52):hover::before {
  background-color: rgba(240, 220, 19, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(53):hover::before {
  background-color: rgba(69, 102, 1, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(54):hover::before {
  background-color: rgba(179, 192, 164, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(55):hover::before {
  background-color: rgba(241, 122, 157, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(56):hover::before {
  background-color: rgba(50, 242, 170, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(57):hover::before {
  background-color: rgba(12, 74, 99, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(58):hover::before {
  background-color: rgba(121, 28, 177, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(59):hover::before {
  background-color: rgba(200, 17, 54, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(60):hover::before {
  background-color: rgba(233, 140, 58, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(61):hover::before {
  background-color: rgba(59, 36, 20, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(62):hover::before {
  background-color: rgba(208, 68, 202, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(63):hover::before {
  background-color: rgba(75, 57, 164, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(64):hover::before {
  background-color: rgba(45, 233, 186, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(65):hover::before {
  background-color: rgba(66, 17, 219, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(66):hover::before {
  background-color: rgba(241, 228, 196, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(67):hover::before {
  background-color: rgba(236, 219, 21, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(68):hover::before {
  background-color: rgba(149, 77, 254, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(69):hover::before {
  background-color: rgba(29, 172, 136, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(70):hover::before {
  background-color: rgba(30, 62, 122, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(71):hover::before {
  background-color: rgba(127, 252, 97, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(72):hover::before {
  background-color: rgba(222, 209, 227, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(73):hover::before {
  background-color: rgba(13, 34, 110, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(74):hover::before {
  background-color: rgba(92, 237, 238, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(75):hover::before {
  background-color: rgba(220, 37, 138, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(76):hover::before {
  background-color: rgba(233, 135, 138, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(77):hover::before {
  background-color: rgba(11, 9, 89, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(78):hover::before {
  background-color: rgba(94, 16, 86, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(79):hover::before {
  background-color: rgba(152, 145, 231, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(80):hover::before {
  background-color: rgba(143, 10, 99, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(81):hover::before {
  background-color: rgba(101, 97, 232, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(82):hover::before {
  background-color: rgba(25, 234, 138, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(83):hover::before {
  background-color: rgba(223, 142, 202, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(84):hover::before {
  background-color: rgba(202, 153, 52, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(85):hover::before {
  background-color: rgba(16, 17, 186, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(86):hover::before {
  background-color: rgba(134, 119, 56, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(87):hover::before {
  background-color: rgba(248, 105, 86, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(88):hover::before {
  background-color: rgba(218, 155, 192, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(89):hover::before {
  background-color: rgba(214, 235, 30, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(90):hover::before {
  background-color: rgba(6, 148, 64, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(91):hover::before {
  background-color: rgba(94, 136, 132, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(92):hover::before {
  background-color: rgba(29, 255, 175, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(93):hover::before {
  background-color: rgba(5, 252, 167, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(94):hover::before {
  background-color: rgba(118, 182, 12, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(95):hover::before {
  background-color: rgba(84, 84, 213, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(96):hover::before {
  background-color: rgba(149, 196, 26, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(97):hover::before {
  background-color: rgba(201, 158, 55, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(98):hover::before {
  background-color: rgba(175, 146, 198, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(99):hover::before {
  background-color: rgba(174, 186, 44, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(100):hover::before {
  background-color: rgba(237, 250, 253, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(101):hover::before {
  background-color: rgba(174, 108, 129, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(102):hover::before {
  background-color: rgba(84, 240, 201, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(103):hover::before {
  background-color: rgba(58, 133, 106, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(104):hover::before {
  background-color: rgba(234, 4, 98, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(105):hover::before {
  background-color: rgba(151, 200, 153, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(106):hover::before {
  background-color: rgba(49, 242, 156, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(107):hover::before {
  background-color: rgba(91, 88, 121, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(108):hover::before {
  background-color: rgba(64, 66, 231, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(109):hover::before {
  background-color: rgba(195, 65, 96, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(110):hover::before {
  background-color: rgba(163, 16, 83, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(111):hover::before {
  background-color: rgba(5, 233, 17, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(112):hover::before {
  background-color: rgba(95, 247, 78, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(113):hover::before {
  background-color: rgba(99, 98, 100, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(114):hover::before {
  background-color: rgba(48, 213, 49, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(115):hover::before {
  background-color: rgba(252, 202, 166, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(116):hover::before {
  background-color: rgba(215, 207, 163, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(117):hover::before {
  background-color: rgba(68, 215, 100, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(118):hover::before {
  background-color: rgba(98, 191, 31, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(119):hover::before {
  background-color: rgba(195, 222, 41, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(120):hover::before {
  background-color: rgba(169, 42, 95, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(121):hover::before {
  background-color: rgba(246, 14, 113, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(122):hover::before {
  background-color: rgba(98, 117, 237, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(123):hover::before {
  background-color: rgba(11, 233, 163, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(124):hover::before {
  background-color: rgba(190, 154, 36, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(125):hover::before {
  background-color: rgba(138, 238, 140, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(126):hover::before {
  background-color: rgba(90, 249, 242, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(127):hover::before {
  background-color: rgba(246, 134, 84, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(128):hover::before {
  background-color: rgba(103, 234, 235, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(129):hover::before {
  background-color: rgba(110, 249, 146, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(130):hover::before {
  background-color: rgba(248, 170, 27, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(131):hover::before {
  background-color: rgba(157, 106, 70, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(132):hover::before {
  background-color: rgba(169, 164, 45, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(133):hover::before {
  background-color: rgba(40, 244, 122, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(134):hover::before {
  background-color: rgba(151, 46, 125, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(135):hover::before {
  background-color: rgba(48, 165, 190, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(136):hover::before {
  background-color: rgba(199, 25, 178, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(137):hover::before {
  background-color: rgba(220, 224, 188, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(138):hover::before {
  background-color: rgba(252, 34, 232, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(139):hover::before {
  background-color: rgba(239, 189, 162, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(140):hover::before {
  background-color: rgba(181, 211, 254, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(141):hover::before {
  background-color: rgba(44, 254, 11, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(142):hover::before {
  background-color: rgba(143, 179, 70, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(143):hover::before {
  background-color: rgba(198, 68, 90, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(144):hover::before {
  background-color: rgba(60, 198, 185, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(145):hover::before {
  background-color: rgba(127, 248, 239, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(146):hover::before {
  background-color: rgba(249, 167, 160, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(147):hover::before {
  background-color: rgba(62, 245, 201, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(148):hover::before {
  background-color: rgba(214, 179, 89, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(149):hover::before {
  background-color: rgba(105, 59, 32, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(150):hover::before {
  background-color: rgba(221, 57, 197, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(151):hover::before {
  background-color: rgba(207, 19, 68, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(152):hover::before {
  background-color: rgba(176, 66, 177, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(153):hover::before {
  background-color: rgba(242, 90, 187, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(154):hover::before {
  background-color: rgba(187, 91, 104, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(155):hover::before {
  background-color: rgba(73, 98, 225, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(156):hover::before {
  background-color: rgba(47, 119, 211, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(157):hover::before {
  background-color: rgba(215, 154, 230, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(158):hover::before {
  background-color: rgba(181, 59, 153, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(159):hover::before {
  background-color: rgba(88, 152, 168, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(160):hover::before {
  background-color: rgba(190, 249, 20, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(161):hover::before {
  background-color: rgba(179, 57, 180, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(162):hover::before {
  background-color: rgba(222, 20, 186, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(163):hover::before {
  background-color: rgba(220, 201, 150, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(164):hover::before {
  background-color: rgba(194, 203, 133, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(165):hover::before {
  background-color: rgba(51, 124, 51, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(166):hover::before {
  background-color: rgba(173, 244, 246, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(167):hover::before {
  background-color: rgba(251, 137, 47, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(168):hover::before {
  background-color: rgba(51, 99, 127, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(169):hover::before {
  background-color: rgba(111, 2, 69, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(170):hover::before {
  background-color: rgba(178, 103, 39, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(171):hover::before {
  background-color: rgba(30, 174, 30, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(172):hover::before {
  background-color: rgba(70, 197, 203, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(173):hover::before {
  background-color: rgba(132, 14, 216, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(174):hover::before {
  background-color: rgba(82, 68, 93, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(175):hover::before {
  background-color: rgba(26, 54, 171, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(176):hover::before {
  background-color: rgba(218, 163, 179, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(177):hover::before {
  background-color: rgba(166, 182, 121, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(178):hover::before {
  background-color: rgba(205, 45, 140, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(179):hover::before {
  background-color: rgba(198, 224, 134, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(180):hover::before {
  background-color: rgba(131, 19, 1, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(181):hover::before {
  background-color: rgba(212, 214, 136, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(182):hover::before {
  background-color: rgba(20, 126, 48, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(183):hover::before {
  background-color: rgba(65, 172, 56, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(184):hover::before {
  background-color: rgba(163, 140, 140, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(185):hover::before {
  background-color: rgba(246, 255, 125, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(186):hover::before {
  background-color: rgba(148, 244, 190, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(187):hover::before {
  background-color: rgba(18, 215, 138, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(188):hover::before {
  background-color: rgba(35, 72, 182, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(189):hover::before {
  background-color: rgba(90, 88, 56, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(190):hover::before {
  background-color: rgba(206, 74, 32, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(191):hover::before {
  background-color: rgba(41, 229, 176, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(192):hover::before {
  background-color: rgba(73, 179, 34, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(193):hover::before {
  background-color: rgba(115, 37, 92, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(194):hover::before {
  background-color: rgba(217, 217, 70, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(195):hover::before {
  background-color: rgba(10, 147, 103, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(196):hover::before {
  background-color: rgba(84, 110, 14, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(197):hover::before {
  background-color: rgba(251, 210, 190, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(198):hover::before {
  background-color: rgba(105, 109, 185, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(199):hover::before {
  background-color: rgba(33, 193, 140, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(200):hover::before {
  background-color: rgba(37, 121, 95, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(201):hover::before {
  background-color: rgba(184, 36, 159, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(202):hover::before {
  background-color: rgba(226, 86, 197, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(203):hover::before {
  background-color: rgba(7, 108, 254, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(204):hover::before {
  background-color: rgba(7, 100, 107, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(205):hover::before {
  background-color: rgba(166, 202, 179, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(206):hover::before {
  background-color: rgba(248, 169, 155, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(207):hover::before {
  background-color: rgba(122, 172, 30, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(208):hover::before {
  background-color: rgba(190, 236, 161, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(209):hover::before {
  background-color: rgba(109, 174, 118, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(210):hover::before {
  background-color: rgba(3, 186, 214, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(211):hover::before {
  background-color: rgba(188, 103, 0, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(212):hover::before {
  background-color: rgba(49, 137, 123, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(213):hover::before {
  background-color: rgba(192, 171, 201, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(214):hover::before {
  background-color: rgba(153, 93, 221, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(215):hover::before {
  background-color: rgba(243, 241, 226, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(216):hover::before {
  background-color: rgba(44, 152, 162, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(217):hover::before {
  background-color: rgba(43, 61, 23, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(218):hover::before {
  background-color: rgba(150, 144, 86, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(219):hover::before {
  background-color: rgba(89, 10, 188, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(220):hover::before {
  background-color: rgba(198, 251, 194, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(221):hover::before {
  background-color: rgba(213, 243, 178, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(222):hover::before {
  background-color: rgba(169, 99, 204, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(223):hover::before {
  background-color: rgba(220, 119, 49, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(224):hover::before {
  background-color: rgba(19, 130, 86, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(225):hover::before {
  background-color: rgba(35, 126, 49, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(226):hover::before {
  background-color: rgba(225, 91, 191, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(227):hover::before {
  background-color: rgba(226, 177, 239, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(228):hover::before {
  background-color: rgba(177, 56, 160, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(229):hover::before {
  background-color: rgba(106, 167, 33, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(230):hover::before {
  background-color: rgba(44, 93, 132, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(231):hover::before {
  background-color: rgba(213, 46, 206, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(232):hover::before {
  background-color: rgba(220, 180, 51, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(233):hover::before {
  background-color: rgba(46, 19, 169, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(234):hover::before {
  background-color: rgba(208, 92, 169, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(235):hover::before {
  background-color: rgba(151, 251, 90, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(236):hover::before {
  background-color: rgba(194, 155, 19, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(237):hover::before {
  background-color: rgba(172, 151, 127, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(238):hover::before {
  background-color: rgba(60, 60, 122, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(239):hover::before {
  background-color: rgba(107, 191, 18, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(240):hover::before {
  background-color: rgba(107, 173, 122, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(241):hover::before {
  background-color: rgba(52, 215, 67, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(242):hover::before {
  background-color: rgba(216, 230, 108, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(243):hover::before {
  background-color: rgba(236, 175, 82, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(244):hover::before {
  background-color: rgba(68, 85, 26, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(245):hover::before {
  background-color: rgba(133, 120, 122, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(246):hover::before {
  background-color: rgba(20, 195, 172, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(247):hover::before {
  background-color: rgba(6, 21, 67, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(248):hover::before {
  background-color: rgba(39, 77, 94, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(249):hover::before {
  background-color: rgba(84, 104, 3, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(250):hover::before {
  background-color: rgba(64, 224, 164, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(251):hover::before {
  background-color: rgba(123, 114, 60, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(252):hover::before {
  background-color: rgba(251, 246, 15, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(253):hover::before {
  background-color: rgba(29, 92, 35, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(254):hover::before {
  background-color: rgba(226, 252, 90, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(255):hover::before {
  background-color: rgba(96, 107, 146, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(256):hover::before {
  background-color: rgba(20, 110, 146, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(257):hover::before {
  background-color: rgba(145, 129, 218, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(258):hover::before {
  background-color: rgba(170, 112, 39, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(259):hover::before {
  background-color: rgba(210, 124, 7, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(260):hover::before {
  background-color: rgba(0, 135, 168, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(261):hover::before {
  background-color: rgba(172, 186, 156, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(262):hover::before {
  background-color: rgba(16, 246, 230, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(263):hover::before {
  background-color: rgba(9, 79, 183, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(264):hover::before {
  background-color: rgba(43, 205, 138, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(265):hover::before {
  background-color: rgba(166, 5, 176, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(266):hover::before {
  background-color: rgba(185, 165, 138, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(267):hover::before {
  background-color: rgba(194, 30, 22, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(268):hover::before {
  background-color: rgba(202, 97, 67, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(269):hover::before {
  background-color: rgba(221, 119, 251, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(270):hover::before {
  background-color: rgba(176, 209, 105, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(271):hover::before {
  background-color: rgba(238, 199, 80, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(272):hover::before {
  background-color: rgba(19, 67, 166, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(273):hover::before {
  background-color: rgba(65, 72, 3, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(274):hover::before {
  background-color: rgba(178, 24, 206, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(275):hover::before {
  background-color: rgba(159, 246, 54, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(276):hover::before {
  background-color: rgba(115, 238, 230, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(277):hover::before {
  background-color: rgba(205, 211, 32, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(278):hover::before {
  background-color: rgba(118, 68, 254, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(279):hover::before {
  background-color: rgba(170, 25, 54, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(280):hover::before {
  background-color: rgba(175, 84, 58, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(281):hover::before {
  background-color: rgba(182, 165, 204, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(282):hover::before {
  background-color: rgba(170, 151, 32, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(283):hover::before {
  background-color: rgba(28, 56, 199, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(284):hover::before {
  background-color: rgba(152, 144, 214, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(285):hover::before {
  background-color: rgba(118, 125, 245, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(286):hover::before {
  background-color: rgba(106, 106, 253, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(287):hover::before {
  background-color: rgba(222, 207, 232, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(288):hover::before {
  background-color: rgba(55, 107, 213, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(289):hover::before {
  background-color: rgba(14, 129, 72, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(290):hover::before {
  background-color: rgba(155, 208, 108, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(291):hover::before {
  background-color: rgba(230, 192, 173, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(292):hover::before {
  background-color: rgba(197, 143, 169, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(293):hover::before {
  background-color: rgba(150, 191, 211, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(294):hover::before {
  background-color: rgba(42, 211, 24, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(295):hover::before {
  background-color: rgba(174, 27, 133, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(296):hover::before {
  background-color: rgba(193, 5, 145, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(297):hover::before {
  background-color: rgba(86, 251, 134, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(298):hover::before {
  background-color: rgba(189, 83, 171, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(299):hover::before {
  background-color: rgba(111, 176, 152, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(300):hover::before {
  background-color: rgba(111, 227, 209, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(301):hover::before {
  background-color: rgba(254, 190, 27, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(302):hover::before {
  background-color: rgba(224, 17, 177, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(303):hover::before {
  background-color: rgba(125, 92, 132, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(304):hover::before {
  background-color: rgba(251, 242, 114, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(305):hover::before {
  background-color: rgba(129, 5, 161, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(306):hover::before {
  background-color: rgba(214, 39, 117, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(307):hover::before {
  background-color: rgba(110, 30, 13, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(308):hover::before {
  background-color: rgba(83, 198, 131, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(309):hover::before {
  background-color: rgba(102, 176, 65, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(310):hover::before {
  background-color: rgba(151, 228, 164, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(311):hover::before {
  background-color: rgba(62, 11, 52, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(312):hover::before {
  background-color: rgba(185, 239, 69, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(313):hover::before {
  background-color: rgba(44, 220, 248, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(314):hover::before {
  background-color: rgba(126, 195, 187, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(315):hover::before {
  background-color: rgba(50, 81, 200, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(316):hover::before {
  background-color: rgba(36, 2, 27, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(317):hover::before {
  background-color: rgba(119, 62, 70, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(318):hover::before {
  background-color: rgba(119, 83, 173, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(319):hover::before {
  background-color: rgba(207, 117, 130, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(320):hover::before {
  background-color: rgba(134, 181, 23, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(321):hover::before {
  background-color: rgba(169, 164, 152, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(322):hover::before {
  background-color: rgba(67, 135, 139, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(323):hover::before {
  background-color: rgba(69, 103, 125, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(324):hover::before {
  background-color: rgba(7, 72, 121, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(325):hover::before {
  background-color: rgba(201, 203, 11, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(326):hover::before {
  background-color: rgba(215, 170, 173, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(327):hover::before {
  background-color: rgba(217, 165, 135, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(328):hover::before {
  background-color: rgba(212, 191, 84, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(329):hover::before {
  background-color: rgba(158, 158, 65, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(330):hover::before {
  background-color: rgba(59, 167, 164, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(331):hover::before {
  background-color: rgba(114, 80, 129, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(332):hover::before {
  background-color: rgba(120, 139, 127, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(333):hover::before {
  background-color: rgba(178, 117, 126, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(334):hover::before {
  background-color: rgba(118, 128, 83, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(335):hover::before {
  background-color: rgba(237, 85, 179, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(336):hover::before {
  background-color: rgba(94, 168, 162, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(337):hover::before {
  background-color: rgba(172, 194, 114, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(338):hover::before {
  background-color: rgba(244, 244, 145, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(339):hover::before {
  background-color: rgba(65, 4, 203, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(340):hover::before {
  background-color: rgba(68, 150, 132, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(341):hover::before {
  background-color: rgba(128, 157, 4, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(342):hover::before {
  background-color: rgba(211, 71, 96, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(343):hover::before {
  background-color: rgba(235, 249, 206, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(344):hover::before {
  background-color: rgba(11, 94, 112, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(345):hover::before {
  background-color: rgba(23, 128, 110, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(346):hover::before {
  background-color: rgba(149, 175, 187, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(347):hover::before {
  background-color: rgba(160, 135, 29, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(348):hover::before {
  background-color: rgba(154, 116, 27, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(349):hover::before {
  background-color: rgba(213, 119, 3, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(350):hover::before {
  background-color: rgba(94, 213, 65, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(351):hover::before {
  background-color: rgba(220, 14, 136, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(352):hover::before {
  background-color: rgba(68, 38, 93, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(353):hover::before {
  background-color: rgba(123, 162, 95, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(354):hover::before {
  background-color: rgba(17, 71, 73, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(355):hover::before {
  background-color: rgba(24, 150, 119, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(356):hover::before {
  background-color: rgba(26, 206, 110, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(357):hover::before {
  background-color: rgba(202, 76, 36, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(358):hover::before {
  background-color: rgba(244, 78, 122, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(359):hover::before {
  background-color: rgba(125, 111, 24, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(360):hover::before {
  background-color: rgba(69, 235, 60, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(361):hover::before {
  background-color: rgba(248, 246, 111, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(362):hover::before {
  background-color: rgba(61, 143, 195, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(363):hover::before {
  background-color: rgba(1, 118, 208, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(364):hover::before {
  background-color: rgba(137, 149, 232, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(365):hover::before {
  background-color: rgba(167, 43, 227, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(366):hover::before {
  background-color: rgba(27, 61, 129, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(367):hover::before {
  background-color: rgba(235, 96, 68, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(368):hover::before {
  background-color: rgba(155, 179, 207, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(369):hover::before {
  background-color: rgba(43, 22, 174, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(370):hover::before {
  background-color: rgba(218, 7, 222, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(371):hover::before {
  background-color: rgba(48, 150, 220, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(372):hover::before {
  background-color: rgba(115, 60, 5, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(373):hover::before {
  background-color: rgba(87, 82, 147, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(374):hover::before {
  background-color: rgba(27, 29, 79, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(375):hover::before {
  background-color: rgba(113, 214, 241, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(376):hover::before {
  background-color: rgba(95, 215, 65, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(377):hover::before {
  background-color: rgba(118, 75, 218, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(378):hover::before {
  background-color: rgba(217, 201, 186, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(379):hover::before {
  background-color: rgba(200, 126, 14, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(380):hover::before {
  background-color: rgba(72, 215, 58, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(381):hover::before {
  background-color: rgba(243, 99, 13, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(382):hover::before {
  background-color: rgba(135, 168, 54, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(383):hover::before {
  background-color: rgba(215, 205, 87, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(384):hover::before {
  background-color: rgba(149, 235, 50, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(385):hover::before {
  background-color: rgba(26, 98, 171, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(386):hover::before {
  background-color: rgba(62, 38, 75, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(387):hover::before {
  background-color: rgba(49, 31, 219, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(388):hover::before {
  background-color: rgba(72, 187, 207, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(389):hover::before {
  background-color: rgba(78, 131, 161, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(390):hover::before {
  background-color: rgba(37, 34, 33, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(391):hover::before {
  background-color: rgba(139, 89, 85, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(392):hover::before {
  background-color: rgba(103, 118, 149, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(393):hover::before {
  background-color: rgba(107, 147, 193, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(394):hover::before {
  background-color: rgba(43, 119, 26, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(395):hover::before {
  background-color: rgba(148, 142, 195, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(396):hover::before {
  background-color: rgba(151, 8, 91, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(397):hover::before {
  background-color: rgba(108, 94, 209, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(398):hover::before {
  background-color: rgba(148, 107, 54, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(399):hover::before {
  background-color: rgba(207, 146, 47, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(400):hover::before {
  background-color: rgba(8, 24, 150, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(401):hover::before {
  background-color: rgba(73, 55, 8, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(402):hover::before {
  background-color: rgba(38, 91, 60, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(403):hover::before {
  background-color: rgba(205, 16, 6, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(404):hover::before {
  background-color: rgba(221, 213, 211, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(405):hover::before {
  background-color: rgba(90, 67, 242, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(406):hover::before {
  background-color: rgba(108, 48, 215, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(407):hover::before {
  background-color: rgba(125, 43, 247, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(408):hover::before {
  background-color: rgba(139, 247, 220, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(409):hover::before {
  background-color: rgba(163, 200, 100, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(410):hover::before {
  background-color: rgba(193, 221, 203, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(411):hover::before {
  background-color: rgba(198, 16, 227, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(412):hover::before {
  background-color: rgba(195, 237, 23, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(413):hover::before {
  background-color: rgba(195, 170, 178, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(414):hover::before {
  background-color: rgba(36, 93, 234, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(415):hover::before {
  background-color: rgba(140, 83, 153, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(416):hover::before {
  background-color: rgba(78, 230, 160, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(417):hover::before {
  background-color: rgba(44, 229, 123, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(418):hover::before {
  background-color: rgba(99, 122, 5, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(419):hover::before {
  background-color: rgba(160, 206, 151, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(420):hover::before {
  background-color: rgba(241, 67, 98, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(421):hover::before {
  background-color: rgba(98, 9, 205, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(422):hover::before {
  background-color: rgba(147, 63, 228, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(423):hover::before {
  background-color: rgba(73, 241, 94, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(424):hover::before {
  background-color: rgba(252, 87, 79, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(425):hover::before {
  background-color: rgba(113, 70, 229, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(426):hover::before {
  background-color: rgba(35, 200, 148, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(427):hover::before {
  background-color: rgba(28, 59, 233, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(428):hover::before {
  background-color: rgba(217, 14, 217, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(429):hover::before {
  background-color: rgba(27, 229, 129, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(430):hover::before {
  background-color: rgba(110, 155, 125, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(431):hover::before {
  background-color: rgba(9, 108, 115, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(432):hover::before {
  background-color: rgba(192, 149, 118, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(433):hover::before {
  background-color: rgba(11, 249, 187, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(434):hover::before {
  background-color: rgba(99, 45, 225, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(435):hover::before {
  background-color: rgba(81, 194, 57, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(436):hover::before {
  background-color: rgba(43, 23, 51, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(437):hover::before {
  background-color: rgba(69, 47, 134, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(438):hover::before {
  background-color: rgba(250, 251, 99, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(439):hover::before {
  background-color: rgba(48, 226, 67, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(440):hover::before {
  background-color: rgba(249, 168, 250, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(441):hover::before {
  background-color: rgba(54, 145, 43, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(442):hover::before {
  background-color: rgba(175, 55, 160, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(443):hover::before {
  background-color: rgba(196, 48, 15, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(444):hover::before {
  background-color: rgba(164, 214, 26, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(445):hover::before {
  background-color: rgba(61, 213, 208, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(446):hover::before {
  background-color: rgba(107, 23, 116, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(447):hover::before {
  background-color: rgba(104, 0, 232, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(448):hover::before {
  background-color: rgba(101, 58, 35, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(449):hover::before {
  background-color: rgba(134, 173, 126, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(450):hover::before {
  background-color: rgba(60, 242, 138, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(451):hover::before {
  background-color: rgba(210, 182, 178, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(452):hover::before {
  background-color: rgba(67, 117, 124, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(453):hover::before {
  background-color: rgba(68, 151, 85, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(454):hover::before {
  background-color: rgba(55, 37, 122, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(455):hover::before {
  background-color: rgba(205, 165, 25, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(456):hover::before {
  background-color: rgba(196, 62, 104, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(457):hover::before {
  background-color: rgba(15, 153, 61, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(458):hover::before {
  background-color: rgba(233, 111, 146, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(459):hover::before {
  background-color: rgba(10, 33, 178, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(460):hover::before {
  background-color: rgba(81, 211, 205, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(461):hover::before {
  background-color: rgba(108, 71, 52, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(462):hover::before {
  background-color: rgba(223, 28, 246, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(463):hover::before {
  background-color: rgba(37, 216, 155, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(464):hover::before {
  background-color: rgba(186, 188, 1, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(465):hover::before {
  background-color: rgba(130, 145, 162, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(466):hover::before {
  background-color: rgba(225, 162, 204, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(467):hover::before {
  background-color: rgba(3, 176, 219, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(468):hover::before {
  background-color: rgba(81, 170, 197, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(469):hover::before {
  background-color: rgba(25, 139, 66, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(470):hover::before {
  background-color: rgba(247, 118, 95, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(471):hover::before {
  background-color: rgba(103, 168, 135, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(472):hover::before {
  background-color: rgba(121, 178, 246, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(473):hover::before {
  background-color: rgba(186, 61, 187, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(474):hover::before {
  background-color: rgba(238, 49, 229, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(475):hover::before {
  background-color: rgba(89, 152, 111, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(476):hover::before {
  background-color: rgba(156, 196, 130, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(477):hover::before {
  background-color: rgba(32, 79, 198, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(478):hover::before {
  background-color: rgba(251, 85, 196, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(479):hover::before {
  background-color: rgba(130, 130, 126, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(480):hover::before {
  background-color: rgba(74, 245, 252, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(481):hover::before {
  background-color: rgba(242, 244, 197, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(482):hover::before {
  background-color: rgba(167, 5, 249, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(483):hover::before {
  background-color: rgba(128, 44, 133, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(484):hover::before {
  background-color: rgba(236, 69, 110, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(485):hover::before {
  background-color: rgba(73, 217, 11, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(486):hover::before {
  background-color: rgba(171, 54, 82, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(487):hover::before {
  background-color: rgba(198, 65, 184, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(488):hover::before {
  background-color: rgba(204, 2, 107, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(489):hover::before {
  background-color: rgba(13, 181, 101, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(490):hover::before {
  background-color: rgba(128, 218, 33, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(491):hover::before {
  background-color: rgba(239, 102, 184, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(492):hover::before {
  background-color: rgba(49, 196, 118, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(493):hover::before {
  background-color: rgba(141, 237, 87, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(494):hover::before {
  background-color: rgba(46, 60, 253, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(495):hover::before {
  background-color: rgba(7, 109, 137, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(496):hover::before {
  background-color: rgba(196, 140, 251, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(497):hover::before {
  background-color: rgba(84, 227, 216, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(498):hover::before {
  background-color: rgba(246, 125, 176, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(499):hover::before {
  background-color: rgba(98, 166, 162, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(500):hover::before {
  background-color: rgba(103, 252, 168, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(501):hover::before {
  background-color: rgba(4, 59, 134, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(502):hover::before {
  background-color: rgba(57, 161, 202, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(503):hover::before {
  background-color: rgba(8, 86, 146, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(504):hover::before {
  background-color: rgba(102, 24, 95, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(505):hover::before {
  background-color: rgba(116, 191, 188, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(506):hover::before {
  background-color: rgba(252, 231, 70, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(507):hover::before {
  background-color: rgba(5, 208, 165, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(508):hover::before {
  background-color: rgba(180, 240, 2, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(509):hover::before {
  background-color: rgba(131, 168, 161, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(510):hover::before {
  background-color: rgba(145, 90, 134, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(511):hover::before {
  background-color: rgba(225, 193, 219, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(512):hover::before {
  background-color: rgba(111, 223, 147, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(513):hover::before {
  background-color: rgba(254, 202, 119, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(514):hover::before {
  background-color: rgba(9, 128, 177, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(515):hover::before {
  background-color: rgba(161, 118, 209, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(516):hover::before {
  background-color: rgba(211, 92, 234, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(517):hover::before {
  background-color: rgba(13, 50, 95, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(518):hover::before {
  background-color: rgba(229, 175, 19, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(519):hover::before {
  background-color: rgba(180, 170, 185, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(520):hover::before {
  background-color: rgba(27, 207, 63, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(521):hover::before {
  background-color: rgba(166, 198, 128, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(522):hover::before {
  background-color: rgba(209, 200, 126, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(523):hover::before {
  background-color: rgba(42, 186, 206, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(524):hover::before {
  background-color: rgba(8, 1, 58, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(525):hover::before {
  background-color: rgba(140, 227, 40, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(526):hover::before {
  background-color: rgba(16, 179, 177, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(527):hover::before {
  background-color: rgba(185, 202, 247, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(528):hover::before {
  background-color: rgba(156, 222, 170, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(529):hover::before {
  background-color: rgba(241, 232, 124, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(530):hover::before {
  background-color: rgba(205, 236, 102, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(531):hover::before {
  background-color: rgba(221, 66, 238, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(532):hover::before {
  background-color: rgba(130, 44, 99, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(533):hover::before {
  background-color: rgba(79, 32, 213, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(534):hover::before {
  background-color: rgba(65, 204, 118, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(535):hover::before {
  background-color: rgba(101, 133, 43, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(536):hover::before {
  background-color: rgba(224, 7, 2, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(537):hover::before {
  background-color: rgba(149, 245, 20, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(538):hover::before {
  background-color: rgba(43, 142, 110, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(539):hover::before {
  background-color: rgba(148, 174, 218, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(540):hover::before {
  background-color: rgba(6, 91, 252, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(541):hover::before {
  background-color: rgba(215, 23, 5, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(542):hover::before {
  background-color: rgba(241, 46, 177, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(543):hover::before {
  background-color: rgba(88, 206, 140, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(544):hover::before {
  background-color: rgba(94, 3, 17, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(545):hover::before {
  background-color: rgba(218, 57, 193, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(546):hover::before {
  background-color: rgba(44, 20, 54, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(547):hover::before {
  background-color: rgba(64, 143, 16, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(548):hover::before {
  background-color: rgba(244, 75, 103, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(549):hover::before {
  background-color: rgba(82, 167, 95, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(550):hover::before {
  background-color: rgba(130, 2, 212, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(551):hover::before {
  background-color: rgba(193, 185, 67, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(552):hover::before {
  background-color: rgba(128, 56, 159, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(553):hover::before {
  background-color: rgba(67, 129, 177, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(554):hover::before {
  background-color: rgba(196, 70, 168, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(555):hover::before {
  background-color: rgba(254, 130, 131, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(556):hover::before {
  background-color: rgba(176, 117, 139, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(557):hover::before {
  background-color: rgba(132, 125, 121, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(558):hover::before {
  background-color: rgba(158, 23, 130, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(559):hover::before {
  background-color: rgba(14, 1, 43, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(560):hover::before {
  background-color: rgba(202, 97, 250, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(561):hover::before {
  background-color: rgba(58, 82, 111, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(562):hover::before {
  background-color: rgba(195, 88, 45, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(563):hover::before {
  background-color: rgba(74, 4, 84, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(564):hover::before {
  background-color: rgba(188, 28, 70, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(565):hover::before {
  background-color: rgba(243, 15, 135, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(566):hover::before {
  background-color: rgba(18, 174, 27, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(567):hover::before {
  background-color: rgba(125, 251, 38, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(568):hover::before {
  background-color: rgba(192, 20, 47, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(569):hover::before {
  background-color: rgba(130, 218, 82, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(570):hover::before {
  background-color: rgba(39, 57, 224, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(571):hover::before {
  background-color: rgba(80, 158, 192, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(572):hover::before {
  background-color: rgba(118, 194, 186, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(573):hover::before {
  background-color: rgba(202, 104, 127, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(574):hover::before {
  background-color: rgba(55, 75, 87, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(575):hover::before {
  background-color: rgba(126, 122, 69, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(576):hover::before {
  background-color: rgba(144, 113, 75, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(577):hover::before {
  background-color: rgba(2, 167, 23, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(578):hover::before {
  background-color: rgba(146, 238, 18, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(579):hover::before {
  background-color: rgba(251, 102, 4, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(580):hover::before {
  background-color: rgba(115, 162, 208, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(581):hover::before {
  background-color: rgba(62, 146, 199, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(582):hover::before {
  background-color: rgba(81, 92, 70, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(583):hover::before {
  background-color: rgba(232, 142, 159, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(584):hover::before {
  background-color: rgba(165, 137, 235, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(585):hover::before {
  background-color: rgba(20, 156, 24, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(586):hover::before {
  background-color: rgba(81, 140, 147, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(587):hover::before {
  background-color: rgba(239, 16, 143, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(588):hover::before {
  background-color: rgba(36, 189, 237, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(589):hover::before {
  background-color: rgba(61, 66, 179, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(590):hover::before {
  background-color: rgba(25, 165, 148, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(591):hover::before {
  background-color: rgba(215, 152, 138, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(592):hover::before {
  background-color: rgba(122, 97, 19, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(593):hover::before {
  background-color: rgba(87, 135, 147, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(594):hover::before {
  background-color: rgba(245, 82, 178, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(595):hover::before {
  background-color: rgba(42, 52, 26, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(596):hover::before {
  background-color: rgba(201, 234, 134, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(597):hover::before {
  background-color: rgba(8, 66, 219, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(598):hover::before {
  background-color: rgba(52, 158, 101, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(599):hover::before {
  background-color: rgba(178, 107, 44, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(600):hover::before {
  background-color: rgba(72, 196, 160, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(601):hover::before {
  background-color: rgba(81, 85, 241, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(602):hover::before {
  background-color: rgba(73, 125, 212, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(603):hover::before {
  background-color: rgba(75, 81, 151, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(604):hover::before {
  background-color: rgba(106, 124, 97, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(605):hover::before {
  background-color: rgba(163, 2, 151, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(606):hover::before {
  background-color: rgba(11, 229, 207, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(607):hover::before {
  background-color: rgba(175, 1, 15, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(608):hover::before {
  background-color: rgba(13, 132, 118, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(609):hover::before {
  background-color: rgba(47, 18, 169, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(610):hover::before {
  background-color: rgba(11, 67, 14, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(611):hover::before {
  background-color: rgba(83, 157, 166, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(612):hover::before {
  background-color: rgba(25, 30, 206, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(613):hover::before {
  background-color: rgba(141, 130, 52, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(614):hover::before {
  background-color: rgba(218, 239, 104, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(615):hover::before {
  background-color: rgba(151, 243, 94, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(616):hover::before {
  background-color: rgba(44, 192, 212, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(617):hover::before {
  background-color: rgba(156, 153, 120, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(618):hover::before {
  background-color: rgba(242, 211, 122, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(619):hover::before {
  background-color: rgba(155, 9, 81, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(620):hover::before {
  background-color: rgba(225, 107, 52, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(621):hover::before {
  background-color: rgba(236, 79, 15, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(622):hover::before {
  background-color: rgba(195, 91, 129, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(623):hover::before {
  background-color: rgba(127, 6, 79, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(624):hover::before {
  background-color: rgba(29, 36, 167, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(625):hover::before {
  background-color: rgba(164, 151, 223, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(626):hover::before {
  background-color: rgba(228, 49, 220, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(627):hover::before {
  background-color: rgba(174, 209, 123, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(628):hover::before {
  background-color: rgba(158, 227, 188, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(629):hover::before {
  background-color: rgba(214, 118, 34, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(630):hover::before {
  background-color: rgba(135, 139, 53, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(631):hover::before {
  background-color: rgba(75, 83, 217, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(632):hover::before {
  background-color: rgba(213, 242, 166, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(633):hover::before {
  background-color: rgba(188, 10, 104, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(634):hover::before {
  background-color: rgba(243, 217, 162, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(635):hover::before {
  background-color: rgba(144, 129, 254, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(636):hover::before {
  background-color: rgba(3, 18, 113, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(637):hover::before {
  background-color: rgba(109, 199, 61, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(638):hover::before {
  background-color: rgba(228, 120, 26, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(639):hover::before {
  background-color: rgba(209, 33, 167, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(640):hover::before {
  background-color: rgba(233, 82, 188, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(641):hover::before {
  background-color: rgba(242, 126, 234, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(642):hover::before {
  background-color: rgba(204, 118, 155, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(643):hover::before {
  background-color: rgba(147, 151, 154, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(644):hover::before {
  background-color: rgba(215, 124, 45, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(645):hover::before {
  background-color: rgba(205, 226, 25, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(646):hover::before {
  background-color: rgba(21, 237, 143, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(647):hover::before {
  background-color: rgba(190, 196, 78, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(648):hover::before {
  background-color: rgba(250, 128, 136, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(649):hover::before {
  background-color: rgba(45, 218, 25, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(650):hover::before {
  background-color: rgba(203, 18, 115, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(651):hover::before {
  background-color: rgba(34, 252, 137, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(652):hover::before {
  background-color: rgba(87, 74, 214, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(653):hover::before {
  background-color: rgba(42, 122, 192, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(654):hover::before {
  background-color: rgba(175, 177, 131, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(655):hover::before {
  background-color: rgba(248, 180, 59, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(656):hover::before {
  background-color: rgba(164, 178, 249, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(657):hover::before {
  background-color: rgba(76, 251, 34, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(658):hover::before {
  background-color: rgba(34, 178, 158, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(659):hover::before {
  background-color: rgba(69, 149, 83, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(660):hover::before {
  background-color: rgba(15, 158, 241, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(661):hover::before {
  background-color: rgba(122, 3, 50, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(662):hover::before {
  background-color: rgba(171, 251, 245, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(663):hover::before {
  background-color: rgba(125, 30, 12, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(664):hover::before {
  background-color: rgba(185, 15, 189, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(665):hover::before {
  background-color: rgba(195, 134, 229, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(666):hover::before {
  background-color: rgba(142, 110, 22, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(667):hover::before {
  background-color: rgba(227, 69, 69, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(668):hover::before {
  background-color: rgba(136, 228, 169, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(669):hover::before {
  background-color: rgba(12, 252, 215, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(670):hover::before {
  background-color: rgba(11, 34, 101, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(671):hover::before {
  background-color: rgba(43, 66, 126, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(672):hover::before {
  background-color: rgba(179, 110, 198, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(673):hover::before {
  background-color: rgba(85, 94, 167, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(674):hover::before {
  background-color: rgba(222, 160, 187, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(675):hover::before {
  background-color: rgba(37, 58, 217, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(676):hover::before {
  background-color: rgba(148, 139, 251, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(677):hover::before {
  background-color: rgba(2, 195, 83, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(678):hover::before {
  background-color: rgba(97, 212, 57, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(679):hover::before {
  background-color: rgba(213, 138, 218, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(680):hover::before {
  background-color: rgba(226, 89, 98, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(681):hover::before {
  background-color: rgba(55, 139, 103, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(682):hover::before {
  background-color: rgba(49, 33, 131, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(683):hover::before {
  background-color: rgba(168, 27, 254, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(684):hover::before {
  background-color: rgba(183, 124, 215, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(685):hover::before {
  background-color: rgba(5, 172, 162, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(686):hover::before {
  background-color: rgba(39, 1, 153, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(687):hover::before {
  background-color: rgba(119, 26, 234, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(688):hover::before {
  background-color: rgba(237, 130, 48, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(689):hover::before {
  background-color: rgba(64, 202, 40, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(690):hover::before {
  background-color: rgba(123, 219, 62, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(691):hover::before {
  background-color: rgba(194, 217, 8, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(692):hover::before {
  background-color: rgba(167, 12, 57, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(693):hover::before {
  background-color: rgba(11, 85, 119, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(694):hover::before {
  background-color: rgba(121, 38, 40, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(695):hover::before {
  background-color: rgba(189, 240, 12, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(696):hover::before {
  background-color: rgba(62, 230, 87, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(697):hover::before {
  background-color: rgba(66, 193, 69, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(698):hover::before {
  background-color: rgba(220, 129, 26, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(699):hover::before {
  background-color: rgba(210, 219, 184, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(700):hover::before {
  background-color: rgba(226, 26, 238, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(701):hover::before {
  background-color: rgba(128, 107, 206, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(702):hover::before {
  background-color: rgba(67, 65, 114, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(703):hover::before {
  background-color: rgba(96, 146, 136, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(704):hover::before {
  background-color: rgba(247, 91, 213, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(705):hover::before {
  background-color: rgba(50, 190, 88, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(706):hover::before {
  background-color: rgba(217, 123, 99, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(707):hover::before {
  background-color: rgba(60, 232, 57, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(708):hover::before {
  background-color: rgba(138, 102, 3, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(709):hover::before {
  background-color: rgba(20, 254, 168, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(710):hover::before {
  background-color: rgba(164, 19, 91, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(711):hover::before {
  background-color: rgba(34, 20, 207, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(712):hover::before {
  background-color: rgba(166, 155, 183, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(713):hover::before {
  background-color: rgba(237, 74, 73, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(714):hover::before {
  background-color: rgba(179, 78, 162, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(715):hover::before {
  background-color: rgba(133, 215, 51, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(716):hover::before {
  background-color: rgba(222, 60, 222, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(717):hover::before {
  background-color: rgba(21, 69, 143, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(718):hover::before {
  background-color: rgba(253, 241, 49, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(719):hover::before {
  background-color: rgba(207, 114, 51, 0.8);
}
.oos-recruitment-us .team-building .team-building-box .bubble-content .bubble:nth-child(720):hover::before {
  background-color: rgba(151, 175, 113, 0.8);
}
@media screen and (max-width: 1400px) {
  .oos-recruitment-us .team-building .team-building-box .bubble-content {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .oos-recruitment-us .team-building {
    width: 1000px;
  }
  .oos-recruitment-us .team-building .desc {
    text-align: center;
    font-size: 14px;
  }
  .oos-recruitment-us .team-building .team-building-box {
    margin: 50px auto 0;
    width: 990px;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item {
    min-width: 330px;
    height: 220px;
  }
}
@media screen and (max-width: 768px) {
  .oos-recruitment-us .team-building {
    padding: 0 20px;
    width: 100%;
  }
  .oos-recruitment-us .team-building .team-building-box {
    margin: 40px auto 0;
    width: 720px;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item {
    min-width: 360px;
    height: 240px;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(1) {
    border-top-left-radius: 5px;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(2) {
    border-top-right-radius: 5px;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(3) {
    border-top-right-radius: unset;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(4) {
    border-bottom-left-radius: unset;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(5) {
    border-bottom-left-radius: 5px;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(6) {
    border-bottom-right-radius: 5px;
  }
}
@media screen and (max-width: 450px) {
  .oos-recruitment-us .team-building .team-building-box {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item {
    margin: 20px auto 0;
    min-width: 320px;
    width: 320px;
    height: 213px;
    border-radius: 5px;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(1) {
    border-top-left-radius: unset;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(2) {
    border-top-right-radius: unset;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(3) {
    border-top-right-radius: unset;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(4) {
    border-bottom-left-radius: unset;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(5) {
    border-bottom-left-radius: unset;
  }
  .oos-recruitment-us .team-building .team-building-box .team-building-item:nth-child(6) {
    border-bottom-right-radius: unset;
  }
}
.oos-recruitment-us .benefit {
  margin: 0 auto;
  width: 1200px;
}
.oos-recruitment-us .benefit .benefit-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  height: 400px;
  text-align: center;
}
.oos-recruitment-us .benefit .benefit-box .benefit-item {
  padding: 30px 0;
  width: 330px;
  height: 180px;
  text-align: center;
  background: linear-gradient(to bottom right, #f0f6ff, #fff);
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0 rgba(116, 130, 166, 0.2);
}
.oos-recruitment-us .benefit .benefit-box .benefit-item .img {
  display: inline-block;
  width: 80px;
  height: 80px;
  background-size: 100% 100%;
}
.oos-recruitment-us .benefit .benefit-box .benefit-item .img.insurance {
  background-image: url("../images/about/recruitment/insurance.webp");
}
.oos-recruitment-us .benefit .benefit-box .benefit-item .img.afternoon-tea {
  background-image: url("../images/about/recruitment/afternoon_tea.webp");
}
.oos-recruitment-us .benefit .benefit-box .benefit-item .img.birthday-party {
  background-image: url("../images/about/recruitment/birthday_party.webp");
}
.oos-recruitment-us .benefit .benefit-box .benefit-item .img.regular-team-building {
  background-image: url("../images/about/recruitment/regular_team_building.webp");
}
.oos-recruitment-us .benefit .benefit-box .benefit-item .img.dormitory {
  background-image: url("../images/about/recruitment/dormitory.webp");
}
.oos-recruitment-us .benefit .benefit-box .benefit-item .img.restaurant {
  background-image: url("../images/about/recruitment/restaurant.webp");
}
.oos-recruitment-us .benefit .benefit-box .benefit-item .title {
  padding-top: 16px;
  font-size: 16px;
  color: #2b3744;
  letter-spacing: 2px;
}
@media screen and (max-width: 1200px) {
  .oos-recruitment-us .benefit {
    width: 1000px;
  }
  .oos-recruitment-us .benefit .benefit-box .benefit-item {
    width: 300px;
    height: 180px;
  }
}
@media screen and (max-width: 768px) {
  .oos-recruitment-us .benefit {
    padding: 0 20px;
    width: 100%;
  }
  .oos-recruitment-us .benefit .benefit-box {
    min-width: 720px;
  }
  .oos-recruitment-us .benefit .benefit-box .benefit-item {
    width: 220px;
    height: 180px;
  }
}
@media screen and (max-width: 450px) {
  .oos-recruitment-us .benefit .benefit-box {
    width: 100%;
    min-width: 100%;
    height: auto;
  }
  .oos-recruitment-us .benefit .benefit-box .benefit-item {
    margin: 20px auto 0;
    padding: 16px 0;
    width: 150px;
    height: 120px;
  }
  .oos-recruitment-us .benefit .benefit-box .benefit-item .img {
    width: 50px;
    height: 50px;
  }
  .oos-recruitment-us .benefit .benefit-box .benefit-item .title {
    font-size: 14px;
  }
}
.oos-recruitment-us .recruitment {
  margin: 0 auto;
  width: 1200px;
}
.oos-recruitment-us .recruitment .recruitment-table-content {
  min-height: 700px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title {
  position: relative;
  padding: 0 30px;
  height: 60px;
  line-height: 60px;
  background-color: #f2f2f2;
  border: 1px solid #d8d7d7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-label {
  float: right;
  height: 58px;
  line-height: 58px;
  font-size: 13px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box {
  float: right;
  margin-top: 11px;
  display: inline-block;
  vertical-align: middle;
  min-width: 138px;
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  color: #3c3d3c;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box:hover > .department-filter-item {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box:hover > .department-filter-item::before {
  top: 9px;
  border-top: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #4c8ffb;
  border-left: 6px solid transparent;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box:hover .department-filter-dropdown-box {
  display: block;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box .department-filter-item {
  padding: 0 15px;
  height: 36px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box > .department-filter-item {
  position: relative;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box > .department-filter-item::before {
  content: " ";
  position: absolute;
  top: 16px;
  right: 15px;
  border-top: 6px solid #474747;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box .department-filter-dropdown-box {
  display: none;
  position: relative;
  background-color: #fff;
  z-index: 1;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box .department-filter-dropdown-box .department-filter-item {
  border: 1px solid #c2c2c2;
  border-top: none;
  cursor: pointer;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box .department-filter-dropdown-box .department-filter-item:nth-last-child(1) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title .department-filter-box .department-filter-dropdown-box .department-filter-item:hover {
  color: #4c8ffb;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header {
  padding: 0 30px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  background-color: #f6f6f6;
  border: 1px solid #d8d7d7;
  border-top: none;
  overflow: hidden;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column {
  float: left;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #5f5f5f;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column.recruitment-title {
  width: calc(100% - 300px - 260px);
  text-align: left;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column.recruitment-number {
  width: 300px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column.actions {
  width: 260px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row {
  padding: 0 30px;
  font-size: 14px;
  color: #696969;
  background-color: #fff;
  border: 1px solid #d8d7d7;
  border-top: none;
  overflow: hidden;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row:hover {
  background-color: #fbfbfb;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row:nth-last-child(1) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row.show-detail .recruitment-detail-info {
  display: block;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info {
  height: 80px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column {
  float: left;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.recruitment-title {
  width: calc(100% - 300px - 260px);
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.recruitment-title .newest-tag {
  display: inline-block;
  padding: 0 6px;
  vertical-align: 1px;
  height: 18px;
  line-height: 18px;
  text-indent: 0;
  font-size: 12px;
  color: #fff;
  background-color: #3d86fe;
  border-radius: 16px;
  transform: scale(0.8);
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.recruitment-number {
  width: 300px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions {
  width: 260px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button {
  width: 120px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #3e3e3e;
  letter-spacing: 1px;
  user-select: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  background: linear-gradient(to bottom, #fafafa, #f9f9f9, #f8f8f8, #f7f7f7, #f6f6f6, #f5f5f5, #f4f4f4, #f3f3f3);
  cursor: pointer;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon.disabled,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon.ph,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-download-icon,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-download-icon.disabled,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-apply-icon,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-apply-icon.disabled,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover .oos-button-link-icon:not(.disabled),
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover .oos-button-download-icon:not(.disabled),
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover .oos-button-apply-icon:not(.disabled),
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-link-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-download-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-download-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-view-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button:hover {
  background-color: #fff;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button.disabled,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button.disabled {
  color: #c3c3c3;
  cursor: not-allowed;
  border: 1px solid #d8d7d7;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button {
  margin-left: 20px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info {
  display: none;
  border-top: 1px solid #d8d7d7;
  overflow: hidden;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .recruitment-title {
  height: 80px;
  line-height: 80px;
  font-size: 16px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .recruitment-content {
  margin-bottom: 50px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: #d5def2;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  background-color: #2a3957;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-link-icon,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-link-icon {
  display: inline-block;
  transition: transform 0.2s linear;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-link-icon.disabled,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-link-icon.disabled {
  color: #bfbfbf;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-link-icon.ph,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-link-icon.ph {
  padding-left: 5px;
  transition: none !important;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-download-icon,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-download-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/technicalSupport/download.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-download-icon.disabled,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-download-icon.disabled {
  background-image: url("../images/technicalSupport/download_disabled.svg");
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-apply-icon,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-apply-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
  width: 12px;
  height: 12px;
  background-image: url("../images/about/recruitment/apply.svg");
  background-size: 100% 100%;
  transition: transform 0.2s linear;
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-apply-icon.disabled,
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-apply-icon.disabled {
  background-image: url("../images/about/recruitment/apply_disabled.svg");
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button:hover .oos-button-link-icon:not(.disabled),
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button:hover .oos-button-link-icon:not(.disabled) {
  transform: translateX(4px);
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button:hover .oos-button-download-icon:not(.disabled),
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button:hover .oos-button-download-icon:not(.disabled) {
  transform: translateY(2px);
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button:hover .oos-button-apply-icon:not(.disabled),
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button:hover .oos-button-apply-icon:not(.disabled) {
  transform: translateY(2px);
}
@media screen and (max-width: 450px) {
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-link-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-link-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-download-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-download-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button .oos-button-view-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .apply-job-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-detail-info .button-box .view-detail-button {
  margin-right: 20px;
}
.oos-recruitment-us .recruitment .recruitment-table-content .empty-text {
  height: 60px;
  line-height: 60px;
  font-size: 12px;
  text-align: center;
  color: #888;
  border: 1px solid #ededed;
  border-top: none;
}
@media screen and (max-width: 1200px) {
  .oos-recruitment-us .recruitment {
    width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .oos-recruitment-us .recruitment {
    padding: 0 20px;
    width: 100%;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content {
    min-height: auto;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column.recruitment-title {
    width: calc(100% - 140px - 220px);
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column.recruitment-number {
    width: 140px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column.actions {
    width: 220px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info {
    height: 60px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column {
    height: 60px;
    line-height: 60px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.recruitment-title {
    width: calc(100% - 140px - 220px);
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.recruitment-number {
    width: 140px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions {
    width: 220px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button {
    width: 100px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: #3e3e3e;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-link-icon {
    display: inline-block;
    transition: transform 0.2s linear;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon.disabled,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-link-icon.disabled {
    color: #bfbfbf;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon.ph,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-link-icon.ph {
    padding-left: 5px;
    transition: none !important;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-download-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-download-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/technicalSupport/download.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-download-icon.disabled,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-download-icon.disabled {
    background-image: url("../images/technicalSupport/download_disabled.svg");
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-apply-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-apply-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/about/recruitment/apply.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-apply-icon.disabled,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-apply-icon.disabled {
    background-image: url("../images/about/recruitment/apply_disabled.svg");
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover .oos-button-link-icon:not(.disabled),
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button:hover .oos-button-link-icon:not(.disabled) {
    transform: translateX(4px);
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover .oos-button-download-icon:not(.disabled),
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button:hover .oos-button-download-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover .oos-button-apply-icon:not(.disabled),
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button:hover .oos-button-apply-icon:not(.disabled) {
    transform: translateY(2px);
  }
}
@media screen and (max-width: 768px) and screen and (max-width: 450px) {
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-link-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-download-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-download-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-view-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button .oos-button-view-icon {
    transform: none !important;
  }
}
@media screen and (max-width: 450px) {
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-title {
    padding: 0 10px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header {
    padding: 0 10px;
    height: 30px;
    font-size: 12px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column {
    height: 30px;
    line-height: 30px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column.recruitment-title {
    width: calc(100% - 70px - 30px);
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column.recruitment-number {
    width: 70px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-header .header-column.actions {
    width: 30px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row {
    padding: 0 10px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info {
    height: 40px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.recruitment-title {
    width: calc(100% - 70px - 30px);
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.recruitment-number {
    width: 70px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions {
    width: 30px;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: #3e3e3e;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    border-radius: 100%;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon {
    display: inline-block;
    transition: transform 0.2s linear;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon.disabled {
    color: #bfbfbf;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon.ph {
    padding-left: 5px;
    transition: none !important;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-download-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/technicalSupport/download.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-download-icon.disabled {
    background-image: url("../images/technicalSupport/download_disabled.svg");
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-apply-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: -1px;
    width: 12px;
    height: 12px;
    background-image: url("../images/about/recruitment/apply.svg");
    background-size: 100% 100%;
    transition: transform 0.2s linear;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-apply-icon.disabled {
    background-image: url("../images/about/recruitment/apply_disabled.svg");
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover .oos-button-link-icon:not(.disabled) {
    transform: translateX(4px);
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover .oos-button-download-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button:hover .oos-button-apply-icon:not(.disabled) {
    transform: translateY(2px);
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .button-text {
    display: none;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon {
    margin-left: 0;
  }
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .apply-job-button {
    display: none;
  }
}
@media screen and (max-width: 450px) and screen and (max-width: 450px) {
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-link-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-download-icon,
  .oos-recruitment-us .recruitment .recruitment-table-content .recruitment-table-row .recruitment-info .row-column.actions .view-detail-button .oos-button-view-icon {
    transform: none !important;
  }
}
.oos-contact-us > div:nth-last-child(1) {
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .oos-contact-us > div:nth-last-child(1) {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .oos-contact-us > div:nth-last-child(1) {
    margin-bottom: 30px;
  }
}
.oos-contact-us .banner {
  position: relative;
  padding-top: 60px;
  height: 450px;
  background-color: #2b3a58;
  overflow: hidden;
}
.oos-contact-us .banner .banner-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.oos-contact-us .banner .banner-content .banner-title {
  padding-top: 120px;
  width: 700px;
}
.oos-contact-us .banner .banner-content .banner-title .title {
  font-size: 46px;
  font-weight: 600;
  color: #d5def2;
  letter-spacing: 2px;
}
.oos-contact-us .banner .banner-content .banner-title .sub-title {
  padding-top: 15px;
  font-size: 14px;
  color: #9ca8c5;
  letter-spacing: 1px;
}
.oos-contact-us .banner .banner-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  height: 450px;
}
.oos-contact-us .banner .banner-bg-img:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1002px;
  height: 450px;
  background-image: url("../images/normalBannerAnim/bg.webp");
  background-size: 100% 100%;
  z-index: 0;
}
@media screen and (max-width: 1800px) {
  .oos-contact-us .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 1700px) {
  .oos-contact-us .banner .banner-content {
    width: 1400px;
  }
  .oos-contact-us .banner .banner-content .banner-title {
    width: 600px;
  }
  .oos-contact-us .banner .banner-content .banner-title .title {
    font-size: 36px;
  }
  .oos-contact-us .banner .banner-bg-img {
    right: -111px;
  }
}
@media screen and (max-width: 1400px) {
  .oos-contact-us .banner .banner-content {
    width: 1200px;
  }
  .oos-contact-us .banner .banner-content .banner-title {
    padding-top: 120px;
    width: 480px;
  }
  .oos-contact-us .banner .banner-content .banner-title .title {
    font-size: 28px;
  }
  .oos-contact-us .banner .banner-content .banner-title .sub-title {
    font-size: 13px;
  }
  .oos-contact-us .banner .banner-bg-img {
    right: -68px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-contact-us .banner .banner-content {
    width: 1000px;
  }
  .oos-contact-us .banner .banner-content .banner-title .title {
    font-size: 24px;
  }
  .oos-contact-us .banner .banner-bg-img {
    right: -60px;
  }
}
@media screen and (max-width: 768px) {
  .oos-contact-us .banner {
    height: auto;
  }
  .oos-contact-us .banner .banner-content {
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .oos-contact-us .banner .banner-content .banner-title {
    padding: 15px 20px;
    width: 400px;
  }
  .oos-contact-us .banner .banner-content .banner-title .sub-title {
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
  }
  .oos-contact-us .banner .banner-content .normal-banner-anim-base-img {
    display: none;
  }
  .oos-contact-us .banner .banner-content .normal-banner-static-img-pad-ph {
    display: block;
  }
  .oos-contact-us .banner .banner-bg-img {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .oos-contact-us .banner .banner-content {
    padding-top: 0;
    display: block;
  }
  .oos-contact-us .banner .banner-content .banner-title {
    padding: 30px 20px 30px;
    width: 100%;
    min-width: 100%;
    text-align: center;
  }
  .oos-contact-us .banner .banner-content .banner-title .title {
    font-size: 18px;
  }
  .oos-contact-us .banner .banner-content .normal-banner-static-img-pad-ph {
    margin: 0 auto;
    width: 300px;
    height: 170px;
  }
}
.oos-contact-us .section-title {
  padding: 60px 0 30px;
  text-align: center;
  color: #212d40;
}
.oos-contact-us .section-title.gray-bg {
  background-color: #f7f8f9;
}
.oos-contact-us .section-title .title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}
.oos-contact-us .section-title .sub-title {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .oos-contact-us .section-title {
    padding: 22px 20px 18px;
  }
  .oos-contact-us .section-title .title {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-contact-us .section-title .title {
    font-size: 18px;
  }
}
.oos-contact-us .contact {
  margin: 20px auto 10px;
  width: 1400px;
}
.oos-contact-us .contact .contact-box {
  margin: 0 auto;
  display: flex;
  width: 1200px;
  flex-direction: row;
  justify-content: space-between;
}
.oos-contact-us .contact .contact-box .contact-item {
  padding: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 550px;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0 rgba(116, 130, 166, 0.2);
}
.oos-contact-us .contact .contact-box .contact-item .icon {
  background-size: 100% 100%;
}
.oos-contact-us .contact .contact-box .contact-item .icon.phone {
  width: 40px;
  height: 50px;
  background-image: url("../images/about/contactUs/phone.webp");
}
.oos-contact-us .contact .contact-box .contact-item .icon.email {
  width: 50px;
  height: 36px;
  background-image: url("../images/about/contactUs/email.webp");
}
.oos-contact-us .contact .contact-box .contact-item .desc {
  padding-left: 30px;
}
.oos-contact-us .contact .contact-box .contact-item .desc .title {
  font-size: 22px;
  color: #2b3744;
}
.oos-contact-us .contact .contact-box .contact-item .desc .text {
  margin-top: 5px;
  font-size: 24px;
  color: #54616e;
}
@media screen and (max-width: 1400px) {
  .oos-contact-us .contact {
    width: 1200px;
  }
  .oos-contact-us .contact .contact-box {
    width: 1200px;
  }
  .oos-contact-us .contact .contact-box .contact-item {
    width: 550px;
  }
}
@media screen and (max-width: 1200px) {
  .oos-contact-us .contact {
    width: 1000px;
  }
  .oos-contact-us .contact .contact-box {
    width: 1000px;
  }
  .oos-contact-us .contact .contact-box .contact-item {
    width: 450px;
  }
}
@media screen and (max-width: 768px) {
  .oos-contact-us .contact {
    margin: 20px auto 0;
    padding: 0 20px;
    width: 100%;
  }
  .oos-contact-us .contact .contact-box {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .oos-contact-us .contact .contact-box .contact-item {
    width: 450px;
  }
  .oos-contact-us .contact .contact-box .contact-item:nth-last-child(1) {
    margin-top: 30px;
  }
  .oos-contact-us .contact .contact-box .contact-item .desc .title {
    font-size: 18px;
  }
  .oos-contact-us .contact .contact-box .contact-item .desc .text {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .oos-contact-us .contact {
    margin: 0 auto 0;
  }
  .oos-contact-us .contact .contact-box .contact-item {
    padding: 40px 0;
    width: 100%;
  }
  .oos-contact-us .contact .contact-box .contact-item:nth-last-child(1) {
    margin-top: 20px;
  }
  .oos-contact-us .contact .contact-box .contact-item .desc .title {
    font-size: 16px;
  }
  .oos-contact-us .contact .contact-box .contact-item .desc .text {
    font-size: 18px;
  }
}
.oos-contact-us .address {
  margin: 20px auto 0;
  width: 1400px;
}
.oos-contact-us .address .address-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.oos-contact-us .address .address-box .address-item {
  position: relative;
  width: 330px;
  height: 454px;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0 rgba(116, 130, 166, 0.2);
  transition: transform 0.5s linear;
}
.oos-contact-us .address .address-box .address-item:hover {
  transform: translateY(-5px);
}
.oos-contact-us .address .address-box .address-item .text-content {
  padding: 30px 25px;
  height: 234px;
}
.oos-contact-us .address .address-box .address-item .text-content .title {
  padding-bottom: 20px;
  font-size: 16px;
  color: #2b3744;
}
.oos-contact-us .address .address-box .address-item .text-content .service,
.oos-contact-us .address .address-box .address-item .text-content .it-support,
.oos-contact-us .address .address-box .address-item .text-content .postal-code,
.oos-contact-us .address .address-box .address-item .text-content .location {
  line-height: 26px;
  font-size: 14px;
  color: #54616e;
}
.oos-contact-us .address .address-box .address-item .map {
  width: 100%;
  height: 220px;
  overflow: hidden;
}
.oos-contact-us .address .address-box .address-item .map iframe {
  width: 100%;
  height: 220px;
  border: none;
}
.oos-contact-us .address .address-box .address-item .map.boston-center-placeholder {
  background-image: url("../images/about/contactUs/boston_rd_center_map.webp");
  background-size: 100% 100%;
}
@media screen and (max-width: 1500px) {
  .oos-contact-us .address {
    width: 1200px;
  }
  .oos-contact-us .address .address-box .address-item {
    width: 280px;
  }
  .oos-contact-us .address .address-box .address-item .map.boston-center-placeholder {
    background-size: cover;
  }
}
@media screen and (max-width: 1200px) {
  .oos-contact-us .address {
    width: 1000px;
  }
  .oos-contact-us .address .address-box .address-item {
    width: 240px;
  }
}
@media screen and (max-width: 768px) {
  .oos-contact-us .address {
    margin: 20px auto 0;
    width: 100%;
    padding: 0 20px;
  }
  .oos-contact-us .address .address-box {
    flex-direction: column;
    align-items: center;
  }
  .oos-contact-us .address .address-box .address-item {
    width: 330px;
  }
  .oos-contact-us .address .address-box .address-item:hover {
    transform: none;
  }
  .oos-contact-us .address .address-box .address-item:not(:nth-child(1)) {
    margin-top: 30px;
  }
  .oos-contact-us .address .address-box .address-item .map.boston-center-placeholder {
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 450px) {
  .oos-contact-us .address {
    margin: 0 auto 0;
  }
  .oos-contact-us .address .address-box {
    flex-direction: column;
    align-items: center;
  }
  .oos-contact-us .address .address-box .address-item {
    width: 100%;
    height: 440px;
  }
  .oos-contact-us .address .address-box .address-item:not(:nth-child(1)) {
    margin-top: 30px;
  }
  .oos-contact-us .address .address-box .address-item .text-content .title {
    font-size: 14px;
  }
  .oos-contact-us .address .address-box .address-item .text-content .service,
  .oos-contact-us .address .address-box .address-item .text-content .it-support,
  .oos-contact-us .address .address-box .address-item .text-content .postal-code,
  .oos-contact-us .address .address-box .address-item .text-content .location {
    line-height: 24px;
    font-size: 13px;
  }
  .oos-contact-us .address .address-box .address-item .map {
    margin: 0 auto;
    width: 330px;
  }
}
.oos-not-found {
  padding-top: 60px;
  height: 1100px;
  background-color: #f5f5f5;
}
.oos-not-found .not-found-tip {
  margin: 200px auto 0;
  width: 800px;
  height: 250px;
  text-align: center;
  color: #c3c3c3;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 5px 5px 0 rgba(116, 130, 166, 0.1);
}
.oos-not-found .not-found-tip .tip-title {
  padding-top: 65px;
  font-size: 40px;
}
.oos-not-found .not-found-tip .tip-text {
  padding-top: 30px;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .oos-not-found .not-found-tip {
    margin: 100px auto 0;
    width: 400px;
    height: 200px;
  }
  .oos-not-found .not-found-tip .tip-title {
    padding-top: 55px;
    font-size: 28px;
  }
  .oos-not-found .not-found-tip .tip-text {
    padding-top: 20px;
    font-size: 16px;
  }
}
@media screen and (max-width: 450px) {
  .oos-not-found {
    padding: 60px 20px 0;
  }
  .oos-not-found .not-found-tip {
    margin: 100px auto 0;
    width: 100%;
    height: 190px;
  }
  .oos-not-found .not-found-tip .tip-title {
    padding-top: 50px;
    font-size: 22px;
  }
  .oos-not-found .not-found-tip .tip-text {
    padding-top: 20px;
    font-size: 16px;
  }
}
.three-demo {
  margin-top: 60px;
}
.three-demo.mountain {
  width: 600px;
  height: 600px;
}
