* {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}

html {
	font-family: PingFangSC, helvetica neue, hiragino sans gb, arial, microsoft yahei ui, microsoft yahei, simsun, sans-serif;
}

body {
	&.scroll-disabled {
		height: 100%;
		overflow: hidden;
	}

	@media screen and (min-width: 769px) {
		min-width: 1200px;
	}

	@media screen and (max-width: 768px) {
		min-width: 360px;
		-webkit-tap-highlight-color: transparent;
	}
}

input,
textarea {
	outline: none;
	border-radius: 0;
}

.oos-main-content {
	min-height: @content-height-1100;

	@media screen and (max-width: 450px) {
		min-height: @content-height-900;
	}
}
