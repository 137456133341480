.oos-leadership {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-normal-banner-core();
	}
	.section-title {
		.oos-section-title-core();
	}
	.content {
		.leader-box {
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			width: @content-height-1100;
			.leader-item {
				margin-top: 90px;
				display: flex;
				flex-direction: row;
				.img {
					width: 260px;
					height: 340px;
					background-size: 100% 100%;
					border-radius: 5px;
					&.president {
						background-image: url("../../images/about/leadership/president.webp");
					}
					&.ceo {
						background-image: url("../../images/about/leadership/ceo.webp");
					}
					&.cto {
						background-image: url("../../images/about/leadership/cto.webp");
					}
					&.technical-director {
						background-image: url("../../images/about/leadership/technical_director.webp");
					}
					&.vice-president {
						background-image: url("../../images/about/leadership/vice_president.webp");
					}
				}
				.text-content {
					padding-left: 45px;
					width: ~"calc(100% - 260px)";
					color: #3e3e3e;
					.name {
						font-size: @h-font;
						.oos-letter-spacing(2px);
						color: #2b3744;
						.en-name {
							padding-left: 10px;
							vertical-align: 3px;
							font-size: @m-font;
							font-weight: 300;
						}
					}
					.title {
						margin-top: 10px;
						font-size: @m-font;
						font-weight: 300;
						.oos-letter-spacing(2px);
					}
					.introduction {
						margin-top: 75px;
						line-height: 32px;
						font-size: @s-font;
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {

		}

		@media screen and (max-width: 768px) {
			.leader-box {
				padding: 0 @content-padding-20;
				flex-direction: column;
				width: 100%;
				.leader-item {
					margin-top: 40px;
					flex-direction: column;
					.img {
						margin: 0 auto;
					}
					.text-content {
						padding-left: 0;
						width: 100%;
						.name {
							margin-top: 20px;
							text-align: center;
						}
						.title {

							text-align: center;
						}
						.introduction {
							margin-top: 15px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.leader-box {
				.leader-item {
					margin-top: 30px;
					.text-content {
						padding-left: 0;
						.name {
							font-size: @l-font;
							.en-name {
								vertical-align: 0.5px;
							}
						}
						.title {
							font-size: @s-font;
						}
						.introduction {
							font-size: @xxs-font;
						}
					}
				}
			}
		}
	}
}
