.oos-image-gallery-detail {
	.banner {
		.oos-normal-banner-core();
	}
	.content {
		.image-gallery-detail-content {
			margin: 0 auto;
			width: @content-width-1200;
			.image-gallery-title {
				padding-top: 75px;
				padding-bottom: 30px;
				text-align: center;
				font-size: @xxl-font;
				.oos-letter-spacing(1px);
			}
			.button-bar {
				margin-bottom: 30px;
				height: 30px;
				text-align: right;
			}
			.download-all-button {
				display: inline-block;
				.oos-button-core(110px, 30px, @xxs-font, #333333);
				border: 1px solid #333333;
			}
			.download-one-button {
				display: inline-block;
				.oos-text-button-core(30px, @xxs-font, 333333);
				.oos-letter-spacing(2px);
				padding: 0;
			}
			.gallery-box {
				margin-bottom: 50px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				.gallery-thumbnail {
					position: relative;
					margin-bottom: 20px;
					background-color: #f5f7fa;
					border: 1px solid #f5f7fa;
					border-radius: 5px;
					&.active {
						border: 1px solid #5c8ae1;
					}
					&:hover {
						.oos-multiple-box-shadow();
						.download-one-button,
						.thumbnail-tip {
							display: block;
						}
					}
					.download-one-button {
						display: none;
						position: absolute;
						top: 5px;
						right: 10px;
						z-index: 1;
					}
					.thumbnail-tip {
						display: none;
						position: absolute;
						top: 10px;
						left: 10px;
						right: 0;
						font-size: @s-font;
						color: #545c6c;
					}
					.gallery-thumbnail-wrap {
						padding: 15px 20px;
						text-align: center;
						display: table-cell;
						vertical-align: middle;
						width: 380px;
						height: 180px;
						.thumbnail {
							margin: 0 auto;
							max-width: 340px;
							max-height: 150px;
							cursor: pointer;
							user-select: none;
						}
					}
				}
			}
			.gallery-large-image-box {
				margin-bottom: 120px;
				.gallery-large-image-title {
					margin-bottom: 20px;
					text-align: center;
					font-size: @l-font;
				}
				.button-bar {
					.download-one-button {
						.oos-button-core(80px, 30px, @xxs-font, #333333);
						border: 1px solid #333333;
					}
				}
				.gallery-large-image {
					width: 100%;
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			.image-gallery-detail-content {
				width: @content-width-1000;
				.gallery-box {
					.gallery-thumbnail {
						.thumbnail-tip {
							font-size: @xxs-font;
						}
						.gallery-thumbnail-wrap {
							padding: 10px;
							width: 320px;
							height: 150px;
							.thumbnail {
								max-width: 300px;
								max-height: 130px;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			.image-gallery-detail-content {
				width: 100%;
				.image-gallery-title {
					padding-top: 50px;
					padding-bottom: 15px;
					font-size: @xl-font;
				}
				.gallery-box {
					.gallery-thumbnail {
						.gallery-thumbnail-wrap {
							width: 340px;
							height: 160px;
							.thumbnail {
								max-width: 320px;
								max-height: 140px;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.image-gallery-detail-content {
				.image-gallery-title {
					padding-top: 30px;
					padding-bottom: 10px;
					font-size: @s-font;
				}
				.button-bar {
					text-align: center;
					margin-bottom: 20px;
				}
				.gallery-box {
					flex-direction: column;
					align-items: center;
					.gallery-thumbnail {
						width: 320px;
						&:hover {
							.thumbnail-tip {
								display: none; // No big image shown in phone.
							}
						}
						.gallery-thumbnail-wrap {
							width: 320px;
							height: 150px;
							.thumbnail {
								max-width: 300px;
								max-height: 130px;
							}
						}
					}
				}
				.gallery-large-image-box {
					display: none;
				}
			}
		}
	}
}
