.oos-solution {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-normal-banner-core();
	}
	.section-nav {
		.oos-section-nav-core();
	}
	.section-title {
		.oos-section-title-core();
	}
	.customer-pain-point {
		padding: 60px 0;
		background-color: #f2f5f8;
		.customer-pain-point-box {
			// A box has three items by default, if your items is two please add a '.two-item'
			// class in html for the box.
			padding: 0 40px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: space-between;
			width: @content-width-1600;
			&:nth-child(2) {
				padding-top: 30px;
			}
			&.two-items {
				width: @content-width-1400;
				.customer-pain-point-item {
					width: 600px;
				}
			}
			.customer-pain-point-item {
				display: flex;
				flex-direction: row;
				padding: 20px 30px;
				width: 470px;
				min-height: 140px;
				background-color: @white;
				border: 1px solid @white;
				border-radius: 5px;
				cursor: default;
				transition: box-shadow .3s linear;
				&:hover {
					background: linear-gradient(to bottom, @white, @white);
					.oos-box-shadow(1px, 6px, 5px, 0, rgba(116, 130, 166, 0.3));
				}
				.customer-pain-point-icon {
					margin-top: 10px;
					display: block;
					width: 60px;
					height: 60px;
					background-size: 100% 100%;
					&.temp {
						border: 1px solid #3392ff;
					}
					// Storage solution.
					// General category.
					// Big data.
					&.inefficient-hdfs {
						background-image: url("../../images/solution/bigData/inefficient_hdfs.webp");
					}
					&.lack-of-segregation {
						background-image: url("../../images/solution/bigData/lack_of_segregation.webp");
					}
					&.data-silos {
						background-image: url("../../images/solution/bigData/data_silos.webp");
					}
					// High performance computing.
					&.lack-of-capacity-high-performance-computing {
						background-image: url("../../images/solution/highPerformanceComputing/lack_of_capacity.webp");
					}
					&.high-complexity-high-performance-computing {
						background-image: url("../../images/solution/highPerformanceComputing/high_complexity.webp");
					}
					&.performance-barely-meets-requirement-high-performance-computing {
						background-image: url("../../images/solution/highPerformanceComputing/performance_barely_meets_requirement.webp");
					}
					// Cloud computing private cloud.
					&.lack-of-flexibility-cloud-computing-private-cloud {
						background-image: url("../../images/solution/cloudComputingPrivateCloud/lack_of_flexibility.webp");
					}
					&.lack-of-sharing-cloud-computing-private-cloud {
						background-image: url("../../images/solution/cloudComputingPrivateCloud/lack_of_sharing.webp");
					}
					&.no-reliability-guarantee-cloud-computing-private-cloud {
						background-image: url("../../images/solution/cloudComputingPrivateCloud/no_reliability_guarantee.webp");
					}
					// Cloud native container storage.
					&.lack-of-flexibility-cloud-native-container-storage {
						background-image: url("../../images/solution/cloudNativeContainerStorage/lack_of_flexibility.webp");
					}
					&.lack-of-sharing-cloud-native-container-storage {
						background-image: url("../../images/solution/cloudNativeContainerStorage/lack_of_sharing.webp");
					}
					&.no-reliability-guarantee-cloud-native-container-storage {
						background-image: url("../../images/solution/cloudNativeContainerStorage/no_reliability_guarantee.webp");
					}
					// Data backup.
					&.too-much-data {
						background-image: url("../../images/solution/dataBackup/too_much_data.webp");
					}
					&.too-many-clients {
						background-image: url("../../images/solution/dataBackup/too_many_clients.webp");
					}
					&.many-backup-types {
						background-image: url("../../images/solution/dataBackup/many_backup_types.webp");
					}
					// Industry category.
					// New energy vehicle.
					&.low-reliability-new-energy-vehicle {
						background-image: url("../../images/solution/newEnergyVehicle/low_reliability.webp");
					}
					&.high-cost-new-energy-vehicle {
						background-image: url("../../images/solution/newEnergyVehicle/high_cost.webp");
					}
					&.hard-to-manage-new-energy-vehicle {
						background-image: url("../../images/solution/newEnergyVehicle/hard_to_manage.webp");
					}
					// Intelligent campus.
					&.hard-to-store-data {
						background-image: url("../../images/solution/intelligentCampus/hard_to_store_data.webp");
					}
					&.poor-hd-experience {
						background-image: url("../../images/solution/intelligentCampus/poor_hd_experience.webp");
					}
					&.low-reliability {
						background-image: url("../../images/solution/intelligentCampus/low_reliability.webp");
					}
					// Intelligent security.
					&.hard-to-expansion {
						background-image: url("../../images/solution/intelligentSecurity/hard_to_expansion.webp");
					}
					&.low-efficiency-of-solve-case {
						background-image: url("../../images/solution/intelligentSecurity/low_efficiency_of_solve_case.webp");
					}
					&.low-reliability {
						background-image: url("../../images/solution/intelligentSecurity/low_reliability.webp");
					}
					// AI.
					&.data-mining {
						background-image: url("../../images/solution/ai/data_mining.webp");
					}
					&.data-process {
						background-image: url("../../images/solution/ai/data_process.webp");
					}
					&.low-reliability {
						background-image: url("../../images/solution/ai/low_reliability.webp");
					}
					// IoT solution.
					// Intelligent police category.
					// Bright project.
					&.hard-to-construct {
						background-image: url("../../images/solution/brightProject/hard_to_construct.webp");
					}
					&.weak-consciousness {
						background-image: url("../../images/solution/brightProject/weak_consciousness.webp");
					}
					&.have-leak {
						background-image: url("../../images/solution/brightProject/have_leak.webp");
					}
					// Major event security.
					&.frequent-safety-incident {
						background-image: url("../../images/solution/majorEventSecurity/frequent_safety_incident.webp");
					}
					&.lack-of-prevention {
						background-image: url("../../images/solution/majorEventSecurity/lack_of_prevention.webp");
					}
					// Intelligent community category.
					// Intelligent community.
					&.lack-of-overall-planning {
						background-image: url("../../images/solution/intelligentCommunity/lack_of_overall_planning.webp");
					}
					&.hard-to-integrate {
						background-image: url("../../images/solution/intelligentCommunity/hard_to_integrate.webp");
					}
					&.hard-to-difference {
						background-image: url("../../images/solution/intelligentCommunity/hard_to_difference.webp");
					}
					// Intelligent community security.
					&.hidden-danger {
						background-image: url("../../images/solution/intelligentCommunitySecurity/hidden_danger.webp");
					}
					&.hard-to-get-evidence {
						background-image: url("../../images/solution/intelligentCommunitySecurity/hard_to_get_evidence.webp");
					}
					&.security-weakness {
						background-image: url("../../images/solution/intelligentCommunitySecurity/security_weakness.webp");
					}
					// High altitude throw object.
					&.hard-to-supervise {
						background-image: url("../../images/solution/highAltitudeThrowObject/hard_to_supervise.webp");
					}
					// Intelligent elevator.
					&.old-elevator {
						background-image: url("../../images/solution/intelligentElevator/old_elevator.webp");
					}
					&.hard-to-rescue {
						background-image: url("../../images/solution/intelligentElevator/hard_to_rescue.webp");
					}
					&.limited-human-resource {
						background-image: url("../../images/solution/intelligentElevator/limited_human_resource.webp");
					}
					&.hard-to-evidence {
						background-image: url("../../images/solution/highAltitudeThrowObject/hard_to_evidence.webp");
					}
					// Intelligent park category.
					// Intelligent park.
					&.low-efficiency {
						background-image: url("../../images/solution/intelligentPark/low_efficiency.webp");
					}
					&.data-not-precipitated {
						background-image: url("../../images/solution/intelligentPark/data_not_precipitated.webp");
					}
					&.high-ecological-richness {
						background-image: url("../../images/solution/intelligentPark/high_ecological_richness.webp");
					}
					// Intelligent scenic spot category.
					// Intelligent scenic spot.a
					&.hard-to-monitor {
						background-image: url("../../images/solution/intelligentScenicSpot/hard_to_monitor.webp");
					}
					&.low-efficiency {
						background-image: url("../../images/solution/intelligentScenicSpot/low_efficiency.webp");
					}
					&.hard-to-manageCar {
						background-image: url("../../images/solution/intelligentScenicSpot/hard_to_manageCar.webp");
					}
					&.hard-to-analyse-customer {
						background-image: url("../../images/solution/intelligentScenicSpot/hard_to_analyse_customer.webp");
					}
					// Carbon neutrality category.
					// Carbon Neutrality.
					&.hard-to-operate {
						background-image: url("../../images/solution/carbonNeutrality/hard_to_operate.webp");
					}
					&.low-efficiency {
						background-image: url("../../images/solution/carbonNeutrality/low_efficiency.webp");
					}
					&.high-cost-and-pollution {
						background-image: url("../../images/solution/carbonNeutrality/high_cost_and_pollution.webp");
					}
					// Intelligent garden category.
					// Intelligent garden.
					&.state-supervision {
						background-image: url("../../images/solution/intelligentGarden/state_supervision.webp");
					}
					&.achievement-supervision {
						background-image: url("../../images/solution/intelligentGarden/achievement_supervision.webp");
					}
					&.curing-guidance {
						background-image: url("../../images/solution/intelligentGarden/curing_guidance.webp");
					}
					// Emergency category.
					// Emergency command.
					&.lack-of-preparation {
						background-image: url("../../images/solution/emergencyCommand/lack_of_preparation.webp");
					}
					&.monitor-untimely {
						background-image: url("../../images/solution/emergencyCommand/monitor_untimely.webp");
					}
					&.hard-to-coordinate {
						background-image: url("../../images/solution/emergencyCommand/hard_to_coordinate.webp");
					}
					// Safety production emergency.
					&.no-precise-query {
						background-image: url("../../images/solution/safetyProductionEmergency/no_precise_query.webp");
					}
					&.limited-law-enforcement {
						background-image: url("../../images/solution/safetyProductionEmergency/limited_law_enforcement.webp");
					}
					&.insufficient-supervision {
						background-image: url("../../images/solution/safetyProductionEmergency/insufficient_supervision.webp");
					}
					&.low-responsibility {
						background-image: url("../../images/solution/safetyProductionEmergency/low_responsibility.webp");
					}
					// General category.
					// IoT platform.
					&.similar-feature {
						background-image: url("../../images/solution/iotPlatform/similar_feature.webp");
					}
					&.data-redundancy {
						background-image: url("../../images/solution/iotPlatform/data_redundancy.webp");
					}
					&.hard-to-interwork {
						background-image: url("../../images/solution/iotPlatform/hard_to_interwork.webp");
					}
					&.long-chain {
						background-image: url("../../images/solution/iotPlatform/long_chain.webp");
					}
				}
				.customer-pain-point-text-content {
					margin-left: 10px;
					width: ~"calc(100% - 60px - 10px)";
					.title {
						font-size: @l-font;
						font-weight: 500;
						color: #2e394c;
						.oos-letter-spacing(1px);
					}
					.desc {
						margin-top: 5px;
						line-height: @h-font;
						font-size: @xs-font;
						color: #b3b9c4;
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {
			.customer-pain-point-box {
				padding: 0;
				width: @content-width-1600;
				&.two-items {
					padding-left: 40px;
					padding-right: 40px;
				}
				.customer-pain-point-item {
					width: 460px;
				}
			}
		}

		@media screen and (max-width: 1700px) {
			.customer-pain-point-box {
				width: @content-width-1400;
				.customer-pain-point-item {
					width: 450px;
				}
			}
		}

		@media screen and (max-width: 1400px) {
			.customer-pain-point-box {
				width: @content-width-1200;
				&.two-items {
					width: @content-width-1200;
					.customer-pain-point-item {
						width: 540px;
					}
				}
				.customer-pain-point-item {
					padding: 20px;
					width: 380px;
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.customer-pain-point-box {
				width: @content-width-1000;
				&.two-items {
					padding-left: 0;
					padding-right: 0;
					width: @content-width-1000;
					.customer-pain-point-item {
						width: 480px;
					}
				}
				.customer-pain-point-item {
					padding: 10px;
					width: 310px;
					.customer-pain-point-text-content {
						width: ~"calc(100% - 60px)";
						.title {
							font-size: @l-font;
						}
						.desc {
							font-size: @xxs-font;
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 10px 0 30px;
			.customer-pain-point-box {
				padding: 0 @content-padding-20;
				width: 100%;
				display: block;
				&:nth-child(2) {
					padding-top: 0;
				}
				&.two-items {
					padding: 0 @content-padding-20;
					width: 100%;
					.customer-pain-point-item {
						width: 100%;
					}
				}
				.customer-pain-point-item {
					padding: 20px 30px;
					margin-top: 20px;
					margin-right: 10px;
					width: 100%;
					.customer-pain-point-text-content {
						width: ~"calc(100% - 60px)";
						.title {
							font-size: @l-font;
						}
						.desc {
							font-size: @m-font;
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.customer-pain-point-box {
				padding: 0 @content-padding-20;
				.customer-pain-point-item {
					padding: 20px 10px;
					.customer-pain-point-text-content {
						.title {
							font-size: @s-font;
						}
						.desc {
							font-size: @xxs-font;
						}
					}
				}
			}
		}
	}
	.scheme-introduction {
		.scheme-introduction-box {
			margin: 0 auto;
			padding: 20px 40px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: @content-width-1600;
			color: #203150;
			.introduction-text-content {
				width: ~"calc(100% - 800px)";
				.title {
					font-size: 30px;
					font-weight: 600;
					.oos-letter-spacing(2px);
				}
				.desc {
					margin-top: 5px;
					line-height: @xh-font;
					font-size: @s-font;
					&.main {
						font-weight: 600;
					}
				}
			}
			.introduction-img {
				width: 712px;
				height: 412px;
				background-size: 100% 100%;
				// Storage solution.
				// General category.
				&.big-data {
					background-image: url('../../images/solution/bigData/introduction.webp');
				}
				&.high-performance-computing {
					background-image: url('../../images/solution/highPerformanceComputing/introduction.webp');
				}
				&.cloud-computing-private-cloud {
					background-image: url('../../images/solution/cloudComputingPrivateCloud/introduction.webp');
				}
				&.cloud-native-container-storage {
					background-image: url('../../images/solution/cloudNativeContainerStorage/introduction.webp');
				}
				&.data-backup {
					background-image: url('../../images/solution/dataBackup/introduction.webp');
				}
				// Industry category.
				&.new-energy-vehicle {
					background-image: url('../../images/solution/newEnergyVehicle/introduction.webp');
				}
				&.intelligent-campus {
					background-image: url('../../images/solution/intelligentCampus/introduction.webp');
				}
				&.intelligent-security {
					background-image: url('../../images/solution/intelligentSecurity/introduction.webp');
				}
				&.ai {
					background-image: url('../../images/solution/ai/introduction.webp');
				}
				// IoT solution.
				// Intelligent police category.
				&.bright-project {
					background-image: url('../../images/solution/brightProject/introduction.webp');
				}
				&.major-event-security {
					background-image: url('../../images/solution/majorEventSecurity/introduction.webp');
				}
				// Intelligent community category.
				&.intelligent-community {
					background-image: url('../../images/solution/intelligentCommunity/introduction.webp');
				}
				&.intelligent-community-security {
					background-image: url('../../images/solution/intelligentCommunitySecurity/introduction.webp');
				}
				&.intelligent-elevator {
					background-image: url('../../images/solution/intelligentElevator/introduction.webp');
				}
				&.high-altitude-throw-object {
					background-image: url('../../images/solution/highAltitudeThrowObject/introduction.webp');
				}
				// Intelligent park category.
				&.intelligent-park {
					background-image: url('../../images/solution/intelligentPark/introduction.webp');
				}
				// Intelligent scenic spot category.
				&.intelligent-scenic-spot {
					background-image: url('../../images/solution/intelligentScenicSpot/introduction.webp');
				}
				&.all-area-tourism {
					background-image: url('../../images/solution/allAreaTourism/introduction.webp');
				}
				// Carbon neutrality category.
				&.carbon-neutrality {
					background-image: url('../../images/solution/carbonNeutrality/introduction.webp');
				}
				// Intelligent garden category.
				&.intelligent-garden {
					background-image: url('../../images/solution/intelligentGarden/introduction.webp');
				}
				// Emergency category.
				&.emergency-command {
					background-image: url('../../images/solution/emergencyCommand/introduction.webp');
				}
				&.safety-production-emergency {
					background-image: url('../../images/solution/safetyProductionEmergency/introduction.webp');
				}
				// General category.
				&.iot-platform {
					background-image: url('../../images/solution/iotPlatform/introduction.webp');
				}
				&.big-data {
					background-image: url('../../images/solution/bigData/introduction.webp');
				}
				&.ai {
					background-image: url('../../images/solution/ai/introduction.webp');
				}
				&.data-backup {
					background-image: url('../../images/solution/dataBackup/introduction.webp');
				}
				&.intelligent-security {
					background-image: url('../../images/solution/intelligentSecurity/introduction.webp');
				}
			}
		}

		@media screen and (max-width: 1800px) {
			.scheme-introduction-box {
				padding: 20px 0;
				width: @content-width-1600;
			}
		}

		@media screen and (max-width: 1700px) {
			.scheme-introduction-box {
				width: @content-width-1400;
			}
		}

		@media screen and (max-width: 1400px) {
			.scheme-introduction-box {
				width: @content-width-1200;
				.introduction-text-content {
					width: ~"calc(100% - 600px)";
					.title {
						font-size: @xxh-font;
					}
					.desc {
						line-height: @h-font;
					}
				}
				.introduction-img {
					width: 550px;
					height: 318.258px
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.scheme-introduction-box {
				width: @content-width-1000;
				.introduction-text-content {
					width: ~"calc(100% - 520px)";
					.title {
						font-size: @xxl-font;
					}
					.desc {
						font-size: @xs-font;
						line-height: @xxl-font;
					}
				}
				.introduction-img {
					width: 500px;
					height: 289.326px
				}
			}
		}

		@media screen and (max-width: 768px) {
			.scheme-introduction-box {
				padding: 20px @content-padding-20;
				flex-direction: column;
				width: 100%;
				.introduction-text-content {
					width: 100%;
					text-align: center;
					.title {
						font-size: @xl-font;
						font-weight: 400;
					}
				}
				.introduction-img {
					margin-top: 20px;
					width: 712px;
					max-width: 100%;
					height: 412px;
				}
			}
		}

		@media screen and (max-width: 450px) {
			.scheme-introduction-box {
				padding: 0 20px 20px;
				.introduction-text-content {
					width: 100%;
					.title {
						font-size: @m-font;
					}
					.desc {
						font-size: @xxs-font;
					}
				}
				.introduction-img {
					margin-top: 10px;
					width: 356px;
					height: 206px;
				}
			}
		}
	}
	.customer-value {
		padding-top: 25px;
		.customer-value-box {
			// A box has three items by default, if your items is two please add a '.two-item'
			// class in html for the box.
			margin: 0 auto;
			padding: 0 40px;
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: space-between;
			width: @content-width-1600;
			&:not(:nth-child(1)) {
				margin-top: 60px;
			}
			&.two-items {
				 width: @content-width-1400;
				 .customer-value-item {
					 width: 600px;
				 }
			 }
			.customer-value-item {
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 20px;
				width: 470px;
				min-height: 160px;
				background-color: #f4f6f9;
				border: 1px solid @white;
				border-radius: 5px;
				cursor: default;
				transition: box-shadow .3s linear;
				&:hover {
					.oos-box-shadow(1px, 6px, 5px, 0, rgba(116, 130, 166, 0.3));
				}
				.customer-value-icon {
					position: absolute;
					display: block;
					top: -25px;
					left: 63px;
					width: 60px;
					height: 60px;
					background-size: 100% 100%;
					&.temp {
						border: 1px solid #3392ff;
					}
					// Storage solution.
					// General category.
					// Big data.

					// High performance computing.
					&.big-capacity-high-performance-computing {
						background-image: url("../../images/solution/highPerformanceComputing/big_capacity.webp");
					}
					&.high-performance-high-performance-computing {
						background-image: url("../../images/solution/highPerformanceComputing/high_performance.webp");
					}
					&.easy-to-use-high-performance-computing {
						background-image: url("../../images/solution/highPerformanceComputing/easy_to_use.webp");
					}
					// Cloud computing private cloud.
					&.high-resource-utilization-cloud-computing-private-cloud {
						background-image: url("../../images/solution/cloudComputingPrivateCloud/high_resource_utilization.webp");
					}
					&.high-safety-cloud-computing-private-cloud {
						background-image: url("../../images/solution/cloudComputingPrivateCloud/high_safety.webp");
					}
					&.high-performance-cloud-computing-private-cloud {
						background-image: url("../../images/solution/cloudComputingPrivateCloud/high_performance.webp");
					}
					// Cloud native container storage.
					&.high-resource-utilization-cloud-native-container-storage {
						background-image: url("../../images/solution/cloudNativeContainerStorage/high_resource_utilization.webp");
					}
					&.high-safety-cloud-native-container-storage {
						background-image: url("../../images/solution/cloudNativeContainerStorage/high_safety.webp");
					}
					&.high-performance-cloud-native-container-storage {
						background-image: url("../../images/solution/cloudNativeContainerStorage/high_performance.webp");
					}
					// Data backup.
					&.flexibility-operation {
						background-image: url("../../images/solution/dataBackup/flexibility_operation.webp");
					}
					&.overall-backup {
						background-image: url("../../images/solution/dataBackup/overall_backup.webp");
					}
					&.easy-to-use {
						background-image: url("../../images/solution/dataBackup/easy_to_use.webp");
					}
					// Industry category.
					// New energy vehicle.
					&.high-reliability-new-energy-vehicle {
						background-image: url("../../images/solution/newEnergyVehicle/high_reliability.webp");
					}
					&.low-cost-new-energy-vehicle {
						background-image: url("../../images/solution/newEnergyVehicle/low_cost.webp");
					}
					&.intelligent-management-new-energy-vehicle {
						background-image: url("../../images/solution/newEnergyVehicle/intelligent_management.webp");
					}
					// Intelligent campus.

					// Intelligent security.
					&.unified-resources {
						background-image: url("../../images/solution/intelligentSecurity/unified_resources.webp");
					}
					&.easy-to-expand {
						background-image: url("../../images/solution/intelligentSecurity/easy_to_expand.webp");
					}
					&.intelligent-manage {
						background-image: url("../../images/solution/intelligentSecurity/intelligent_manage.webp");
					}
					// AI.
					&.high-performance {
						background-image: url("../../images/solution/ai/high_performance.webp");
					}
					&.high-reliability {
						background-image: url("../../images/solution/ai/high_reliability.webp");
					}
					&.hardware-independence {
						background-image: url("../../images/solution/ai/hardware_independence.webp");
					}
					// IoT solution.
					// Intelligent police category.
					// Bright project.
					&.rational-layout {
						background-image: url("../../images/solution/brightProject/rational_layout.webp");
					}
					&.information-sharing {
						background-image: url("../../images/solution/brightProject/information_sharing.webp");
					}
					&.scientific-operation {
						background-image: url("../../images/solution/brightProject/scientific_operation.webp");
					}
					&.system-security {
						background-image: url("../../images/solution/brightProject/system_security.webp");
					}
					&.ai-and-big-data {
						background-image: url("../../images/solution/brightProject/ai_and_big_data.webp");
					}
					// Major event security.
					&.one-screen-control {
						background-image: url("../../images/solution/majorEventSecurity/one_screen_control.webp");
					}
					&.practical-application {
						background-image: url("../../images/solution/majorEventSecurity/practical_application.webp");
					}
					&.multi-level-fusion {
						background-image: url("../../images/solution/majorEventSecurity/multi_level_fusion.webp");
					}
					// Intelligent community category.
					// Intelligent community.
					&.owner-value {
						background-image: url("../../images/solution/intelligentCommunity/owner_value.webp");
					}
					&.property-value {
						background-image: url("../../images/solution/intelligentCommunity/property_value.webp");
					}
					&.real-estate-value {
						background-image: url("../../images/solution/intelligentCommunity/real_estate_value.webp");
					}
					&.government-property {
						background-image: url("../../images/solution/intelligentCommunity/government_property.webp");
					}
					// Intelligent community security.
					&.community-grid {
						background-image: url("../../images/solution/intelligentCommunitySecurity/community_grid.webp");
					}
					&.defense-line {
						background-image: url("../../images/solution/intelligentCommunitySecurity/defense_line.webp");
					}
					&.information-integration {
						background-image: url("../../images/solution/intelligentCommunitySecurity/information_integration.webp");
					}
					&.key-person {
						background-image: url("../../images/solution/intelligentCommunitySecurity/key_person.webp");
					}
					&.community-archive {
						background-image: url("../../images/solution/intelligentCommunitySecurity/community_archive.webp");
					}
					// Intelligent elevator.
					&.maintenance-supervision {
						background-image: url("../../images/solution/intelligentElevator/maintenance_supervision.webp");
					}
					&.data-archive {
						background-image: url("../../images/solution/intelligentElevator/data_archive.webp");
					}
					&.cut-cost {
						background-image: url("../../images/solution/intelligentElevator/cut_cost.webp");
					}
					&.emergency-ability {
						background-image: url("../../images/solution/intelligentElevator/emergency_ability.webp");
					}
					// High altitude throw object.
					&.accurate-monitoring {
						background-image: url("../../images/solution/highAltitudeThrowObject/accurate_monitoring.webp");
					}
					&.automatic-alarm {
						background-image: url("../../images/solution/highAltitudeThrowObject/automatic_alarm.webp");
					}
					&.strong-adaptability {
						background-image: url("../../images/solution/highAltitudeThrowObject/strong_adaptability.webp");
					}
					// Intelligent park category.
					// Intelligent park.
					&.unified-management {
						background-image: url("../../images/solution/intelligentPark/unified_management.webp");
					}
					&.linkage-command {
						background-image: url("../../images/solution/intelligentPark/linkage_command.webp");
					}
					&.experience-optimization {
						background-image: url("../../images/solution/intelligentPark/experience_optimization.webp");
					}
					&.intelligent-management {
						background-image: url("../../images/solution/intelligentPark/intelligent_management.webp");
					}
					&.strong-versatility {
						background-image: url("../../images/solution/intelligentPark/strong_versatility.webp");
					}
					&.all-area-security-control {
						background-image: url("../../images/solution/intelligentPark/all_area_security_control.webp");
					}
					// Intelligent scenic spot category.
					// Intelligent scenic spot.
					&.software-defined-scenic {
						background-image: url("../../images/solution/intelligentScenicSpot/software_defined_scenic.webp");
					}
					&.highly-integrated {
						background-image: url("../../images/solution/intelligentScenicSpot/highly_integrated.webp");
					}
					&.unified-management {
						background-image: url("../../images/solution/intelligentScenicSpot/unified_management.webp");
					}
					&.cloud-flexible {
						background-image: url("../../images/solution/intelligentScenicSpot/cloud_flexible.webp");
					}
					&.face-track {
						background-image: url("../../images/solution/intelligentScenicSpot/face_track.webp");
					}
					// All area tourism.
					&.help-for-acceptance {
						background-image: url("../../images/solution/allAreaTourism/help_for_acceptance.webp");
					}
					&.big-data-support {
						background-image: url("../../images/solution/allAreaTourism/big_data_support.webp");
					}
					&.improve-service-level {
						background-image: url("../../images/solution/allAreaTourism/improve_service_level.webp");
					}
					&.promote-consumption {
						background-image: url("../../images/solution/allAreaTourism/promote_consumption.webp");
					}
					// Carbon neutrality category.
					// Carbon Neutrality.
					&.energy-saving {
						background-image: url("../../images/solution/carbonNeutrality/energy_saving.webp");
					}
					&.improve-efficiency {
						background-image: url("../../images/solution/carbonNeutrality/improve_efficiency.webp");
					}
					&.reform-industry {
						background-image: url("../../images/solution/carbonNeutrality/reform_industry.webp");
					}
					&.economic-transformation {
						background-image: url("../../images/solution/carbonNeutrality/economic_transformation.webp");
					}
					// Intelligent garden category.
					// Intelligent garden.
					&.intelligent-manage {
						background-image: url("../../images/solution/intelligentGarden/intelligent_manage.webp");
					}
					&.data-monitor {
						background-image: url("../../images/solution/intelligentGarden/data_monitor.webp");
					}
					&.cut-cost {
						background-image: url("../../images/solution/intelligentGarden/cut_cost.webp");
					}
					// Emergency category.
					// Emergency command.
					&.alarm-quickly {
						background-image: url("../../images/solution/emergencyCommand/alarm_quickly.webp");
					}
					&.early-warning {
						background-image: url("../../images/solution/emergencyCommand/early_warning.webp");
					}
					&.unified-scheduling {
						background-image: url("../../images/solution/emergencyCommand/unified_scheduling.webp");
					}
					// Safety production emergency.
					&.comprehensive-supervision {
						background-image: url("../../images/solution/safetyProductionEmergency/comprehensive_supervision.webp");
					}
					&.visual-monitoring {
						background-image: url("../../images/solution/safetyProductionEmergency/visual_monitoring.webp");
					}
					// General category.
					// IoT platform.
					&.easy-to-develop {
						background-image: url("../../images/solution/iotPlatform/easy_to_develop.webp");
					}
					&.continuous-upgrade {
						background-image: url("../../images/solution/iotPlatform/continuous_upgrade.webp");
					}
					&.optimal-scheme {
						background-image: url("../../images/solution/iotPlatform/optimal_scheme.webp");
					}
					&.security-compatibility {
						background-image: url("../../images/solution/iotPlatform/security_compatibility.webp");
					}
				}
				.customer-value-title {
					position: relative;
					padding: 0 30px;
					width: 150px;
					text-align: center;
					font-size: @h-font;
				}
				.customer-value-desc {
					position: relative;
					padding: 0 12px;
					width: calc(100% - 150px);
					line-height: @xh-font;
					font-size: @s-font;
					color: #959eae;
					&::before,
					&::after {
						content: " ";
						position: absolute;
						display: block;
						width: 22px;
						height: 14px;
						background-image: url("../../images/solution/text_content_angle_dark.webp");
					}
					&::before {
						top: -12px;
						left: 0;
					}
					&::after {
						bottom: -12px;
						right: 0;
						transform: rotateZ(180deg);
					}
					&.small-font {
						font-size: @xxs-font;
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {
			padding-top: 25px;
			.customer-value-box {
				padding: 0;
				width: @content-width-1600;
				&.two-items {
					padding: 0 40px;
				}
				.customer-value-item {
					width: 460px;
				}
			}
		}

		@media screen and (max-width: 1700px) {
			.customer-value-box {
				width: @content-width-1400;
				.customer-value-item {
					padding: 20px 10px;
					width: 450px;
					.customer-value-title {
						padding: 0 20px;
						width: 130px;
					}
					.customer-value-desc {
					    width: calc(100% - 130px);
				    }
				}
			}
		}

		@media screen and (max-width: 1400px) {
			.customer-value-box {
				width: @content-width-1200;
				&.two-items {
					width: @content-width-1200;
					.customer-value-item {
						width: 540px;
					}
				}
				.customer-value-item {
					width: 380px;
					.customer-value-icon {
						left: 33px;
					}
					.customer-value-title {
						padding: 0 15px;
						width: 110px;
						font-size: @xxl-font;
					}
					.customer-value-desc {
						width: calc(100% - 110px);
					}
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.customer-value-box {
				width: @content-width-1000;
				&.two-items {
					padding-left: 0;
					padding-right: 0;
					width: @content-width-1000;
					.customer-value-item {
						width: 480px;
					}
				}
				.customer-value-item {
					width: 310px;
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding-top: 25px;
			.customer-value-box {
				padding: 0 @content-padding-20;
				width: 100%;
				display: block;
				&:not(:nth-child(1)) {
					margin-top: 50px;
				}
				&.two-items {
					padding: 0 @content-padding-20;
					width: 100%;
					.customer-value-item {
						width: 100%;
					}
				}
				.customer-value-item {
					padding: 20px;
					width: 100%;
					&:nth-child(n + 2) {
						margin-top: 50px;
					}
					.customer-value-icon {
						left: 64px;
					}
					.customer-value-title {
						padding: 0 30px;
						width: 150px;
					}
					.customer-value-desc {
						width: calc(100% - 150px);
						font-size: @l-font;
						&.small-font {
							font-size: @m-font;
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.customer-value-box {
				.customer-value-item {
					&:not(:nth-child(1)) {
						margin-top: 40px;
					}
					.customer-value-icon {
						top: -30px;
						left: 28px;
					}
					.customer-value-title {
						padding: 0 15px;
						width: 80px;
						font-size: @m-font;
					}
					.customer-value-desc {
						width: calc(100% - 80px);
						font-size: @xs-font;
						&.small-font {
							font-size: @xxs-font;
						}
					}
				}
			}
		}
	}
	.networking {
		.networking-img {
			margin: 0 auto;
			display: block;
			width: @content-width-1600;
			&.normal {
				width: 900px;
			}
			&.small {
				width: 800px;
			}
		}

		@media screen and (max-width: 1800px) {
			.networking-img {
				width: @content-width-1600;
			}
		}

		@media screen and (max-width: 1600px) {
			.networking-img {
				width: @content-width-1400;
			}
		}

		@media screen and (max-width: 1400px) {
			.networking-img {
				width: @content-width-1200;
			}
		}

		@media screen and (max-width: 1200px) {
			.networking-img {
				width: @content-width-1000;
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			.networking-img {
				width: 100%;
				&.normal {
					width: 100%;
				}
				&.small {
					width: 100%;
				}
			}
		}
	}
	.case {
		.case-box {
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			width: @content-width-1200;
			.case-item {
				padding: 35px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				background-color: #f4f6f9;
				border-radius: 5px;
				&:not(:nth-child(1)) {
					margin-top: 30px;
				}
				.text-content {
					width: ~"calc(100% - 50px - 500px)";
					&.one-row {
						width: 100%;
					}
					.title {
						font-size: @xxl-font;
						color: #2b3744;
						.oos-letter-spacing(1px);
					}
					.introduction {
						padding-top: 10px;
						font-size: @s-font;
						line-height: @xh-font;
						color: #959eae;
					}
					.point-content {
						padding-top: 10px;
						.point {
							position: relative;
							padding-bottom: 10px;
							font-size: @s-font;
							color: #2e394c;
							&:not(.no-symbol) {
								padding-left: 16px;
								&::before {
									content: " ";
									position: absolute;
									top: 5px;
									left: 0;
									width: 8px;
									height: 8px;
									background-color: #2e394c;
									border-radius: 100%;
								}
							}
							&:nth-last-child(1) {
								padding-bottom: 0;
							}
						}
					}
				}
				.img {
					width: 500px;
					border-radius: 5px;
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1600px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			.case-box {
				width: @content-width-1000;
				.case-item {
					.text-content {
						width: ~"calc(100% - 50px - 400px)";
					}
					.img {
						width: 400px;
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			.case-box {
				padding: 0 @content-padding-20;
				width: 100%;
				.case-item {
					padding: @content-padding-20;
					flex-direction: column;
					&:not(:nth-child(1)) {
						margin-top: 20px;
					}
					.text-content {
						width: 100%;
						.title {
							text-align: center;
							font-size: @l-font;
						}
						.introduction {
							font-size: @s-font;
						}
						.point-content {
							.point {
								font-size: @xs-font;
							}
						}
					}
					.img {
						margin-top: 20px;
						width: 100%;
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.case-box {
				.case-item {
					.text-content {
						.title {
							font-size: @m-font;
						}
						.introduction {
							font-size: @xxs-font;
						}
						.point-content {
							.point {
								font-size: @xxs-font;
							}
						}
					}
				}
			}
		}
	}
}
