.oos-home {
	.case {
		margin-top: 10px;
		margin-bottom: 60px;
		.case-box {
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 450px * 3 + 40px * 2;
			.case-item {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 17px 22px 17px 15px;
				width: 450px;
				height: 170px;
				background: linear-gradient(to bottom, #f0f6ff, @white);
				border: 1px solid @white;
				border-radius: 5px;
				.oos-box-shadow(1px, 2px, 7px, 0, rgba(116, 130, 166, 0.2));
				cursor: pointer;
				transition: box-shadow .3s linear;
				&:hover {
					background: linear-gradient(to bottom, @white, @white);
					.oos-box-shadow(0, 0, 5px, 0, rgba(116, 130, 166, 0.3));
				}
				.angle {
					position: absolute;
					display: block;
					width: 22px;
					height: 14px;
					background-image: url("../../../images/home/text_content_angle.webp");
					&.top-left {
						top: -10px;
						left: -8px;
					}
					&.bottom-right {
						bottom: -10px;
						right: -8px;
						transform: rotateZ(180deg);
					}
				}
				.case-img {
					width: 130px;
					height: 130px;
					background-size: 100% 100%;
					border: 1px solid @white;
					border-radius: 5px;
					&.intelligent-security-community {
						background-image: url("../../../images/home/case/xi_xian_police_department.webp");
					}
					&.intelligent-community-security {
						background-image: url("../../../images/home/case/qin_han_new_city_wei_liu_jia_yuan_intelligent_community.webp");
					}
					&.all-area-tourism {
						background-image: url("../../../images/home/case/qin_han_new_city_all_area_tourism.webp");
					}
				}
				.case-content {
					position: relative;
					margin-left: 15px;
					width: ~"calc(100% - 130px - 15px)";
					.case-title-wrap {
						display: flex;
						justify-content: space-between;
						align-items: center;
						.case-title-text {
							width: ~"calc(100% - 75px)";
							font-size: @m-font;
							.oos-text-overflow-ellipsis();
						}
						.view-detail-button {
							.oos-text-button-core(@xxs-font, @xxs-font, #adb4c0);
							margin-right: -10px;
						}
					}
					.case-text-content {
						line-height: @h-font;
						font-size: @xxs-font;
						color: #959eae;
						.oos-multiple-line-text-overflow-ellipsis(3);
					}
				}
			}

			@media screen and (max-width: 1800px) {
				width: 450px * 3 + 35px * 2;
			}

			@media screen and (max-width: 1700px) {
				width: 450px * 3 + 20px * 2;
			}

			@media screen and (max-width: 1400px) {
				width: 410px * 3 + 15px * 2;
				.case-item {
					width: 410px;
					.case-content {
						.case-title-wrap {
							.case-title-text {
								padding-top: 4px;
							}
						}
					}
				}
			}

			@media screen and (max-width: 1300px) {
				width: 400px * 3 + 15px * 2;
				.case-item {
					width: 400px;
					.case-img {
						width: 90px;
						height: 90px;
					}
					.case-content {
						width: ~"calc(100% - 90px - 15px)";
					}
				}
			}

			@media screen and (max-width: 1200px) {
				flex-direction: column;
				width: 100%;
				.case-item {
					margin: 0 auto 20px;
					width: 80%;
				}
			}

			@media screen and (max-width: 768px) {

			}

			@media screen and (max-width: 450px) {
				padding: 0 @content-padding-20;
				.case-item {
					margin: 0 auto 20px;
					width: 100%;
					&:nth-last-child(1) {
						margin-bottom: 0;
					}
					.case-img {
						width: 80px;
						height: 80px;
					}

					.case-content {
						width: ~"calc(100% - 80px - 15px)";
						.case-title-wrap {
							.case-title-text {
								padding: 5px;
								font-size: @s-font;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1400px) {
			margin-top: 0;
			margin-bottom: 30px;
		}
	}
}
