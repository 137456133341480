.oos-not-found {
	padding-top: @top-bar-height;
	height: @content-height-1100; // The same with content min-height.
	background-color: @gray-white;
	.not-found-tip {
		margin: 200px auto 0;
		width: 800px;
		height: 250px;
		text-align: center;
		color: @light-gray;
		background-color: @white;
		border-radius: 5px;
		.oos-box-shadow(1px, 5px, 5px, 0, rgba(116, 130, 166, 0.1));
		.tip-title {
			padding-top: 65px;
			font-size: 40px;
		}
		.tip-text {
			padding-top: 30px;
			font-size: @l-font;
		}
	}

	@media screen and (max-width: 1800px) {

	}

	@media screen and (max-width: 1700px) {

	}

	@media screen and (max-width: 1400px) {

	}

	@media screen and (max-width: 1200px) {

	}

	@media screen and (max-width: 768px) {
		.not-found-tip {
			margin: 100px auto 0;
			width: 400px;
			height: 200px;
			.tip-title {
				padding-top: 55px;
				font-size: @xxh-font;
			}
			.tip-text {
				padding-top: 20px;
				font-size: @m-font;
			}
		}
	}

	@media screen and (max-width: 450px) {
		padding: @top-bar-height @content-padding-20 0;
		.not-found-tip {
			margin: 100px auto 0;
			width: 100%;
			height: 190px;
			.tip-title {
				padding-top: 50px;
				font-size: @xxl-font;
			}
			.tip-text {
				padding-top: 20px;
				font-size: @m-font;
			}
		}
	}
}
