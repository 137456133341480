.oos-top-bar {
	position: absolute;
	top: 0;
    padding: 0 30px;
	width: 100%;
	min-width: @content-width-1200;
    height: @top-bar-height;
    line-height: @top-bar-height;
	background-color: @blue-black;
	z-index: 5; // Must be bigger than the same value of banner-hot-tab-wrap.
	user-select: none;
	&.news-carousel-active {
		background-color: rgba(42, 55, 78, 0.4);
		background-image: linear-gradient(rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01));
		.top-bar-content {
			.search-box {
				background-color: #1256cd;
				&.active {
					background-color: #1256cd !important;
				}
				.search-input {
					color: @white;
					.oos-placeholder-color(#d3e7f0);
					background-color: #1256cd;
				}
			}
		}
	}
	.top-bar-content {
		margin: 0 auto;
		max-width: 1920px;
		.oos-clear-fix();
		opacity: 0.96;

		.logo {
			float: left;
			margin-top: ((@top-bar-height - 30px) / 2);
			width: 80px;
			height: 30px;
			background-image: url("../../images/bar/logo.webp");
			background-size: 100% 100%;
			&:hover {
				cursor: pointer;
			}
		}
		.menu-box {
			margin-left: 35px;
			display: inline-block;
			.menu {
				float: left;
				position: relative;
				margin-right: 35px;
				padding: 0 15px;
				font-size: @xxs-font;
				.oos-letter-spacing(2px);
				cursor: pointer;
				&:nth-last-child(1) {
					margin-right: 0;
				}
				.menu-text {
					position: relative;
					display: block;
					color: @white-opacity;
					&.active {
						color: @white;
						&::before {
							content: " ";
							position: absolute;
							left: 50%;
							bottom: 0;
							width: 15px;
							height: 2px;
							background-color: @dark-blue;
							transform: translateX(-50%);
						}
					}
				}
				.normal-drop-down-menu {
					display: none;
					position: absolute;
					top: 60px;
					left: -30px;
					padding: 0 30px;
					color: @black;
					.oos-letter-spacing(1px);
					background-color: @white;
					border-radius: 5px;
					.oos-box-shadow(0, 0, 1px, 1px);
					z-index: 1;
					animation: oos-top-bar-drop-down-menu-fade-in 0.4s ease;
					&.active {
						display: block;
					}
					&::before {
						content: " ";
						position: absolute;
						top: -16px;
						left: 70px;
						border-top: 8px solid transparent;
						border-right: 8px solid transparent;
						border-bottom: 8px solid @white;
						border-left: 8px solid transparent;
					}
					&.product {
						width: 350px;
						&::before {
							left: 50px;
						}
					}
					&.solution {
						width: 360px;
						&::before {
							left: 65px;
						}
					}
					&.technical-support {
						width: 375px;
						&::before {
							left: 65px;
						}
					}
					&.about {
						width: 380px;
						&::before {
							left: 52px;
						}
					}
					.menu-title {
						height: 50px;
						line-height: 50px;
						font-size: @s-font;
						color: #2e394c;
						border-bottom: 1px solid #d1d5db;
					}
					.sub-menu-box {
						padding: 10px 0 25px;
						line-height: 20px;
						.sub-menu-row {
							.sub-menu {
								margin-right: 30px;
								display: inline-block;
								line-height: 40px;
								font-size: @xxs-font;
								color: #2e394c;
								border-bottom: 1px solid @white;
								cursor: pointer;
								&:hover {
									color: @light-blue;
								}
								&:nth-last-child(1) {
									margin-right: 0;
								}
								&.active {
									color: @light-blue;
									border-bottom: 2px solid @dark-blue;
								}
							}
						}
					}
				}
			}
		}
		.button-box {
			float: right;
			.button {
				float: right;
				.oos-text-button-core(60px, @xxs-font, #adb4c0);
				padding: 0 20px;
			}
			.button-ph {
				display: none;
				margin-top: 20px;
				width: 20px;
				height: 20px;
				background-size: 100%;
				&.menu {
					float: right;
					background-image: url("../../images/bar/menu_phone.webp");
				}
				&.sign {
					float: right;
					margin-right: 20px;
					background-image: url("../../images/bar/sign_phone.webp");
				}
			}
		}
		.search-box {
			float: right;
			position: relative;
			margin-right: 50px;
			padding: 0 20px;
			height: 60px;
			background-color: #202b44;
			transition: padding 0.2s linear; // For pad and phone search active.
			cursor: pointer;
			&.active {
				background-color: #202b44 !important;
				cursor: text;
				.search-input {
					width: 300px;
					cursor: text;
				}
			}
			.search-input {
				width: 220px;
				font-size: @xxs-font;
				color: #b3b9c4;
				background-color: #202b44;
				border: none;
				outline: none;
				.oos-placeholder-color();
				transition: width 0.3s linear;
				cursor: pointer;
				&:focus {
					border: none;
				}
			}
			.search-icon,
			.search-icon-ph {
				float: right;
				margin-top: 21px;
				width: 18px;
				height: 18px;
				background-image: url("../../images/home/search.svg");
				background-size: 100% 100%;
			}
			.search-icon {
				display: block;
			}
			.search-icon-ph {
				display: none;
			}
			.result-drop-down-box {
				display: none;
				position: absolute;
				top: 60px;
				left: 0;
				width: 100%;
				max-height: (50px * 5) + (50px / 2);
				border: 1px solid #dedede;
				background-color: @white;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				overflow-y: auto;
				z-index: 3;
				animation: fade-in-bottom-up .3s;
				&.visible {
					display: block;
				}
				.result-item {
					padding: 0 20px;
					height: 50px;
					line-height: 50px;
					font-size: @xs-font;
					.oos-letter-spacing(1px);
					border-bottom: 1px solid #dedede;
					&:nth-last-child(1) {
						border-bottom: none;
					}
					&:not(.empty) {
						cursor: pointer;
						&:hover {
							background-color: #f5f5f5;
						}
					}
					&.empty {
						color: @gray;
					}
					.result-item-text {
						margin-right: 20px;
					}
					.oos-button-icon();
				}
			}
		}

		@media screen and (max-width: 1500px) {
			.menu-box {
				.menu {
					margin-right: 20px;
				}
			}
			.search-box {
				margin-right: 30px;
			}
		}

		@media screen and (max-width: 1400px) {
			.menu-box {
				.menu {
					margin-right: 10px;
				}
			}
			.search-box {
				margin-right: 20px;
			}
		}

		@media screen and (max-width: 1200px) {
			.search-box {
				margin-right: 10px;
				background-color: @blue-black;
				&:not(.active) {
					.search-input {
						background-color: @blue-black;
					}
				}
				&.active {
					.search-input {
						width: 250px;
					}
				}
				.search-icon {
					display: none;
				}
				.search-icon-ph {
					display: block;
				}
				.search-input {
					width: 0;
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0;
			min-width: 100%;
			.menu-box {
				display: none;
			}
			.search-box {
				position: static; // Let result box to be absolute in higher ancestor element.
				margin-right: 10px;
				&.active {
					margin-right: 10px;
					padding-right: 10px;
					.search-input {
						width: 120px;
					}
				}
				.result-box {
					left: 15px;
					width: ~"calc(100% - (15px * 2))";
				}
			}
			.button-box {
				.button {
					&.sign-in,
					&.sign-out,
					&.trial {
						display: none;
					}
				}
				.button-ph {
					display: block;
				}
			}
		}

		@media screen and (max-width: 450px) {
			.search-box {
				.result-drop-down-box {
					left: @content-padding-20;
					right: @content-padding-20;
					width: auto;
				}
			}
		}
	}
	.product-drop-down-menu {
		display: none;
		position: absolute;
		top: 60px;
		left: 0;
		right: 0;
		width: 100vw;
		min-width: @content-width-1200;
		height: 485px;
		background-color: #253250;
		.oos-box-shadow(1px, 3px, 10px, 0, rgba(42, 57, 87, 0.8));
		z-index: 1;
		animation: oos-top-bar-drop-down-menu-fade-in 0.4s ease;
		&.active {
			display: block;
		}
		.menu-box {
			padding: 10px 80px 40px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			.menu-item {
				margin-left: 40px;
				margin-top: 30px;
				position: relative;
				padding: 30px 40px;
				min-width: 520px;
				height: 210px;
				background-color: #f2f8fd;
				border-radius: 10px;
				overflow: hidden;
				cursor: pointer;
				.title,
				.sub-title {
					position: relative;
					width: 320px;
					z-index: 3;
				}
				.title {
					line-height: @xl-font;
					font-size: @xl-font;
					font-weight: 600;
					color: #283553;
					.oos-text-overflow-ellipsis();
				}
				.sub-title {
					padding-top: 10px;
					line-height: @xl-font;
					font-size: @xs-font;
					color: #9ca8c5;
					.oos-multiple-line-text-overflow-ellipsis(2);
				}
				.view-detail-button {
					margin-top: 40px;
					margin-left: -10px;
					.oos-text-button-core(@xxs-font, @xxs-font, #adb4c0, left);
				}
				.bg {
					position: absolute;
					right: 0;
					bottom: 0;
					width: 268px;
					height: 156px;
					background-size: 100% 100%;
					z-index: 2;

					&.uniio {
						background-image: url("../../images/product/uniio/banner_static_img.webp");
					}
					&.orcabd {
						background-image: url("../../images/product/orcabd/banner_static_img.webp");
					}
					&.orcafs {
						background-image: url("../../images/product/orcafs/banner_static_img.webp");
					}
				}
			}
		}

		@media screen and (max-width: 1850px) {
			.menu-box {
				padding: 10px 60px 40px;
				.menu-item {
					min-width: 490px;
				}
			}
		}

		@media screen and (max-width: 1800px) {
			.menu-box {
				.menu-item {
					min-width: 480px;
					.title,
					.sub-title {

					}
				}
			}
		}

		@media screen and (max-width: 1700px) {
			.menu-box {
				padding: 10px 40px 30px;
				.menu-item {
					min-width: 470px;
					.title,
					.sub-title {
						width: 300px;
					}
					.title {
						font-size: @l-font;
					}
				}
			}
		}

		@media screen and (max-width: 1680px) {
			.menu-box {
				padding: 10px 20px 20px;
			}
		}

		@media screen and (max-width: 1650px) {
			.menu-box {
				.menu-item {
					margin-left: 20px;
					min-width: 470px;
				}
			}
		}

		@media screen and (max-width: 1550px) {
			.menu-box {
				.menu-item {
					min-width: 460px;
					.title,
					.sub-title {
						width: 290px;
					}
				}
			}
		}

		@media screen and (max-width: 1490px) {
			.menu-box {
				.menu-item {
					min-width: 430px;
				}
			}
		}

		@media screen and (max-width: 1390px) {
			.menu-box {
				.menu-item {
					margin-left: 40px;
					min-width: 560px;
					.title,
					.sub-title {
						width: 300px;
					}
				}
			}
		}

		@media screen and (max-width: 1260px) {
			.menu-box {
				.menu-item {
					margin-left: 40px;
					min-width: 510px;
					.title,
					.sub-title {
						width: 300px;
					}
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.menu-box {
				.menu-item {
					min-width: 490px;
					.title,
					.sub-title {
						width: 290px;
					}
				}
			}
		}

		@media screen and (max-width: 768px) {

		}
	}
	.solution-drop-down-menu {
		display: none;
		position: absolute;
		top: 60px;
		left: 0;
		right: 0;
		width: 100vw;
		min-width: @content-width-1200;
		height: 485px;
		background-color: #253250;
		.oos-box-shadow(1px, 3px, 10px, 0, rgba(42, 57, 87, 0.8));
		z-index: 1;
		animation: oos-top-bar-drop-down-menu-fade-in 0.4s ease;
		&.active {
			display: flex;
			flex-direction: row;
		}
		.category-menu-box {
			width: 180px;
			height: 100%;
			background-color: #202e49;
			.category-menu-item {
				height: 50px;
				line-height: 50px;
				cursor: pointer;
				&.active {
					background-color: #1d2841;
					.menu-text {
						color: @white;
					}
				}
				.menu-text {
					padding-left: 35px;
					display: inline-block;
					width: 100%;
					font-size: @xs-font;
					color: @white-opacity;
				}
			}
		}
		.category-content {
			width: ~"calc(100% - 180px)";
			.secondary-category-menu-box {
				padding-left: @content-padding-20;
				display: flex;
				flex-direction: row;
				height: 40px;
				line-height: 40px;
				background-color: #202e49;
				.secondary-category-menu-item {
					position: relative;
					padding: 0 @content-padding-20;
					cursor: pointer;
					.menu-text {
						display: inline-block;
						height: 38px;
						font-size: @xs-font;
						color: @white-opacity;
					}
					&.active {
						.menu-text {
							color: @white;
							&:before {
								content: " ";
								position: absolute;
								left: 50%;
								bottom: 0;
								transform: translateX(-50%);
								width: 15px;
								height: 2px;
								background-color: @dark-blue;
							}
						}
					}
				}
			}
			.secondary-category-content-box {
				padding: 0 40px 40px 0;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-start;
				height: ~"calc(100% - 40px)";
				overflow-y: auto;
				.solution-section-item {
					margin-left: 40px;
					margin-top: 30px;
					position: relative;
					padding: 30px 40px;
					min-width: 500px;
					height: 210px;
					background-color: #f2f8fd;
					border-radius: 10px;
					overflow: hidden;
					cursor: pointer;
					.title,
					.sub-title {
						position: relative;
						z-index: 3;
					}
					.title {
						width: 280px;
					}
					.sub-title {
						width: 270px;
					}
					.title {
						line-height: @xl-font;
						font-size: @xl-font;
						font-weight: 600;
						color: #283553;
						.oos-text-overflow-ellipsis();
					}
					.sub-title {
						padding-top: 10px;
						line-height: @xl-font;
						font-size: @xs-font;
						color: #9ca8c5;
						.oos-multiple-line-text-overflow-ellipsis(2);
					}
					.view-detail-button {
						margin-top: 40px;
						margin-left: -10px;
						.oos-text-button-core(@xxs-font, @xxs-font, #adb4c0, left);
					}
					.big-arrow {
						position: absolute;
						top: 40px;
						right: 30px;
						width: 70px;
						height: 51px;
						background-image: url("../../images/bar/arrow_big.webp");
						background-size: 100% 100%;
						opacity: 0.6;
						z-index: 1;
					}
					.bg {
						position: absolute;
						right: 0;
						bottom: 0;
						width: 330px;
						height: 170px;
						background-size: 100% 100%;
						z-index: 2;
						.solution-banner-static-img();
					}
				}
			}
		}

		@media screen and (max-width: 1850px) {
			.category-menu-box {
				width: 170px;
			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					padding: 0 30px 30px 0;
					.solution-section-item {
						min-width: 480px;
						.title {

						}
						.sub-title {
							width: 250px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {
			.category-menu-box {

			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					.solution-section-item {
						min-width: 480px;
						.title,
						.sub-title {

						}
					}
				}
			}
		}

		@media screen and (max-width: 1770px) {
			.category-menu-box {

			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					padding: 0 30px 30px 0;
					.solution-section-item {
						min-width: 460px;
						.sub-title {
							width: 240px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1710px) {
			.category-menu-box {

			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					padding: 0 30px 30px 0;
					.solution-section-item {
						min-width: 440px;
						.title,
						.sub-title {
							width: 250px;
						}
						.title {
							font-size: @l-font;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1680px) {
			.category-menu-box {
				width: 160px;
			}
			.category-content {
				width: ~"calc(100% - 160px)";
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					padding: 0 20px 20px 0;
					.solution-section-item {
						min-width: 450px;
						.title,
						.sub-title {
							width: 240px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1650px) {
			.category-menu-box {

			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					.solution-section-item {
						margin-left: 20px;
						min-width: 430px;
						.title,
						.sub-title {
							width: 220px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1550px) {
			.category-menu-box {

			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					.solution-section-item {
						min-width: 410px;
						.title {
							width: 200px;
						}
						.sub-title {
							width: 190px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1490px) {
			.category-menu-box {

			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					.solution-section-item {
						margin-left: 40px;
						min-width: 560px;
						.title,
						.sub-title {
							width: 300px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1390px) {
			.category-menu-box {

			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					.solution-section-item {
						margin-left: 40px;
						min-width: 500px;
						.title,
						.sub-title {
							width: 260px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1260px) {
			.category-menu-box {

			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					.solution-section-item {
						margin-left: 40px;
						min-width: 480px;
						.title,
						.sub-title {
							width: 240px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.category-menu-box {

			}
			.category-content {
				.secondary-category-menu-box {

				}
				.secondary-category-content-box {
					.solution-section-item {
						min-width: 455px;
						.title,
						.sub-title {
							width: 225px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {

		}
	}

	@media screen and (max-width: 1200px) {
		&.news-carousel-active {
			.top-bar-content {
				.search-box {
					&:not(.active) {
						.search-input {
							background-color: #1256cd;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		min-width: 100%;
		padding: 0 @content-padding-20;
	}
}
