// Contents.
@import url("./home/homeBanner");
@import url("./home/homeProduct");
@import url("./home/homeSolution");
@import url("./home/homeSource");
@import url("./home/homeCase");
@import url("./home/homePartner");
@import url("./home/homeContactUs");

.oos-home {
	.section-title {
		.oos-section-title-core();

		&.home-case-title {
			padding: 205px 0 30px;
		}

		@media screen and (max-width: 768px) {
			&.home-product-title {
				padding: 20px + 22px 0 18px;
			}
			&.home-case-title {
				padding: 155px @content-padding-20 18px;
			}
		}

		@media screen and (max-width: 450px) {
			&.home-case-title {
				padding: 285px @content-padding-20 18px;
			}
		}
	}
}
