.oos-pad-and-ph-drop-down-menu {
	display: none;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 @content-padding-20;
	background: #faf9f9;
	transform: translateY(-100%);
	transition: transform .5s ease-in-out;
	user-select: none;
	z-index: 99;
	&.active {
		transform: translateY(0);
		.menu-nav-title {
			transform: translateY(0);
		}
		.menu-section {
			transform: translateX(0);
		}
	}
	.menu-nav-title {
		height: 50px;
		line-height: 50px;
		font-size: @m-font;
		text-align: center;
		transform: translateY(-100%);
		transition: transform .3s ease .3s;
	}
	.menu-section {
		position: relative;
		font-size: @xs-font;
		color: @black;
		transform: translateX(120%);
		&::after {
			content: " ";
			width: 200%;
			height: 200%;
			position: absolute;
			top: 0;
			left: 0;
			border-top: 1px solid #d8d7d7;
			transform: scale(.5, .5);
			transform-origin: top left;
			z-index: -1;
		}
		&:nth-child(1) {
			transition: transform .3s ease .15s;
		}
		&:nth-child(2) {
			transition: transform .3s ease .2s;
		}
		&:nth-child(3) {
			transition: transform .3s ease .25s;
		}
		&:nth-child(4) {
			transition: transform .3s ease .3s;
		}
		&:nth-child(5) {
			transition: transform .3s ease 0.35s;
		}
		&:nth-child(6) {
			transition: transform .3s ease 0.4s;
		}
		&:nth-child(7) {
			transition: transform .3s ease 0.45s;
		}
		&:nth-child(8) {
			transition: transform .3s ease 0.5s;
		}
		.menu-solution-category-name-ph {
			position: relative;
			height: 40px;
			line-height: 40px;
			&.active {
				.name-ph {
					font-size: @xs-font;
					color: #545c6c;
				}
				.arrow-ph {
					background-image: url("../../images/general/arrow_downward_active.svg");
					transform: rotate(180deg);
				}
			}
			.name-ph {
				font-size: @xs-font;
			}
			.arrow-ph {
				content: " ";
				position: absolute;
				top: 12px;
				right: 0;
				width: 12px;
				height: 20px;
				background-image: url("../../images/general/arrow_downward.svg");
				background-size: 100% 100%;
				transition: transform 0.2s linear;
			}
		}
		.menu-box {
			&.solution {
				display: none;
				&.active {
					display: block;
				}
			}
			.menu-item {
				display: inline-block;
				padding: 15px 15px 15px 0;
				height: 50px;
				vertical-align: middle;
				z-index: 1;
				&.active {
					color: @light-blue;
				}
			}
		}
	}
	.close-button {
		position: absolute;
		left: 50%;
		bottom: 20px;
		width: 40px;
		height: 40px;
		text-align: center;
		transform: translateX(-50%);
		img {
			display: inline-block;
			margin-top: 10px;
			vertical-align: 10%;
			width: 20px;
			height: 20px;
			&.rock {
				animation: oos-pad-and-ph-close-button-motion .2s linear infinite;
			}
		}
	}

	@media screen and (max-width: 768px) {
		display: block;
	}
}
