.oos-about-us {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-normal-banner-core();
	}
	.section-title {
		.oos-section-title-core();
	}
	.introduction {
		margin: 0 auto;
		width: @content-width-1200;
		color: #545c6c;
		.firm-name {
			font-size: @xxl-font;
			font-weight: 600;
			color: #2b3744;
			.oos-letter-spacing(1px);
		}
		.firm-alias-name {
			padding: 10px 0 15px;
			font-size: @s-font;
			font-weight: 300;
			.oos-letter-spacing(1px);
		}
		.desc {
			line-height: 32px;
			font-size: @m-font;
		}
		.introduction-bg {
			margin: 50px auto 0;
			display: block;
			width: 100%;
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			width: @content-width-1000;
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			width: 100%;
			text-align: center;
			.firm-name {
				font-size: @l-font;
			}
			.firm-alias-name {
				font-size: @xxs-font;
			}
			.desc {
				font-size: @s-font;
			}
			.introduction-bg {
				margin-top: 30px;
			}
		}

		@media screen and (max-width: 450px) {
			.firm-name {
				font-size: @m-font;
			}
			.introduction-bg {
				margin-top: 20px;
			}
		}
	}
	.mission {
		margin: 0 auto;
		width: @content-width-1000;
		color: #545c6c;
		.desc {
			text-align: center;
			font-size: @m-font;
		}
		.mission-box {
			margin-top: 80px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.mission-item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 140px;
				.img {
					width: 110px;
					height: 109px;
					background-size: 100% 100%;
					&.focus {
						background-image: url("../../images/about/aboutUs/focus.webp");
					}
					&.trustworthy {
						background-image: url("../../images/about/aboutUs/trustworthy.webp");
					}
					&.quality {
						background-image: url("../../images/about/aboutUs/quality.webp");
					}
				}
				.desc {
					margin-top: 20px;
					line-height: 34px;
					font-size: @s-font;
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			width: @content-width-800;
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			width: 100%;
			display: block;
			.desc {
				font-size: @s-font;
			}
			.mission-box {
				margin-top: 30px;
				padding: 0 @content-padding-20;
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 450px) {
			.desc {
				line-height: 28px;
			}
			.mission-box {
				margin-top: 20px;
				flex-direction: column;
				align-items: center;
				.mission-item {
					&:not(:nth-child(1)) {
						margin-top: 20px;
					}
					.desc {
						line-height: 28px;
						font-size: @xs-font;
					}
				}
			}
		}
	}
	.honor {
		margin: 0 auto;
		width: @content-width-1200;
		color: #545c6c;
		.desc {
			text-align: center;
			font-size: @m-font;
		}
		.honor-box {
			margin-top: 80px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.honor-item {
				.img-wrap {
					padding: 30px 20px;
					display: table-cell;
					text-align: center;
					vertical-align: middle;
					width: 362px; // Left and right border will occupy 2px.
					height: 482px;
					border: 1px solid #d8d7d7;
					border-radius: 5px;
					.img {
						margin: 0 auto;
						display: table-cell;
						vertical-align: middle;
						max-width: 320px;
						max-height: 420px;
					}
				}
				.name {
					padding-top: 10px;
					width: 362px;
					height: 45px;
					text-align: center;
					font-size: @m-font;
				}
			}
		}
		.view-more-button {
			.oos-button-core(120px, 30px, @xxs-font, #d5def2);
			margin: 50px auto 0;
			background-color: #2a3957;
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			width: @content-width-1000;
			.honor-box {
				.honor-item {
					.img-wrap {
						padding: 15px;
						width: 324px; // Left and right border will occupy 2px.
						height: 452px;
					}
					.name {
						width: 324px;
						font-size: @s-font;
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			width: 100%;
			.desc {
				font-size: @s-font;
			}
			.honor-box {
				margin-top: 30px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.honor-item {
					&:not(:nth-child(1)) {
						margin-top: 30px;
					}
					.img-wrap {
						padding: 15px;
						width: 362px; // Left and right border will occupy 2px.
						height: 482px;
					}
					.name {
						width: 362px;
						font-size: @s-font;
					}
				}
			}
			.view-more-button {
				margin: 30px auto 50px;
			}
		}

		@media screen and (max-width: 450px) {
			.desc {
				line-height: 28px;
			}
			.honor-box {
				margin-top: 20px;
				.honor-item {
					margin-top: 20px;
					.img-wrap {
						padding: 10px;
						width: 100%;
						min-width: 322px;
						height: 444px;
					}
					.name {
						width: 100%;
						min-width: 322px;
						font-size: @s-font;
					}
				}
			}
			.view-more-button {
				margin: 20px auto 30px;
			}
		}
	}
}
