@keyframes oos-modal-mask-fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.3;
	}
}

@keyframes oos-top-bar-drop-down-menu-fade-in {
	0% {
		opacity: 0;
		transform: translate3d(0, 10px, 0);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes oos-pad-and-ph-close-button-motion {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes oos-carousel-switch-fade-in {
	0% {
		opacity: 0.1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes oos-carousel-pagination-progress {
	0% {
		width: 0;
	}
	100% {
		width: 99%;
	}
}

@keyframes oos-dynamic-icon-60-16-active {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 0 -960px;
	}
}

@keyframes oos-dynamic-icon-60-16-leave {
	0% {
		background-position: 0 -960px;
	}
	100% {
		background-position: 0 0;
	}
}

@keyframes oos-product-banner-light-bar-thumb-left-right-motion {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(20px);
	}
}

@keyframes oos-product-banner-light-bar-thumb-right-left-motion {
	0% {
		transform: translateX(20px);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes oos-product-banner-light-ray-left-motion {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-140px);
	}
}

@keyframes oos-product-banner-light-ray-right-motion {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-223px);
	}
}

@keyframes oos-product-banner-curtain-fade-in-and-out {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	25% {
		transform: translateY(2px);
		opacity: 0.4;
	}
	50% {
		transform: translateY(0);
		opacity: 1;
	}
	75% {
		transform: translateY(2px);
		opacity: 0.4;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes oos-product-banner-conveyor-belt-stuff-motion {
	0% {
		transform: translateX(0) translateY(0);
	}
	100% {
		transform: translateX(-153px) translateY(-74px);
	}
}

@keyframes oos-product-banner-application-motion {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(5px);
	}
	50% {
		transform: translateY(0);
	}
	75% {
		transform: translateY(-5px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes oos-normal-banner-float-img-motion {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(5px);
	}
	50% {
		transform: translateY(0);
	}
	75% {
		transform: translateY(-5px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes oos-normal-banner-curtain-fade-in-and-out {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	25% {
		transform: translateY(2px);
		opacity: 0.4;
	}
	50% {
		transform: translateY(0);
		opacity: 1;
	}
	75% {
		transform: translateY(2px);
		opacity: 0.4;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes oos-normal-banner-curtain-window-fade-in-and-out {
	0% {
		opacity: 0.2;
		background-image: url("../../images/normalBannerAnim/light_curtain_window_blue.webp");
	}
	20% {
		opacity: 0.4;
		background-image: url("../../images/normalBannerAnim/light_curtain_window_blue.webp");
	}
	40% {
		opacity: 0.6;
		background-image: url("../../images/normalBannerAnim/light_curtain_window_white.webp");
	}
	60% {
		opacity: 0.8;
		background-image: url("../../images/normalBannerAnim/light_curtain_window_blue.webp");
	}
	80% {
		opacity: 0.6;
		background-image: url("../../images/normalBannerAnim/light_curtain_window_white.webp");
	}
	100% {
		opacity: 0.2;
		background-image: url("../../images/normalBannerAnim/light_curtain_window_blue.webp");
	}
}

@keyframes oos-normal-banner-light-point-fade-in-and-out {
	0% {
		opacity: 1;
	}
	25% {
		opacity: 0.2;
	}
	50% {
		opacity: 1;
	}
	75% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

@keyframes oos-normal-banner-light-bar-thumb-left-right-motion {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(20px);
	}
}

@keyframes oos-normal-banner-light-bar-thumb-right-left-motion {
	0% {
		transform: translateX(20px);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes oos-normal-banner-light-ray-up-motion {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-120px);
	}
}

@keyframes oos-normal-banner-light-ray-bottom-motion {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-145px);
	}
}

@keyframes oos-normal-banner-bright-project-car-a-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(87px) translateY(44px);
	}
	70% {
		transform: translateX(87px) translateY(44px);
		opacity: 1;
	}
	80% {
		transform: translateX(87px) translateY(44px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-bright-project-car-b-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(55px) translateY(-27px);
	}
	70% {
		transform: translateX(55px) translateY(-27px);
		opacity: 1;
	}
	80% {
		transform: translateX(55px) translateY(-27px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-intelligent-elevator-wifi-motion {
	50% {
		transform: translateY(-10px);
	}
}

@keyframes oos-normal-banner-intelligent-elevator-car-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateY(0);
		opacity: 1;
	}
	70% {
		transform: translateY(-90px);
		opacity: 1;
	}
	80% {
		transform: translateY(-90px);
		opacity: 0;
	}
	89% {
		opacity: 0;
	}
	90% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes oos-normal-banner-intelligent-elevator-door-left-motion {
	0% {
		transform: skewY(25deg) translateX(0);
	}
	15% {
		transform: skewY(25deg) translateX(-12px);
	}
	28% {
		transform: skewY(25deg) translateX(-12px);
	}
	41% {
		transform: skewY(25deg) translateX(0);
	}
	100% {
		transform: skewY(25deg) translateX(0);
	}
}

@keyframes oos-normal-banner-intelligent-elevator-door-right-motion {
	0% {
		transform: skewY(25deg) translateX(0);
	}
	15% {
		transform: skewY(25deg) translateX(12px);
	}
	28% {
		transform: skewY(25deg) translateX(12px);
	}
	41% {
		transform: skewY(25deg) translateX(0);
	}
	100% {
		transform: skewY(25deg) translateX(0);
	}
}

@keyframes oos-normal-banner-intelligent-elevator-man-left-fade-in-out {
	0% {
		opacity: 1;
	}
	15% {
		opacity: 1;
	}
	25% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-intelligent-elevator-man-right-fade-in-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-intelligent-garden-cloud-motion {
	50% {
		transform: translateX(10px) translateY(-10px);
	}
}

@keyframes oos-normal-banner-intelligent-garden-cable-car-motion {
	50% {
		transform: translateX(46px) translateY(41px);
	}
}

@keyframes oos-normal-banner-intelligent-garden-car-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(79px) translateY(38px);
	}
	70% {
		transform: translateX(79px) translateY(38px);
		opacity: 1;
	}
	80% {
		transform: translateX(79px) translateY(38px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-intelligent-community-car-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(76px) translateY(38px);
	}
	70% {
		transform: translateX(76px) translateY(38px);
		opacity: 1;
	}
	80% {
		transform: translateX(76px) translateY(38px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-intelligent-community-security-cloud-motion {
	50% {
		transform: translateX(-15px) translateY(15px);
	}
}

@keyframes oos-normal-banner-building-light-blink {
	50% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-intelligent-community-security-bicycle-man-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(91px) translateY(44px);
	}
	70% {
		transform: translateX(91px) translateY(44px);
		opacity: 1;
	}
	75% {
		transform: translateX(91px) translateY(44px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-intelligent-community-security-balloon-motion {
	50% {
		transform: translateY(-10px);
	}
}

@keyframes oos-normal-banner-intelligent-community-security-man-arm-motion {
	50% {
		transform: translateY(2px);
	}
}

@keyframes oos-normal-banner-ball-man-arm-motion {
	50% {
		transform: translateY(1px);
	}
}

@keyframes oos-normal-banner-ball-motion-x {
	50% {
		transform: translateX(43px);
	}
}

@keyframes oos-normal-banner-ball-motion-y {
	50% {
		transform: translateY(21px);
	}
}

@keyframes oos-normal-banner-intelligent-community-security-bus-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(-85px) translateY(-41px);
	}
	70% {
		transform: translateX(-85px) translateY(-41px);
		opacity: 1;
	}
	80% {
		transform: translateX(-85px) translateY(-41px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-high-altitude-throw-object-cloud-motion {
	50% {
		transform: translateX(10px) translateY(10px);
	}
}


@keyframes oos-normal-banner-high-altitude-throw-object-car-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(-85px) translateY(-41px);
	}
	70% {
		transform: translateX(-85px) translateY(-41px);
		opacity: 1;
	}
	80% {
		transform: translateX(-85px) translateY(-41px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-high-altitude-throw-object-bicycle-man-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(68px) translateY(32px);
	}
	70% {
		transform: translateX(68px) translateY(32px);
		opacity: 1;
	}
	75% {
		transform: translateX(68px) translateY(32px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-all-area-tourism-z-cloud-motion {
	0% {
		opacity: 0;
	}
	30% {
		transform: translateX(0);
		opacity: 0;
	}
	50% {
		transform: translateX(-5px);
		opacity: 1;
	}
	70% {
		transform: translateX(0);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-all-area-tourism-bird-motion {
	0% {
		transform: translateX(0) translateY(0);
		opacity: 1;
	}
	50% {
		transform: translateX(-80px) translateY(65px);
		opacity: 1;
	}
	80% {
		transform: translateX(-190px) translateY(35px);
		opacity: 1;
	}
	85% {
		transform: translateX(-190px) translateY(35px);
		opacity: 1;
	}
	87% {
		transform: translateX(-190px) translateY(35px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-all-area-tourism-bird-wing-motion {
	0% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	2.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	7.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	10% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	12.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	15% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	17.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	20% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	22.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	25% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	27.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	30% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	32.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	35% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	37.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	40% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	42.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	45% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	47.5% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_b.webp");
	}
	50% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
	}
	51% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_c.webp");
	}
	100% {
		background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_c.webp");
	}
}

@keyframes oos-normal-intelligent-park-car-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(-90px) translateY(-44px);
	}
	70% {
		transform: translateX(-90px) translateY(-44px);
		opacity: 1;
	}
	80% {
		transform: translateX(-90px) translateY(-44px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-intelligent-scenic-spot-cloud-motion {
	50% {
		transform: translateX(10px) translateY(-10px);
	}
}

@keyframes oos-normal-banner-intelligent-scenic-spot-wave-motion {
	50% {
		transform: translateX(10px) translateY(5px);
	}
}

@keyframes oos-normal-banner-intelligent-scenic-spot-boat-motion {
	0% {
		opacity: 1;
	}
	75% {
		transform: translateX(66px) translateY(36px);
		opacity: 1;
	}
	80% {
		transform: translateX(66px) translateY(36px);
		opacity: 1;
	}
	90% {
		transform: translateX(66px) translateY(36px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-intelligent-scenic-spot-sailboat-man-motion {
	0% {
		transform: translateX(0) translateY(0);
		opacity: 0;
	}
	19% {
		opacity: 0;
	}
	20% {
		transform: translateX(0) translateY(0);
		opacity: 1;
	}
	95% {
		transform: translateX(83px) translateY(40.5px);
		opacity: 1;
	}
	97.5% {
		transform: translateX(83px) translateY(40.5px);
		opacity: 1;
	}
	100% {
		transform: translateX(83px) translateY(40.5px);
		opacity: 0;
	}
}

@keyframes oos-normal-banner-carbon-neutrality-car-motion {
	0% {
		opacity: 1;
	}
	50% {
		transform: translateX(-87px) translateY(-42px);
	}
	70% {
		transform: translateX(-87px) translateY(-42px);
		opacity: 1;
	}
	80% {
		transform: translateX(-87px) translateY(-42px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes oos-normal-banner-carbon-neutrality-smoke-a-motion {
	0% {
		transform: translateX(0);
		opacity: 0;
	}
	29% {
		transform: translateX(0);
		opacity: 0;
	}
	30% {
		transform: translateX(1px);
		opacity: 1;
	}
	34% {
		transform: translateX(2px);
		opacity: 1;
	}
	38% {
		transform: translateX(3px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 0;
	}
}

@keyframes oos-normal-banner-carbon-neutrality-smoke-b-motion {
	0% {
		transform: translateX(0);
		opacity: 0;
	}
	33% {
		transform: translateX(0);
		opacity: 0;
	}
	34% {
		transform: translateX(1px);
		opacity: 0;
	}
	38% {
		transform: translateX(2px);
		opacity: 1;
	}
	42% {
		transform: translateX(3px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 0;
	}
}

@keyframes oos-normal-banner-carbon-neutrality-smoke-c-motion {
	0% {
		transform: translateX(0);
		opacity: 0;
	}
	37% {
		transform: translateX(0);
		opacity: 0;
	}
	38% {
		transform: translateX(1px);
		opacity: 0;
	}
	42% {
		transform: translateX(2px);
		opacity: 1;
	}
	46% {
		transform: translateX(3px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 0;
	}
}

@keyframes oos-normal-banner-carbon-neutrality-smoke-d-motion {
	0% {
		transform: translateX(0);
		opacity: 0;
	}
	41% {
		transform: translateX(0);
		opacity: 0;
	}
	42% {
		transform: translateX(1px);
		opacity: 0;
	}
	46% {
		transform: translateX(2px);
		opacity: 1;
	}
	50% {
		transform: translateX(3px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 0;
	}
}

@keyframes oos-normal-banner-carbon-neutrality-smoke-e-motion {
	0% {
		transform: translateX(0);
		opacity: 0;
	}
	45% {
		transform: translateX(0);
		opacity: 0;
	}
	46% {
		transform: translateX(1px);
		opacity: 0;
	}
	50% {
		transform: translateX(2px);
		opacity: 1;
	}
	54% {
		transform: translateX(3px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 0;
	}
}

@keyframes oos-normal-banner-carbon-neutrality-smoke-f-motion {
	0% {
		transform: translateX(0);
		opacity: 0;
	}
	49% {
		transform: translateX(0);
		opacity: 0;
	}
	50% {
		transform: translateX(1px);
		opacity: 0;
	}
	54% {
		transform: translateX(2px);
		opacity: 1;
	}
	58% {
		transform: translateX(3px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 0;
	}
}
