.oos-home {
	.source {
		position: relative;
		height: 450px;
		background-color: #f5f7fa;
		.source-globe-wrap {
			position: relative;
			height: 450px;
			overflow: hidden;
			user-select: none;
			.source-globe-content {
				&.hide {
					display: none;
				}
				.source-globe {
					position: relative;
					top: -150px;
					left: -160px;
					margin: 0 auto;
					width: 1400px;
					height: 1400px;
				}
				.source-globe-placeholder-wrap {
					position: relative;
					top: -150px;
					left: -160px;
					margin: 0 auto;
					width: 1400px;
					height: 1400px;
					z-index: 0;
					&.hide {
						display: none;
					}
					.source-globe-placeholder {
						position: absolute;
						top: 212px;
						left: 212px;
						width: 977px;
						height: 605.36px;
						background-image: url("../../../images/home/source/globe_placeholder.webp");
						background-size: 100% 100%;
					}
				}
			}

			.source-globe-static-img-ph {
				&.hide {
					display: none;
				}
			}
		}
		.source-globe-legend-wrap {
			position: absolute;
			top: 80px;
			left: 50%;
			width: @content-width-1400;
			transform: translateX(-50%);
			.source-globe-legend-content {
				position: absolute;
				top: 0;
				right: 50px;
				width: 150px;
				.legend-title {
					font-size: @xxh-font;
					.oos-letter-spacing(2px);
				}
				.legend-item {
					position: relative;
					margin-top: 20px;
					padding-left: 18px;
					font-size: @m-font;
					font-weight: 300;
					color: #b3b9c4;
					.oos-letter-spacing(1px);
					&::before {
						content: " ";
						position: absolute;
						top: 8px;
						left: 0;
						display: inline-block;
						width: 8px;
						height: 8px;
						border-radius: 100%;
					}
					&.opened {
						&::before {
							background-color: #23815d;
						}
					}
					&.opening {
						&::before {
							background-color: #2a86f8;
						}
					}
					&.to-be-opened {
						&::before {
							background-color: #3c1d14;
						}
					}
				}
			}
		}
		.source-content-wrap {
			position: absolute;
			top: 300px;
			left: 50%;
			padding: 25px 25px 25px 0;
			display: flex;
			flex-direction: row;
			width: @content-width-1400;
			height: 300px;
			background-color: @white;
			border-radius: 5px;
			.oos-box-shadow(1px, 1px, 5px, 5px, rgba(116, 130, 166, 0.1));
			transform: translateX(-50%);
			z-index: 1;
			.overview-text-content-box {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				padding: 0 30px;
				width: 500px;
				height: 300px - 25px * 2;
				.overview-text-content-item {
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 50%;
					height: 105px;
					text-align: center;
					.item-number {
						width: 100%;
						font-size: 32px;
						font-weight: 600;
						color: #1c2d47;
						.oos-letter-spacing(2px);
					}
					.item-text {
						width: 100%;
						font-size: @s-font;
						font-weight: 300;
						color: #495672;
					}
				}
			}
			.industry-trend-wrap {
				flex: 1;
				height: 300px - 25px * 2;
				background: linear-gradient(to right, #f3f5f8, @white);
				.industry-trend-carousel {
					position: relative;
					margin: 0 auto;
					height: 100%;
					.industry-trend-opinion {
						position: absolute;
						top: 10px;
						right: 40px;
						width: 98px;
						height: 31px;
						background-image: url("../../../images/home/source/opinion.webp");
						background-size: 100% 100%;
						z-index: 2;
					}
					.industry-trend-carousel-item {
						display: none;
						margin: 0 auto;
						height: 100%;
						padding: 0 40px;
						overflow: hidden; // Enable BFC.
						&.active {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							animation: oos-carousel-switch-fade-in 0.4s ease-in-out forwards;
						}
						.industry-trend-img-wrap {
							min-width: 130px;
							height: 100px;
							position: relative;
							z-index: -1;
							.industry-trend-img {
								position: absolute;
								bottom: -90px;
								left: -50px;
								background-size: 100% 100%;
								&.distributed-storage {
									width: 215px;
									height: 177px;
									background-image: url("../../../images/home/source/distributed_storage.webp");
								}
								&.iot {
									width: 215px;
									height: 178px;
									background-image: url("../../../images/home/source/iot.webp");
								}
								&.edge-computing {
									width: 215px;
									height: 179px;
									background-image: url("../../../images/home/source/edge_computing.webp");
								}
								&.virtualization {
									width: 215px;
									height: 179px;
									background-image: url("../../../images/home/source/virtualization.webp");
								}
							}
						}
						.industry-trend-content {
							position: relative;
							margin-left: 15px;
							flex: 1;
							.oos-letter-spacing(1px);
							.title {
								padding-bottom: 5px;
								font-weight: 500;
								.oos-letter-spacing(2px);
							}
							.text {
								height: 78px;
								line-height: @xh-font;
								font-size: @xs-font;
								color: #545c6c;
								.oos-multiple-line-text-overflow-ellipsis(3);
							}
							.quote {
								position: absolute;
								display: block;
								width: 40px;
								height: 40px;
								background-image: url("../../../images/home/text_content_quote.webp");
								background-size: 100% 100%;
								&.top-left {
									top: -50px;
									left: 0;
								}
								&.bottom-right {
									bottom: -70px;
									right: 0;
									transform: rotateZ(180deg);
								}
							}
						}
					}
					.pagination {
						position: absolute;
						right: 100px;
						bottom: 20px;
						user-select: none;
						.pagination-item {
							position: relative;
							margin-right: 10px;
							display: inline-block;
							width: 30px;
							height: 3px;
							background-color: #bdc7da;
							border-radius: 2px;
							&:nth-last-child(1) {
								margin-right: 0;
							}
							&.active {
								width: 100px;
								background-color: #bdc7da;
								&::after {
									content: " ";
									position: absolute;
									display: block;
									top: 0;
									left: 0;
									bottom: 0;
									background-color: #788298;
									border-radius: 2px;
									animation: oos-carousel-pagination-progress 10s linear;
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1500px) {
			.source-globe-legend-wrap {
				width: @content-width-1200;
			}
			.source-content-wrap {
				width: @content-width-1200;
			}
		}

		@media screen and (max-width: 1300px) {
			.source-globe-legend-wrap {
				width: @content-width-1000;
			}
			.source-content-wrap {
				width: @content-width-1000;
				.overview-text-content-box {
					width: 350px;
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.source-globe-legend-wrap {
				.source-globe-legend-content {
					right: 0;
					width: 100px;
					.legend-title {
						font-size: @xxl-font;
					}
					.legend-item {
						font-size: @m-font;
					}
				}
			}
			.source-content-wrap {
				.overview-text-content-box {
					width: 320px;
					.overview-text-content-item {
						.item-number {
							font-size: @xxh-font;
						}
					}
				}
				.industry-trend-wrap {
					.industry-trend-carousel {
						.industry-trend-carousel-item {
							.industry-trend-content {
								.text {
									font-size: @xxs-font;
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			.source-globe-wrap {
				.source-globe-static-img-ph {
					margin: 50px auto 0;
					width: 600px;
					height: 371.77px;
					background-image: url("../../../images/home/source/globe_placeholder.webp");
					background-size: 100% 100%;
				}
			}
			.source-globe-legend-wrap {
				display: none;
				width: 100%;
			}
			.source-content-wrap {
				top: 200px;
				padding-top: 0;
				padding-bottom: 0;
				width: ~"calc(100% - (@{content-padding-20} * 2))";
				height: 380px;
				flex-direction: column;
				justify-content: center;
				.overview-text-content-box {
					flex-direction: column;
					width: 100%;
					height: 130px;
					.overview-text-content-item {
						width: 25%;
						height: 130px;
					}
				}
				.industry-trend-wrap {
					width: 100%;
					.industry-trend-carousel {
						.industry-trend-opinion {
							right: 0;
						}
						.industry-trend-carousel-item {
							padding: 0;
							height: 250px;
							.industry-trend-img-wrap {
								min-width: 130px;
								.industry-trend-img {
									position: absolute;
									bottom: -95px;
									left: -25px;
								}
							}
							.industry-trend-content {
								.user-name-title {
									font-size: @s-font;
								}
								.quote {
									width: 30px;
									height: 30px;
									&.top-left {
										top: -40px;
									}
									&.bottom-right {
										bottom: -55px;
									}
								}
							}
						}
						.pagination {
							bottom: 29px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.source-globe-wrap {
				.source-globe-static-img-ph {
					width: 300px;
					height: 185.88px;
				}
			}

			.source-globe-legend-wrap {

			}

			.source-content-wrap {
				top: 125px;
				padding-right: 0;
				height: 585px;
				overflow: hidden;
				.overview-text-content-box {
					padding: @content-padding-20;
					flex-direction: row;
					flex-wrap: wrap;
					height: 200px;
					.overview-text-content-item {
						width: 50%;
						height: 70px;
						.item-number {
							font-size: @xl-font;
						}
					}
				}
				.industry-trend-wrap {
					height: 385px;
					.industry-trend-carousel {
						.industry-trend-opinion {
							top: 20px;
							right: 10px;
						}
						.industry-trend-carousel-item {
							padding: 20px 10px 0;
							height: 375px;
							&.active {
								flex-direction: column;
							}
							.industry-trend-img-wrap {
								height: 120px;
								.industry-trend-img {
									position: relative;
									top: -20px;
									bottom: unset;
									left: unset;
									transform: scale(0.8, 0.8);
								}
							}
							.industry-trend-content {
								margin-top: 30px;
								margin-left: 0;
								.title {
									padding-top: 5px;
								}
								.text {
									height: 130px;
									font-size: @xxs-font;
									.oos-multiple-line-text-overflow-ellipsis(5);
								}
								.quote {
									width: 20px;
									height: 20px;
									&.top-left {
										top: -25px;
									}
									&.bottom-right {
										bottom: 10px;
									}
								}
							}
						}
						.pagination {
							bottom: 20px;
							left: 50%;
							width: 220px;
							transform: translateX(-50%);
						}
					}
				}
			}
		}
	}
}
