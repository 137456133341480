.oos-home {
	.product {
		.product-title-tab-wrap {
			border-bottom: 1px solid #e3e6ed;
			.product-title-tab {
				display: flex;
				flex-direction: row;
				margin: 0 auto;
				margin-bottom: -1px;
				width: @content-width-1600;
				height: 40px;
				.product-name-tab-item {
					position: relative;
					padding: 10px 0;
					width: 290px;
					cursor: pointer;
					&::after {
						content: " ";
						position: absolute;
						top: 13px;
						right: -1px;
						width: 1px;
						height: 14px;
						background-color: #c8d1dd;
					}
					&:nth-last-child(1) {
						&::after {
							display: none;
						}
					}
					&.active {
						background-color: #fcfdff;
						border-top-left-radius: 10px;
						border-top-right-radius: 10px;
						border: 1px solid #e3e6ed;
						border-bottom: 1px solid #fcfdff;
						&::after {
							display: none;
						}
					}
					.title-text {
						display: inline-block;
						width: 100%;
						height: 20px;
						line-height: 20px;
						text-align: center;
						font-size: @m-font;
						font-weight: 300;
						color: #545c6c;
						.oos-letter-spacing(1px);
						user-select: none;
					}
				}
			}
		}
		.product-content-wrap {
			position: relative;
			background-color: #f5f7fa;
			border-bottom: 1px solid #e3e6ed;
			transition: all .3s linear;
			&:hover {
				background-color: transparent;
				.product-content-active-bg {
					opacity: 1;
				}
			}
			.product-content-active-bg {
				position: absolute;
				opacity: 0;
				transition: all .5s linear;
				&.bg {
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-image: url("../../../images/home/product/active_bg.webp");
					background-size: cover;
					z-index: -2;
				}
				&.main-bg {
					right: 0;
					bottom: 0;
					width: 860px;
					height: 230px;
					background-image: url("../../../images/home/product/active_main_bg.webp");
					background-size: cover;
					z-index: -1;
				}
			}
			.product-content-box {
				margin: 0 auto;
				width: @content-width-1600;
				.product-content {
					display: none;
					&.active {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						padding: 70px 0;
					}
					.overview-content {
						width: 740px;
						.name {
							font-size: 34px;
							font-weight: 600;
							color: #545c6c;
							.oos-letter-spacing(2px);
						}
						.desc {
							margin-top: 10px;
							font-size: @s-font;
							color: #545c6c;
							.oos-letter-spacing(2px);
						}
						.scenario-box {
							margin-top: 12px;
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							align-content: space-between;
							.scenario-item {
								margin-top: 10px;
								margin-right: 10px;
								display: inline-block;
								padding: 0 25px;
								height: 30px;
								line-height: 30px;
								font-size: @xxs-font;
								color: #545c6c;
								.oos-letter-spacing(1px);
								border: 1px solid #d2d7e2;
								border-radius: 3px;
							}
						}
						.view-detail-button {
							margin-top: 23px;
							.oos-button-core(120px, 30px, @xxs-font, #d5def2);
							background-color: #2a3957;
						}
					}
					.feature-box {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: space-between;
						align-content: space-between;
						width: 760px;
						min-height: 210px;
						.feature {
							display: flex;
							flex-direction: row;
							align-items: center;
							padding: 0 10px 0 0;
							width: 240px;
							height: 90px;
							background: linear-gradient(to bottom, #f0f6ff, @white);
							border: 1px solid @white;
							border-radius: 5px;
							.oos-box-shadow(1px, 5px, 5px, 0, rgba(116, 130, 166, 0.1));
							cursor: default;
							transition: box-shadow .3s linear;
							&:hover {
								background: linear-gradient(to bottom, @white, @white);
								.oos-box-shadow(1px, 6px, 5px, 0, rgba(116, 130, 166, 0.3));
							}

							.feature-icon {
								display: block;
							}
							.feature-text-content {
								width: ~"calc(100% - 60px)";
								.title,
								.desc {
									.oos-text-overflow-ellipsis();
								}
								.title {
									font-size: @l-font;
									font-weight: 500;
									color: #2e394c;
									.oos-letter-spacing(1px);
								}
								.desc {
									margin-top: 2px;
									font-size: @xxs-font;
									color: #b3b9c4;
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {
			.product-content-wrap {
				.product-content-box {
					.product-content {
						.overview-content {
							width: 670px;
						}
						.feature-box {
							.feature {

							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1700px) {
			.product-title-tab-wrap {
				.product-title-tab {
					width: @content-width-1400;
				}
			}
			.product-content-wrap {
				.product-content-box {
					width: @content-width-1400;
					.product-content {
						.overview-content {
							width: 600px;
							.name {
								font-size: 30px;
							}
							.desc {
								font-size: @xs-font;
							}
						}
						.feature-box {
							.feature {

							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1400px) {
			.product-title-tab-wrap {
				.product-title-tab {
					width: @content-width-1200;
					.product-name-tab-item {
						width: 280px;
						.title-text {
							font-size: @s-font;
						}
					}
				}
			}
			.product-content-wrap {
				.product-content-box {
					width: @content-width-1200;
					.product-content {
						.overview-content {
							width: 480px;
							.name {
								font-size: 24px;
							}
							.desc {
								font-size: @xs-font;
								.oos-multiple-line-text-overflow-ellipsis(4);
							}
						}
						.feature-box {
							width: 660px;
							.feature {
								width: 210px;
								.feature-text-content {
									.title {
										font-size: @s-font;
									}
									.desc {

									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.product-title-tab-wrap {
				.product-title-tab {
					width: @content-width-1000;
					.product-name-tab-item {
						width: 240px;
						.title-text {
							font-size: @xs-font;
						}
					}
				}
			}
			.product-content-wrap {
				.product-content-box {
					width: @content-width-1000;
					.product-content {
						.overview-content {
							width: 460px;
							.name {
								font-size: 20px;
							}
							.desc {
								font-size: @xs-font;
								.oos-multiple-line-text-overflow-ellipsis(5);
							}
						}
						.feature-box {
							width: 460px;
							min-height: 300px;
							.feature {
								width: 210px;
								.feature-text-content {
									.title {
										font-size: @s-font;
									}
									.desc {

									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		.product-ph {
			border-bottom: 1px solid #e3e6ed;
			.product-content-wrap-ph {
				.product-name-ph {
					position: relative;
					padding: 0 @content-padding-20;
					height: 50px;
					line-height: 50px;
					background-color: #fcfdff;
					border-top: 1px solid #e3e6ed;
					&.active {
						.arrow-ph {
							background-image: url("../../../images/general/arrow_downward_active.svg");
							transform: rotate(180deg);
						}
					}
					.name-ph {
						font-size: @s-font;
						font-weight: 500;
						.oos-letter-spacing(1px);
						color: #545c6c;
					}
					.arrow-ph {
						content: " ";
						position: absolute;
						top: 15px;
						right: 12px;
						width: 12px;
						height: 20px;
						background-image: url("../../../images/general/arrow_downward.svg");
						background-size: 100% 100%;
						transition: transform 0.2s linear;
					}
				}
				.product-content-ph {
					display: none;
					padding: 25px @content-padding-20;
					background-color: #f6f7fb;
					&.active {
						display: block;
					}
					.overview-content-ph {
						.desc-ph {
							line-height: @xxl-font;
							font-size: @xs-font;
							font-weight: 300;
							color: #545c6c;
						}
						.scenario-box-ph {
							margin-top: 5px;
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							.scenario-item-ph {
								margin-top: 10px;
								margin-right: 10px;
								padding: 4px 14px;
								font-size: @xxs-font;
								color: #545c6c;
								border: 1px solid #d1d6df;
							}
						}
					}
					.feature-box-ph {
						padding-top: 10px;
						.feature-ph {
							margin-top: 15px;
							padding: 15px 10px;
							display: flex;
							flex-direction: row;
							align-items: center;
							background: linear-gradient(to bottom, #f0f6ff, @white);
							border: 1px solid @white;
							border-radius: 5px;
							box-shadow: 1px 6px 5px 0 rgb(116 130 166 / 30%);
							.feature-icon-ph {

							}
							.feature-text-content-ph {
								padding-left: 5px;
								width: ~"calc(100% - 60px)";
								.title-ph {
									font-size: @m-font;
									color: #2e394c;
								}
								.desc-ph {
									padding-top: 3px;
									font-size: @xxs-font;
									color: #b3b9c4;
									.oos-text-overflow-ellipsis();
								}
							}
						}
					}
					.button-box-ph {
						.view-detail-button-ph {
							margin: 30px auto 0;
							.oos-button-core(120px, 30px, @xxs-font, #d5def2);
							background-color: #2a3957;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 601px) {
		.product-ph {
			.product-content-wrap-ph {
				.product-content-ph {
					.feature-box-ph {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						.feature-ph {
							max-width: ~"calc(50% - 5px)";
							min-width: ~"calc(50% - 5px)";
							&:nth-child(2n + 1) {
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
	}
}
