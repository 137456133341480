.banner {
	.banner-content {
		.normal-banner-anim-base-img {
			position: absolute;
			top: -22px;
			right: 19px;
			width: 574px;
			height: 461px;
			background-image: url("../../images/normalBannerAnim/base.webp");
			background-size: 100% 100%;
			z-index: 0;
			i {
				display: block !important;
			}
			.banner-light-curtain {
				position: absolute;
				z-index: 2;
				&.big {
					top: 45px;
					right: 265px;
					width: 220px;
					height: 207px;
					background-image: url("../../images/normalBannerAnim/light_curtain_big.webp");
					background-size: 100% 100%;
					animation: oos-normal-banner-curtain-fade-in-and-out 5s linear infinite;
				}
				&.small {
					top: 225px;
					right: 411px;
					width: 13px;
					height: 42px;
					background-image: url("../../images/normalBannerAnim/light_curtain_small.webp");
					background-size: 100% 100%;
					animation: oos-normal-banner-curtain-fade-in-and-out 2.5s linear infinite;
				}
			}
			.banner-light-window-curtain {
				position: absolute;
				top: 77px;
				right: -23px;
				width: 62px;
				height: 44px;
				// background-image: url("../../images/normalBannerAnim/light_curtain_window_blue.webp");
				background-size: 100% 100%;
				z-index: 2;
				animation: oos-normal-banner-curtain-window-fade-in-and-out 10s linear infinite;
			}
			.banner-light-point-small {
				position: absolute;
				width: 3px;
				height: 9px;
				border-radius: 3px;
				background-color: @white;
				transform: skewY(-28deg);
				z-index: 2;
				&::before,
				&::after {
					content: " ";
					position: absolute;
					width: 3px;
					height: 9px;
					background-color: @white;
					border-radius: 3px;
				}
				&::before {
					top: 0;
					left: -6px;
				}
				&::after {
					top: 0;
					left: -12px;
				}
				&.left {
					top: 234px;
					right: 280px;
					animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.2s infinite;
					&::before {
						animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.4s infinite;
					}
					&::after {
						animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.6s infinite;
					}
				}
				&.right {
					top: 107px;
					right: 76px;
					animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 2.6s infinite;
					&::before {
						animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 2.4s infinite;
					}
					&::after {
						animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 2.2s infinite;
					}
				}
			}
			.banner-light-circle {
				position: absolute;
				top: 324px;
				right: 40px;
				width: 169px;
				height: 84px;
				background-image: url("../../images/normalBannerAnim/light_circle.webp");
				z-index: 2;
				filter: blur(4px);
				animation: oos-normal-banner-light-point-fade-in-and-out 6s linear infinite;
			}
			.banner-light-bar-box {
				position: absolute;
				top: 163px;
				right: 91px;
				transform: skewY(-28deg);
				z-index: 2;
				.light-bar {
					position: absolute;
					width: 20px;
					height: 5px;
					background-color: #384473;
					overflow: hidden;
					&::before {
						content: " ";
						position: absolute;
						display: block;
						width: 6px;
						height: 5px;
						background-color: #4d598b;
					}
					&:nth-child(1) {
						&::before {
							animation: oos-normal-banner-light-bar-thumb-left-right-motion 1s linear infinite;
						}
					}
					&:nth-child(2) {
						top: -9px;
						left: 0;
						width: 20px;
						height: 5px;
						&::before {
							animation: oos-normal-banner-light-bar-thumb-right-left-motion 1s linear 1.7s infinite;
						}
					}
				}
			}
			.banner-light-ray-box {
				position: absolute;
				z-index: 1;
				.light-ray {
					position: absolute;
					top: 0;
					width: 12px;
					height: 6px;
					background-color: #49f3ff;
					border-radius: 6px;
					backdrop-filter: blur(10px);
					.oos-box-shadow(0, 0, 2px, 2px, rgba(73, 243, 255, 0.2));
					// filter: blur(1px);
				}
				&.top {
					top: 132px;
					right: 187px;
					transform: skewY(-26deg);
					.light-ray {
						animation: oos-normal-banner-light-ray-up-motion 2s ease-out infinite;
						&:nth-child(1) {
							animation-delay: 2s;
						}
						&:nth-child(2) {
							top: 9px;
							left: 9px;
							animation-delay: 0.3s;
						}
						&:nth-child(3) {
							top: 18px;
							left: 18px;
							animation-delay: 1.7s;
						}
						&:nth-child(4) {
							top: 27px;
							left: 27px;
							animation-delay: 1.1s;
						}
						&:nth-child(5) {
							top: 37px;
							left: 38px;
							animation-delay: 0.8s;
						}
					}
				}
				&.bottom {
					top: 306px;
					right: 213px;
					transform: skewY(26deg);
					.light-ray {
						animation: oos-normal-banner-light-ray-bottom-motion 2s ease-out infinite;
						&:nth-child(1) {
							top: -1px;
							left: 39px;
							animation-delay: 0.3s;
						}
						&:nth-child(2) {
							top: 9px;
							left: 29px;
							animation-delay: 1.1s;
						}
						&:nth-child(3) {
							top: 18px;
							left: 19px;
							animation-delay: 2s;
						}
						&:nth-child(4) {
							top: 27px;
							left: 10px;
							animation-delay: 0.7s;
						}
						&:nth-child(5) {
							top: 37px;
							animation-delay: 1.8s;
						}
					}
				}
			}
			.banner-float-img {
				position: absolute;
				background-size: 100% 100%;
				z-index: 1;
				animation: oos-normal-banner-float-img-motion 10s linear infinite;
				& > i {
					position: absolute;
					background-size: 100% 100%;
				}
				// Solution.
				// Storage solution.
				// General category.
				&.big-data {
					top: 54px;
					right: 306px;
					width: 138px;
					height: 185px;
					background-image: url("../../images/normalBannerAnim/solution/bigData/float_base.webp");
				}
				&.high-performance-computing {
					top: 29px;
					right: 274px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/solution/highPerformanceComputing/float_base.webp");
				}
				&.cloud-computing-private-cloud {
					top: 29px;
					right: 274px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/solution/cloudComputingPrivateCloud/float_base.webp");
				}
				&.cloud-native-container-storage {
					top: 29px;
					right: 274px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/solution/cloudNativeContainerStorage/float_base.webp");
				}
				&.data-backup {
					top: 88px;
					right: 290px;
					width: 171px;
					height: 153px;
					background-image: url("../../images/normalBannerAnim/solution/dataBackup/float_base.webp");
				}
				// General category.
				// Industry category.
				&.new-energy-vehicle {
					top: 29px;
					right: 274px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/solution/newEnergyVehicle/float_base.webp");
				}
				&.intelligent-campus {
					top: 40px;
					right: 275px;
					width: 201px;
					height: 197px;
					background-image: url("../../images/normalBannerAnim/solution/intelligentCampus/float_base.webp");
				}
				&.intelligent-security {
					top: 65px;
					right: 319px;
					width: 106px;
					height: 148px;
					background-image: url("../../images/normalBannerAnim/solution/intelligentSecurity/float_base.webp");
				}
				&.ai {
					top: 45px;
					right: 274px;
					width: 201px;
					height: 194px;
					background-image: url("../../images/normalBannerAnim/solution/ai/float_base.webp");
				}
				// Industry category.
				// IoT solution.
				// Intelligent police category.
				&.bright-project {
					top: 110px;
					right: 274px;
					width: 201px;
					height: 130px;
					background-image: url("../../images/normalBannerAnim/solution/brightProject/float_base.webp");
					.build {
						top: 32px;
						right: 108px;
						width: 89px;
						height: 56px;
						background-image: url("../../images/normalBannerAnim/solution/brightProject/float_building.webp");
						z-index: 2;
					}
					.monitor-a {
						top: 0;
						right: 126px;
						width: 19px;
						height: 36px;
						background-image: url("../../images/normalBannerAnim/solution/brightProject/float_monitor_a.webp");
					}
					.monitor-b {
						top: 52px;
						right: 41px;
						width: 19px;
						height: 36px;
						background-image: url("../../images/normalBannerAnim/solution/brightProject/float_monitor_b.webp");
						z-index: 2;
					}
					.monitor-c {
						top: 70px;
						right: 106px;
						width: 19px;
						height: 36px;
						background-image: url("../../images/normalBannerAnim/solution/brightProject/float_monitor_c.webp");
						z-index: 2;
					}
					.car-a {
						top: 26px;
						right: 114px;
						width: 16px;
						height: 9px;
						background-image: url("../../images/normalBannerAnim/solution/brightProject/float_car_a.webp");
						animation: oos-normal-banner-bright-project-car-a-motion 12s ease-in-out infinite;
						z-index: 1;
					}
					.car-b {
						top: 84px;
						right: 132px;
						width: 13px;
						height: 11px;
						background-image: url("../../images/normalBannerAnim/solution/brightProject/float_car_b.webp");
						animation: oos-normal-banner-bright-project-car-b-motion 8s ease-in-out infinite;
						z-index: 1;
					}
				}
				&.major-event-security {
					top: 25px;
					right: 266px;
					width: 218px;
					height: 217px;
					background-image: url("../../images/normalBannerAnim/solution/majorEventSecurity/float_base.webp");
				}
				// Intelligent community category.
				&.intelligent-community {
					top: 65px;
					right: 275px;
					width: 201px;
					height: 177px;
					background-image: url("../../images/normalBannerAnim/solution/intelligentCommunity/float_base.webp");
					.building {
						top: 54px;
						right: 116px;
						width: 85px;
						height: 71px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentCommunity/float_building.webp");
						z-index: 2;
					}
					.garden {
						top: 88px;
						right: 44px;
						width: 119px;
						height: 69px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentCommunity/float_garden.webp");
						z-index: 2;
					}
					.car {
						top: 76px;
						right: 104px;
						width: 34px;
						height: 17px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentCommunity/float_car.webp");
						z-index: 1;
						animation: oos-normal-banner-intelligent-community-car-motion 12s ease-in-out infinite;
					}
				}
				&.intelligent-community-security {
					top: 103px;
					right: 275px;
					width: 202px;
					height: 138px;
					background-image: url("../../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_base.webp");
					.cloud {
						top: -55px;
						right: 118px;
						width: 32px;
						height: 31px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_cloud.webp");
						animation: oos-normal-banner-intelligent-community-security-cloud-motion 9s linear infinite;
					}
					.building {
						top: 8px;
						right: 118px;
						width: 66px;
						height: 67px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_building.webp");
						z-index: 2;
					}
					.building-light-a {
						top: 8px;
						right: 161px;
						width: 1px;
						height: 3px;
						background-color: @red;
						.oos-box-shadow(0, 0, 2px, 1px, @red);
						animation: oos-normal-banner-building-light-blink 6s linear infinite 2.3s;
					}
					.building-light-b {
						top: 12px;
						right: 163px;
						width: 1px;
						height: 3px;
						background-color: @red;
						.oos-box-shadow(0, 0, 2px, 1px, @red);
						animation: oos-normal-banner-building-light-blink 6s linear infinite;
					}
					.bicycle-man {
						top: 39px;
						right: 139px;
						width: 10px;
						height: 13px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_bicycle_man.webp");
						animation: oos-normal-banner-intelligent-community-security-bicycle-man-motion 24s linear infinite 2s;
						z-index: 1;
					}
					.balloon {
						top: -20px;
						right: 31px;
						width: 27px;
						height: 42px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_balloon.webp");
						animation: oos-normal-banner-intelligent-community-security-balloon-motion 6s ease-in-out infinite;
					}
					.ball-man-arm-a {
						top: 72px;
						right: 135px;
						width: 1px;
						height: 3px;
						background-color: #d73c45;
						animation: oos-normal-banner-ball-man-arm-motion 4s ease-in-out infinite;
						z-index: 1;
					}
					.ball-man-arm-b {
						top: 91px;
						right: 92px;
						width: 1px;
						height: 3px;
						background-color: #e5bf3e;
						animation: oos-normal-banner-ball-man-arm-motion 4s ease-in-out infinite;
						z-index: 1;
					}
					.ball {
						top: 67px;
						right: 137px;
						animation: oos-normal-banner-ball-motion-x 8s ease-out infinite;
						z-index: 2;
						&::before {
							content: " ";
							position: absolute;
							top: 0;
							left: 0;
							width: 3px;
							height: 3px;
							border-radius: 100%;
							background-color: @white;
							z-index: 3;
							animation: oos-normal-banner-ball-motion-y 8s linear infinite;
						}
					}
					.bus {
						top: 103px;
						right: 90px;
						width: 22px;
						height: 16px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_bus.webp");
						animation: oos-normal-banner-intelligent-community-security-bus-motion 12s ease-out infinite;
						z-index: 3;
					}
				}
				&.intelligent-elevator {
					top: 85px;
					right: 287px;
					width: 177px;
					height: 158px;
					background-image: url("../../images/normalBannerAnim/solution/intelligentElevator/float_base.webp");
					.wifi {
						top: -25px;
						right: 115px;
						width: 63px;
						height: 50px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentElevator/float_wifi.webp");
						animation: oos-normal-banner-intelligent-elevator-wifi-motion 6s ease-in-out infinite;
					}
					.elevator-car {
						top: -29px;
						right: 15px;
						width: 82px;
						height: 153px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentElevator/float_elevator_car.webp");
						z-index: 1;
						animation: oos-normal-banner-intelligent-elevator-car-motion 8s ease-in-out infinite;
						.man-right {
							position: absolute;
							top: 100px;
							right: 38px;
							width: 10px;
							height: 34px;
							background-image: url("../../images/normalBannerAnim/solution/intelligentElevator/float_man_right.webp");
							// animation: oos-normal-banner-intelligent-elevator-man-right-fade-in-out 3s linear infinite;
						}
					}
					.door-frame {
						top: 27px;
						right: -5px;
						width: 119px;
						height: 107px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentElevator/float_door_frame.webp");
						z-index: 2;
					}
					.door-left {
						top: 68px;
						right: 82px;
						width: 12px;
						height: 47px;
						background-color: #e4dfdb;
						border-right: 1px solid #d8d7d7;
						transform: skewY(25deg) translateX(0);
						z-index: 1;
						animation: oos-normal-banner-intelligent-elevator-door-left-motion 8s linear infinite;
					}
					.door-right {
						top: 75px;
						right: 70px;
						width: 12px;
						height: 47px;
						background-color: #e4dfdb;
						transform: skewY(25deg) translateX(0);
						z-index: 1;
						animation: oos-normal-banner-intelligent-elevator-door-right-motion 8s linear infinite;
					}
					.man-left {
						top: 90px;
						right: 85px;
						width: 14px;
						height: 32px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentElevator/float_man_left.webp");
						z-index: 3;
						animation: oos-normal-banner-intelligent-elevator-man-left-fade-in-out 8s linear infinite;
					}
				}
				&.high-altitude-throw-object {
					top: 114px;
					right: 275px;
					width: 202px;
					height: 127px;
					background-image: url("../../images/normalBannerAnim/solution/highAltitudeThrowObject/float_base.webp");
					.building {
						top: -45px;
						right: 130px;
						width: 71px;
						height: 117px;
						background-image: url("../../images/normalBannerAnim/solution/highAltitudeThrowObject/float_building.webp");
						z-index: 2;
					}
					.building-light-a {
						top: -43px;
						right: 177px;
						width: 1px;
						height: 3px;
						background-color: @red;
						.oos-box-shadow(0, 0, 2px, 1px, @red);
						animation: oos-normal-banner-building-light-blink 6s linear infinite 2.3s;
					}
					.building-light-b {
						top: -48px;
						right: 175px;
						width: 1px;
						height: 3px;
						background-color: @red;
						.oos-box-shadow(0, 0, 2px, 1px, @red);
						animation: oos-normal-banner-building-light-blink 6s linear infinite;
					}
					.cloud {
						top: -65px;
						right: 50px;
						width: 32px;
						height: 31px;
						background-image: url("../../images/normalBannerAnim/solution/highAltitudeThrowObject/float_cloud.webp");
						animation: oos-normal-banner-high-altitude-throw-object-cloud-motion 9s linear infinite;
					}
					.car {
						top: 74px;
						right: 45px;
						width: 19px;
						height: 11px;
						background-image: url("../../images/normalBannerAnim/solution/highAltitudeThrowObject/float_car.webp");
						animation: oos-normal-banner-high-altitude-throw-object-car-motion 12s ease-in-out infinite 1s;
						z-index: 1;
					}
					.camera {
						top: 13px;
						right: 89px;
						width: 80px;
						height: 66px;
						background-image: url("../../images/normalBannerAnim/solution/highAltitudeThrowObject/float_camera.webp");
						z-index: 4;
					}
					.bicycle-man {
						top: 63px;
						right: 163px;
						width: 10px;
						height: 13px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentCommunitySecurity/float_bicycle_man.webp");
						animation: oos-normal-banner-high-altitude-throw-object-bicycle-man-motion 20s linear infinite 2s;
						z-index: 3;
					}
					.ball-man-arm-a {
						top: 61px;
						right: 135px;
						width: 1px;
						height: 3px;
						background-color: #d73c45;
						animation: oos-normal-banner-ball-man-arm-motion 4s ease-in-out infinite;
						z-index: 1;
					}
					.ball-man-arm-b {
						top: 81px;
						right: 92px;
						width: 1px;
						height: 3px;
						background-color: #e5bf3e;
						animation: oos-normal-banner-ball-man-arm-motion 4s ease-in-out infinite;
						z-index: 1;
					}
					.ball {
						top: 56px;
						right: 137px;
						animation: oos-normal-banner-ball-motion-x 8s ease-out infinite;
						z-index: 5;
						&::before {
							content: " ";
							position: absolute;
							top: 0;
							left: 0;
							width: 3px;
							height: 3px;
							border-radius: 100%;
							background-color: @white;
							z-index: 5;
							animation: oos-normal-banner-ball-motion-y 8s linear infinite;
						}
					}
				}
				// Intelligent park category.
				&.intelligent-park {
					top: 70px;
					right: 272px;
					width: 207px;
					height: 170px;
					background-image: url("../../images/normalBannerAnim/solution/intelligentPark/float_base.webp");
					.building-a {
						top: 66px;
						right: 103px;
						width: 100px;
						height: 62px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentPark/float_building_a.webp");
						z-index: 2;
					}
					.building-b {
						top: 19px;
						right: 2px;
						width: 58px;
						height: 100px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentPark/float_building_b.webp");
						z-index: 2;
					}
					.building-c {
						top: 95px;
						right: 54px;
						width: 78px;
						height: 58px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentPark/float_building_c.webp");
						z-index: 2;
					}
					.car {
						top: 109px;
						right: 33px;
						width: 22px;
						height: 16px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentPark/float_car.webp");
						z-index: 1;
						animation: oos-normal-intelligent-park-car-motion 12s ease-in-out infinite;
					}
				}
				// Intelligent scenic spot category.
				&.intelligent-scenic-spot {
					top: 110px;
					right: 273px;
					width: 203px;
					height: 124px;
					background-image: url("../../images/normalBannerAnim/solution/intelligentScenicSpot/float_base.webp");
					.cloud {
						top: -20px;
						right: 155px;
						width: 32px;
						height: 31px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentScenicSpot/float_cloud.webp");
						animation: oos-normal-banner-intelligent-scenic-spot-cloud-motion 9s linear infinite;
						z-index: 1;
					}
					.wave-a {
						top: 62px;
						right: 165px;
						width: 16px;
						height: 9px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentScenicSpot/float_wave.webp");
						animation: oos-normal-banner-intelligent-scenic-spot-wave-motion 6s linear infinite 0.3s;
						z-index: 1;
					}
					.wave-b {
						top: 70px;
						right: 140px;
						width: 16px;
						height: 9px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentScenicSpot/float_wave.webp");
						animation: oos-normal-banner-intelligent-scenic-spot-wave-motion 6s linear infinite 0.7s;
						z-index: 1;
					}
					.wave-c {
						top: 60px;
						right: 142px;
						width: 16px;
						height: 9px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentScenicSpot/float_wave.webp");
						animation: oos-normal-banner-intelligent-scenic-spot-wave-motion 6s linear infinite 1.3s;
						z-index: 1;
					}
					.wave-d {
						top: 85px;
						right: 110px;
						width: 16px;
						height: 9px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentScenicSpot/float_wave.webp");
						animation: oos-normal-banner-intelligent-scenic-spot-wave-motion 6s linear infinite 0.8s;
						z-index: 1;
					}
					.boat {
						top: 25px;
						right: 152px;
						width: 31px;
						height: 41px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentScenicSpot/float_boat.webp");
						animation: oos-normal-banner-intelligent-scenic-spot-boat-motion 12s linear infinite;
						z-index: 2;
					}
					.sailboat-man {
						top: 36px;
						right: 178px;
						width: 17px;
						height: 28px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentScenicSpot/float_sailboat_man.webp");
						animation: oos-normal-banner-intelligent-scenic-spot-sailboat-man-motion 18s linear infinite;
						z-index: 3;

					}
				}
				&.all-area-tourism {
					top: 100px;
					right: 258px;
					width: 218px;
					height: 141px;
					background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/float_base.webp");
					.cloud {
						top: 17px;
						right: 62px;
						width: 144px;
						height: 35px;
						background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/cloud.webp");
						z-index: 1;
						animation: oos-normal-banner-all-area-tourism-z-cloud-motion 15s linear infinite;
					}
					.tree {
						top: 22px;
						right: 157px;
						width: 27px;
						height: 42px;
						background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/tree.webp");
						z-index: 2;
					}
					.bird {
						top: 10px;
						right: 10px;
						width: 17px;
						height: 11px;
						background-image: url("../../images/normalBannerAnim/solution/allAreaTourism/bird_a.webp");
						z-index: 3;
						animation:
							oos-normal-banner-all-area-tourism-bird-motion 16s linear infinite,
							oos-normal-banner-all-area-tourism-bird-wing-motion 16s ease-out infinite;
					}
				}
				// Carbon neutrality category.
				&.carbon-neutrality {
					top: 114px;
					right: 271px;
					width: 205px;
					height: 125px;
					background-image: url("../../images/normalBannerAnim/solution/carbonNeutrality/float_base.webp");
					.car {
						top: 75px;
						right: 61px;
						width: 19px;
						height: 16px;
						background-image: url("../../images/normalBannerAnim/solution/carbonNeutrality/float_car.webp");
						animation: oos-normal-banner-carbon-neutrality-car-motion 12s linear infinite;
						z-index: 1;
					}
					.factory {
						top: 19px;
						right: 84px;
						width: 108px;
						height: 85px;
						background-image: url("../../images/normalBannerAnim/solution/carbonNeutrality/float_factory.webp");
						z-index: 2;
					}
					.smoke-a {
						top: -6px;
						right: 123px;
						width: 31px;
						height: 38px;
						background-image: url("../../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_a.webp");
						animation: oos-normal-banner-carbon-neutrality-smoke-a-motion 5s linear infinite;
						z-index: 3;
					}
					.smoke-b {
						top: -18px;
						right: 83px;
						width: 69px;
						height: 56px;
						background-image: url("../../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_d.webp");
						animation: oos-normal-banner-carbon-neutrality-smoke-b-motion 5s linear infinite;
						z-index: 4;
					}
					.smoke-c {
						top: -30px;
						right: 24px;
						width: 128px;
						height: 105px;
						background-image: url("../../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_c.webp");
						animation: oos-normal-banner-carbon-neutrality-smoke-c-motion 5s linear infinite;
						z-index: 5;
					}
					.smoke-d {
						top: -40px;
						right: -26px;
						width: 186px;
						height: 121px;
						background-image: url("../../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_d.webp");
						animation: oos-normal-banner-carbon-neutrality-smoke-d-motion 5s linear infinite;
						z-index: 6;
					}
					.smoke-e {
						top: -28px;
						right: -8px;
						width: 129px;
						height: 88px;
						background-image: url("../../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_e.webp");
						animation: oos-normal-banner-carbon-neutrality-smoke-e-motion 5s linear infinite;
						z-index: 7;
					}
					.smoke-f {
						top: -12px;
						right: -18px;
						width: 118px;
						height: 76px;
						background-image: url("../../images/normalBannerAnim/solution/carbonNeutrality/float_smoke_f.webp");
						animation: oos-normal-banner-carbon-neutrality-smoke-f-motion 5s linear infinite;
						z-index: 8;
					}
				}
				// Intelligent garden category.
				&.intelligent-garden {
					top: 85px;
					right: 275px;
					width: 207px;
					height: 147px;
					background-image: url("../../images/normalBannerAnim/solution/intelligentGarden/float_base.webp");
					.cloud {
						top: -10px;
						right: 150px;
						width: 32px;
						height: 31px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentGarden/float_cloud.webp");
						animation: oos-normal-banner-intelligent-garden-cloud-motion 6s linear infinite;
					}
					.cable-car {
						top: 9px;
						right: 81px;
						width: 16px;
						height: 17px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentGarden/float_cable_car.webp");
						z-index: 1;
						animation: oos-normal-banner-intelligent-garden-cable-car-motion 12s ease-in-out infinite;
					}
					.cable {
						top: 4px;
						right: 34px;
						width: 61px;
						height: 58px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentGarden/float_cable.webp");
						z-index: 1;
					}
					.cable-post-a {
						top: 3px;
						right: 93px;
						width: 4px;
						height: 8px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentGarden/float_cable_post.webp");
						z-index: 2;
					}
					.cable-post-b {
						top: 58px;
						right: 31px;
						width: 4px;
						height: 8px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentGarden/float_cable_post.webp");
						z-index: 2;
					}
					.guide-board {
						top: 44px;
						right: 42px;
						width: 21px;
						height: 55px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentGarden/float_guide_board.webp");
						z-index: 2;
					}
					.car {
						top: 54px;
						right: 128px;
						width: 34px;
						height: 17px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentGarden/float_car.webp");
						z-index: 1;
						animation: oos-normal-banner-intelligent-garden-car-motion 12s ease-in-out infinite 1s;
					}
					.bottom-trees {
						top: 50px;
						right: 65px;
						width: 134px;
						height: 82px;
						background-image: url("../../images/normalBannerAnim/solution/intelligentGarden/float_bottom_trees.webp");
						z-index: 2;
					}
				}
				// Emergency category.
				&.emergency-command {
					top: 25px;
					right: 275px;
					width: 209px;
					height: 217px;
					background-image: url("../../images/normalBannerAnim/solution/emergencyCommand/float_base.webp");
				}
				&.safety-production-emergency {
					top: 40px;
					right: 275px;
					width: 203px;
					height: 195px;
					background-image: url("../../images/normalBannerAnim/solution/safetyProductionEmergency/float_base.webp");
				}
				// General category.
				&.iot-platform {
					top: 46px;
					right: 274px;
					width: 201px;
					height: 193px;
					background-image: url("../../images/normalBannerAnim/solution/iotPlatform/float_base.webp");
				}
				// Technical support.
				&.document,
				&.document-detail {
					top: 10px;
					right: 275px;
					width: 201px;
					height: 231px;
					background-image: url("../../images/normalBannerAnim/technicalSupport/float_document.webp");
				}
				&.after-sales-case {
					top: 29px;
					right: 275px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/technicalSupport/float_after_sales_case.webp");
				}
				&.image-gallery,
				&.image-gallery-detail {
					top: 7px;
					right: 275px;
					width: 201px;
					height: 232px;
					background-image: url("../../images/normalBannerAnim/technicalSupport/float_image_gallery.webp");
				}
				&.guarantee {
					top: 36px;
					right: 275px;
					width: 201px;
					height: 203px;
					background-image: url("../../images/normalBannerAnim/technicalSupport/float_guarantee.webp");
				}
				// News.
				&.firm-news,
				&.firm-news-detail {
					top: 29px;
					right: 275px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/news/float_news.webp");
				}
				// About.
				&.about-us {
					top: 29px;
					right: 275px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/about/float_about_us.webp");
				}
				&.leadership {
					top: 31px;
					right: 275px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/about/float_leadership.webp");
				}
				&.honor {
					top: 29px;
					right: 275px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/about/float_honor.webp");
				}
				&.recruitment {
					top: 29px;
					right: 275px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/about/float_recruitment.webp");
				}
				&.contact-us {
					top: 29px;
					right: 275px;
					width: 201px;
					height: 210px;
					background-image: url("../../images/normalBannerAnim/about/float_contact_us.webp");
				}
			}
		}
		// Static image for pad and phone.
		.normal-banner-static-img-pad-ph {
			display: none;
			min-width: 300px;
			height: 170px;
			background-size: 100% 100%;

			// Solution.
			.solution-banner-static-img();
			// Technical support.
			&.document,
			&.document-detail {
				background-image: url("../../images/technicalSupport/document/banner_static_img.webp");
			}
			&.after-sales-case {
				background-image: url("../../images/technicalSupport/afterSalesCase/banner_static_img.webp");
			}
			&.image-gallery,
			&.image-gallery-detail {
				background-image: url("../../images/technicalSupport/imageGallery/banner_static_img.webp");
			}
			&.guarantee {
				background-image: url("../../images/technicalSupport/guarantee/banner_static_img.webp");
			}
			// News.
			&.firm-news,
			&.firm-news-detail {
				background-image: url("../../images/firmNews/banner_static_img.webp");
			}
			// About.
			&.about-us {
				background-image: url("../../images/about/aboutUs/banner_static_img.webp");
			}
			&.leadership {
				background-image: url("../../images/about/leadership/banner_static_img.webp");
			}
			&.honor {
				background-image: url("../../images/about/honor/banner_static_img.webp");
			}
			&.recruitment {
				background-image: url("../../images/about/recruitment/banner_static_img.webp");
			}
			&.contact-us {
				background-image: url("../../images/about/contactUs/banner_static_img.webp");
			}
		}
	}
}
