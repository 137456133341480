.oos-foot-bar {
	padding: 45px ~"calc((100% - @{content-width-1600}) / 2)" 0;
	color: @white;
	background-color: #272e42;
	border-top: 1px solid #3d4355;
	.foot-bar-content {
		position: relative;
		margin: 0 auto;
		width: 100%;
		height: 390px;
		.nav-box {
			float: left; // Float and enable BFC.
			.nav-section {
				float: left;
				width: 180px;
				&.product {
					width: 300px;
				}
				.title {
					margin-bottom: 25px;
					font-weight: 600;
					font-size: @m-font;
					.oos-letter-spacing(2px);
				}
				.item {
					margin-bottom: 20px;
					font-size: @s-font;
					font-weight: 300;
					color: #a5acc0;
					.oos-letter-spacing(1px);
					cursor: pointer;
				}
			}
		}
		.contact-box {
			float: right;
			text-align: right;
			.oos-letter-spacing(1px);
			.er-code-img {
				margin-top: 4px;
				display: inline-block;
				width: 155px;
				height: 155px;
				background-image: url("../../images/bar/er_code.webp");
				background-size: 100% 100%;
				border-radius: 5px;
			}
			.er-code-title {
				margin-top: 15px;
				font-size: @xl-font;
			}
			.er-code-desc,
			.phone-time,
			.location,
			.phone {
				margin-top: 5px;
				font-size: @s-font;
				font-weight: 300;
			}
			.phone {
				margin-top: 20px;
				font-size: 30px;
				.oos-letter-spacing(1px);
			}
			.location {
				margin-top: 6px;
			}
			.er-code-desc,
			.phone-time {
				font-size: @xxs-font;
			}
		}
		.copyright-and-record {
			position: absolute;
			left: 0;
			bottom: 55px;
			text-align: center;
			font-size: @s-font;
			font-weight: 300;
			color: #8f9299;
			.copyright {
				display: inline;
				.divider {
					display: inline;
				}
			}
			.record {
				display: inline;
				a {
					color: #8f9299;
					text-decoration: none;
					height: 16px;
				}
			}
		}
	}

	@media screen and (max-width: 1700px) {
		padding: 45px ~"calc((100% - @{content-width-1400}) / 2)" 0;
	}

	@media screen and (max-width: 1400px) {
		padding: 45px ~"calc((100% - @{content-width-1200}) / 2)" 0;
		.foot-bar-content {
			.nav-box {
				.nav-section {
					width: 150px;
					&.product {
						width: 270px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		padding: 45px ~"calc((100% - @{content-width-1000}) / 2)" 0;
		.foot-bar-content {
			display: flex;
			flex-direction: column;
			height: 570px;
			.nav-box {
				display: flex;
				flex-direction: row;
				.nav-section {
					flex: 1;
					&.product {
						flex: 1.6;
						min-width: 210px;
					}
				}
			}
			.contact-box {
				margin-top: 30px;
				display: flex;
				flex-direction: row;
				float: none;
				text-align: left;
				.contact-text-content {
					margin-left: 30px;
					.er-code-title {
						margin-top: 10px;
						font-size: @l-font;
					}
					.phone {
						font-size: @xxl-font;
					}
				}
			}
			.copyright-and-record {
				font-size: @xxs-font;
			}
		}
	}

	@media screen and (max-width: 768px) {
		padding: 45px @content-padding-20 0;
		.foot-bar-content {
			.copyright-and-record {
				font-size: @mini-font;
			}
		}
	}

	@media screen and (max-width: 550px) {
		padding: 45px @content-padding-20 0;
		.foot-bar-content {
			display: block;
			height: 335px;
			.nav-box {
				display: none;
			}
			.contact-box {
				margin-top: 0;
				display: block;
				text-align: right;
				.er-code-img {
					margin-top: 0;
					width: 100px;
					height: 100px;
				}
				.contact-text-content {
					margin-left: 0;
				}
			}
			.copyright-and-record {
				bottom: 30px;
				width: 100%;
				text-align: right;
				font-size: @mini-font;
				.copyright {
					display: block;
					.divider {
						display: none;
					}
				}
				.record {
					display: block;
				}
			}
		}
	}
}
