.oos-recruitment-us {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-normal-banner-core();
	}
	.section-title {
		.oos-section-title-core();
	}
	.team-building {
		margin: 0 auto;
		width: @content-width-1200;
		.desc {
			line-height: 32px;
			font-size: @m-font;
			color: #545c6c;
		}
		.team-building-box {
			position: relative;
			margin-top: 50px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			.team-building-item {
				min-width: 400px;
				height: 270px;
				background-size: 100% 100%;
				&:nth-child(1) {
					background-image: url("../../images/about/recruitment/team_building_1.webp");
					border-top-left-radius: 5px;
				}
				&:nth-child(2) {
					background-image: url("../../images/about/recruitment/team_building_2.webp");
				}
				&:nth-child(3) {
					background-image: url("../../images/about/recruitment/team_building_3.webp");
					border-top-right-radius: 5px;
				}
				&:nth-child(4) {
					background-image: url("../../images/about/recruitment/team_building_4.webp");
					border-bottom-left-radius: 5px;
				}
				&:nth-child(5) {
					background-image: url("../../images/about/recruitment/team_building_5.webp");
				}
				&:nth-child(6) {
					background-image: url("../../images/about/recruitment/team_building_6.webp");
					border-bottom-right-radius: 5px;
				}
			}
			.bubble-content {
				position: absolute;
				top: 0;
				left: 0;
				width: @content-width-1200;
				height: 800px;
				.bubble {
					position: relative;
					float: left;
					width: 30px;
					height: 30px;
					&::before {
						content: " ";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background-color: transparent;
						border-radius: 50%;
						transform: scale3d(0.1, 0.1, 1);
						transition: .5s transform ease-in, .5s background ease-in;
					}
					&:hover {
						&::before {
							transform: scale3d(1.5, 1.5, 1.5);
							transition: 0s transform;
						}
					}

					.rgbRandomMixin() {
						@functions: ~`
							(function() {
							    // Create a customized less fuc named 'rbgRandom'.
								this.rgbRandom = function() {
									return Math.round(Math.random() * 255);
								}
							})()
						`;
					}
					.rgbRandomMixin();

					.loop(@n, @i: 1) when (@i =< @n) {
					    &:nth-child(@{i}) {
							&:hover {
								&::before {
									background-color: rgba(~`rgbRandom()`, ~`rgbRandom()`, ~`rgbRandom()`, .8);
								}
							}
						}
					    .loop(@n, @i + 1);
					}
					.loop(720, 1);
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {
			.team-building-box {
				.bubble-content {
					display: none;
				}
			}
		}

		@media screen and (max-width: 1200px) {
			width: @content-width-1000;
			.desc {
				text-align: center;
				font-size: @s-font;
			}
			.team-building-box {
				margin: 50px auto 0;
				width: 990px;
				.team-building-item {
					min-width: 330px;
					height: 220px;
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			width: 100%;
			.team-building-box {
				margin: 40px auto 0;
				width: 360px * 2;
				.team-building-item {
					min-width: 360px;
					height: 240px;
					&:nth-child(1) {
						border-top-left-radius: 5px;
					}
					&:nth-child(2) {
						border-top-right-radius: 5px;
					}
					&:nth-child(3) {
						border-top-right-radius: unset;
					}
					&:nth-child(4) {
						border-bottom-left-radius: unset;
					}
					&:nth-child(5) {
						border-bottom-left-radius: 5px;
					}
					&:nth-child(6) {
						border-bottom-right-radius: 5px;
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.team-building-box {
				margin: 0 auto;
				display: block;
				width: 100%;
				.team-building-item {
					margin: 20px auto 0;
					min-width: 320px;
					width: 320px;
					height: 213px;
					border-radius: 5px;
					&:nth-child(1) {
						border-top-left-radius: unset;
					}
					&:nth-child(2) {
						border-top-right-radius: unset;
					}
					&:nth-child(3) {
						border-top-right-radius: unset;
					}
					&:nth-child(4) {
						border-bottom-left-radius: unset;
					}
					&:nth-child(5) {
						border-bottom-left-radius: unset;
					}
					&:nth-child(6) {
						border-bottom-right-radius: unset;
					}
				}
			}
		}
	}
	.benefit {
		margin: 0 auto;
		width: @content-width-1200;
		.benefit-box {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: space-between;
			height: 180px * 2 + 40px;
			text-align: center;
			.benefit-item {
				padding: 30px 0;
				width: 330px;
				height: 180px;
				text-align: center;
				background: linear-gradient(to bottom right, #f0f6ff, @white);
				border: 1px solid @white;
				border-radius: 5px;
				.oos-box-shadow(1px, 2px, 7px, 0, rgba(116, 130, 166, 0.2));
				.img {
					display: inline-block;
					width: 80px;
					height: 80px;
					background-size: 100% 100%;
					&.insurance {
						background-image: url("../../images/about/recruitment/insurance.webp");
					}
					&.afternoon-tea {
						background-image: url("../../images/about/recruitment/afternoon_tea.webp");
					}
					&.birthday-party {
						background-image: url("../../images/about/recruitment/birthday_party.webp");
					}
					&.regular-team-building {
						background-image: url("../../images/about/recruitment/regular_team_building.webp");
					}
					&.dormitory {
						background-image: url("../../images/about/recruitment/dormitory.webp");
					}
					&.restaurant {
						background-image: url("../../images/about/recruitment/restaurant.webp");
					}
				}
				.title {
					padding-top: 16px;
					font-size: @m-font;
					color: #2b3744;
					.oos-letter-spacing(2px);
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			width: @content-width-1000;
			.benefit-box {
				.benefit-item {
					width: 300px;
					height: 180px;
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			width: 100%;
			.benefit-box {
				min-width: 720px;
				.benefit-item {
					width: 220px;
					height: 180px;
				}
			}
		}

		@media screen and (max-width: 450px) {
			.benefit-box {
				width: 100%;
				min-width: 100%;
				height: auto;
				.benefit-item {
					margin: 20px auto 0;
					padding: 16px 0;
					width: 150px;
					height: 120px;
					.img {
						width: 50px;
						height: 50px;
					}
					.title {
						font-size: @s-font;
					}
				}
			}
		}
	}
	.recruitment {
		margin: 0 auto;
		width: @content-width-1200;
		.recruitment-table-content {
			min-height: 700px;
			.recruitment-table-title {
				position: relative;
				padding: 0 30px;
				height: 60px;
				line-height: 60px;
				background-color: #f2f2f2;
				border: 1px solid #d8d7d7;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				.department-filter-label {
					float: right;
					height: 58px;
					line-height: 58px;
					font-size: @xs-font;
				}
				.department-filter-box {
					float: right;
					margin-top: 11px;
					display: inline-block;
					vertical-align: middle;
					min-width: 138px;
					height: 36px;
					line-height: 36px;
					font-size: @xs-font;
					color: #3c3d3c;
					background-color: @white;
					border-radius: 5px;
					cursor: pointer;
					&:hover {
						& > .department-filter-item {
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
							&::before {
								top: 9px;
								border-top: 6px solid transparent;
								border-right: 6px solid transparent;
								border-bottom: 6px solid @dark-blue;
								border-left: 6px solid transparent;
							}
						}
						.department-filter-dropdown-box {
							display: block;
						}
					}
					.department-filter-item {
						padding: 0 15px;
						height: 36px;
					}
					& > .department-filter-item {
						position: relative;
						border: 1px solid #c2c2c2;
						border-radius: 5px;
						&::before {
							content: " ";
							position: absolute;
							top: 16px;
							right: 15px;
							border-top: 6px solid #474747;
							border-right: 6px solid transparent;
							border-bottom: 6px solid transparent;
							border-left: 6px solid transparent;
						}
					}
					.department-filter-dropdown-box {
						display: none;
						position: relative;
						background-color: @white;
						z-index: 1;
						.department-filter-item {
							border: 1px solid #c2c2c2;
							border-top: none;
							cursor: pointer;
							&:nth-last-child(1) {
								border-bottom-left-radius: 5px;
								border-bottom-right-radius: 5px;
							}
							&:hover {
								color: @dark-blue;
							}
						}
					}
				}
			}
			.recruitment-table-header {
				padding: 0 30px;
				width: 100%;
				height: 40px;
				font-size: @s-font;
				background-color: #f6f6f6;
				border: 1px solid #d8d7d7;
				border-top: none;
				overflow: hidden; // Enable BFC.
				.header-column {
					float: left;
					height: 40px;
					line-height: 40px;
					text-align: center;
					color: #5f5f5f;
					&.recruitment-title {
						width: ~"calc(100% - 300px - 260px)";
						text-align: left;
					}
					&.recruitment-number {
						width: 300px;
					}
					&.actions {
						width: 260px;
					}
				}
			}
			.recruitment-table-row {
				padding: 0 30px;
				font-size: @s-font;
				color: #696969;
				background-color: @white;
				border: 1px solid #d8d7d7;
				border-top: none;
				overflow: hidden; // Enable BFC.
				&:hover {
					background-color: #fbfbfb;
				}
				&:nth-last-child(1) {
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
				}
				&.show-detail {
					.recruitment-detail-info {
						display: block;
					}
				}
				.recruitment-info {
					height: 80px;
					.row-column  {
						float: left;
						height: 80px;
						line-height: 80px;
						text-align: center;
						&.recruitment-title {
							width: ~"calc(100% - 300px - 260px)";
							text-align: left;
							.oos-text-overflow-ellipsis();
							.newest-tag {
								display: inline-block;
								padding: 0 6px;
								vertical-align: 1px;
								height: 18px;
								line-height: 18px;
								text-indent: 0;
								font-size: @xxs-font;
								color: @white;
								background-color: #3d86fe;
								border-radius: 16px;
								transform: scale(.8);
							}
						}
						&.recruitment-number {
							width: 300px;
						}
						&.actions {
							width: 260px;
							.view-detail-button,
							.apply-job-button {
								.oos-button-core(120px, 36px, @xxs-font, @black);
								display: inline-block;
								vertical-align: middle;
								border: 1px solid #c2c2c2;
								border-radius: 5px;
								background: linear-gradient(to bottom, #fafafa, #f9f9f9, #f8f8f8, #f7f7f7, #f6f6f6, #f5f5f5, #f4f4f4, #f3f3f3);
								cursor: pointer;
								&:hover {
									background-color: @white;
								}
								&.disabled {
									color: @light-gray;
									cursor: not-allowed;
									border: 1px solid #d8d7d7;
								}
							}
							.apply-job-button {
								margin-left: 20px;
							}
						}
					}
				}
				.recruitment-detail-info {
					display: none;
					border-top: 1px solid #d8d7d7;
					overflow: hidden;
					.recruitment-title {
						height: 80px;
						line-height: 80px;
						font-size: @m-font;
					}
					.recruitment-content {
						margin-bottom: 50px;
					}
					.button-box {
						padding-bottom: 20px;
						display: flex;
						flex-direction: row;
						justify-content: center;
						.view-detail-button,
						.apply-job-button {
							.oos-button-core(100px, 30px, @xxs-font, #d5def2);
							background-color: #2a3957;
						}
						.view-detail-button {
							margin-right: 20px;
						}
					}
				}
			}
			.empty-text {
				height: 60px;
				line-height: 60px;
				font-size: @xxs-font;
				text-align: center;
				color: @gray;
				border: 1px solid #ededed;
				border-top: none;
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			width: @content-width-1000;
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			width: 100%;
			.recruitment-table-content {
				min-height: auto;
				.recruitment-table-header {
					.header-column {
						&.recruitment-title {
							width: ~"calc(100% - 140px - 220px)";
						}
						&.recruitment-number {
							width: 140px;
						}
						&.actions {
							width: 220px;
						}
					}
				}
				.recruitment-table-row {
					.recruitment-info {
						height: 60px;
						.row-column  {
							height: 60px;
							line-height: 60px;
							&.recruitment-title {
								width: ~"calc(100% - 140px - 220px)";
							}
							&.recruitment-number {
								width: 140px;
							}
							&.actions {
								width: 220px;
								.view-detail-button,
								.apply-job-button {
									.oos-button-core(100px, 36px, @xxs-font, @black);
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.recruitment-table-content {
				.recruitment-table-title {
					padding: 0 10px;
				}
				.recruitment-table-header {
					padding: 0 10px;
					height: 30px;
					font-size: @xxs-font;
					.header-column {
						height: 30px;
						line-height: 30px;
						&.recruitment-title {
							width: ~"calc(100% - 70px - 30px)";
						}
						&.recruitment-number {
							width: 70px;
						}
						&.actions {
							width: 30px;
						}
					}
				}
				.recruitment-table-row {
					padding: 0 10px;
					.recruitment-info {
						height: 40px;
						.row-column  {
							height: 40px;
							line-height: 40px;
							font-size: @xxs-font;
							&.recruitment-title {
								width: ~"calc(100% - 70px - 30px)";
							}
							&.recruitment-number {
								width: 70px;
							}
							&.actions {
								width: 30px;
								.view-detail-button  {
									.oos-button-core(24px, 24px, @xxs-font, @black);
									border-radius: 100%;
									.button-text {
										display: none;
									}
									.oos-button-link-icon {
										margin-left: 0;
									}
								}
								.apply-job-button {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
