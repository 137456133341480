.oos-document-detail {
	.banner {
		.oos-normal-banner-core();
	}
	.content {
		.document-title {
			padding-top: 75px;
			padding-bottom: 30px;
			text-align: center;
			font-size: @xxl-font;
			.oos-letter-spacing(1px);
		}
		.button-bar {
			width: @content-width-1200;
			margin: 0 auto 30px;
			height: 30px;
			text-align: right;
		}
		.download-document-button {
			display: inline-block;
			.oos-button-core(110px, 30px, @xxs-font, #333333);
			border: 1px solid #333333;
		}
		.document-content {
			width: @content-width-1200;
			margin: 50px auto 0;
			height: 900px;
			&.pdf {
				.document-pdf-iframe {
					height: 100%;
					border: 1px solid #ccc;
				}
			}
			&.excel {
				height: auto;
				min-height: 600px;
				.document-excel-frame {
					height: 100%;
					table {
						display: block;
						font-size: @s-font;
						border-collapse: collapse;
						tbody {
							th, td {
								padding: 10px;
								text-align: left;
								font-weight: normal;
								border: 1px solid #ccc;
								span {
									font-size: @s-font !important;
								}
							}
						}
					}
					.excel-cover-sheet {
						table {
							tbody {
								tr {
									td {
										&:first-child {
											background-color: #f5f7f9;
										}
									}
								}
							}
						}
					}
					.excel-content-sheet {
						margin-top: 50px;
						margin-bottom: 30px;
						table {
							thead {
								tr {
									th {
										background-color: #f5f7f9;
									}
								}
							}
							tbody {
								tr {
									&:nth-child(1) {
										background-color: #f5f7f9;
									}
									td {
										position: relative;
										&.normal {

										}
										&.active {
											&::before {
												content: " ";
												position: absolute;
												top: -1px;
												right: -1px;
												bottom: -1px;
												left: -1px;
												border: 1px solid #06bc7b;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.section-title {
			.oos-section-title-core();
		}
		.feedback-content {
			margin: 20px auto 50px;
			width: @content-width-1200;
			.feedback-title {
				margin-bottom: 50px;
				padding-bottom: 10px;
				font-size: @xl-font;
				border-bottom: 1px solid #5c8ae1;
				&::before {
					content: " ";
					float: left;
					margin-top: 5px;
					margin-right: 10px;
					display: block;
					width: 6px;
					height: 20px;
					background-color: #608ff9;
				}
				.feedback-sub-title {
					padding-left: 20px;
					font-size: @xs-font;
					color: @gray;
				}
			}
			.content-input,
			input {
				font-size: @m-font;
				color: @black;
				.oos-placeholder-color();
				background-color: transparent;
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: 1px solid #d1d5db;
				outline: none;
				transition: border 0.5s linear;
				&:focus {
					border-bottom: 1px solid @black;
				}
			}
			.content-input {
				margin-bottom: 30px;
				display: block;
				width: 100%;
				height: 110px;
				resize: none;
				.oos-placeholder-color();
			}
			.contact-tips {
				margin-bottom: 30px;
				font-size: @xs-font;
				color: @gray;
			}
			.contact-box {
				margin-bottom: 30px;
				input {
					display: inline-block;
					height: 50px;
					line-height: 50px;
					vertical-align: middle;
					&:nth-child(1) {
						width: 18%;
					}
					&:nth-child(2) {
						margin-left: 2%;
						width: 18%;
					}
					&:nth-child(3) {
						margin-left: 2%;
						width: 18%;
					}
					&:nth-child(4) {
						margin-left: 2%;
						width: 40%;
					}
				}
			}
			.is-problem-solved-box {
				margin-bottom: 30px;
				color: @black;
				.is-problem-solved-title {
					padding-right: 10px;
				}
				input[type="radio"] {
					margin-right: 5px;
					vertical-align: -1px;
				}
				label {
					padding-right: 10px;
				}
			}
			.confirm-privacy-statement-box {
				margin-bottom: 40px;
				height: 18px;
				line-height: 18px;
				font-size: @xs-font;
				input[type="checkbox"] {
					vertical-align: -1px;
				}
				label {
					user-select: none;
					.read-statement {
						padding-left: 8px;
						color: @gray;
					}
					.privacy-statement-name {
						color: #608ff9;
						text-decoration: none;
						cursor: pointer;
					}
					.store-data {
						color: @gray;
					}
				}
			}
			.submit-button {
				.oos-button-core(120px, 30px, @xxs-font, #d5def2);
				background-color: #2a3957;
				&.disabled {
					background-color: @gray;
					cursor: not-allowed;
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			.button-bar {
				width: @content-width-1000;
			}
			.document-content {
				width: @content-width-1000;
			}
			.feedback-content {
				width: @content-width-1000;
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			.document-title {
				padding-top: 50px;
				padding-bottom: 15px;
				font-size: @xl-font;
			}
			.button-bar {
				width: 100%;
			}
			.document-content {
				margin-top: 30px;
				width: 100%;
				&.excel {
					.document-excel-frame {
						table {
							font-size: @mini-font;
							overflow: auto;
							tbody {
								display: table-row-group;
                                vertical-align: middle;
								th, td {
									padding: 5px;
									span {
										font-size: @mini-font !important;
									}
								}
							}
						}
						.excel-cover-sheet {

						}
						.excel-content-sheet {
							margin-top: 30px;
							margin-bottom: 20px;
						}
					}
				}
			}
			.feedback-content {
				width: 100%;
			}
		}

		@media screen and (max-width: 450px) {
			.document-title {
				padding-top: 30px;
				padding-bottom: 10px;
				font-size: @s-font;
			}
			.button-bar {
				text-align: center;
				margin-bottom: 20px;
			}
			.document-content {
				margin-top: 20px;
			}
			.feedback-content {
				margin-top: 10px;
				.content-input,
				input {
					font-size: @s-font;
				}
				.contact-tips {
					margin-bottom: 10px;
				}
				.contact-box {
					input {
						&:nth-child(1),
						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4) {
							margin-left: 0;
							width: 100%;
						}
					}
				}
				.is-problem-solved-box {
					margin-bottom: 20px;
					.is-problem-solved-title,
					label {
						font-size: @s-font;
					}
					.is-problem-solved-title {
						display: block;
						margin-bottom: 10px;
					}
					label {
						padding-right: 20px;
					}
				}
				.confirm-privacy-statement-box {
					font-size: @xxs-font;
				}
				.submit-button {
					margin: 0 auto;
				}
			}
		}
	}
}
