.oos-contact-us {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-normal-banner-core();
	}
	.section-title {
		.oos-section-title-core();
	}
	.contact {
		margin: 20px auto 10px;
		width: @content-width-1400;
		.contact-box {
			margin: 0 auto;
			display: flex;
			width: @content-width-1200;
			flex-direction: row;
			justify-content: space-between;
			.contact-item {
				padding: 50px 0;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				width: 550px;
				text-decoration: none;
				border-radius: 5px;
				.oos-box-shadow(1px, 2px, 7px, 0, rgba(116, 130, 166, 0.2));
				.icon {
					background-size: 100% 100%;
					&.phone {
						width: 40px;
						height: 50px;
						background-image: url("../../images/about/contactUs/phone.webp");
					}
					&.email {
						width: 50px;
						height: 36px;
						background-image: url("../../images/about/contactUs/email.webp");
					}
				}
				.desc {
					padding-left: 30px;
					.title {
						font-size: @xxl-font;
						color: #2b3744;
					}
					.text {
						margin-top: 5px;
						font-size: @h-font;
						color: #54616e;
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {
			width: @content-width-1200;
			.contact-box {
				width: @content-width-1200;
				.contact-item {
					width: 550px;
				}
			}
		}

		@media screen and (max-width: 1200px) {
			width: @content-width-1000;
			.contact-box {
				width: @content-width-1000;
				.contact-item {
					width: 450px;
				}
			}
		}

		@media screen and (max-width: 768px) {
			margin: 20px auto 0;
			padding: 0 @content-padding-20;
			width: 100%;
			.contact-box {
				width: 100%;
				flex-direction: column;
				align-items: center;
				.contact-item {
					width: 450px;
					&:nth-last-child(1) {
						margin-top: 30px;
					}
					.desc {
						.title {
							font-size: @l-font;
						}
						.text {
							font-size: @xl-font;
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			margin: 0 auto 0;
			.contact-box {
				.contact-item {
					padding: 40px 0;
					width: 100%;
					&:nth-last-child(1) {
						margin-top: 20px;
					}
					.desc {
						.title {
							font-size: @m-font;
						}
						.text {
							font-size: @l-font;
						}
					}
				}
			}
		}
	}
	.address {
		margin: 20px auto 0;
		width: @content-width-1400;
		.address-box {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.address-item {
				position: relative;
				width: 330px;
				height: 454px;
				border-radius: 5px;
				.oos-box-shadow(1px, 2px, 7px, 0, rgba(116, 130, 166, 0.2));
				transition: transform 0.5s linear;
				&:hover {
					transform: translateY(-5px);
				}
				.text-content {
					padding: 30px 25px;
					height: 234px;
					.title {
						padding-bottom: 20px;
						font-size: @m-font;
						color: #2b3744;
					}
					.service,
					.it-support,
					.postal-code,
					.location {
						line-height: @xh-font;
						font-size: @s-font;
						color: #54616e;
					}
				}
				.map {
					width: 100%;
					height: 220px;
					overflow: hidden;
					iframe {
						width: 100%;
						height: 220px;
						border: none;
					}
					&.boston-center-placeholder {
						background-image: url("../../images/about/contactUs/boston_rd_center_map.webp");
						background-size: 100% 100%;
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1500px) {
			width: @content-width-1200;
			.address-box {
				.address-item {
					width: 280px;
					.map {
						&.boston-center-placeholder {
							background-size: cover;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			width: @content-width-1000;
			.address-box {
				.address-item {
					width: 240px;
				}
			}
		}

		@media screen and (max-width: 1000px) {

		}

		@media screen and (max-width: 768px) {
			margin: 20px auto 0;
			width: 100%;
			padding: 0 @content-padding-20;
			.address-box {
				flex-direction: column;
				align-items: center;
				.address-item {
					width: 330px;
					&:hover {
						transform: none;
					}
					&:not(:nth-child(1)) {
						margin-top: 30px;
					}
					.map {
						&.boston-center-placeholder {
							background-size: 100% 100%;
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			margin: 0 auto 0;
			.address-box {
				flex-direction: column;
				align-items: center;
				.address-item {
					width: 100%;
					height: 440px;
					&:not(:nth-child(1)) {
						margin-top: 30px;
					}
					.text-content {
						.title {
							font-size: @s-font;
						}
						.service,
						.it-support,
						.postal-code,
						.location {
							line-height: @h-font;
							font-size: @xs-font;
						}
					}
					.map {
						// 这里居中没有生效，因为之前已经设置成了绝对定位的元素。看下怎么改。
						margin: 0 auto;
						width: 330px;
					}
				}
			}
		}
	}
}
