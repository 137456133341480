.oos-clear-fix () {
    display: block;
    zoom: 1;

    &::after {
        content: " ";
        display: block; // The clear attribute only take effect on block element.
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

.oos-placeholder-color (@color: #696969) {
	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&::-ms-input-placeholder {
		color: @color !important;
		font-weight: 400 !important;
	}
}

.oos-box-shadow (@xOffset: 0, @yOffset: 0, @blurRadius: 1px, @spreadRadius: 1px, @color: #e9e9e9) {
    box-shadow: @arguments;
}

.oos-multiple-box-shadow () {
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
				0 6px 16px 0 rgba(0, 0, 0, 0.08),
				0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.oos-text-overflow-ellipsis () {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.oos-multiple-line-text-overflow-ellipsis (@line: 3) {
	overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-clamp: @line;
	box-orient: vertical;
	// Support with prefix.
	-webkit-line-clamp: @line;
	-webkit-box-orient: vertical;
	-moz-line-clamp: @line;
	-moz-box-orient: vertical;
	-ms-line-clamp: @line;
	-ms-box-orient: vertical;
}

.oos-letter-spacing (@num: 3px) {
    letter-spacing: @num;
}

.oos-button-icon () {
	.oos-button-link-icon {
		display: inline-block;
		transition: transform 0.2s linear;
		&.disabled {
			color: #bfbfbf;
		}
		&.ph {
			padding-left: 5px;
			transition: none !important;
		}
	}
	.oos-button-download-icon {
		display: inline-block;
		margin-left: 5px;
		vertical-align: -1px;
		width: 12px;
		height: 12px;
		background-image: url("../../images/technicalSupport/download.svg");
		background-size: 100% 100%;
		transition: transform 0.2s linear;
		&.disabled {
			background-image: url("../../images/technicalSupport/download_disabled.svg");
		}
	}
	.oos-button-apply-icon {
		display: inline-block;
		margin-left: 5px;
		vertical-align: -1px;
		width: 12px;
		height: 12px;
		background-image: url("../../images/about/recruitment/apply.svg");
		background-size: 100% 100%;
		transition: transform 0.2s linear;
		&.disabled {
			background-image: url("../../images/about/recruitment/apply_disabled.svg");
		}
	}
	&:hover {
		.oos-button-link-icon {
			&:not(.disabled) {
				transform: translateX(4px);
			}
		}
		.oos-button-download-icon {
			&:not(.disabled) {
				transform: translateY(2px);
			}
		}
		.oos-button-apply-icon {
			&:not(.disabled) {
				transform: translateY(2px);
			}
		}
	}

	@media screen and (max-width: 450px) {
		.oos-button-link-icon,
		.oos-button-download-icon,
		.oos-button-view-icon {
			// Disable icon transition in phone device.
			transform: none !important;
		}
	}
}

.oos-button-core (@width: 180px, @height: 50px, @fontSize: @m-font, @color: @white) {
	width: @width;
	height: @height;
	line-height: @height;
	text-align: center;
	font-size: @fontSize;
	font-weight: 300;
	color: @color;
	.oos-letter-spacing(1px);
	cursor: pointer;
	border-radius: 5px;
	user-select: none;
	.oos-button-icon();
}

.oos-text-button-core (@height: 50px, @fontSize: @m-font, @color: @white, @text-align: right) {
	padding: 10px;
	min-height: @height;
	line-height: @height;
	text-align: @text-align;
	font-size: @fontSize;
	font-weight: 300;
	color: @color;
	.oos-letter-spacing(1px);
	cursor: pointer;
	user-select: none;
	.oos-button-icon();
}

.oos-section-nav-core () {
	position: sticky;
	top: 0;
	background-color: #f2f4f7;
	border: 1px solid #e3e6ed;
	z-index: 1;
	.section-nav-box {
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		width: @content-width-1600;
		height: 50px;
		line-height: 50px;
		user-select: none;
		.nav-item {
			position: relative;
			margin-right: 40px;
			font-size: @s-font;
			color: #233759;
			&:hover {
				cursor: pointer;
			}
			&.active {
				&::before {
					content: " ";
					position: absolute;
					left: 50%;
					bottom: 0;
					width: 15px;
					height: 2px;
					background-color: @dark-blue;
					transform: translateX(-50%);
				}
			}
		}
	}

	@media screen and (max-width: 1800px) {
		.section-nav-box {
			width: @content-width-1600;
		}
	}

	@media screen and (max-width: 1700px) {
		.section-nav-box {
			width: @content-width-1400;
		}
	}

	@media screen and (max-width: 1400px) {
		.section-nav-box {
			width: @content-width-1200;
		}
	}

	@media screen and (max-width: 1200px) {
		.section-nav-box {
			width: @content-width-1000;
		}
	}

	@media screen and (max-width: 768px) {
		display: none;
	}
}

.oos-section-title-core () {
	padding: 60px 0 30px;
	text-align: center;
	color: #212d40;
	&.gray-bg {
		background-color: #f7f8f9;
	}
	.title {
		font-size: @xxl-font;
		font-weight: 600;
		.oos-letter-spacing(2px);
	}
	.sub-title {
		margin-top: 5px;
		font-size: @xxs-font;
		.oos-letter-spacing(1px);
	}

	@media screen and (max-width: 768px) {
		padding: 22px @content-padding-20 18px;

		.title {
			font-size: @xl-font;
		}
	}

	@media screen and (max-width: 450px) {
		.title {
			font-size: @l-font;
		}
	}
}

.oos-page-content-bottom-margin {
	& > div {
		&:nth-last-child(1) {
			margin-bottom: 90px;
		}
	}

	@media screen and (max-width: 768px) {
		& > div {
			&:nth-last-child(1) {
				margin-bottom: 60px;
			}
		}
	}

	@media screen and (max-width: 450px) {
		& > div {
			&:nth-last-child(1) {
				margin-bottom: 30px;
			}
		}
	}
}

/*
.oos-document-excel-table-content-column-core () {
	&:nth-child(1),
	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(7) {
		width: 10%;
	}
	&:nth-child(2) {
		width: 13%;
	}
	&:nth-child(5) {
		width: 7%;
	}
	&:nth-child(6) {
		width: 30%;
	}
}
*/

.oos-product-banner-core {
	position: relative;
	padding-top: 60px;
	height: 390px + 60px;
	background-color: @gray-blue;
	overflow: hidden;
	.banner-content {
		padding: 0 ~"calc((100% - @{content-width-1600}) / 2)";
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 100%;
		.banner-title {
			position: relative;
			padding-top: 120px;
			width: 750px;
			font-weight: 300;
			color: #d5def2;
			z-index: 1; // Higher than anim content.
			.title {
				font-size: 46px;
				font-weight: 600;
				.oos-letter-spacing(2px);
			}
			.sub-title {
				margin-top: 15px;
				line-height: @h-font;
				font-size: @s-font;
				color: #9ca8c5;
				.oos-letter-spacing(1px);
			}
		}
		.product-banner-anim-base-img {
			top: -40px;
			right: 0;
		}
		.product-banner-static-img-pad-ph {

		}
	}

	@media screen and (min-width: 1921px) {
		.banner-content {
			position: relative;
			margin: 0 auto;
			padding: unset;
			width: @content-width-1600;
		}
	}

	@media screen and (max-width: 1800px) {
		.banner-content {
			padding: 0 ~"calc((100% - @{content-width-1600}) / 2)";
		}
	}

	@media screen and (max-width: 1700px) {
		.banner-content {
			padding: 0 ~"calc((100% - @{content-width-1400}) / 2)";
			.banner-title {
				width: 700px;
				.title {
					font-size: 40px;
				}
			}
		}
	}

	@media screen and (max-width: 1400px) {
		.banner-content {
			padding: 0 ~"calc((100% - @{content-width-1200}) / 2)";
			.banner-title {
				padding-top: 80px;
				width: 500px;
				.title {
					font-size: @xxh-font;
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.banner-content {
			padding: 0 ~"calc((100% - @{content-width-1000}) / 2)";
			.banner-title {
				padding-top: 60px;
				width: 450px;
				.title {
					font-size: @xxl-font;
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		height: auto;
		.banner-content {
			padding-top: 30px;
			width: 100%;
			justify-content: space-between;
			.banner-title {
				padding: 10px @content-padding-20 30px;
				width: 450px; // Flexable value.
				.sub-title {
					font-size: @xs-font;
					.oos-multiple-line-text-overflow-ellipsis(2);
				}
				.button-box {
					justify-content: center;
				}
			}
			.product-banner-anim-base-img {
				display: none;
			}
			.product-banner-static-img-pad-ph {
				display: block;
			}
		}
	}

	@media screen and (max-width: 450px) {
		.banner-content {
			padding-top: 0;
			display: block;
			width: 100%;
			overflow: hidden; // Enable BFC.
			.banner-title {
				padding: 30px @content-padding-20 30px;
				width: 100%;
				min-width: 100%;
				text-align: center;
				.title {
					font-size: @l-font;
				}
				.sub-title {
					font-size: @xxs-font;
				}
				.button-box {
					justify-content: center;
				}
			}
			.product-banner-anim-base-img {
				display: none;
			}
			.product-banner-static-img-pad-ph {
				float: right;
			}
		}
	}
}

.oos-normal-banner-core {
	position: relative;
	padding-top: 60px;
	height: 390px + 60px;
	background-color: @gray-blue;
	overflow: hidden;
	.banner-content {
		position: relative;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: @content-width-1600;
		height: 100%;
		overflow: hidden;
		z-index: 1; // Make banner-content higher than banner-bg-img in z-axis.
		.banner-title {
			padding-top: 120px;
			width: 700px;
			.title {
				font-size: 46px;
				font-weight: 600;
				color: #d5def2;
				.oos-letter-spacing(2px);
			}
			.sub-title {
				padding-top: 15px;
				font-size: @s-font;
				color: #9ca8c5;
				.oos-letter-spacing(1px);
			}
		}
	}
	.banner-bg-img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		max-width: 1920px;
		height: 450px;
		&:before {
			content: " ";
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: 1002px;
			height: 450px;
			background-image: url("../../images/normalBannerAnim/bg.webp");
			background-size: 100% 100%;
			z-index: 0;
		}
	}

	@media screen and (max-width: 1800px) {
		.banner-bg-img {
			right: -60px;
		}
	}

	@media screen and (max-width: 1700px) {
		.banner-content {
			width: @content-width-1400;
			.banner-title {
				width: 600px;
				.title {
					font-size: 36px;
				}
			}
		}
		.banner-bg-img {
			right: -111px;
		}
	}

	@media screen and (max-width: 1400px) {
		.banner-content {
			width: @content-width-1200;
			.banner-title {
				padding-top: 120px;
				width: 480px;
				.title {
					font-size: @xxh-font;
				}
				.sub-title {
					font-size: @xs-font;
				}
			}
		}
		.banner-bg-img {
			right: -68px;
		}
	}

	@media screen and (max-width: 1200px) {
		.banner-content {
			width: @content-width-1000;
			.banner-title {
				.title {
					font-size: @h-font;
				}
			}
		}
		.banner-bg-img {
			right: -60px;
		}
	}

	@media screen and (max-width: 768px) {
		height: auto;
		.banner-content {
			padding-top: 20px;
			width: 100%;
			justify-content: space-between;
			.banner-title {
				padding: 15px @content-padding-20;
				width: 400px; // Flexable value.
				.sub-title {
					font-size: @xxs-font;
					.oos-multiple-line-text-overflow-ellipsis(2);
				}
			}
			.normal-banner-anim-base-img {
				display: none;
			}
			.normal-banner-static-img-pad-ph {
				display: block;
			}
		}
		.banner-bg-img {
			display: none;
		}
	}

	@media screen and (max-width: 450px) {
		.banner-content {
			padding-top: 0;
			display: block;
			.banner-title {
				padding: 30px @content-padding-20 30px;
				width: 100%;
				min-width: 100%;
				text-align: center;
				.title {
					font-size: @l-font;
				}
			}
			.normal-banner-static-img-pad-ph {
				margin: 0 auto;
				width: 300px;
				height: 170px;
			}
		}
	}
}

.solution-banner-static-img {
	// Storage solution.
	// General category.
	&.big-data {
		background-image: url("../../images/solution/bigData/banner_static_img.webp");
	}
	&.high-performance-computing {
		background-image: url("../../images/solution/highPerformanceComputing/banner_static_img.webp");
	}
	&.cloud-computing-private-cloud {
		background-image: url("../../images/solution/cloudComputingPrivateCloud/banner_static_img.webp");
	}
	&.cloud-native-container-storage {
		background-image: url("../../images/solution/cloudNativeContainerStorage/banner_static_img.webp");
	}
	&.data-backup {
		background-image: url("../../images/solution/dataBackup/banner_static_img.webp");
	}
	// Industry category.
	&.new-energy-vehicle {
		background-image: url("../../images/solution/newEnergyVehicle/banner_static_img.webp");
	}
	&.intelligent-campus {

		background-image: url("../../images/solution/intelligentCampus/banner_static_img.webp");
	}
	&.intelligent-security {
		background-image: url("../../images/solution/intelligentSecurity/banner_static_img.webp");
	}
	&.ai {
		background-image: url("../../images/solution/ai/banner_static_img.webp");
	}
	// IoT solution.
	// Intelligent campus category.
	&.intelligent-campus {
		background-image: url("../../images/solution/intelligentCampus/banner_static_img.webp");
	}
	// Intelligent police category.
	&.bright-project {
		background-image: url("../../images/solution/brightProject/banner_static_img.webp");
	}
	&.major-event-security {
		background-image: url("../../images/solution/majorEventSecurity/banner_static_img.webp");
	}
	// Intelligent community category.
	&.intelligent-community {
		background-image: url("../../images/solution/intelligentCommunity/banner_static_img.webp");
	}
	&.intelligent-community-security {
		background-image: url("../../images/solution/intelligentCommunitySecurity/banner_static_img.webp");
	}
	&.intelligent-elevator {
		background-image: url("../../images/solution/intelligentElevator/banner_static_img.webp");
	}
	&.high-altitude-throw-object {
		background-image: url("../../images/solution/highAltitudeThrowObject/banner_static_img.webp");
	}
	// Intelligent park category.
	&.intelligent-park {
		background-image: url("../../images/solution/intelligentPark/banner_static_img.webp");
	}
	// Intelligent scenic spot category.
	&.intelligent-scenic-spot {
		background-image: url("../../images/solution/intelligentScenicSpot/banner_static_img.webp");
	}
	&.all-area-tourism {
		background-image: url("../../images/solution/allAreaTourism/banner_static_img.webp");
	}
	// Carbon neutrality category.
	&.carbon-neutrality {
		background-image: url("../../images/solution/carbonNeutrality/banner_static_img.webp");
	}
	// Intelligent garden category.
	&.intelligent-garden {
		background-image: url("../../images/solution/intelligentGarden/banner_static_img.webp");
	}
	// Emergency category.
	&.emergency-command {
		background-image: url("../../images/solution/emergencyCommand/banner_static_img.webp");
	}
	&.safety-production-emergency {
		background-image: url("../../images/solution/safetyProductionEmergency/banner_static_img.webp");
	}
	// General category.
	&.iot-platform {
		background-image: url("../../images/solution/iotPlatform/banner_static_img.webp");
	}
}
