.oos-home {
	.banner {
		position: relative;
		padding-top: @top-bar-height;
		height: @banner-height-545;
		background-color: @gray-blue;
		&.news-carousel-active {
			background-color: unset;
			background-image: linear-gradient(to right top, #2996ab 30%, #0b47d6);
		}
		.banner-carousel-wrap {
			position: relative;
			margin: 0 auto;
			max-width: 1920px;
			height: 485px;
			&:hover {
				.carousel {
					.switch-button {
						display: block;
					}
				}
			}
			.carousel {
				position: relative;
				padding: 0 ~"calc((100% - @{content-width-1600}) / 2)";
				height: 100%;
				z-index: 1;
				.switch-button {
					position: absolute;
					top: 210px;
					display: none;
					width: 16px;
					height: 29px;
					cursor: pointer;
					user-select: none;
					z-index: 2;
					&.prev {
						left: 15px;
						background-image: url("../../../images/home/banner/switch_prev.webp");
					}
					&.next {
						right: 15px;
						background-image: url("../../../images/home/banner/switch_next.webp");
					}
				}
				.carousel-item {
					display: none;
					margin: 0 auto;
					width: 100%;
					height: 100%;
					&.active {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						animation: oos-carousel-switch-fade-in 0.8s ease-in;
					}
					&.product {
						.carousel-product-desc {
							position: relative;
							padding-top: 120px;
							width: 800px;
							height: 485px;
							font-weight: 300;
							color: #d5def2;
							z-index: 1; // Higher than anim content.
							.name {
								font-size: 45px;
								font-weight: 600;
								.oos-letter-spacing(2px);
								&:nth-child(2) {
									font-size: 40px;
								}
							}
							.desc {
								margin-top: 5px;
								line-height: 24px;
								font-size: @s-font;
								.oos-letter-spacing(1px);
								.oos-multiple-line-text-overflow-ellipsis(2);
							}
							.view-detail-button {
								margin-top: 20px;
								.oos-button-core(110px, 30px, @xxs-font, #d5def2);
								border: 1px solid #455370;
							}
						}
						.product-banner-anim-base-img {
							top: -64px;
							right: 0;
						}
						.product-banner-static-img-pad-ph {

						}
					}
					&.news {
						.carousel-news-bg {
							position: absolute;
							top: -60px;
							left: 0;
							right: 0;
							max-width: 1920px;
							height: 545px;
							background-image: url("../../../images/home/banner/news_carousel_bg.webp");
							background-size: cover;
							z-index: -1; // Make carousel-news-bg lower than carousel-news-desc in z-axis.
						}
						.carousel-news-desc {
							padding-top: 120px;
							min-width: 100%;
							text-align: center;
							.title {
								font-size: 38px;
								font-weight: 600;
								color: #d5def2;
								.oos-letter-spacing(2px);
							}
							.desc {
								padding-top: 15px;
								font-size: @m-font;
								line-height: @xl-font;
								font-weight: 300;
								color: #b4c3e4;
							}
							.view-detail-button {
								margin: 55px auto 0;
								.oos-button-core(110px, 30px, @xxs-font, #d5def2);
								border: 1px solid #63a2cd;
							}
						}
					}
				}
				.pagination {
					position: absolute;
					top: 400px;
					left: ~"calc((100% - @{content-width-1600}) / 2)";
					display: inline-block;
					margin-right: 70px;
					height: 60px;
					line-height: 64px;
					user-select: none;
					&.news-carousel-active {
						.pagination-item {
							background-color: #bfd5e8;
							opacity: 0.8;
							&.active {
								background-color: #bfd5e8;
								&::after {
									background-color: @white;
								}
							}
						}
					}
					.pagination-item {
						position: relative;
						margin-right: 10px;
						display: inline-block;
						width: 30px;
						height: 3px;
						background-color: #374766;
						border-radius: 2px;
						&:nth-last-child(1) {
							margin-right: 0;
						}
						&.active {
							width: 100px;
							background-color: #374766;
							&::after {
								content: " ";
								position: absolute;
								display: block;
								top: 0;
								left: 0;
								bottom: 0;
								background-color: #d5def2;
								border-radius: 2px;
								animation: oos-carousel-pagination-progress 8s linear;
							}
						}
						&.paused {
							&::after {
								animation-play-state: paused;
							}
						}
					}
				}
			}
			.carousel-3d-model-wrap {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 485px;
				overflow: hidden;
				user-select: none;
				.carousel-3d-model-bg-img {
					position: absolute;
					right: 0;
					width: 850px;
					height: 483px;
					background-image: url("../../../images/home/banner/3d_model_bg.webp");
					background-size: cover;
					z-index: 0;
				}
				.carousel-3d-model-content {
					position: absolute;
					right: ~"calc((100% - @{content-width-1600}) / 2)";
					margin-top: 60px;
					width: 750px;
					height: 470px;
					z-index: 2;
				}
			}

			@media screen and (max-width: 1800px) {
				.carousel {
					padding: 0 ~"calc((100% - @{content-width-1600}) / 2)";
					.carousel-item {
						&.product {
							.carousel-product-desc {
								width: 770px;
							}
							.product-banner-static-img-pad-ph {

							}
						}
					}
					.pagination {
						left: ~"calc((100% - @{content-width-1600}) / 2)";
					}
				}
				.carousel-3d-model-wrap {
					.carousel-3d-model-bg-img {
						right: -30px;
					}
				}
			}

			@media screen and (max-width: 1700px) {
				.carousel {
					padding: 0 ~"calc((100% - @{content-width-1400}) / 2)";
					.carousel-item {
						&.product {
							.carousel-product-desc {
								width: 700px;
								.name {
									font-size: 38px;
								}
							}
							.product-banner-static-img-pad-ph {

							}
						}
					}
					.pagination {
						left: ~"calc((100% - @{content-width-1400}) / 2)";
					}
				}
				.carousel-3d-model-wrap {
					.carousel-3d-model-bg-img {
						right: -100px;
					}
				}
			}

			@media screen and (max-width: 1550px) {
				.carousel {
					padding: 0 ~"calc((100% - @{content-width-1300}) / 2)";
					.pagination {
						left: ~"calc((100% - @{content-width-1300}) / 2)";
					}
				}
				.carousel-3d-model-wrap {
					.carousel-3d-model-bg-img {
						right: -130px;
					}
				}
			}

			@media screen and (max-width: 1400px) {
				.carousel {
					padding: 0 ~"calc((100% - @{content-width-1200}) / 2)";
					.carousel-item {
						&.product {
							.carousel-product-desc {
								padding-top: 120px;
								width: 550px;
								.name {
									font-size: 28px;
								}
								.desc {
									font-size: @xs-font;
								}
							}
							.product-banner-static-img-pad-ph {

							}
						}
						&.news {
							.carousel-news-desc {
								.title {
									font-size: 32px;
								}
							}
						}
					}
					.pagination {
						left: ~"calc((100% - @{content-width-1200}) / 2)";
					}
				}
				.carousel-3d-model-wrap {
					.carousel-3d-model-bg-img {
						right: -170px;
					}
					.carousel-3d-model-content {
						right: -40px;
					}
				}
			}

			@media screen and (max-width: 1280px) {
				.carousel {
					padding: 0 ~"calc((100% - @{content-width-1100}) / 2)";
					.pagination {
						left: ~"calc((100% - @{content-width-1100}) / 2)";
					}
				}
			}

			@media screen and (max-width: 1200px) {
				.carousel {
					padding: 0 ~"calc((100% - @{content-width-1000}) / 2)";
					.carousel-item {
						&.product {
							.carousel-product-desc {
								padding-top: 80px;
								width: 400px;
								.name {
									font-size: @xl-font;
								}
								.desc {

								}
							}
							.product-banner-static-img-pad-ph {

							}
						}
					}
					.pagination {
				        left: ~"calc((100% - @{content-width-1000}) / 2)";
				    }
				}
			}
		}
		/*
		.banner-hot-tab-wrap {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 3;
			.banner-hot-tab-box {
				position: relative;
				margin: 0 auto;
				max-width: @content-width-1600;
				.banner-hot-tab {
					padding: 10px 15px;
					display: flex;
					flex-direction: row;
					width: 760px;
					height: 110px;
					background: linear-gradient(to bottom, #f1f3f6, @white);
					border: 1px solid #f1f3f6;
					border-radius: 5px;
					.oos-box-shadow(1px, 3px, 5px, 0, rgba(42, 57, 87, 0.2));
					user-select: none;

					.hot-link-item {
						flex: 1;
						width: 242.66px;
						padding-right: 10px;
						display: flex;
						flex-direction: row;
						align-items: center;
						height: 90px;
						z-index: 1;
						&:hover {
							cursor: pointer;
						}
						&:nth-child(1) {
							margin-left: 0;
						}
						.hot-link-item-icon {
							margin: 0 10px;
							width: 45px;
							height: 45px;
							&.product-icon {
								background-image: url("../../../images/home/banner/hot_tab_icon_product.webp");
								background-size: 100% 100%;
							}
							&.solution-icon {
								background-image: url("../../../images/home/banner/hot_tab_icon_solution.webp");
								background-size: 100% 100%;
							}
							&.news-icon {
								background-image: url("../../../images/home/banner/hot_tab_icon_news.webp");
								background-size: 100% 100%;
							}
						}
						.hot-link-item-text-content {
							// width: 242.66px - 60px - (15px * 2);
							width: ~"calc(100% - 45px - 20px - 5px)";
							margin-left: 5px;
							.title,
							.desc {
								.oos-text-overflow-ellipsis();
							}
							.title {
								font-size: @l-font;
								font-weight: 500;
								color: #2e394c;
								.oos-letter-spacing(1px);
							}
							.desc {
								margin-top: 2px;
								font-size: @xxs-font;
								color: #b3b9c4;
							}
						}
					}
				}
				.hot-link-thumb {
					position: absolute;
					top: 10px;
					left: 15px;
					width: 242.66px;
					height: 90px;
					background-color: @white;
					border: 1px solid #f1f3f6;
					border-radius: 5px;
					z-index: 0;
				}
			}
			@media screen and (max-width: 1800px) {
				.banner-hot-tab-box {
					max-width: @content-width-1600;
				}
			}

			@media screen and (max-width: 1700px) {
				.banner-hot-tab-box {
					max-width: @content-width-1400;
				}
			}

			@media screen and (max-width: 1550px) {
				.banner-hot-tab-box {
					max-width: @content-width-1300;
				}
			}

			@media screen and (max-width: 1400px) {
				.banner-hot-tab-box {
					max-width: @content-width-1200;
				}
			}

			@media screen and (max-width: 1280px) {
				.banner-hot-tab-box {
					max-width: @content-width-1100;
				}
			}

			@media screen and (max-width: 1200px) {
				.banner-hot-tab-box {
					max-width: @content-width-1000;
				}
			}
		}
		 */
		.banner-bottom-bg-placeholder {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 55px;
			background-color: @white;
		}

		@media screen and (max-width: 1200px) {
			.banner-carousel-wrap {
				&:hover {
					.carousel {
						.switch-button {
							// Pad and phone devices do not need this, use gesture controller instead.
							display: none;
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			height: 280px;
			& + .section-title-box {
				padding-top: 60px + 75px;
			}
			.banner-carousel-wrap {
				height: auto;
				.carousel {
					height: auto;
					.switch-button {
						display: none;
					}
					.carousel-item {
						padding-top: 30px;
						height: 220px;
						&.active {
							position: relative;
							&.product {
								.carousel-product-desc {
									margin: 0 auto;
									padding-top: 20px;
									width: 100%;
									height: auto;
									text-align: center;
									.name {
										font-size: @l-font;
									}
									.desc {
										padding: 0 6%;
										font-size: @xxs-font;
										.oos-multiple-line-text-overflow-ellipsis(2);
									}
									.view-detail-button {
										margin: 20px auto 0;
									}
								}
								.product-banner-anim-base-img,
								.banner-point-bg {
									display: none;
								}
								.product-banner-static-img-pad-ph {
									display: block;
									margin-top: 34px;
								}
							}
							&.news {
								.carousel-news-desc {
									padding-top: 10px;
									.title {
										font-size: @xxl-font;
										font-weight: 500;
									}
									.desc {

									}
									.view-detail-button {
										margin-top: 30px;
									}
								}
							}
						}
					}
					.pagination {
						top: 170px;
						left: 50%;
						margin-right: 0;
						transform: translateX(-50%);
					}
				}
				.carousel-3d-model-wrap {
					display: none;
				}
			}
			/*
			.banner-hot-tab-wrap {
				top: 280px;
				height: 110px;
				.banner-hot-tab-box {
					width: 100%;
					max-width: 100%;
					.banner-hot-tab {
						margin: 0 auto;
						padding: 10px 10px;
						width: 100%;
						max-width: 100%;
						justify-content: center;
						border: none;
						.hot-link-item {
							margin-right: 5px;
							padding-top: 15px;
							padding-bottom: 10px;
							padding-right: 0;
							flex-direction: column;
							align-items: center;
							width: ~"calc((100% - (15px * 2) - (10px * 2)) / 3)";
							background-color: @white;
							border: 1px solid #f1f3f6;
							border-radius: 5px;
							&:nth-last-child(1) {
								margin-right: 0;
							}
							.hot-link-item-icon {
								width: 35px;
								height: 35px;
							}
							.hot-link-item-text-content {
								margin-top: 5px;
								margin-left: 0;
								padding: 0 10px;
								width: 100%;
								text-align: center;
								.title {
									font-size: @s-font;
									.oos-multiple-line-text-overflow-ellipsis(2);
								}
								.desc {
									display: none;
								}
							}
						}
					}
				}

			}
			*/
			.banner-bottom-bg-placeholder {
				display: none;
			}
		}

		@media screen and (max-width: 450px) {
			height: @banner-height-400;
			.banner-carousel-wrap {
				height: 340px;
				.carousel {
					height: 100%;
					.switch-button {
						display: none;
					}
					.carousel-item {
						height: 100%;
						&.active {
							position: relative;
							display: block;
							&.product {
								.carousel-product-desc {
									margin: 0 auto;
									padding: 0 @content-padding-20 0;
									width: 100%;
									text-align: center;
									.name {
										font-size: @l-font;
									}
									.desc {
										padding: 0 6%;
										font-size: @xxs-font;
										.oos-multiple-line-text-overflow-ellipsis(2);
									}
									.view-detail-button {
										margin: 20px auto 0;
									}
								}
								.product-banner-anim-base-img,
								.banner-point-bg {
									display: none;
								}
								.product-banner-static-img-pad-ph {
									float: right;
									margin-top: 24px;
									display: block;
								}
							}
							&.news {
								.carousel-news-bg {
									width: 100%;
									height: 400px;
								}
								.carousel-news-desc {
									padding: 65px @content-padding-20 0;
									.title {
										font-size: @l-font;
										font-weight: 500;
									}
									.desc {
										display: none;
									}
									.view-detail-button {
										margin-top: 40px;
									}
								}
							}
						}
					}
					.pagination {
						top: 285px;
						left: 50%;
						width: 220px;
						transform: translateX(-50%);
					}
				}
				.carousel-3d-model-wrap {
					display: none;
				}
			}
			/*
			.banner-hot-tab-wrap {
				top: @banner-height-400;
				.banner-hot-tab-box {
					.banner-hot-tab {
						.hot-link-item {
							padding-top: 10px;
							.hot-link-item-icon {
								width: 25px;
								height: 25px;
							}
							.hot-link-item-text-content {
								.title {
									font-size: @xxs-font;
									.oos-multiple-line-text-overflow-ellipsis(2);
								}
							}
						}
					}
				}
			}
			*/
		}
	}

	@media screen and (max-width: 450px) {
		.banner {
			&.news-carousel-active {
				background-image: linear-gradient(to right top, #2996ab 20%, #0b47d6);
			}
		}
	}
}
