.oos-cookie-policy-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px 50px;
	background-color: @white;
	transform: translateY(100%);
	transition: transform 0.8s ease;
	.oos-box-shadow(0, 10px, 20px, 10px, hsla(0, 0%, 71%, .5));
	z-index: 3;
	&.visible {
		display: block;
		transform: translateY(0);
	}
	&.hide {
		transform: translateY(100%);
	}
	.oos-cookie-policy-content {
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: @content-width-1600;
		.text-wrap {
			.title {
				font-size: @l-font;
				font-weight: bold;
				.oos-letter-spacing(1px);
			}
			.desc {
				margin-top: 10px;
				font-size: @s-font;
				a {
					color: #608ff9;
					text-decoration: none;
					cursor: pointer;
				}
			}
		}
		.accept-button-wrap {
			display: flex;
			flex-direction: row;
			align-items: center;
			.accept-button {
				display: inline-block;
				.oos-button-core(110px, 30px, @xxs-font, #d5def2);
				background-color: #2a3957;
			}
		}
	}

	@media screen and (max-width: 1700px) {
		.oos-cookie-policy-content {
			width: @content-width-1400;
		}
	}

	@media screen and (max-width: 1500px) {
		.oos-cookie-policy-content {
			width: @content-width-1300;
		}
	}

	@media screen and (max-width: 1400px) {
		.oos-cookie-policy-content {
			width: @content-width-1200;
		}
	}

	@media screen and (max-width: 1200px) {
		.oos-cookie-policy-content {
			width: @content-width-1000;
			.text-wrap {
				width: 800px;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		.oos-cookie-policy-content {
			width: @content-width-800;
			.text-wrap {
				width: 600px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		padding: 20px @content-padding-20;
		.oos-cookie-policy-content {
			display: flex;
			flex-direction: column;
			width: 100%;
			.text-wrap {
				width: 100%;
				.desc {
					font-size: @xxs-font;
				}
			}
			.accept-button-wrap {
				margin-top: 20px;
			}
		}
	}

	@media screen and (max-width: 450px) {
		.oos-cookie-policy-content {
			.text-wrap {
				.title {
					font-size: @m-font;
				}
			}
			.accept-button-wrap {
				.accept-button {
					width: 100%;
				}
			}
		}
	}
}