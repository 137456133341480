.oos-home {
	.contact-us {
		position: relative;
		overflow: hidden;
		&::before {
			content: " ";
			position: absolute;
			bottom: -35px;
			right: 300px;
			display: block;
			width: 830px;
			height: 229px;
			background-image: url("../../../images/home/contact_us_bg.webp");
			background-size: 100% 100%;
			opacity: 0.2;
		}
		.contact-us-content {
			margin: 0 auto;
			padding: 0 0 90px;
			width: @content-width-1400;
			input[type="text"],
			textarea {
				font-size: @m-font;
				color: @black;
				background-color: transparent;
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: 1px solid #d1d5db;
				outline: none;
				transition: border 0.5s linear;
				&:focus {
					border-bottom: 1px solid @black;
				}
			}
			.username-phone {
				margin-bottom: 30px;
				input[type="text"] {
					display: inline-block;
					width: 49%;
					height: 50px;
					line-height: 50px;
					vertical-align: middle;
					.oos-placeholder-color();
					&:nth-child(2) {
						margin-left: 2%;
					}
				}
			}
			.consultation-input {
				margin-bottom: 30px;
				display: block;
				width: 100%;
				height: 110px;
				resize: none;
				.oos-placeholder-color();
			}
			.confirm-privacy-statement {
				margin-bottom: 40px;
				height: 18px;
				line-height: 18px;
				font-size: @xs-font;
				input[type="checkbox"] {
					vertical-align: -1px;
				}
				label {
					user-select: none;
					.read-statement {
						padding-left: 8px;
						color: @gray;
					}
					.privacy-statement-name {
						color: #608ff9;
						text-decoration: none;
						cursor: pointer;
					}
					.store-data {
						color: @gray;
					}
				}
			}
			.submit-button {
				.oos-button-core(120px, 30px, @xxs-font, #d5def2);
				background-color: #2a3957;
				&.disabled {
					background-color: @gray;
					cursor: not-allowed;
				}
			}
		}

		@media screen and (max-width: 1800px) {
			&::before {
				right: 200px;
			}
		}

		@media screen and (max-width: 1700px) {
			&::before {
				right: 100px;
			}
		}

		@media screen and (max-width: 1400px) {
			&::before {
				bottom: -85px;
				right: -200px;
			}
			.contact-us-content {
				width: @content-width-1200;
				.submit-button {
					margin: 25px auto 0;
				}
			}
		}

		@media screen and (max-width: 1300px) {
			.contact-us-content {
				width: @content-width-1000;
			}
		}

		@media screen and (max-width: 1200px) {
			.contact-us-content {
				width: @content-width-1000;
			}
		}

		@media screen and (max-width: 1000px) {
			padding: 0 @content-padding-20;
			.contact-us-content {
				width: 100%;
			}
		}

		@media screen and (max-width: 450px) {
			&::before {
				display: none;
			}

			.contact-us-content {
				padding-bottom: 50px;
				input[type="text"],
				textarea {
					font-size: @s-font;
				}

				.confirm-privacy-statement {
					font-size: @xxs-font;
				}
			}
		}
	}
}
