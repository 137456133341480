.oos-modal {
	position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
	overflow: auto;
	outline: 0;
    z-index: 5;

	.oos-modal-content {
		position: relative;
		// top: 100px; // This value will be calculated dynamically in js code.
		margin: 0 auto;
		padding: 0 50px 30px;
		background: @white;
		border-radius: 5px;
		// .oos-box-shadow(0, 0, 3px, 3px, #f5f5f5);
		transition: top .1s ease-out;
		.oos-modal-hide-button-box {
			position: relative;
			height: 40px;
			line-height: 40px;
			text-align: right;
			.oos-modal-hide-button {
				position: absolute;
				right: -45px;
				width: 40px;
				height: 40px;
				text-align: center;
				cursor: pointer;
				&::before,
				&::after {
					content: " ";
					display: inline-block;
					width: 2px;
					height: 12px;
					background-color: @gray;
				}
				&::before {
					transform: skewX(45deg);
				}
				&::after {
					margin-left: -2px;
					transform: skewX(-45deg);
				}
				&:hover {
					&::before,
					&::after {
						background-color: @black;
					}
				}
			}
		}
		.oos-modal-title {
			padding-bottom: 40px;
			text-align: center;
			font-weight: 600;
			font-size: 38px;
			color: @gray-blue;
		}
		.oos-modal-form-content {
			input {
				border: none;
				outline: none;
			}
			textarea {
				display: block;
				padding: 10px;
				width: 100%;
				height: 100%;
				border: 1px solid #d1d5db;
				border-radius: 5px;
				resize: none;
				outline: none;
			}
			.oos-modal-form-item {
				margin-top: 22px;
				height: 40px;
				line-height: 38px;
				border: 1px solid #d1d5db;
				border-radius: 5px;
				&:nth-child(1) {
					margin-top: 0;
				}
				&.large {
					height: 120px;
					line-height: 120px;
					border: none;
				}
				&.verification-code {
					display: inline-block;
					width: ~"calc(100% - 120px - 10px)";
				}
				&.send-verify-code {
					display: inline-block;
					width: ~"calc(100% - 90px)";
				}
				.label-img {
					float: left;
					position: relative;
					width: 38px;
					height: 38px;
					&::before {
						content: " ";
						position: absolute;
						top: 11px;
						right: 0;
						width: 1px;
						height: 18px;
						background-color: #dadada;
					}
					&.job {
						background-image: url("../../images/modal/form/job.webp");
						background-size: 100% 100%;
					}
					&.fullname {
						background-image: url("../../images/modal/form/fullname.webp");
						background-size: 100% 100%;
					}
					&.phone {
						background-image: url("../../images/modal/form/phone.webp");
						background-size: 100% 100%;
					}
					&.email {
						background-image: url("../../images/modal/form/email.webp");
						background-size: 100% 100%;
					}
					&.password {
						background-image: url("../../images/modal/form/password.webp");
						background-size: 100% 100%;
					}
					&.verification-code {
						background-image: url("../../images/modal/form/code.webp");
						background-size: 100% 100%;
					}
					&.resume {
						background-image: url("../../images/modal/form/resume.webp");
						background-size: 100% 100%;
					}
					&.firm {
						background-image: url("../../images/modal/form/firm.webp");
						background-size: 100% 100%;
					}
				}
				input {
					display: inline-block;
					vertical-align: middle;
					padding-left: 15px;
					width: ~"calc(100% - 38px)";
					&[type="file"] {
						display: none;
					}
					&.fake-file-input {
						cursor: pointer;
					}
				}
			}
			.verification-code-img {
				float: right;
				margin-top: 22px;
				display: inline-block;
				border: 1px solid #d1d5db;
				border-radius: 5px;
				cursor: pointer;
			}
			.send-verify-code-button {
				float: right;
				margin-top: 22px;
				display: inline-block;
				.oos-button-core(80px, 40px, @xxs-font, @black);
				border: 1px solid #d1d5db;
				&.disabled {
					color: @gray;
					cursor: not-allowed;
				}
			}
			.confirm-service-agreement {
				margin-top: 12px;
				height: 18px;
				line-height: 18px;
				font-size: @xxs-font;
				input[type="checkbox"] {
					vertical-align: -1px;
				}
				label {
					user-select: none;
					.read-agreement {
						padding-left: 8px;
						color: @gray;
					}
					.agreement-name {
						padding-left: 2px;
						color: #608ff9;
						text-decoration: none;
						cursor: pointer;
					}
				}
			}
			.submit-button {
				margin-top: 40px;
				.oos-button-core(100%, 40px);
				background-color: @gray-blue;
				&.disabled {
					background-color: @gray;
					cursor: not-allowed;
				}
			}
		}
		.oos-modal-form-switch-action {
			margin-top: 10px;
			font-size: @xxs-font;
			overflow: hidden;
			.switch-tips {
				display: inline-block;
				color: @gray;
			}
			.switch-action-link {
				display: inline-block;
				.oos-text-button-core(30px, @xxs-font, 333333);
				padding-left: 5px;
				color: #608ff9;
				&.forget-password {
					float: right;
					color: @orange;
				}
			}
		}
		.oos-modal-success-tips-box {
			padding-top: 40px;
			text-align: center;
			.success-img {
				margin: 0 auto;
				width: 140px;
				height: 80px;
				background-image: url("../../images/modal/success_img.webp");
				background-size: 100% 100%;
			}
			.success-title {
				margin-top: 30px;
				font-size: 30px;
			}
			.success-desc {
				margin-top: 15px;
				line-height: 20px;
				font-size: @xxs-font;
				color: @gray;
			}
			.success-ok-button {
				margin: 35px auto 0;
				.oos-button-core(140px, 40px, @m-font, @black);
				border: 1px solid #a3a3a3;
			}
		}
		.oos-certificate-picture-box {
			width: 100%;
			img {
				display: block;
				width: 100%;
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20 25px;

			.oos-modal-hide-button-box {
				.oos-modal-hide-button {
					right: -20px;
				}
			}
			.oos-modal-title {
				padding-bottom: 30px;
				font-size: @xxh-font;
				&::before,
				&::after {
					vertical-align: 10px;
				}
			}
			.oos-modal-form-content {
				input,
				textarea {
					font-size: @xs-font;
				}

				.submit-button {
					margin-top: 30px;
					.oos-button-core(100%, 40px, @xs-font);
					background-color: #2a3957;
					&.disabled {
						background-color: @gray;
						cursor: not-allowed;
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.oos-modal-title {
				font-size: @h-font;
				&::before,
				&::after {
					vertical-align: 8px;
				}
			}
			.oos-modal-form-content {
				input,
				textarea {
					font-size: @xxs-font;
				}
			}
		}
	}
}

.oos-modal-mask {
	display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
	opacity: .3;
    z-index: 4;
    animation: oos-modal-mask-fade-in .3s;
    &.visible {
        display: block;
    }
}
