.oos-placeholder {
	height: 100%;
	background-color: @white;
	.placeholder-banner {
		height: 450px;
		background-color: @gray-blue;
	}
	.placeholder-content {
		height: ~"calc(100% - 450px)";
		background-color: @white;
	}

	@media screen and (max-width: 768px) {
		.placeholder-banner {
			height: 250px;
		}
		.placeholder-content {
			height: ~"calc(100% - 250px)";
		}
	}

	@media screen and (max-width: 450px) {
		.placeholder-banner {
			height: 390px;
		}
		.placeholder-content {
			height: ~"calc(100% - 390px)";
		}
	}
}
