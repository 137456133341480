.oos-apply-trial {
	position: relative;
	padding: 0 ~"calc((100% - @{content-width-1600}) / 2)";
	background-color: #1e2536;
	overflow: hidden;
	&::before {
		content: " ";
		position: absolute;
		top: 0;
		right: ~"calc((100% - @{content-width-1600}) / 2)";
		display: block;
		width: 800px;
		height: 150px;
		background-image: url("../../images/bar/apply_trial_bg.webp");
		background-size: 100% 100%;
		z-index: 0;
		transition: transform 0.2s linear;
	}
	&:hover {
		&::before {
			transform: scale(1.02);
		}
	}
	.apply-trial-content {
		position: relative;
		margin: 0 auto;
		padding: 35px 0;
		height: 150px;
		overflow: hidden;
		z-index: 1;
		.apply-trial-title {
			font-size: @xxl-font;
			font-weight: 600;
			color: @white;
			.oos-letter-spacing(1px);
		}
		.apply-trial-button {
			margin-top: 17px;
			.oos-button-core(120px, 30px, @xxs-font, @white);
			background-color: #1855d3;
		}
	}

	@media screen and (max-width: 1700px) {
		padding: 0 ~"calc((100% - @{content-width-1400}) / 2)";
		&::before {
			right: ~"calc((100% - @{content-width-1400}) / 2)";
		}
	}

	@media screen and (max-width: 1400px) {
		padding: 0 ~"calc((100% - @{content-width-1200}) / 2)";
		&::before {
			right: ~"calc((100% - @{content-width-1200}) / 2)";
		}

		.apply-trial-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.apply-trial-title {
				text-align: center;
			}
			.apply-trial-button {
				margin: 0 auto;
			}
		}
	}

	@media screen and (max-width: 1200px) {
		padding: 0 ~"calc((100% - @{content-width-1000}) / 2)";
		&::before {
			right: ~"calc((100% - @{content-width-1000}) / 2)";
		}
	}

	@media screen and (max-width: 1000px) {
		&::before {
			right: 50%;
			transform: translateX(50%);
			transition: none;
		}
		&:hover {
			 &::before {
				 transform: translateX(50%);
			 }
		 }
		.apply-trial-content {
			.apply-trial-title {
				font-size: @m-font;
			}
			.apply-trial-button {
				display: inline-block;
			}
		}
	}

	@media screen and (max-width: 800px) {
		&::before {
			width: 100%;
		}
	}

	@media screen and (max-width: 440px) {
		&::before {
			right: 0;
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			transform: none;
		}
		&:hover {
			&::before {
				transform: none;
			}
		}
	}
}

.oos-service {
	padding: 42px 0;
	background-color: #272e42;
	.service-box {
		margin: 0 auto;
		width: 800px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.service-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 40px;
			line-height: 40px;
			.service-icon {
				width: 30px;
				height: 30px;
				background-size: 100% 100%;
				&.full-day {
					background-image: url("../../images/bar/service_full_day.webp");
				}
				&.original-factory {
					background-image: url("../../images/bar/service_original_factory.webp");
				}
				&.trial {
					background-image: url("../../images/bar/service_trial.webp");
				}
				&.all-period {
					background-image: url("../../images/bar/service_all_period.webp");
				}
			}
			.service-text {
				margin-left: 10px;
				font-size: @xl-font;
				color: @white;
				.oos-letter-spacing(1px);
			}
		}
	}

	@media screen and (max-width: 1000px) {
		.service-box {
			.service-item {
				.service-text {
					font-size: @m-font;
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		padding: 30px @content-padding-20;
		.service-box {
			width: 100%;
			flex-wrap: wrap;
			.service-item {
				min-width: 50%;
				justify-content: center;
				&:nth-child(n + 3) {
					margin-top: 20px;
				}
				.service-text {
					font-size: @m-font;
				}
			}
		}
	}

	@media screen and (max-width: 450px) {
		padding: 30px @content-padding-20;
		.service-box {
			.service-item {
				.service-icon {
					width: 20px;
					height: 20px;
				}
				.service-text {
					font-size: @s-font;
				}
			}
		}
	}
}
