.oos-home {
	.solution {
		position: relative;
		margin: 0 auto;
		max-width: 1920px;
		background-color: #f5f7fa;
		border-top: 1px solid #e3e6ed;
		border-bottom: 1px solid #e3e6ed;
		transition: opacity .3s linear;
		&:hover {
			background-color: transparent;
			.solution-content-active-bg {
				opacity: 1;
			}
		}
		.solution-content-active-bg {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: url("../../../images/home/solution/active_bg.webp");
			background-size: cover;
			opacity: 0;
			transition: opacity .5s linear;
			z-index: -1;
		}
		.solution-content {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.solution-overview {
				width: 450px;
				height: 480px;
				background: @gray-blue url("../../../images/home/solution/overview.webp") no-repeat center bottom;
				overflow: hidden; // Enable BFC.
				.solution-overview-text {
					padding: 50px 40px 0;
					text-align: center;
					line-height: @xxh-font;
					font-size: @s-font;
					color: #d5def2;
					.oos-letter-spacing(2px);
				}
			}
			.solution-category-box {
				position: relative;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				align-content: space-between;
				padding: 65px 50px 55px;
				width: ~"calc(100% - 450px)";
				height: 480px;
				.solution-category-item {
					position: relative;
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 25px 15px 15px 15px;
					width: 430px;
					height: 160px;
					background: linear-gradient(to bottom, #f0f6ff, @white);
					border: 1px solid @white;
					border-radius: 5px;
					.oos-box-shadow(1px, 5px, 5px, 0, rgba(116, 130, 166, 0.1));
					cursor: pointer;
					transition: box-shadow .3s linear;
					&:hover {
						background: linear-gradient(to bottom, @white, @white);
						.oos-box-shadow(1px, 6px, 5px, 0, rgba(116, 130, 166, 0.3));
					}
					.solution-category-icon {
						position: absolute;
						top: -25px;
						left: 22px;
					}
					.solution-category-title-content {
						position: relative;
						width: 120px;
						height: 100%;
						.title {
							padding-top: 6px;
							font-size: @h-font;
							.oos-letter-spacing(2px);
						}
						.view-detail-button {
							position: absolute;
							left: -10px;
							bottom: 0;
							.oos-text-button-core(@xxs-font, @xxs-font, #adb4c0, left);
						}
					}
					.solution-category-text-content {
						position: relative;
						padding: 0 12px;
						width: calc(100% - 120px);
						.angle {
							position: absolute;
							display: block;
							width: 22px;
							height: 14px;
							background-image: url("../../../images/home/text_content_angle.webp");
							&.top-left {
								top: -12px;
								left: 0;
							}
							&.bottom-right {
								bottom: -12px;
								right: 0;
								transform: rotateZ(180deg);
							}
						}
						.solution-category-text-content-text {
							line-height: @xl-font;
							font-size: @xxs-font;
							color: #959eae;
							.oos-multiple-line-text-overflow-ellipsis(5);
						}
					}
				}
			}
		}

		@media screen and (max-width: 1870px) {
			.solution-content {
				.solution-category-box {
					.solution-category-item {
						width: 32%;
					}
				}
			}
		}

		@media screen and (max-width: 1700px) {
			.solution-content {
				.solution-category-box {
					padding-left: 30px;
					padding-right: 30px;
					.solution-category-item {
						.solution-category-title-content {
							width: 100px;
							.title {
								font-size: @xl-font;
							}
						}
						.solution-category-text-content {
							width: ~"calc(100% - 100px)";
						}
					}
				}
			}
		}

		@media screen and (max-width: 1600px) {
			.solution-content {
				.solution-category-box {
					.solution-category-item {
						.solution-category-title-content {
							width: 80px;
							.title {
								font-size: @m-font;
							}
						}
						.solution-category-text-content {
							width: ~"calc(100% - 80px)";
						}
					}
				}
			}
		}

		@media screen and (max-width: 1400px) {
			.solution-content {
				flex-direction: column;
				.solution-overview {
					width: 100%;
					height: 200px;
					background-position: 80% 50%;
					.solution-overview-text {
						margin-left: 150px;
						padding-top: 80px;
						width: 550px;
						text-align: left;
						font-size: @m-font;
					}
				}
				.solution-category-box {
					padding-left: @content-padding-20;
					padding-right: @content-padding-20;
					width: 100%;
				}
			}
		}

		@media screen and (max-width: 1200px) {

		}

		@media screen and (max-width: 768px) {
			.solution-content {
				.solution-overview {
					background-position: 100% 10%;
					.solution-overview-text {
						margin-left: 30px;
						padding: 70px 0 0 0;
						width: 370px;
						font-size: @s-font;
					}
				}
				.solution-category-box {
					flex-direction: row;
					flex-wrap: wrap;
					height: (160px + 40px) * 6 + 65px + 55px;
					.solution-category-item {
						margin-top: 40px;
						width: 100%;
						.oos-dynamic-icon {
							width: 50px;
							height: 50px;
						}
						&:nth-child(1) {
							margin-top: 0;
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.solution-content {
				.solution-overview {
					width: 100vw;
					height: 100vw;
					background-size: 100% 100%;
					background-position: 10% 70%;
					.solution-overview-text {
						margin: 0 auto;
						padding: @content-padding-20;
						width: 100%;
						text-align: center;
						font-size: @xxs-font;
					}
				}
			}
		}
	}
}
