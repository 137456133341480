.oos-float-bar {
	display: none;
	position: fixed;
	right: 20px;
	bottom: 120px;
	z-index: 10;

	&.visible {
		display: block;
	}
	.scroll-to-top {
		width: 40px;
		height: 40px;
		text-align: center;
		background-color: @blue-black;
		border: 1px solid #1855d3;
		border-radius: 100%;
		cursor: pointer;
		opacity: 0.8;
		&:hover {
			.arrow {
				transform: translateY(-2px);
			}
		}
		.arrow {
			display: inline-block;
			margin-top: 4px;
			width: 32px;
			height: 32px;
			background-image: url("../../images/bar/arrow_scroll_top.webp");
			background-size: 100% 100%;
			transition: transform 0.2s linear;
		}
	}
	.apply-trial {
		margin-top: 10px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: @xxs-font;
		color: #adb4c0;
		background-color: @blue-black;
		border: 1px solid #1855d3;
		border-radius: 100%;
		cursor: pointer;
		opacity: 0.8;
	}

	@media screen and (min-width: 1921px) {
		right: ~"calc((100vw - 1920px) / 2 + 20px)";
	}

	@media screen and (max-width: 450px) {
		display: none !important;
	}
}
