// This is the less file for Perfect-Scrollbar component
// with some customize style modifications.

// Container style.
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    touch-action: auto;
}

// Scrollbar rail styles.
.ps__rail-x {
    // Please don't change 'position'
    position: absolute;
    // There must be 'bottom' or 'top' for ps__rail-x
    bottom: 0;
    display: none;
    height: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
}

.ps__rail-y {
    // Please don't change 'position'.
    position: absolute;
    // There must be 'right' or 'left' for ps__rail-y.
    right: 0;
    display: none;
    width: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    z-index: 2;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: #eee;
    opacity: 0.9;
}

// Scrollbar thumb styles.
.ps__thumb-x {
    // Please don't change 'position'.
    position: absolute;
    // There must be 'bottom' for ps__thumb-x.
    bottom: 2px;
    height: 4px;
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, height .2s ease-in-out;
}

.ps__thumb-y {
    // Please don't change 'position'.
    position: absolute;
    // There must be 'right' for ps__thumb-y.
    right: 2px;
    width: 4px;
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
    height: 11px;
    background-color: #999;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    width: 11px;
    background-color: #999;
}

// Rest som styles for custom.
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
	background-color: transparent !important;
	opacity: 0.9;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
	height: 4px !important;
	background-color: #999;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
	width: 4px !important;
	background-color: #999;
}
