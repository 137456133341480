.oos-guarantee {
	.banner {
		.oos-normal-banner-core();
	}
	.content {
		.guarantee-content {
			margin: 0 auto;
			width: @content-width-1200;
			.search-box-label {
				margin-top: 60px;
				font-size: @l-font;
			}
			.search-box {
				position: relative;
				margin-top: 8px;
				padding: 0 30px;
				height: 70px;
				line-height: 70px;
				border: 1px solid #97a6b8;
				border-radius: 10px;
				input {
					width: 95%;
					font-size: @m-font;
					outline: none;
				}
				img {
					float: right;
					margin-top: 24px;
					cursor: pointer;
				}
			}
			.search-result-box {
				margin-bottom: 20px;
				padding: 40px 30px;
				color: #3e3e3e;
				background: linear-gradient(to bottom, #f0f6ff, @white);
				border: 1px solid @white;
				border-radius: 5px;
				.oos-box-shadow(1px, 2px, 7px, 0, rgba(116, 130, 166, 0.2));
				.state {
					font-size: @xxl-font;
					.state-text {
						&.not-found {

						}
						&.invalid {
							color: @orange;
						}
						&.valid {
							color: @green;
						}
					}
				}
				.expiration {
					margin-top: 12px;
				}
				.customer {
					margin-top: 8px;
				}
			}
			.guarantee-state-explain-table {
				.guarantee-state-explain-table-header {
					padding: 0 30px;
					font-size: @s-font;
					color: #5f5f5f;
					background-color: #f6f6f6;
					border: 1px solid #d8d7d7;
					border-top: none;
					overflow: hidden; // Enable BFC.
					.header-column {
						float: left;
						height: 40px;
						line-height: 40px;
						&.state {
							width: 300px;
							text-align: left;
						}
						&.explain {
							width: 698px;
						}
					}
				}
				.guarantee-state-explain-table-row {
					padding: 0 30px;
					font-size: @s-font;
					border: 1px solid #d8d7d7;
					border-top: none;
					overflow: hidden; // Enable BFC.
					&:nth-last-child(1) {
						border-bottom-left-radius: 5px;
						border-bottom-right-radius: 5px;
					}
					.row-column {
						float: left;
						height: 70px;
						line-height: 70px;
						color: #696969;
						&.state {
							width: 300px;
							text-align: left;
							&.not-fund {

							}
							&.invalid {
								color: @orange;
							}
							&.valid {
								color: @green;
							}
						}
						&.explain {
							width: 698px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			.guarantee-content {
				width: @content-width-1000;
				.technical-support-table-search {

				}
				.search-result-box {

				}
				.technical-support-table-title {

				}
				.guarantee-state-explain-table {
					.guarantee-state-explain-table-header {
						font-size: @xs-font;
						.header-column {
							&.state {
								width: 180px;
							}
							&.explain {
								width: ~"calc(100% - 180px)";
							}
						}
					}
					.guarantee-state-explain-table-row {
						font-size: @xs-font;
						.row-column {
							height: 60px;
							line-height: 60px;
							&.state {
								width: 180px;
							}
							&.explain {
								width: ~"calc(100% - 180px)";
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			.guarantee-content {
				width: 100%;
				.technical-support-table-search {

				}
				.search-result-box {

				}
				.technical-support-table-title {

				}
				.guarantee-state-explain-table {
					.guarantee-state-explain-table-header {
						font-size: @xs-font;
						.header-column {
							height: 30px;
							line-height: 30px;
							&.state {
								width: 90px;
							}
							&.explain {
								width: ~"calc(100% - 90px)";
							}
						}
					}
					.guarantee-state-explain-table-row {
						font-size: @xs-font;
						.row-column {
							height: 60px;
							line-height: 60px;
							&.state {
								width: 90px;
							}
							&.explain {
								width: ~"calc(100% - 90px)";
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.guarantee-content {
				.technical-support-table-search {

				}
				.search-result-box {
					padding: 30px 10px;
					.state {
						font-size: @m-font;
					}
					.expiration,
					.customer {
						font-size: @xs-font;
					}
				}
				.technical-support-table-title {

				}
				.guarantee-state-explain-table {
					.guarantee-state-explain-table-header {
						padding: 0 10px;
						font-size: @xxs-font;
						.header-column {
							height: 30px;
							line-height: 30px;
							&.state {
								width: 50px;
							}
							&.explain {
								width: ~"calc(100% - 50px)";
							}
						}
					}
					.guarantee-state-explain-table-row {
						padding: 0 10px;
						font-size: @xxs-font;
						&.more-text-ph {
							.row-column {
								height: 90px;
								line-height: 90px;
								.text {
									display: inline-block;
									vertical-align: middle;
									line-height: 22px;
								}
							}
						}
						.row-column {
							min-height: 60px;
							line-height: 60px;
							&.state {
								width: 50px;
							}
							&.explain {
								width: ~"calc(100% - 50px)";
							}
						}
					}
				}
			}
		}
	}
}
