.oos-home {
	.partner {
		padding: 0 @content-padding-20 - 10px 60px @content-padding-20;
		background-color: #f7f8f9;
		.partner-content-wrap {
			margin: 0 auto;
			max-width: 1920px;
			.partner-box {
				margin-bottom: 20px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-content: space-between;
				flex-wrap: wrap;
				.partner-item {
					flex: 1;
					margin-right: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100px;
					text-decoration: none;
					background: linear-gradient(to bottom, #f0f6ff, @white);
					border: 1px solid @white;
					border-radius: 5px;
					.oos-box-shadow(1px, 5px, 5px, 0, rgba(116, 130, 166, 0.1));
					cursor: pointer;
					transition: box-shadow .3s linear;
					&:hover {
						background: linear-gradient(to bottom, @white, @white);
						.oos-box-shadow(1px, 6px, 5px, 0, rgba(116, 130, 166, 0.3));
					}
					.partner-logo {
						width: 160px;
						height: 40px;
						background-position: 50%;
						background-repeat: no-repeat;
						background-size: contain;
						&.h3c {
							background-image: url("../../../images/home/partnerLogo/h3c.webp");
						}
						&.intel {
							background-image: url("../../../images/home/partnerLogo/intel.webp");
						}
						&.szsm {
							background-image: url("../../../images/home/partnerLogo/szsm.webp");
						}
						&.zgdx {
							background-image: url("../../../images/home/partnerLogo/zgdx.webp");
						}
						&.hw {
							background-image: url("../../../images/home/partnerLogo/hw.webp");
						}
						&.zksg {
							background-image: url("../../../images/home/partnerLogo/zksg.webp");
						}
						&.zgtxfw {
							background-image: url("../../../images/home/partnerLogo/zgtxfw.webp");
						}
						&.zgkjbkxjsyjs {
							background-image: url("../../../images/home/partnerLogo/zgkjbkxjsyjs.webp");
						}
						&.gabjtyjs {
							background-image: url("../../../images/home/partnerLogo/gabjtyjs.webp");
						}
						&.wxxjjsyjs {
							background-image: url("../../../images/home/partnerLogo/wxxjjsyjs.webp");
						}
						&.zghz {
							background-image: url("../../../images/home/partnerLogo/zghz.webp");
						}
						&.lenovo {
							background-image: url("../../../images/home/partnerLogo/lenovo.webp");
						}
						&.lc {
						    background-image: url("../../../images/home/partnerLogo/lc.webp");
					    }
						&.xbnlkjdx {
							background-image: url("../../../images/home/partnerLogo/xbnlkjdx.webp");
						}
						&.xxzc {
							background-image: url("../../../images/home/partnerLogo/xxzc.webp");
						}
						&.xbgydx {
							background-image: url("../../../images/home/partnerLogo/xbgydx.webp");
						}
						&.alibaba {
							background-image: url("../../../images/home/partnerLogo/alibaba.webp");
						}
						&.sxkjdx {
							background-image: url("../../../images/home/partnerLogo/sxkjdx.webp");
						}
						&.jrsa {
							background-image: url("../../../images/home/partnerLogo/jrsa.webp");
						}
						&.xbsj {
							background-image: url("../../../images/home/partnerLogo/xbsj.webp");
						}
						&.kp {
							background-image: url("../../../images/home/partnerLogo/kp.webp");
						}
						&.ft {
							background-image: url("../../../images/home/partnerLogo/ft.webp");
						}
						&.yfy {
							background-image: url("../../../images/home/partnerLogo/yfy.webp");
						}
						&.hkws {
							background-image: url("../../../images/home/partnerLogo/hkws.webp");
						}
					}
				}

				@media screen and (max-width: 1750px) {
					.partner-item {
						height: 100px;
					}
				}

				@media screen and (max-width: 1600px) {
					flex-wrap: wrap;
					.partner-item {
						min-width:  ~"calc(25% - 10px)";
						height: 120px;
						&:nth-child(n + 5) {
							margin-top: 20px;
						}
					}
				}

				@media screen and (max-width: 1500px) {

				}

				@media screen and (max-width: 1400px) {

				}

				@media screen and (max-width: 1300px) {

				}

				@media screen and (max-width: 1200px) {

				}

				@media screen and (max-width: 768px) {
					.partner-item {
						min-width:  ~"calc(50% - 10px)";
						height: 110px;
						&:nth-child(n + 5) {
							margin-top: 0;
						}
						&:nth-child(n + 3) {
							margin-top: 20px;
						}
					}
				}

				@media screen and (max-width: 450px) {

					.partner-item {
						height: 80px;
					}
				}
			}

			.show-more-button-box {
				padding-top: 10px;
				text-align: center;
				&.hide {
					display: none;
				}
				.show-more-button {
					display: none;
					.oos-button-core(120px, 30px, @xxs-font, #d5def2);
					background-color: #2a3957;
				}
			}
		}

		@media screen and (max-width: 1600px) {
			padding-left: 80px;
			padding-right: 70px;
		}

		@media screen and (max-width: 1600px) {
			.partner-content-wrap {
				.show-more-button-box {
					.show-more-button {
						display: inline-block;
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding-left: 20px;
			padding-right: 10px;
			padding-bottom: 30px;
		}

		@media screen and (max-width: 450px) {
			padding-left: 10px;
			padding-right: 0;
			padding-bottom: 30px;
		}
	}
}
