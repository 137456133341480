.oos-technical-support {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-normal-banner-core();
	}
	.content {
		.technical-support-content {
			position: relative;
			margin: 0 auto;
			padding-top: 60px;
			width: @content-width-1200;
			min-height: 700px;
			.technical-support-table-search {
				position: absolute;
				top: -40px;
				padding: 31px 30px;
				width: 100%;
				height: 80px;
				background: linear-gradient(to bottom, rgba(219, 224, 234, 0.96), rgba(232, 235, 241, 0.96), rgba(253, 253, 253, 0.96));
				backdrop-filter: blur(10px);
				border-radius: 5px;
				.oos-box-shadow(1px, 3px, 5px, 0, rgba(42, 57, 87, 0.2));
				z-index: 1;
				.search-input {
					width: ~"calc(100% - 30px)";
					font-size: @m-font;
					color: #000;
					.oos-letter-spacing(1px);
					background-color: transparent;
					border: none;
					outline: none;
					.oos-placeholder-color();
					&:focus {
						border: none;
					}
				}
				.search-icon {
					position: absolute;
					top: 30px;
					right: 30px;
					display: block;
					width: 20px;
					height: 20px;
					background-image: url("../../images/technicalSupport/search.svg");
					background-size: 100% 100%;
				}
			}
			.technical-support-table-title {
				padding: 0 30px;
				height: 60px;
				line-height: 60px;
				background-color: #f2f2f2;
				border: 1px solid #d8d7d7;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				.oos-clear-fix();
				user-select: none;
				.title-text {
					font-size: @s-font;
				}
				.product-filter-label {
					float: right;
					height: 58px;
					line-height: 58px;
					font-size: @xs-font;
				}
				.product-filter-box {
					float: right;
					margin-top: 11px;
					display: inline-block;
					vertical-align: middle;
					min-width: 138px;
					height: 36px;
					line-height: 36px;
					font-size: @s-font;
					color: #3c3d3c;
					background-color: @white;
					border-radius: 5px;
					cursor: pointer;
					&.product {
						margin-right: 30px;
					}
					&:hover {
						& > .product-filter-item {
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
							.arrow {
								background-image: url("../../images/general/arrow_downward_active.svg");
								transform: rotate(180deg);
							}
						}
						.product-filter-drop-down-box {
							display: block;
						}
					}
					.product-filter-item {
						padding: 0 15px;
						height: 36px;
					}
					& > .product-filter-item {
						position: relative;
						border: 1px solid #c2c2c2;
						border-radius: 5px;
						.arrow {
							position: absolute;
							top: 8px;
							right: 15px;
							display: block;
							width: 12px;
							height: 20px;
							background-image: url("../../images/general/arrow_downward.svg");
							background-size: 100% 100%;
							transition: transform 0.2s linear;
						}
					}
					.product-filter-drop-down-box {
						display: none;
						position: relative;
						background-color: @white;
						z-index: 1;
						.product-filter-item {
							border: 1px solid #c2c2c2;
							border-top: none;
							cursor: pointer;
							&:nth-last-child(1) {
								border-bottom-left-radius: 5px;
								border-bottom-right-radius: 5px;
							}
							&:hover {
								color: @dark-blue;
							}
						}
					}
				}
			}
			.technical-support-table {
				&:nth-last-child(1) {
					.technical-support-table-row {
						&:nth-last-child(1) {
							border-bottom-left-radius: 5px;
							border-bottom-right-radius: 5px;
						}
					}
				}
				.technical-support-table-header {
					padding: 0 30px;
					width: 100%;
					height: 40px;
					font-size: @s-font;
					background-color: #f6f6f6;
					border: 1px solid #d8d7d7;
					border-top: none;
					overflow: hidden; // Enable BFC.
					.header-column {
						float: left;
						height: 40px;
						line-height: 40px;
						text-align: center;
						color: #5f5f5f;
						.oos-letter-spacing(1px);
						&:nth-last-child(1) {
							border-right: none;
						}
						&.document-name {
							width: ~"calc(100% - 200px - 260px)";
							text-align: left;
							&.one-button {
								width: ~"calc(100% - 200px - 120px)";
							}
						}
						&.publish-time {
							width: 200px;
						}
						&.actions {
							width: 260px;
							&.one-button {
								width: 120px;
							}
						}
					}
				}
				.technical-support-table-row {
					padding: 0 30px;
					width: 100%;
					height: 80px;
					font-size: @s-font;
					color: #696969;
					background-color: @white;
					border: 1px solid #d8d7d7;
					border-top: none;
					overflow: hidden; // Enable BFC.
					&:hover {
						background-color: #fbfbfb;
					}
					.row-column  {
						float: left;
						height: 80px;
						line-height: 80px;
						text-align: center;
						&.document-name {
							width: ~"calc(100% - 200px - 260px)";
							text-align: left;
							.oos-text-overflow-ellipsis();
							&.one-button {
								width: ~"calc(100% - 200px - 120px)";
							}
							span {
								&:not(.disabled) {
									&:hover {
										color: @light-blue;
										cursor: pointer;
									}
								}
							}
							.lock-tag {
								margin-right: 5px;
								display: inline-block;
								width: 16px;
								height: 16px;
								vertical-align: -2px;
								background-image: url("../../images/technicalSupport/lock.svg");
								background-size: 100% 100%;
							}
						}
						&.publish-time {
							width: 200px;
						}
						&.actions {
							width: 260px;
							&.one-button {
								width: 120px;
							}
							.view-document-button,
							.download-button,
							.view-image-button {
								.oos-button-core(120px, 36px, @xxs-font, @black);
								display: inline-block;
								vertical-align: middle;
								border: 1px solid #c2c2c2;
								border-radius: 5px;
								background: linear-gradient(to bottom, #fafafa, #f9f9f9, #f8f8f8, #f7f7f7, #f6f6f6, #f5f5f5, #f4f4f4, #f3f3f3);
								cursor: pointer;
								&:hover {
									background-color: @white;
								}
								&.disabled {
									color: @light-gray;
									cursor: not-allowed;
									border: 1px solid #d8d7d7;
								}
							}
							.view-document-button {
								margin-right: 20px;
							}
						}
					}
				}
			}
			.table-empty-placeholder {
				display: inline-block;
				width: 100%;
				height: 60px;
				line-height: 60px;
				font-size: @xxs-font;
				text-align: center;
				color: #696969;
				.oos-letter-spacing(1px);
				border: 1px solid #d8d7d7;
				border-top: none;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1700px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			.technical-support-content {
				width: @content-width-1000;
				.technical-support-table-search {

				}
				.technical-support-table-title {

				}
				.technical-support-table {
					.technical-support-table-header {
						.header-column {
							&.document-name {
								width: ~"calc(100% - 120px - 220px)";
								&.one-button {
									width: ~"calc(100% - 120px - 110px)";
								}
							}
							&.publish-time {
								width: 120px;
							}
							&.actions {
								width: 220px;
								&.one-button {
									width: 110px;
								}
							}
						}
					}
					.technical-support-table-row {
						.row-column {
							&.document-name {
								width: ~"calc(100% - 120px - 220px)";
								&.one-button {
									width: ~"calc(100% - 120px - 110px)";
								}
							}
							&.publish-time {
								width: 120px;
							}
							&.actions {
								width: 220px;
								&.one-button {
									width: 110px;
								}
								.view-document-button,
								.download-button,
								.view-image-button {
									.oos-button-core(100px, 36px, @xxs-font, @black);
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			.technical-support-content {
				margin: 0 auto;
				padding-top: 50px;
				width: 100%;
				.technical-support-table-search {
					top: -35px;
					padding: 25px @content-padding-20;
					height: 70px;
					// In ios or other phone os, the alpha channel is not the same as the PC's.
					// The same value of alpha of rgba in ios looks like more transparent than PC.
					// So, we should increase the value of opacity in phone device.
					background: linear-gradient(to bottom, rgba(219, 224, 234, 0.99), rgba(232, 235, 241, 0.99), rgba(253, 253, 253, 0.99));
					.search-input {
						width: calc(100% - 30px);
					}
					.search-icon {
						top: 25px;
						right: @content-padding-20;
					}
				}
				.technical-support-table-title {
					padding: 0 @content-padding-20;
				}
				.technical-support-table {
					.technical-support-table-header {
						padding: 0 @content-padding-20;
						.header-column {
							&.document-name {
								width: ~"calc(100% - 120px - 100px)";
							}
							&.publish-time {
								width: 120px;
							}
							&.actions {
								width: 100px;
							}
						}
					}
					.technical-support-table-row {
						padding: 0 @content-padding-20;
						height: 60px;
						.row-column {
							height: 60px;
							line-height: 60px;
							&.document-name {
								width: ~"calc(100% - 120px - 100px)";
							}
							&.publish-time {
								width: 120px;
							}
							&.actions {
								width: 100px;
								.view-document-button {
									display: none;
								}
								.download-button,
								.view-image-button {
									.oos-button-core(100px, 36px, @xxs-font, @black);
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.technical-support-content {
				margin: 0 auto;
				padding-top: 40px;
				.technical-support-table-search {
					top: -30px;
					padding: 21px 10px;
					height: 60px;
					.search-input {
						width: calc(100% - 25px);
						font-size: @xs-font;
					}
					.search-icon {
						top: 20px;
						right: 10px;
					}
				}
				.technical-support-table-title {
					padding: 0 10px;
					.product-filter-label {
						font-size: @xxs-font;
					}
					.product-filter-box {
						min-width: 100px;
						font-size: @xxs-font;
						&.product {
							margin-right: 20px;
						}
					}
				}
				.technical-support-table {
					.technical-support-table-header {
						padding: 0 10px;
						height: 30px;
						font-size: @xxs-font;
						.header-column {
							height: 30px;
							line-height: 30px;
							&.document-name {
								width: ~"calc(100% - 30px)";
								&.one-button {
									width: ~"calc(100% - 30px)";
								}
							}
							&.publish-time {
								display: none;
							}
							&.actions {
								width: 30px;
								&.one-button {
									width: 30px;
								}
							}
						}
					}
					.technical-support-table-row {
						padding: 0 10px;
						height: 40px;
						font-size: @xxs-font;
						.row-column {
							height: 40px;
							line-height: 40px;
							&.document-name {
								width: ~"calc(100% - 30px)";
								&.one-button {
									width: ~"calc(100% - 30px)";
								}
								.lock-tag {
									margin-right: 3px;
									width: 12px;
									height: 12px;
									vertical-align: -1px;
								}
							}
							&.publish-time {
								display: none;
							}
							&.actions {
								width: 30px;
								&.one-button {
									width: 30px;
								}
								.download-button,
								.view-image-button {
									.oos-button-core(24px, 24px, @xxs-font, @black);
									border-radius: 100%;
									.button-text {
										display: none;
									}
									.oos-button-download-icon,
									.oos-button-apply-icon {
										margin-left: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
