.oos-news {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-normal-banner-core();
	}
	.content {
		margin: 0 auto;
		width: 1200px;
		.news-box {
			margin-top: 60px;
			padding-bottom: 25px; // Reserve some place for new-item's shadow.
			overflow: hidden;
			.news-item {
				position: relative;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 40px 30px;
				height: 250px;
				transition: transform .2s linear;
				cursor: pointer;
				&::before {
					// Use this pseudo element as border.
					content: " ";
					display: block;
					position: absolute;;
					top: 0;
					left: 30px;
					right: 30px;
					width: ~"calc(100% - 30px - 30px)";
					height: 1px;
					background-color: #d8d7d7;
				}
				&:nth-child(1) {
					&::before {
						content: "";
						display: none;
					}
				}
				&:hover {
					transform: translateY(-1px);
					.oos-box-shadow(0, 10px, 20px, 0, hsla(0, 0%, 71%, .5));
					&::before {
						content: "";
						display: none;
					}
					&+.news-item {
						&::before {
							content: "";
							display: none;
						}
					}
				}
				.cover {
					width: 370px;
					height: 170px;
					background-color: @gray-white;
					background-size: cover;
					border: 1px solid #f2f2f2;
					border-radius: 5px;
				}
				.time {
					width: 220px;
					height: 170px;
					text-align: center;
					.date {
						margin-top: 60px;
						font-size: @xxl-font;
						color: #2b3744;
						.oos-letter-spacing(2px);
					}
					.year {
						padding-top: 10px;
						font-size: @s-font;
						font-weight: 300;
						.oos-letter-spacing(2px);
					}
				}
				.text {
					width: 550px;
					height: 170px;
					.title {
						padding-top: 10px;
						font-size: @l-font;
						color: #2b3744;
						.oos-text-overflow-ellipsis();
					}
					.summary {
						margin-top: 25px;
						line-height: 30px;
						font-size: @s-font;
						color: #959eae;
						.oos-multiple-line-text-overflow-ellipsis(2);
					}
					.view-detail-button {
						.oos-text-button-core(@xxs-font, @xxs-font, #adb4c0);
						float: right;
						display: inline-block;
						margin-top: 20px;
						margin-left: -10px;
					}
				}
			}
			.skeleton-item {
				position: relative;
				padding: 40px 30px;
				display: flex;
				flex-direction: row;
				height: 250px;
				cursor: pointer;
				&::before {
					content: " ";
					display: block;
					position: absolute;;
					top: 0;
					left: 30px;
					right: 30px;
					width: ~"calc(100% - 30px - 30px)";
					height: 1px;
					background-color: #f2f2f2;
				}
				&:nth-child(1) {
					&::before {
						content: "";
						display: none;
					}
				}
				.cover {
					width: 370px;
					height: 170px;
					background-color: @gray-white;
				}
				.time {
					width: 220px;
					height: 170px;
					text-align: center;
					.date {
						margin: 60px auto 0;
						width: 60px;
						height: 27px;
						background-color: @gray-white;
					}
					.year {
						margin: 10px auto 0;
						width: 60px;
						height: 14px;
						background-color: @gray-white;
					}
				}
				.text {
					width: 550px;
					height: 170px;
					.title {
						height: 35px;
						background-color: @gray-white;
					}
					.summary {
						margin-top: 30px;
						height: 70px;
						background-color: @gray-white;
					}
					.view-detail-button {
						margin-top: 10px;
						height: 20px;
						background-color: @gray-white;
					}
				}
			}
		}
		.news-pagination {
			margin-top: 50px;
			height: 30px;
			text-align: center;
			.news-pagination-item {
				display: inline-block;
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				font-size: @m-font;
				color: #2b3744;
				vertical-align: middle;
				cursor: pointer;
				&:hover {
					color: #1855d3;
				}
				&.active {
					color: @white;
					background-color: #1855d3;
					border-radius: 5px;
				}
			}
		}

		@media screen and (max-width: 1200px) {
			width: @content-width-1000;
			.news-box {
				.news-item {
					.cover {
						width: 320px;
					}
					.time {
						width: 150px;
						.date {
							font-size: @xl-font;
						}
					}
					.text {
						width: 470px;
						.title {
							font-size: @m-font;
						}
						.summary {
							font-size: @xs-font;
						}
					}
				}
				.skeleton-item {
					.cover {
						width: 320px;
					}
					.time {
						width: 150px;
					}
					.text {
						width: 470px;
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			width: 100%;
			.news-box {
				padding: 0 @content-padding-20;
				.news-item {
					padding: 30px 0;
					border-bottom: 1px solid #d8d7d7;
					&::before {
						content: "";
						display: none;
					}
					&:hover {
						box-shadow: none;
						transform: none;
						&::before {
							content: "";
							display: none;
						}
					}
					.cover {
						min-width: 248px;
					}
					.time {
						width: 100px;
						max-width: 100px;
						min-width: 100px;
					}
					.text {
						width: 380px;
						max-width: 380px;
						min-width: 380px;
					}
				}
				.skeleton-item {
					padding: 30px 0;
					.cover {
						min-width: 248px;
					}
					.time {
						width: 100px;
						max-width: 100px;
						min-width: 100px;
					}
					.text {
						width: 380px;
						max-width: 380px;
						min-width: 380px;
					}
				}
			}
			.news-pagination {
				margin-top: 60px;
			}
		}

		@media screen and (max-width: 450px) {
			.news-box {
				margin-top: 20px;
				padding: 0 @content-padding-20;
				.news-item {
					padding: @content-padding-20 0;
					flex-direction: column;
					height: auto;
					.cover {
						min-width: 100%;
						width: 100%;
						height: 200px;
					}
					.time {
						min-width: 100%;
						width: 100%;
						height: auto;
						text-align: left;
						.date,
						.year {
							margin-top: 10px;
							display: inline-block;
						}
						.date {
							font-size: @m-font;
						}
						.year {
							padding-left: 10px;
							vertical-align: 1px;
							font-weight: 400;
						}
					}
					.text {
						margin-top: 10px;
						min-width: 100%;
						width: 100%;
						height: auto;
						.title {
							font-size: @s-font;
						}
						.summary {
							margin-top: 10px;
							line-height: @h-font;
						}
						.view-detail-button {
							margin-top: 10px;
						}
					}
				}
				.skeleton-item {
					padding: 20px 0;
					flex-direction: column;
					height: auto;
					.cover {
						min-width: 100%;
						width: 100%;
						height: 200px;
					}
					.time {
						min-width: 100%;
						width: 100%;
						height: auto;
						.date,
						.year {
							margin-top: 10px;
							display: inline-block;
							height: 15px;
						}
						.year {
							margin-left: 10px;
						}
					}
					.text {
						margin-top: 10px;
						min-width: 100%;
						width: 100%;
						height: auto;
						.summary {
							margin-top: 10px;
						}
					}
				}
			}
			.news-pagination {
				margin-top: 30px;
			}
		}
	}
}
