.oos-news-detail {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-normal-banner-core();
	}
	.content {
		margin: 0 auto;
		padding-top: 75px;
		width: @content-width-1200;
		min-height: 1100px; // Consider to right side bar height and padding content.
		.oos-clear-fix();
		.news-item {
			float: left;
			margin-bottom: 65px;
			padding-right: 120px;
			width: 900px;
			.title {
				font-size: 26px;
			}
			.time {
				margin-top: 10px;
				font-size: @xxs-font;
				color: #959eae;
				.oos-letter-spacing(1px);
				.view-count {
					margin-left: 30px;
				}
			}
			.news-content {
				margin-top: 35px;
				white-space: pre-wrap;
				// Fix rich text content style.
				& > p {
					display: block !important;
					min-height: 22px;
				}
				& img {
					max-width: 100% !important;
					border-radius: 5px !important;
				}
			}
		}
		.skeleton-news-item {
			float: left;
			margin-bottom: 65px;
			padding-right: 120px;
			width: 900px;
			.title {
				height: 40px;
				background-color: @gray-white;
			}
			.time {
				margin-top: 15px;
				width: 290px;
				height: 12px;
				background-color: @gray-white;
			}
			.news-content {
				margin-top: 35px;
				height: 900px;
				background-color: @gray-white;
			}
		}
		.right-side-bar {
			float: right;
			padding-top: 5px;
			width: 300px;
			.hot-product-img {
				height: 150px;
				background-image: url("../../images/product/hot_product.webp");
				background-size: 100% 100%;
				border-radius: 5px;
				cursor: pointer;
			}
			.last-new {
				margin-top: 55px;
				&.fixed {
					position: fixed;
					top: 20px;
					right: ~"calc((100vw - 1200px) / 2)";
					margin-top: 0;
					width: 300px;
				}
				.last-news-title {
					position: relative;
					padding-bottom: 15px;
					font-size: @l-font;
					color: #2b3744;
					border-bottom: 1px solid #f2f2f2;
					&::before {
						content: " ";
						display: block;
						position: absolute;
						left: 0;
						bottom: -1px;
						width: 50%;
						height: 1px;
						background-color: #1855d3;
					}
				}
				.last-news-box {
					margin-top: 25px;
					.last-news-item {
						position: relative;
						padding: 15px 0;
						height: 100px;
						overflow: hidden; // Enable BFC.
						cursor: pointer;
						transition: all .2s;
						&::before {
							content: " ";
							display: block;
							position: absolute;
							left: 0;
							bottom: 0;
							width: 0;
							height: 1px;
							background-color: #1855d3;
							transition: width .2s;
						}
						&:hover {
							transform: scale(1.02, 1.02);
							&::before {
								width: 100%;
							}
						}
						.cover {
							float: left;
							width: 100px;
							height: 70px;
							border-radius: 5px;
						}
						.text-content {
							float: right;
							width: 185px;
							.title {
								font-size: @m-font;
								color: #2b3744;
								.oos-multiple-line-text-overflow-ellipsis(2);
							}
							.time {
								margin-top: 10px;
								font-size: @xxs-font;
								color: #959eae;
								.oos-letter-spacing(1px);
							}
						}
					}
				}
			}
		}
		.skeleton-right-side-bar {
			float: right;
			padding-top: 5px;
			width: 300px;
			.hot-product-img {
				height: 150px;
				background-color: @gray-white;
			}
			.last-new {
				margin-top: 55px;
				&.fixed {
					position: fixed;
					top: 20px;
					right: ~"calc((100vw - 1200px) / 2)";
					margin-top: 0;
					width: 300px;
				}
				.last-news-title {
					position: relative;
					padding-bottom: 15px;
					height: 27px;
					background-color: @gray-white;
					border-bottom: 1px solid @gray-white;
				}
				.last-news-box {
					margin-top: 25px;
					.last-news-item {
						position: relative;
						padding: 15px 0;
						height: 100px;
						overflow: hidden; // Enable BFC.
						.cover {
							float: left;
							width: 100px;
							height: 70px;
							background-color: @gray-white;
						}
						.text-content {
							float: right;
							width: 185px;
							.title {
								height: 45px;
								background-color: @gray-white;
							}
							.time {
								margin-top: 10px;
								height: 16px;
								background-color: @gray-white;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			width: 100%;
			min-height: 800px;
			.news-item {
				padding-right: 0;
				width: 100%;
				.title {
					margin-top: 30px;
					font-size: @xxl-font;
				}
				.time {
					margin-top: 5px;
					font-size: @xs-font;
				}
				.news-content {
					margin-top: 20px;
					// Fix rich text content style.
					& > p {
						min-height: @m-font;
					}
				}
			}
			.skeleton-news-item {
				padding-right: 0;
				width: 100%;
				.title {
					margin-top: 30px;
				}
				.time {
					margin-top: 10px;
				}
				.news-content {
					margin-top: 20px;
					// Fix rich text content style.
					& > p {
						min-height: @m-font;
					}
				}
			}
			.right-side-bar,
			.skeleton-right-side-bar {
				display: none;
			}
		}

		@media screen and (max-width: 450px) {
			.news-item {
				.title {
					margin-top: 20px;
					font-size: @l-font;
				}
				.time {
					margin-top: 5px;
					font-size: @xxs-font;
				}
				.news-content {
					// Fix rich text content style.
					& > p {
						min-height: @m-font;
					}
				}
			}
			.skeleton-news-item {

			}
		}
	}
}
