.anim-and-static-content () {
	.product-banner-anim-base-img {
		position: absolute;
		// The values of top and right are determined in using page's less file.
		// top: 0;
		// right: 0;
		width: 998px;
		height: 549px;
		background-image: url("../../images/productBannerAnim/base.webp");
		background-size: 100% 100%;
		overflow: hidden;
		z-index: 0;
		i {
			display: block !important;
		}
		.banner-light-curtain {
			position: absolute;
			z-index: 2;
			&.big {
				top: 151px;
				right: 354px;
				width: 135px;
				height: 172px;
				background-image: url("../../images/productBannerAnim/light_curtain_big.webp");
				background-size: 100% 100%;
				animation: oos-product-banner-curtain-fade-in-and-out 5s linear infinite;
			}
			&.small {
				top: 131px;
				right: 126px;
				width: 13px;
				height: 42px;
				background-image: url("../../images/productBannerAnim/light_curtain_small.webp");
				background-size: 100% 100%;
				animation: oos-product-banner-curtain-fade-in-and-out 2.5s linear infinite;
			}
		}
		.banner-conveyor-belt-base {
			position: absolute;
			top: 299px;
			right: 408px;
			width: 426px;
			height: 218px;
			background-image: url("../../images/productBannerAnim/conveyor_belt_base.webp");
			background-size: 100% 100%;
			z-index: 1;
		}
		.banner-scenario {
			position: absolute;
			background-size: 100% 100%;
			z-index: 2;
			&.uniio {
				top: 273px;
				right: 586px;
				width: 235px;
				height: 143px;
				background-image: url("../../images/productBannerAnim/uniio/scenario.webp");
			}
			&.orcabd {
				top: 250px;
				right: 586px;
				width: 235px;
				height: 167px;
				background-image: url("../../images/productBannerAnim/orcabd/scenario.webp");
			}
			&.orcafs {
				top: 253px;
				right: 576px;
				width: 258px;
				height: 168px;
				background-image: url("../../images/productBannerAnim/orcafs/scenario.webp");
			}
		}
		.banner-application {
			position: absolute;
			animation: oos-product-banner-application-motion 10s linear infinite;
			background-size: 100% 100%;
			z-index: 2;
			&.uniio {
				top: 217px;
				right: 390px;
				width: 55px;
				height: 62px;
				background-image: url("../../images/productBannerAnim/uniio/application.webp");
			}
			&.orcabd {
				top: 200px;
				right: 385px;
				width: 71px;
				height: 79px;
				background-image: url("../../images/productBannerAnim/orcabd/application.webp");
			}
			&.orcafs {
				top: 170px;
				right: 380px;
				width: 82px;
				height: 119px;
				background-image: url("../../images/productBannerAnim/orcafs/application.webp");
			}
		}
		.banner-conveyor-belt-stuff {
			position: absolute;
			top: 467px;
			right: 432px;
			width: 48px;
			height: 38px;
			background-image: url("../../images/productBannerAnim/conveyor_belt_stuff.webp");
			background-size: 100% 100%;
			animation: oos-product-banner-conveyor-belt-stuff-motion 8s ease-out infinite;
			z-index: 3;
		}
		.banner-name {
			position: absolute;
			background-size: 100% 100%;
			z-index: 5;
			&.uniio {
				top: 365px;
				right: 320px;
				width: 103px;
				height: 81px;
				background-image: url("../../images/productBannerAnim/uniio/name.webp");
			}
			&.orcabd {
				top: 365px;
				right: 318px;
				width: 99px;
				height: 77px;
				background-image: url("../../images/productBannerAnim/orcabd/name.webp");
			}
			&.orcafs {
				top: 365px;
				right: 324px;
				width: 92px;
				height: 76px;
				background-image: url("../../images/productBannerAnim/orcafs/name.webp");
			}
		}
		.banner-data-process {
			position: absolute;
			top: 356px;
			right: 183px;
			width: 341px;
			height: 270px;
			background-image: url("../../images/productBannerAnim/data_process.webp");
			background-size: 100% 100%;
			z-index: 4;
		}
		.banner-light-bar-box {
			position: absolute;
			top: 431px;
			right: 710px;
			transform: skewY(28deg);
			z-index: 2;
			.light-bar {
				position: absolute;
				width: 20px;
				height: 5px;
				background-color: #384473;
				overflow: hidden;
				&::before {
					content: " ";
					position: absolute;
					display: block;
					width: 6px;
					height: 5px;
					background-color: #4d598b;
				}
				&:nth-child(1) {
					&::before {
						animation: oos-product-banner-light-bar-thumb-left-right-motion 1s linear infinite;
					}
				}
				&:nth-child(2) {
					top: -9px;
					left: 0;
					width: 20px;
					height: 5px;
					&::before {
						animation: oos-product-banner-light-bar-thumb-right-left-motion 1s linear 1.7s infinite;
					}
				}
			}
		}
		.banner-light-ray-box {
			position: absolute;
			z-index: 0;
			.light-ray {
				position: absolute;
				top: 0;
				width: 12px;
				height: 6px;
				background-color: #49f3ff;
				border-radius: 6px;
				backdrop-filter: blur(10px);
				.oos-box-shadow(0, 0, 2px, 2px, rgba(73, 243, 255, 0.2));
				// filter: blur(1px);
			}
			&.left {
				top: 315px;
				right: 485px;
				transform: skewY(-26deg);
				.light-ray {
					animation: oos-product-banner-light-ray-left-motion 2.33s ease-out infinite;
					&:nth-child(1) {
						animation-delay: 0.3s;
					}
					&:nth-child(2) {
						top: 9px;
						left: 9px;
						animation-delay: 1.1s;
					}
					&:nth-child(3) {
						top: 18px;
						left: 18px;
						animation-delay: 2s;
					}
					&:nth-child(4) {
						top: 27px;
						left: 27px;
						animation-delay: 0.7s;
					}
					&:nth-child(5) {
						top: 37px;
						left: 38px;
						animation-delay: 1.8s;
					}
				}
			}
			&.right {
				top: 150px;
				right: 246px;
				transform: skewY(-23deg);
				.light-ray {
					animation: oos-product-banner-light-ray-right-motion 3.72s ease-out infinite;
					&:nth-child(1) {
						animation-delay: 2s;
					}
					&:nth-child(2) {
						top: 9px;
						left: 9px;
						animation-delay: 0.3s;
					}
					&:nth-child(3) {
						top: 18px;
						left: 18px;
						animation-delay: 1.7s;
					}
					&:nth-child(4) {
						top: 27px;
						left: 27px;
						animation-delay: 1.1s;
					}
					&:nth-child(5) {
						top: 37px;
						left: 38px;
						animation-delay: 0.8s;
					}
				}
			}
		}
		.banner-light-point-small {
			position: absolute;
			width: 3px;
			height: 9px;
			top: 139px;
			right: 94px;
			border-radius: 3px;
			background-color: @white;
			transform: skewY(-28deg);
			animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.2s infinite;
			z-index: 2;
			&::before,
			&::after {
				content: " ";
				position: absolute;
				width: 3px;
				height: 9px;
				background-color: @white;
				border-radius: 3px;
			}
			&::before {
				top: 0;
				left: -6px;
				animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.4s infinite;
			}
			&::after {
				top: 0;
				left: -12px;

				animation: oos-normal-banner-light-point-fade-in-and-out 2.5s linear 0.6s infinite;
			}
		}
		.banner-float-img {
			position: absolute;
			background-size: 100% 100%;
			z-index: 1;
			& > i {
				position: absolute;
				background-size: 100% 100%;
			}
			&.uniio {
				top: 118px;
				right: 421px;
				width: 304px;
				height: 220px;
				background-image: url("../../images/productBannerAnim/uniio/float_base.webp");
			}
			&.orcabd {
				top: 112px;
				right: 427px;
				width: 285px;
				height: 224px;
				background-image: url("../../images/productBannerAnim/orcabd/float_base.webp");
			}
			&.orcafs {
				top: 104px;
				right: 427px;
				width: 285px;
				height: 202px;
				background-image: url("../../images/productBannerAnim/orcafs/float_base.webp");
			}
		}
	}
	.banner-point-bg {
		position: absolute;
		top: 0;
		right: 0;
		width: 1735px;
		height: 480px;
		background-image: url("../../images/productBannerAnim/point_bg.webp");
		background-size: 100% 100%;
		z-index: -1;
	}

	// Static image for phone.
	.product-banner-static-img-pad-ph {
		display: none;
		width: 268px;
		min-width: 268px;
		height: 156px;
		background-size: 100% 100%;
		&.uniio {
			background-image: url("../../images/product/uniio/banner_static_img.webp");
		}
		&.orcabd {
			background-image: url("../../images/product/orcabd/banner_static_img.webp");
		}
		&.orcafs {
			background-image: url("../../images/product/orcafs/banner_static_img.webp");
		}
	}
}

// For dashboard banner.
.banner {
	.banner-carousel-wrap {
		.carousel {
			.carousel-item {
				.anim-and-static-content();
			}
		}
	}
}

// For product banner.
.banner {
	.banner-content {
		.anim-and-static-content();
	}
}
