.oos-product {
	.oos-page-content-bottom-margin();
	.banner {
		.oos-product-banner-core();
	}
	.section-nav {
		.oos-section-nav-core();
	}
	.section-title {
		.oos-section-title-core();
	}
	.introduction {
		background-color: #f2f5f8;
		.introduction-content-wrap {
			margin: 0 auto;
			width: @content-width-1600;
			.overview-content {
				padding: 40px 40px 0;
				.name {
					font-size: 30px;
					font-weight: 600;
					color: #545c6c;
				}
				.desc-box {
					margin-top: 10px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					.desc-item {
						width: 720px;
						line-height: @h-font;
						font-size: @xxs-font;
						color: #545c6c;
						.oos-letter-spacing(2px);
					}
				}
				.scenario-box {
					margin-top: 12px;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-content: space-between;
					.scenario-item {
						margin-top: 10px;
						margin-right: 10px;
						display: inline-block;
						padding: 0 25px;
						height: 30px;
						line-height: 30px;
						font-size: @xxs-font;
						color: #545c6c;
						.oos-letter-spacing(1px);
						border: 1px solid #d2d7e2;
						border-radius: 3px;
					}
				}
				.button-box {
					margin-top: 20px;
					display: flex;
					flex-direction: row;
					.apply-trial-button,
					.view-document-button {
						.oos-button-core(120px, 30px, @xxs-font, #d5def2);
						background-color: #2a3957;
					}
					.apply-trial-button {
						margin-right: 10px;
					}
				}
			}
			.feature-box {
				margin: 0 auto;
				padding: 40px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				align-content: space-between;
				width: 100%;
				min-height: 160px * 2 + 20px + 80px;
				&.uniio {
					min-height: 160px * 3 + 20px + 80px;
				}
				.feature-item {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding: 25px;
					width: 490px;
					height: 160px;
					background-color: @white;
					border: 1px solid @white;
					border-radius: 5px;
					cursor: default;
					transition: box-shadow .3s linear;
					&.placeholder {
						visibility: hidden;
					}
					&:hover {
						background: linear-gradient(to bottom, @white, @white);
						.oos-box-shadow(1px, 6px, 5px, 0, rgba(116, 130, 166, 0.3));
					}
					.feature-icon {
						display: block;
					}
					.feature-text-content {
						margin-left: 10px;
						width: ~"calc(100% - 60px - 10px)";
						.title {
							font-size: @xl-font;
							font-weight: 600;
							color: #2e394c;
							.oos-letter-spacing(1px);
							.oos-text-overflow-ellipsis();
						}
						.desc {
							margin-top: 2px;
							font-size: @xxs-font;
							color: #a6acb7;
							.oos-multiple-line-text-overflow-ellipsis(4);
						}
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {
			.introduction-content-wrap {
				width: @content-width-1600;
				.overview-content {
					padding: 40px 0 0;
					.desc-box {
						.desc-item {
							width: 740px;
						}
					}
				}
				.feature-box {
					padding: 40px 0;
				}
			}
		}

		@media screen and (max-width: 1700px) {
			.introduction-content-wrap {
				width: @content-width-1400;
				.overview-content {
					.desc-box {
						.desc-item {
							width: 685px;
						}
					}
				}
				.feature-box {
					.feature-item {
						width: 450px;
					}
				}
			}
		}

		@media screen and (max-width: 1400px) {
			.introduction-content-wrap {
				width: @content-width-1200;
				.overview-content {
					padding: 40px 0 0;
					.name {
						font-size: 24px;
					}
					.desc-box {
						.desc-item {
							width: 580px;
							line-height: @xh-font;
						}
					}
				}
				.feature-box {
					min-height: 150px * 2 + 15px + 80px;
					&.uniio {
						min-height: 150px * 3 + 15px + 80px;
					}
					.feature-item {
						padding: 15px 10px;
						width: 390px;
						height: 150px;
						.feature-text-content {
							.title {
								font-size: @m-font;
							}
							.desc {
								.oos-multiple-line-text-overflow-ellipsis(5);
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.introduction-content-wrap {
				width: @content-width-1000;
				.overview-content {
					.name {
						font-size: 22px;
					}
					.desc-box {
						.desc-item {
							width: 490px;
						}
					}
				}
				.feature-box {
					.feature-item {
						padding: 10px;
						width: 325px;
						.feature-text-content {
							margin-left: 0;
							width: ~"calc(100% - 60px)";
							.title {
								font-size: @s-font;
							}
							.desc {
								.oos-multiple-line-text-overflow-ellipsis(6);
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			.introduction-content-wrap {
				padding: 0 @content-padding-20;
				width: 100%;
				.overview-content {
					.name {
						text-align: center;
					}
					.desc-box {
						flex-direction: column;
						.desc-item {
							width: 100%;
						}
					}
					.button-box {
						justify-content: center;
						.apply-trial-button {
							margin-right: 30px;
						}
					}
				}
				.feature-box {
					padding: 30px 0;
					min-height: 150px * 3 + 15px + 80px;
					&.uniio {
						min-height: 150px * 3 + 15px + 80px;
					}
					.feature-item {
						padding: 15px 10px;
						width: 355px;
						height: 150px;
						.feature-text-content {
							.title {
								font-size: @m-font;
							}
							.desc {
								.oos-multiple-line-text-overflow-ellipsis(6);
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 760px) {
			.introduction-content-wrap {
				.feature-box {
					min-height: 130px * 6 + 75px + 40px;
					&.uniio {
						min-height: 130px * 8 + 75px + 40px
					}
					.feature-item {
						width: 100%;
						height: 130px;
						&.placeholder {
							display: none;
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.introduction-content-wrap {
				.overview-content {
					padding-top: @content-padding-20;
					.name {
						font-size: @l-font;
					}
					.desc-box {
						.desc-item {
							line-height: @h-font;
							text-align: center;
							font-size: @xxs-font;
						}
					}
					.scenario-box {
						.scenario-item {
							padding: 4px 14px;
							line-height: @xxl-font;
						}
					}
				}
				.feature-box {
					min-height: 150px * 6 + 75px + 40px;
					&.uniio {
						min-height: 150px * 8 + 75px + 40px;
					}
					padding: 20px 0;
					flex-direction: column;
					.feature-item {
						width: 100%;
						height: 150px;
						.feature-text-content {
							.title {
								font-size: @s-font;
							}
						}
					}
				}
			}
		}
	}
	.scenario {
		.scenario-content-wrap {
			margin: 0 auto;
			padding: 0 40px;
			width: @content-width-1600;
			.scenario-box {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				&:nth-child(n + 2) {
					margin-top: 35px;
				}
				.scenario-item {
					display: flex;
					flex-direction: row;
					padding: 30px;
					width: ~"calc(50% - 30px)";
					min-height: 220px;
					background-color: #f4f6f9;
					border-radius: 5px;
					.oos-box-shadow(1px, 5px, 5px, 0, rgba(116, 130, 166, 0.1));
					.scenario-icon {
						margin-top: 10px;
						display: block;
						width: 80px;
						height: 80px;
						background-size: 100% 100%;
						// UniIO.
						&.virtualization {
							background-image: url("../../images/product/uniio/virtualization.webp");
						}
						&.cloud-computing {
							background-image: url("../../images/product/uniio/cloud_computing.webp");
						}
						&.database {
							background-image: url("../../images/product/uniio/database.webp");
						}
						&.private-cloud {
							background-image: url("../../images/product/uniio/private_cloud.webp");
						}
						&.five-g {
							background-image: url("../../images/product/uniio/five_g.webp");
						}
						&.container-cloud {
							background-image: url("../../images/product/uniio/cloud_computing.webp");
						}
						// OrcaFS.
						&.high-performance-computing {
							background-image: url("../../images/product/orcafs/high_performance_computing.webp");
						}
						&.big-data-analysis {
							background-image: url("../../images/product/orcafs/big_data_analysis.webp");
						}
						&.non-linear-editing {
							background-image: url("../../images/product/orcafs/non_linear_editing.webp");
						}
						&.medical-imaging {
							background-image: url("../../images/product/orcafs/medical_imaging.webp");
						}
						// OrcaBD.
						&.video-monitor {
							background-image: url("../../images/product/orcabd/video_monitor.webp");
						}
						&.hybrid-cloud-storage {
							background-image: url("../../images/product/orcabd/hybrid_cloud_storage.webp");
						}
						&.data-archiving {
							background-image: url("../../images/product/orcabd/data_archiving.webp");
						}
						&.disaster-recovery-backup {
							background-image: url("../../images/product/orcabd/disaster_recovery_backup.webp");
						}
					}
					.scenario-text-content {
						margin-left: 20px;
						width: ~"calc(100% - 100px - 20px)";
						.title {
							font-size: @xxl-font;
							font-weight: 600;
							color: #2e394c;
							.oos-letter-spacing(2px);
						}
						.desc {
							margin-top: 5px;
							line-height: @xxl-font;
							font-size: @xxs-font;
							color: #2b3744;
							.oos-letter-spacing(1px);
						}
						.ability-box {
							margin-top: 10px;
							.ability-item {
								margin-top: 5px;
								position: relative;
								padding-left: 15px;
								line-height: @xl-font;
								font-size: @xxs-font;
								color: #54616e;
								.oos-letter-spacing(1px);
								&::before {
									content: " ";
									position: absolute;
									top: 4px;
									left: 0;
									display: block;
									width: 10px;
									height: 10px;
									background-color: #3392ff;
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {
			.scenario-content-wrap {
				padding: 0;
			}
		}

		@media screen and (max-width: 1700px) {
			.scenario-content-wrap {
				width: @content-width-1400;
				.scenario-box {
					&:nth-child(n + 2) {
						margin-top: 30px;
					}
					.scenario-item {
						padding: @content-padding-20;
						width: ~"calc(50% - 20px)";
						.scenario-text-content {
							.title {
								font-size: @xl-font;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1400px) {
			.scenario-content-wrap {
				width: @content-width-1200;
				.scenario-box {
					&:nth-child(n + 2) {
						margin-top: 25px;
					}
					.scenario-item {
						width: ~"calc(50% - 15px)";
						.scenario-text-content {
							.title {
								font-size: @l-font;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.scenario-content-wrap {
				width: @content-width-1000;
				.scenario-box {
					&:nth-child(n + 2) {
						margin-top: 20px;
					}
					.scenario-item {
						padding: 20px 15px;
						width: ~"calc(50% - 10px)";
						.scenario-text-content {
							.title {
								font-size: @m-font;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			.scenario-content-wrap {
				padding: 0 20px;
				width: 100%;
				.scenario-box {
					flex-direction: column;
					&:nth-child(n + 2) {
						margin-top: 20px;
					}
					.scenario-item {
						width: 100%;
						&:nth-child(n + 2) {
							margin-top: 20px;
						}
						.scenario-text-content {
							.title {
								font-size: @xl-font;
							}
							.ability-box {
								.ability-item {
									line-height: @xl-font;
									font-size: @xxs-font;
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.scenario-content-wrap {
				.scenario-box {
					.scenario-item {
						flex-direction: column;
						.scenario-icon {
							margin: 0 auto;
						}
						.scenario-text-content {
							margin-top: 20px;
							margin-left: 0;
							width: 100%;
							.title {
								text-align: center;
								font-size: @m-font;
							}
							.desc {
								margin-top: 10px;
								font-size: @xs-font;
								line-height: @xxl-font;
							}
							.ability-box {
								.ability-item {
									margin-top: 10px;
									line-height: @xl-font;
									font-size: @xxs-font;
								}
							}
						}
					}
				}
			}
		}
	}
	.specification {
		.specification-img {
			margin: 0 auto;
			display: block;
			width: @content-width-1600;
		}

		@media screen and (max-width: 1800px) {
			.specification-img {
				width: @content-width-1600;
			}
		}

		@media screen and (max-width: 1600px) {
			.specification-img {
				width: @content-width-1400;
			}
		}

		@media screen and (max-width: 1400px) {
			.specification-img {
				width: @content-width-1200;
			}
		}

		@media screen and (max-width: 1200px) {
			.specification-img {
				width: @content-width-1000;
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			.specification-img {
				width: 100%;
			}
		}
	}
	.resource {
		.resource-box {
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: @content-width-1600;
			.resource-item {
				position: relative;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 0 40px;
				width: ~"calc((100% - 60px - 60px) / 3)";
				height: 150px;
				background: linear-gradient(to bottom, #f0f6ff, @white);
				border: 1px solid @white;
				border-radius: 5px;
				.oos-box-shadow(1px, 2px, 7px, 0, rgba(116, 130, 166, 0.2));
				cursor: pointer;
				transition: box-shadow .3s linear;
				&:hover {
					background: linear-gradient(to bottom, @white, @white);
					.oos-box-shadow(0, 0, 5px, 0, rgba(116, 130, 166, 0.3));
					.resource-action-icon {
						&.download {
							transform: translateY(5px);
						}
						&.forward {
							transform: translateX(5px);
						}
					}
				}
				.resource-icon {
					display: block;
					width: 72px;
					height: 95px;
					background-image: url("../../images/product/resource.webp");
					background-size: 100% 100%;
				}
				.resource-text-content {
					width: ~"calc(100% - 72px - 25px)";
					.resource-title {
						font-size: @xxh-font;
						color: #2b3744;
					}
					.resource-desc {
						margin-top: 10px;
						font-size: @m-font;
						color: #5d616b;
					}
				}
				.resource-action-icon {
					position: absolute;
					right: 40px;
					display: block;
					transition: transform 0.2s linear;
					&.download {
						top: (150px - 21px) ./ 2;
						width: 24px;
						height: 21px;
						background-image: url("../../images/product/download.webp");
						background-size: 100% 100%;
					}
					&.forward {
						top: (150px - 23px) ./ 2;
						width: 14px;
						height: 23px;
						background-image: url("../../images/product/forward.webp");
						background-size: 100% 100%;
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {
			.resource-box {
				width: @content-width-1600;
			}
		}

		@media screen and (max-width: 1700px) {
			.resource-box {
				width: @content-width-1400;
			}
		}

		@media screen and (max-width: 1400px) {
			.resource-box {
				width: @content-width-1200;
				.resource-item {
					.resource-text-content {
						.resource-title {
							font-size: @xxl-font;
						}
						.resource-desc {
							font-size: @s-font;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1200px) {
			.resource-box {
				width: @content-width-1000;
				.resource-item {
					padding: 0 @content-padding-20;
					.resource-action-icon {
						right: @content-padding-20;
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			padding: 0 @content-padding-20;
			.resource-box {
				flex-direction: column;
				align-items: center;
				width: 100%;
				.resource-item {
					padding: 0 40px;
					width: 500px;
					&:nth-child(n + 2) {
						margin-top: 20px;
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			padding: 0 @content-padding-20;
			.resource-box {
				.resource-item {
					width: 100%;
					height: 100px;
					&:nth-child(n + 2) {
						margin-top: 20px;
					}
					&:hover {
						.resource-action-icon {
							&.download {
								transform: none;
							}
							&.forward {
								transform: none;
							}
						}
					}
					.resource-icon {
						width: 36px;
						height: 47.5px;
					}
					.resource-text-content {
						width: ~"calc(100% - 36px - 25px)";
						.resource-title {
							font-size: @m-font;
						}
						.resource-desc {
							font-size: @xs-font;
						}
					}
					.resource-action-icon {
						transition: none;
						&.download {
							top: (100px - 21px) ./ 2;
						}
						&.forward {
							top: (100px - 23px) ./ 2;
						}
					}
				}
			}
		}
	}
	.case {
		.case-box {
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			width: @content-width-1200;
			.case-item {
				padding: 35px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				background-color: #f4f6f9;
				border-radius: 5px;
				.text-content {
					width: ~"calc(100% - 50px - 500px)";
					&.one-row {
						width: 100%;
					}
					.title {
						font-size: @xxl-font;
						color: #2b3744;
						.oos-letter-spacing(1px);
					}
					.introduction {
						padding-top: 10px;
						font-size: @s-font;
						line-height: @xh-font;
						color: #959eae;
					}
					.point-content {
						padding-top: 10px;
						.point {
							position: relative;
							padding-bottom: 10px;
							font-size: @s-font;
							color: #2e394c;
							&:not(.no-symbol) {
								padding-left: 16px;
								&::before {
									content: " ";
									position: absolute;
									top: 5px;
									left: 0;
									width: 8px;
									height: 8px;
									background-color: #2e394c;
									border-radius: 100%;
								}
							}
							&:nth-last-child(1) {
								padding-bottom: 0;
							}
						}
					}
				}
				.img {
					width: 500px;
					border-radius: 5px;
				}
			}
		}

		@media screen and (max-width: 1800px) {

		}

		@media screen and (max-width: 1600px) {

		}

		@media screen and (max-width: 1400px) {

		}

		@media screen and (max-width: 1200px) {
			.case-box {
				width: @content-width-1000;
				.case-item {
					.text-content {
						width: ~"calc(100% - 50px - 400px)";
					}
					.img {
						width: 400px;
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			.case-box {
				padding: 0 @content-padding-20;
				width: 100%;
				.case-item {
					padding: @content-padding-20;
					flex-direction: column;
					.text-content {
						width: 100%;
						.title {
							text-align: center;
							font-size: @m-font;
						}
						.introduction {
							font-size: @xxs-font;
						}
						.point-content {
							.point {
								font-size: @xxs-font;
							}
						}
					}
					.img {
						margin-top: 30px;
						width: 500px;
					}
				}
			}
		}

		@media screen and (max-width: 450px) {
			.case-box {
				.case-item {
					.img {
						width: 100%;
					}
				}
			}
		}
	}
}
